import React, { ReactNode } from 'react'
import { ThemeProvider } from 'styled-components/macro'

import { themeBuilder } from './themeByPalette'
import { ThemeBuilderOptions } from './types'

export type StyleContextProps = {
  theme?: ThemeBuilderOptions
  children: ReactNode
}

export const StylesContext = ({ children, theme }: StyleContextProps) => (
  <ThemeProvider theme={themeBuilder(theme)}>{children}</ThemeProvider>
)
