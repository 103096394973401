import React from 'react'
import { useTranslation } from 'react-i18next'

import IProvider from '@models/IProvider'
import { checkCircleSolid } from '@assets/icons'
import { timesCircleSolid } from '@assets/icons'
import { useViewport } from '@hooks'
import { LoginType } from '@pages/Login/models'
import { Ellipsis } from '@components'
import {
  Root,
  Loader,
  SuccessIcon,
  MessagesContainer,
  Message,
  SubMessage,
  FailureIcon,
} from './styles'
import { LoginStatus } from '@models/loginStatus'

export interface ILoginViewProps {
  /**
   * View will vary depending on whether it is "Standard" or "Mfa" (after a challenge)
   */
  type: LoginType
  /**
   * The selected provider
   */
  provider: IProvider
  /**
   * Set to true when login is successful
   */
  success: boolean
  /**
   * Set to true after a long wait
   */
  isLongWait: boolean
  /**
   * The client's name
   */
  clientName: string
  /**
   * Login Status
   */
  status: LoginStatus
}

/**
 * This view is used on the login page. It is composed of a load spinner with
 * the provider's logo inside, a message, and a submessage. The view can vary
 * depending on which type of login it is, whether the user has been waiting
 * for a while, or whether the login is successful.
 */
const LoginView = ({
  type,
  provider,
  success,
  isLongWait,
  clientName,
  status,
}: ILoginViewProps) => {
  const { t } = useTranslation('v2_login')
  const { isMobile } = useViewport()
  const loaderSize = isMobile ? 40 : 58

  const getMessage = () => {
    if (success) {
      return t('success')
    } else if (status === LoginStatus.FailedErrorCode) {
      return t('failed_login')
    } else {
      if (type === 'Mfa') {
        return t('verifying')
      }
      return t('connecting')
    }
  }

  const getSubMessage = () => {
    const redirectName = type === 'Standard' ? provider.display_name : clientName
    if (success) {
      return t('redirect_message', { redirectName })
    }
    if (status === LoginStatus.FailedErrorCode) {
      if (type === 'Interactive') {
        return t('authentication_declined_interactive', { clientName })
      }
      return t('authentication_declined', { clientName })
    }
    if (isLongWait) {
      return t('longer_than_expected')
    }
    if (type === 'Mfa') {
      return t('verify_answer')
    }
    return undefined
  }

  const renderLoader = () =>
    !success && (
      <Loader
        thickness={isMobile ? 'small' : 'medium'}
        size={isMobile ? 'small' : 'medium'}
        Spinner={{
          color: provider.main_bg_color,
        }}
      >
        <img src={provider.icon_url} alt="Provider Logo" />
      </Loader>
    )

  const renderIcon = () =>
    success ? (
      <SuccessIcon
        icon={checkCircleSolid}
        color={provider.main_bg_color}
        dimension={loaderSize}
        show={success}
      />
    ) : (
      status === LoginStatus.FailedErrorCode && (
        <FailureIcon
          data-view="login-with-failed-errorcode"
          icon={timesCircleSolid}
          color={provider.main_bg_color}
          dimension={loaderSize}
        />
      )
    )

  const renderMessage = () => (
    <Message>
      <div>{getMessage()}</div>
      {!success && <Ellipsis size={3} gap={3} marginBottom={4} animated />}
    </Message>
  )

  const renderSubMessage = () => (
    <SubMessage hide={!success && !isLongWait && type === 'Standard'}>{getSubMessage()}</SubMessage>
  )

  return (
    <Root>
      {renderLoader()}
      {renderIcon()}
      <MessagesContainer>
        {renderMessage()}
        {renderSubMessage()}
      </MessagesContainer>
    </Root>
  )
}

export default LoginView
