/* eslint-disable @typescript-eslint/no-explicit-any */
import IProvider from '../models/IProvider'
import { getBranchListData, stepIsBranchSelection } from './branches'
// import { ILoginCsMockResponse, ILoginResponse } from '../models/IResponse';

/*
 * Check if the provider is cs-mock
 */

export function isCsMock(provider: IProvider): boolean {
  const { connector_id, provider_id } = provider
  return connector_id === 'mock' || provider_id === 'uk-cs-mock'
}

/*
 * Get a provider's return url based on successful login response
 */
export function getReturnUrlFromLoginResponse(provider: IProvider, response: any): string {
  if (isCsMock(provider)) {
    return response.data.return_url
  }
  return response.data.result.authorization_uri
}

/*
 * If a provider's auth inputs contain branch data, extract it and set it as its own field
 */
export const extractBranchDataFromAuthInputs = (provider: IProvider): IProvider => {
  const branchAuthInput = provider.auth_inputs.find(stepIsBranchSelection)

  if (branchAuthInput) {
    return {
      ...provider,
      branches: {
        authInputKey: branchAuthInput.fields[0].id,
        branchesList: getBranchListData(branchAuthInput, provider),
      },
      auth_inputs: provider.auth_inputs.filter((x) => !stepIsBranchSelection(x)),
    }
  }
  return provider
}
