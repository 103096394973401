import { useSelector } from 'react-redux'
import IStoreState from '@store/IStoreState'
import { LoginType } from '@pages/Login/models'
import { usePageContext } from '@hooks'
import { Page } from '@models/IPage'
import useSuccessRedirect from './useSuccessRedirect'
import useLongWait from './useLongWait'
import useLoginRequest from './useLoginRequest'
import { LoginStatus } from '@models/loginStatus'
import { useEffect, useState } from 'react'

const selector = (state: IStoreState) => ({
  provider: state.providers.selectedProvider,
  clientName: state.client.clientSettings.client_name,
  loginInfo: state.login.loginInformation,
  loginStatus: state.login.loginStatus,
})

const useLogin = () => {
  const { provider, clientName, loginInfo, loginStatus } = useSelector(selector)
  const { returnUrl } = useLoginRequest(provider, loginInfo)
  const { previousPage, page } = usePageContext()
  const [type, setType] = useState<LoginType>(previousPage === Page.MFA ? 'Mfa' : 'Standard')

  useEffect(() => {
    // only change the loginType on the login page itself.
    // the `previousPage` will always be `Login` just after the
    // redirect from Login page and will thus would set the
    // type to `Standard` even though it should be `Mfa`
    if (page === Page.LOGIN) {
      setType(previousPage === Page.MFA ? 'Mfa' : 'Standard')
    }
  }, [page, previousPage])

  useSuccessRedirect(loginStatus, returnUrl, type)
  const isLongWait = useLongWait()

  return {
    provider,
    isLongWait,
    isSuccessfulLogin: loginStatus === LoginStatus.Success,
    type,
    clientName,
    status: loginStatus,
  }
}

export default useLogin
