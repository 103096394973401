import ActionTypeKeys from '../actions/ActionTypeKeys'
import ActionTypes from '../actions/ActionTypes'

import {
  IClientState,
  VerificationApiPlan,
  DataApiPlan,
  DataUseCategory,
  ConsentPageType,
} from '../store/IStoreState'

const initialState: IClientState = {
  clientSettings: {
    cancellation_uri: '',
    client_name: '',
    data_api_plan: DataApiPlan.Develop,
    verification_api_plan: VerificationApiPlan.None,
    data_use: '',
    data_use_category: DataUseCategory.None,
    logo_uri: '',
    primary_colour: '',
    secondary_colour: '',
    tertiary_colour: '',
    is_agent: false,
  },
  redirectUri: '',
  consentId: '',
  flowType: '',
  scope: [],
  publicScope: [],
  authFlowId: '',
  id: '',
  authUriMode: '',
  providerId: '',
  consentPageType: ConsentPageType.Default,
  clientAttributes: {
    regulatory_information: {
      uk: {
        ais_regulated: false,
      },
      eu: {
        ais_regulated: false,
      },
    },
  },
}

export default function clientReducer(
  state: IClientState = initialState,
  action: ActionTypes,
): IClientState {
  switch (action.type) {
    case ActionTypeKeys.SAVE_CLIENT_SETTINGS:
      return {
        ...state,
        clientSettings: action.payload,
      }
    case ActionTypeKeys.SAVE_CLIENT_ATTRIBUTES:
      return {
        ...state,
        clientAttributes: action.payload,
      }
    case ActionTypeKeys.SET_CONSENT_ID:
      return {
        ...state,
        consentId: action.payload,
      }
    case ActionTypeKeys.SET_FLOW_TYPE:
      return {
        ...state,
        flowType: action.payload,
      }
    case ActionTypeKeys.SAVE_SESSION: {
      const session = action.payload

      const extendPayload =
        'extend' in session
          ? {
              connectionId: session.connection_id,
              extend: {
                nextReconfirmationDate: session.extend.next_reconfirmation_date,
                ...(session.extend.reauth
                  ? {
                      reauth: {
                        link: session.extend.reauth.link,
                      },
                    }
                  : {}),
              },
            }
          : {}

      return {
        ...state,
        ...{
          id: session.client_id,
          consentId: session.consent_id,
          scope: session.scope.split(' '),
          publicScope: session.public_scope?.split(' ') ?? [],
          redirectUri: session.redirect_uri,
          providerId: session.provider_id,
          flowType: session.auth_flow,
          authFlowId: session.auth_flow_id,
          origin: session.origin,
          isRedirectedToBank: session.is_redirected_to_bank,
          state: session.state,
          authUriMode: session.auth_uri_mode,
          countryId: session.country_id?.toLowerCase(),
          consentPageType: session.consent_page_type,
          ...extendPayload,
        },
      }
    }
    default:
      return state
  }
}
