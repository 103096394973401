import styled from 'styled-components'
import { ContainerStyle, gap } from '@styles'

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  ${gap(10, 'px', 'h')}
  ${ContainerStyle}
`
