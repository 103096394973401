import React from 'react'
import { Root } from './styles'

export interface ILabelProps {
  text: string
}

const Label = ({ text }: ILabelProps) => <Root>{text}</Root>

export default Label
