import {
  EuTheme,
  EuSearchInput,
  EuInput,
  EuInputProps,
  EuFontAwesomeIcon,
  EuPaper,
  EuPrimaryButton,
  EuPrimaryButtonProps,
  EuSecondaryButton,
  EuSecondaryButtonProps,
  EuCredit,
  EuLink,
  EuLoader,
  EuLoaderProps,
  EuFooter,
  EuTooltip,
  EuStepper,
  EuStep,
  EuStepLabel,
} from '@tl-prisma/enduser'

export const PrismaTheme = EuTheme
export const PrismaIcon = EuFontAwesomeIcon
export const PrismaSearchInput = EuSearchInput
export const PrismaInput = EuInput
export type PrismaInputProps = EuInputProps
export const PrismaPaper = EuPaper
export const PrismaPrimaryButton = EuPrimaryButton
export const PrismaSecondaryButton = EuSecondaryButton
export type PrismaPrimaryButtonProps = EuPrimaryButtonProps
export type PrismaSecondaryButtonProps = EuSecondaryButtonProps
export const PrismaCredit = EuCredit
export const PrismaLink = EuLink
export const PrismaLoader = EuLoader
export type PrismaLoaderProps = EuLoaderProps
export const PrismaFooter = EuFooter
export const PrismaTooltip = EuTooltip
export const PrismaStepper = EuStepper
export const PrismaStep = EuStep
export const PrismaStepLabel = EuStepLabel
