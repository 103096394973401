import styled, { css } from 'styled-components'
import { gap } from '@styles'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  ${gap(20, 'px', 'v')}
`

export const AddButton = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ccc;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 300;
  cursor: pointer;
  padding: 14px;
  :hover {
    box-shadow: 6px 9px 25px -2px rgba(204, 204, 204, 0.5);
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%232D2D2DFF' stroke-width='3' stroke-dasharray='7%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    svg {
      color: #2d2d2d;
    }
  }
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23CCCCCCFF' stroke-width='3' stroke-dasharray='7%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 10px;
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      user-select: none;
    `}
`

export const AddButtonLeftSide = styled.div`
  display: flex;
  align-items: center;
  ${gap(10, 'px', 'h')}
`
