import { NotificationBanner } from '@components'
import { INotification } from '@models/IAuthInput'
import { useLocalisedNotification } from '@pages/Mfa/hooks/useMfa'
import React from 'react'
import { NotificationContainer } from './styles'

function LocalizedNotificationMessage({ notification }: { notification: INotification }) {
  const localizedNotificationMessage = useLocalisedNotification([notification])

  return <>{localizedNotificationMessage ?? notification.message}</>
}

function Notification({
  notification,
  shouldLocalize = false,
}: {
  notification: INotification
  shouldLocalize?: boolean
}) {
  return (
    <NotificationContainer>
      <NotificationBanner
        type="error"
        message={
          shouldLocalize ? (
            <LocalizedNotificationMessage notification={notification} />
          ) : (
            notification.message
          )
        }
      />
    </NotificationContainer>
  )
}

export default Notification
