import { checkSolid } from '@assets/icons'
import React, { ChangeEventHandler, ReactNode, useCallback, useState } from 'react'
import { useControllableProp } from '@hooks'
import { CheckboxContainer, CheckboxInput, CheckboxLabel, CheckmarkIcon } from './styles'

export interface ICheckboxProps {
  children: ReactNode
  themeColor: string
  className?: string
  hasError?: boolean
  hasErrorFlash?: boolean
  isChecked?: boolean
  'data-view'?: string
  'aria-errormessage'?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
}

function Checkbox({
  children,
  themeColor,
  className,
  hasError,
  hasErrorFlash,
  isChecked: isCheckedProp,
  onChange: onChangeProp,
  'data-view': dataView,
  'aria-errormessage': ariaErrorMessage,
}: ICheckboxProps) {
  const [isCheckedState, setIsCheckedState] = useState(false)
  const [isControlled, isChecked] = useControllableProp({
    state: isCheckedState,
    prop: isCheckedProp,
  })

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (!isControlled) {
        setIsCheckedState(!isChecked)
      }

      onChangeProp?.(e)
    },
    [isChecked, isControlled, onChangeProp],
  )

  return (
    <CheckboxLabel className={className} data-view={dataView}>
      <CheckboxInput
        onChange={onChange}
        type="checkbox"
        aria-invalid={hasError}
        aria-errormessage={hasError ? ariaErrorMessage : ''}
      />
      <CheckboxContainer hasErrorFlash={hasErrorFlash} color={themeColor} aria-hidden="true">
        {isChecked && <CheckmarkIcon icon={checkSolid} color={themeColor} />}
      </CheckboxContainer>
      <div>{children}</div>
    </CheckboxLabel>
  )
}

export default Checkbox
