/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, FC } from 'react'
import { Page } from '@models/IPage'
import usePageManager from './usePageManager'

interface IPageContext {
  page?: Page
  isFirstPage: boolean
  goBack: (times?: number) => void
  goBackToPage: (page: Page) => void
  previousPage: Page | undefined
  locationState: any
  changePage: (page: Page, state?: object) => void
  showCloseOverlay: boolean
  closeApp: () => void
  cancelCloseApp: () => void
  confirmCloseApp: () => void
}

export const initialPageContext: IPageContext = {
  page: undefined,
  isFirstPage: true,
  goBack: () => undefined,
  goBackToPage: () => undefined,
  previousPage: undefined,
  locationState: {},
  changePage: () => undefined,
  showCloseOverlay: false,
  closeApp: () => undefined,
  cancelCloseApp: () => undefined,
  confirmCloseApp: () => undefined,
}

export const PageContext = createContext<IPageContext>(initialPageContext)

interface IPageProviderProps {
  children: React.ReactNode
}

export const PageProvider: FC<IPageProviderProps> = ({ children }) => {
  const currentPage = usePageManager()
  return <PageContext.Provider value={currentPage}>{children}</PageContext.Provider>
}
