import { useTranslation } from 'react-i18next'
import useConsent from './useConsent'

function useCondensedSubtitle({ isRegulated }: { isRegulated: boolean }) {
  const { t } = useTranslation('v3_consent')
  const { providerName, scope, clientName } = useConsent()

  const hasOngoingAccess = scope.includes('offline_access')

  if (isRegulated) {
    return t(hasOngoingAccess ? 'subtitle_ongoing_regulated' : 'subtitle_one_off_regulated', {
      clientName,
      providerName,
    })
  }

  return t(hasOngoingAccess ? 'subtitle_ongoing_unregulated' : 'subtitle_one_off_unregulated', {
    clientName,
    providerName,
  })
}

export default useCondensedSubtitle
