import React, { createContext, FC, useState, useEffect } from 'react'
import { maxMobileViewportWidth } from '@constants/viewport'

interface IViewportContext {
  width: number
  height: number
  isMobile: boolean
}

export const ViewportContext = createContext<IViewportContext>({
  width: 0,
  height: 0,
  isMobile: true,
})

interface IViewportProviderProps {
  children: React.ReactNode
}

export const ViewportProvider: FC<IViewportProviderProps> = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  const viewport: IViewportContext = {
    width,
    height,
    isMobile: width < maxMobileViewportWidth,
  }

  return <ViewportContext.Provider value={viewport}>{children}</ViewportContext.Provider>
}
