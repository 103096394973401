import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const ChoiceValue = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`

export const ChoiceIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`
