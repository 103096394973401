import { FaIcon } from '@assets/icons'
import { Button } from '@components'
import { ErrorLayout } from '@layouts'
import React from 'react'
import { ErrorUI } from './errors'
import { ButtonsContainer, Message, Title } from './styles'

interface IErrorProps {
  error: ErrorUI
}

const ErrorContainer = ({ error }: IErrorProps) => {
  const { color, title, message, button, icon } = error

  return (
    <ErrorLayout>
      <FaIcon icon={icon} color={color} size="9x" />
      <Title>{title}</Title>
      <Message>{message}</Message>
      {button && (
        <ButtonsContainer>
          <Button label={button.text} variant="primary" color="#058ED8" onClick={button.handler} />
        </ButtonsContainer>
      )}
    </ErrorLayout>
  )
}

export default ErrorContainer
