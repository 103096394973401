import styled from 'styled-components'
import { ContainerStyle, gap, hideScrollBar } from '@styles'
import { device } from '@constants/devices'

export const Container = styled.div`
  height: 100%;
  ${ContainerStyle}
`

export const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const AuthInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${gap(16, 'px', 'v')}
  overflow-y: scroll;
  ${hideScrollBar}
  margin-bottom: 48px;

  ${device.desktop} {
    ${gap(24, 'px', 'v')}
  }
`

export const SubmitContainer = styled.div`
  > button {
    width: 100%;
  }
  margin-bottom: 16px;
`
