import styled from 'styled-components'
import { VisuallyHidden } from '@styles'
import { FaIcon } from '@assets/icons'
import { device } from '@constants/devices'

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  position: relative;
`

export const CheckboxInput = styled.input`
  ${VisuallyHidden}
`

export const CheckboxContainer = styled.div<{
  color: string
  hasErrorFlash?: boolean
}>`
  display: inline-block;
  align-self: flex-start;
  width: 17px;
  height: 17px;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${({ hasErrorFlash, color }) => (hasErrorFlash ? color : '#575757')};
  background-color: #fff;
  flex-shrink: 0;
  margin-right: 12px;
  position: relative;
  margin-top: 3px;

  ${device.desktop} {
    ${CheckboxLabel}:hover & {
      border-color: ${({ color }) => color};
    }
  }
`

export const CheckmarkIcon = styled(FaIcon)<{
  color: string
}>`
  color: ${({ color }) => color};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
`
