import React from 'react'
import { EmptyPageLayout } from '@layouts'
import { Button } from '@components'
import { useTranslation } from 'react-i18next'
import { Root, LogoContainer, Logo, Text, ButtonContainer } from './styles'
import useIOSRedirect from './hooks'

const IOSRedirect = () => {
  const { t } = useTranslation('v2_ios_redirect')
  const { providerLogo, redirectName, onConfirmClick, onCancelClick } = useIOSRedirect()

  const renderLogo = () => (
    <LogoContainer>
      <Logo src={providerLogo} alt="Provider Logo" />
    </LogoContainer>
  )

  const renderText = () => <Text>{t('redirect_message', { redirectName })}</Text>

  const renderButtons = () => (
    <ButtonContainer>
      <Button variant="primary" label={t('confirm')} color="#058ed8" onClick={onConfirmClick} />
      <Button variant="secondary" label={t('cancel')} color="#058ed8" onClick={onCancelClick} />
    </ButtonContainer>
  )

  return (
    <EmptyPageLayout>
      <div data-view="page-id-ios-redirect"></div>
      <Root>
        {renderLogo()}
        {renderText()}
        {renderButtons()}
      </Root>
    </EmptyPageLayout>
  )
}

export default IOSRedirect
