/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { IValidationRule, AllowedCharacters } from '@models/IAuthInput'
import { getFreeInputType } from '@utils/authInputs'
import { plusLight, questionCircleSolid, FaIcon } from '@assets/icons'
import { Input } from '@components'
import { useClientColors } from '@hooks'
import useMultiClearTextInputValue from './hooks'
import { AddButton, Root, AddButtonLeftSide } from './styles'

export interface IMultiClearTextInputValueProps {
  /**
   * The ID of the input used to return to the connector
   */
  id: string
  /**
   * The input's valdations
   */
  validations: IValidationRule[]
  /**
   * True for a password
   */
  isSensitive: boolean
  /**
   * Allowed characters define text or numbers
   */
  allowedCharacters: AllowedCharacters
  /**
   * Function to be called to save the input data
   */
  saveData: (key: string, val: any) => void
}

/**
 * This component displays a text input field to collect user
 * information, with the option to add extra similar fields
 */
const MultiClearTextInputValue = ({
  id,
  validations,
  isSensitive,
  allowedCharacters,
  saveData,
}: PropsWithChildren<IMultiClearTextInputValueProps>) => {
  const { t } = useTranslation(['fieldNames', 'fieldHelpTexts', 'v2_auth_inputs'])
  const displayName = t(`fieldNames:${id}_field_display_name`)
  const helpText = t(`fieldHelpTexts:${id}_field_help_text`)
  const { tertiaryColor } = useClientColors()
  const { values, handleValue, addValue } = useMultiClearTextInputValue(id, saveData)

  const renderInputs = () =>
    values.map((_, i) => (
      <Input
        type={getFreeInputType(allowedCharacters, isSensitive) as any}
        label={helpText}
        placeholder={displayName}
        validations={validations}
        saveValue={(value: string | null) => handleValue(value, i)}
        activeColor={tertiaryColor}
      />
    ))

  const renderAddInputButton = () => (
    <AddButton onClick={addValue} disabled={!values.every((v) => v !== null)}>
      <AddButtonLeftSide>
        <FaIcon icon={plusLight as any} />
        <div>{t('v2_auth_inputs:add_field', { displayName })}</div>
      </AddButtonLeftSide>
      <FaIcon icon={questionCircleSolid} />
    </AddButton>
  )
  return (
    <Root>
      {renderInputs()}
      {renderAddInputButton()}
    </Root>
  )
}

export default MultiClearTextInputValue
