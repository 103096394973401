import styled from 'styled-components'
import { ContainerStyle, hideScrollBar, gap } from '@styles'

export const ChallengeContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${ContainerStyle}
  ${gap(40, 'px', 'v')}
  overflow-y: auto;
  ${hideScrollBar}
  margin-bottom: 48px;
`

export const ButtonsContainer = styled.div`
  ${ContainerStyle}
  > button {
    width: 100%;
  }
  margin-bottom: 40px;
`

export const NotificationContainer = styled.div`
  ${ContainerStyle}
  margin-bottom: 20px;
`
