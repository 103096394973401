/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ClientType } from '@models/clientType'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import IStoreState, { ConsentPageType, DataUseCategory } from '@store/IStoreState'
import { sendConsentGrantedAnalytics } from '@actions/analytics'
import { grantConsentRequest, updateSessionRequest } from '@middlewares/api'
import { setConsentId } from '@actions/client/clientActions'
import { cancelClickedAction } from '@actions/general/generalActions'
import { EventType } from '@middlewares/analytics'
import { getSessionId } from '@utils/helpers'
import useTranslatedDataUse from '@hooks/useTranslatedDataUse'
import {
  AMAZON_CONSENT_PAGE_ENABLED_CLIENT_IDS,
  CONDENSED_CONSENT_ENABLED_LANGUAGE_IDS,
  NEW_CONSENT_SUBTITLE_COPY_CLIENT_IDS,
} from '@utils/constants'
import useStateRestore from '@hooks/useStateRestore'
import { Page } from '@models/IPage'
import { getLanguageId } from '../../i18n'
import { useHiddenAuthUri } from './hooks'
import { ConsentContextProvider } from './context'
import {
  ConsentContentEURegulated,
  ConsentContentEUUnregulated,
  ConsentContentUKAgent,
  ConsentContentUKRegulated,
  ConsentContentUKUnregulated,
  ConsentContentMonthlyInvoiceVerification,
  ConsentContentAmazon,
  CondensedUKUnregulated,
  CondensedUKRegulated,
  CondensedUKAgent,
  CondensedEURegulated,
  CondensedEUUnregulated,
  ConsentContentSignupPlus,
  ConsentContentBankAccountVerification,
} from './components'
import { getRegulatoryInformation, isClientRegulated } from '@utils/client'
import { getProviderScope, isOnlyInfoScope, hasSignupPlusScope } from '@utils/scope'

const consentStateSelector = (state: IStoreState) => {
  const clientId = state.client.id
  const isAgent = state.client.clientSettings.is_agent
  const { clientAttributes } = state.client
  const provider = state.providers.selectedProvider
  const activeCountry = provider.country
  const regulatoryInformation = getRegulatoryInformation({ activeCountry, clientAttributes })
  const isRegulated = isClientRegulated({ activeCountry, clientAttributes })
  const consentPageType = state.client.consentPageType

  console.log(consentPageType)

  const clientType: ClientType = (() => {
    switch (activeCountry) {
      case 'uk': {
        if (isAgent) {
          return ClientType.UkAgent
        }

        if (isRegulated) {
          return ClientType.UkRegulated
        }

        return ClientType.UkUnregulated
      }
      // default is all other eu countries
      default: {
        return isRegulated ? ClientType.EuRegulated : ClientType.EuUnregulated
      }
    }
  })()

  const { scope, publicScope } = state.client
  const providerScopeMappings = provider.provider_scope_mappings ?? {}

  const providerScope = getProviderScope({ scope, providerScopeMappings })

  return {
    clientName: state.client.clientSettings.client_name,
    clientId,
    providerName: provider.display_name,
    providerLogo: provider.icon_url,
    providerId: provider.provider_id,
    providerScope,
    clientType,
    scope,
    publicScope,
    dataUse: state.client.clientSettings.data_use,
    dataUseCategory: state.client.clientSettings.data_use_category,
    email: state.client.clientSettings.email,
    clientLogo: state.client.clientSettings.logo_uri,
    regulatoryInformation,
    isRegulated,
    consentPageType,
  }
}

const Consent = () => {
  const {
    clientName,
    clientId,
    providerName,
    providerLogo,
    providerScope,
    providerId,
    clientType,
    scope,
    dataUse,
    dataUseCategory,
    email,
    clientLogo,
    regulatoryInformation,
    isRegulated,
    publicScope,
    consentPageType,
  } = useSelector(consentStateSelector)

  const [dataUseCopy, setDataUseCopy] = useState('')
  const translatedDataUse = useTranslatedDataUse({ dataUse, dataUseCategory })

  const abortControllerRef = useRef(new AbortController())
  const abortSignal = abortControllerRef.current.signal

  useEffect(() => {
    return () => {
      // the warning not relevant here bcs the "abortControllerRef"
      // does *not* hold a reference to a DOM node
      // eslint-disable-next-line react-hooks/exhaustive-deps
      abortControllerRef.current.abort()
    }
  }, [abortControllerRef])

  useEffect(() => {
    if (dataUseCopy) {
      const { sessionId } = getSessionId()
      updateSessionRequest({
        sessionId: sessionId!,
        dataUseDescription: dataUseCopy,
        languageId: getLanguageId(),
      })
    }
  }, [dataUseCopy])

  useStateRestore(Page.CONSENT)

  // Background call to authUri to retrieve bank link
  const { preLoadedUrl, hiddenAuthUriFinished } = useHiddenAuthUri(abortSignal)

  const dispatch = useDispatch()

  const condensedConsentCopy = CONDENSED_CONSENT_ENABLED_LANGUAGE_IDS.includes(getLanguageId())

  const onAllowClick = async () => {
    if (preLoadedUrl) {
      // Bank link successfully retrieved -> send event
      // (redirection taken care of by using native `a` HTML tag w/ `href`)
      dispatch(sendConsentGrantedAnalytics())
    } else {
      const consentId = await grantConsentRequest(dataUseCopy, providerId!, getLanguageId())
      if (consentId) {
        dispatch(sendConsentGrantedAnalytics(consentId))
        dispatch(setConsentId(consentId))
      }
    }
  }

  const onCancelClick = () => {
    dispatch(cancelClickedAction(EventType.ConsentDenied))
  }

  const content = useMemo(() => {
    if (AMAZON_CONSENT_PAGE_ENABLED_CLIENT_IDS.includes(clientId)) {
      return <ConsentContentAmazon />
    }

    if (consentPageType === ConsentPageType.BankAccountVerification) {
      return <ConsentContentBankAccountVerification />
    }

    if (
      isOnlyInfoScope(scope) &&
      dataUseCategory === DataUseCategory.MonthlyInvoiceVerification &&
      !isRegulated
    ) {
      return <ConsentContentMonthlyInvoiceVerification />
    }

    // we have to use `publicScope` as the `scope` variable doesn't
    // include product scopes (like "signupplus")
    if (hasSignupPlusScope(publicScope)) {
      return <ConsentContentSignupPlus />
    }

    if (condensedConsentCopy) {
      switch (clientType) {
        case ClientType.UkRegulated:
          return <CondensedUKRegulated />
        case ClientType.UkUnregulated:
          return <CondensedUKUnregulated />
        case ClientType.UkAgent:
          return <CondensedUKAgent />
        case ClientType.EuRegulated:
          return <CondensedEURegulated />
        case ClientType.EuUnregulated:
          return <CondensedEUUnregulated />
      }
    }

    switch (clientType) {
      case ClientType.UkRegulated:
        return <ConsentContentUKRegulated />
      case ClientType.UkUnregulated:
        return <ConsentContentUKUnregulated />
      case ClientType.UkAgent:
        return <ConsentContentUKAgent />
      case ClientType.EuRegulated:
        return <ConsentContentEURegulated />
      case ClientType.EuUnregulated:
        return <ConsentContentEUUnregulated />
    }
  }, [clientId, clientType, dataUseCategory, condensedConsentCopy, isRegulated, scope, publicScope])

  return (
    <ConsentContextProvider
      value={{
        clientName,
        clientId,
        providerName,
        providerLogo,
        providerScope,
        clientType,
        scope,
        dataUse: translatedDataUse,
        dataUseCopy,
        email,
        setDataUseCopy,
        clientLogo,
        onAllowClick,
        onCancelClick,
        hiddenAuthUriFinished,
        regulatoryInformation,
        dataUseCategory,
        shouldDisplayNewSubtitleCopy: NEW_CONSENT_SUBTITLE_COPY_CLIENT_IDS.includes(clientId),
        publicScope,
        preLoadedUrl,
      }}
    >
      <div data-view="page-id-consent"></div>
      {content}
    </ConsentContextProvider>
  )
}

export default Consent
