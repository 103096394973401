import { IBranchItem } from '@pages/Branches/models'
import { IAuthInputMethod } from './IAuthInput'

export default interface IProvider {
  readonly connector_id: string
  readonly provider_id?: string
  readonly country: string
  readonly auth_method: string
  readonly division: string
  readonly display_name: string
  readonly name: string
  readonly disabled: boolean
  readonly logo_url: string
  readonly alternative_url: string
  readonly main_bg_color?: string
  readonly main_button_color?: string
  readonly main_dropdown_color?: string
  readonly mobile_bg_color?: string
  readonly mobile_button_color?: string
  readonly mobile_help_link_color?: string
  readonly max_login_time?: number
  readonly white_logo_url?: string
  readonly is_beta: boolean
  readonly icon_url: string
  readonly white_icon_url: string
  readonly auth_inputs: IAuthInputMethod[]
  readonly branches?: IBranches
  readonly selectedBranchId?: string
  readonly provider_scope_mappings?: Record<string, string[] | undefined>
}

export interface IBranches {
  readonly authInputKey: string
  readonly branchesList: IBranchItem[]
}

export enum ProviderProtocolKeys {
  CREDENTIALS_SHARING = 'cs',
  OAUTH = 'oauth',
  OPEN_BANKING = 'ob',
  FINTS = 'fints',
}
