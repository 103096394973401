import React from 'react'
import { ScrollableList } from '@components'
import { IBranchItem } from '@pages/Branches/models'
import { useBranchListStyle } from './hooks'

interface IBranchListProps {
  branches: IBranchItem[]
  highlight: string
  onBranchClick: (b: IBranchItem) => void
}

const BranchList = ({ branches, highlight, onBranchClick }: IBranchListProps) => {
  const style = useBranchListStyle()
  return (
    <ScrollableList
      items={branches}
      keyProp="name"
      imgProp="logo"
      textProp="name"
      highlight={highlight}
      onItemClick={onBranchClick}
      {...style}
    />
  )
}

export default BranchList
