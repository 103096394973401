import React from 'react'
import { DefaultPageLayout } from '@layouts'
import { AuthInput, Button, Footer, LinkingLogos } from '@components'
import { useTranslation } from 'react-i18next'
import { NotificationType } from '@models/IAuthInput'
import useStateRestore from '@hooks/useStateRestore'
import { Page } from '@models/IPage'
import { Form, AuthInputsContainer, SubmitContainer, Container } from './styles'
import useAuthInputs from './hooks'
import Notification from './components/Notification'

const AuthInputs = () => {
  const { t } = useTranslation('v2_auth_inputs')
  const {
    getPageTitle,
    step,
    isLastStep,
    saveData,
    submitAuthInputs,
    canSubmit,
    provider,
    notification,
    getPageSubtitle,
    middleIcon,
    client,
    isValidating,
    notificationFromValidation,
  } = useAuthInputs()

  useStateRestore(Page.AUTHINPUTS)

  const renderAuthInputs = () =>
    step?.fields ? (
      <AuthInputsContainer>
        {/*  eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        {step.fields!.map((field) => (
          <AuthInput key={field.id} authInput={field} saveData={saveData} />
        ))}
      </AuthInputsContainer>
    ) : null

  const renderSubmitButton = () => (
    <SubmitContainer>
      <Button
        variant="primary"
        label={isLastStep ? t('proceed') : t('next')}
        onClick={submitAuthInputs}
        disabled={!canSubmit}
        type="submit"
        color={provider.main_button_color || 'black'}
        dataView="button-submit"
        loading={isValidating}
      />
    </SubmitContainer>
  )

  return step ? (
    <DefaultPageLayout
      title={getPageTitle()}
      subtitle={getPageSubtitle()}
      logo={
        <LinkingLogos
          leftLogo={client.clientSettings.logo_uri}
          middleLogo={middleIcon}
          rightLogo={provider.icon_url}
        />
      }
    >
      <div data-view="page-id-auth-inputs"></div>
      <Container>
        <Form onSubmit={() => submitAuthInputs()}>
          {notification && notification.type === NotificationType.ERROR && (
            <Notification notification={notification} />
          )}
          {notificationFromValidation &&
            notificationFromValidation.type === NotificationType.ERROR && (
              <Notification notification={notificationFromValidation} shouldLocalize />
            )}
          {renderAuthInputs()}
          {renderSubmitButton()}
          <Footer />
        </Form>
      </Container>
    </DefaultPageLayout>
  ) : null
}

export default AuthInputs
