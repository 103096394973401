import React from 'react'
import { IAuthInput } from '@models/IAuthInput'
import { MfaQuestion, MfaAnswer } from './components'
import { Root } from './styles'

export interface IMfaChallengeProps {
  challenge: IAuthInput
  saveAnswer: (id: string, answer: string | undefined) => void
}

const MfaChallenge = ({ challenge, saveAnswer }: IMfaChallengeProps) => {
  const renderQuestion = () => <MfaQuestion challenge={challenge} />
  const renderAnswer = () => <MfaAnswer challenge={challenge} saveAnswer={saveAnswer} />
  return (
    <Root>
      {renderQuestion()}
      {renderAnswer()}
    </Root>
  )
}

export default MfaChallenge
