import React, { Fragment, ReactNode } from 'react'
import { useViewport } from '@hooks'
import { DesktopContainer, ScrollingDiv } from '../../styles'

function ConsentLayoutUKEU({
  notesAccordion,
  notesTabs,
  agreement,
}: {
  notesAccordion: ReactNode
  notesTabs: ReactNode
  agreement: ReactNode
}) {
  const { isMobile } = useViewport()
  const Container = isMobile ? Fragment : DesktopContainer

  return (
    <Container>
      {isMobile ? (
        <>
          {agreement}
          {notesAccordion}
        </>
      ) : (
        <>
          <ScrollingDiv>{notesTabs}</ScrollingDiv>
          {agreement}
        </>
      )}
    </Container>
  )
}

export default ConsentLayoutUKEU
