import { OverviewPage } from '@pages/Overview/types'
import { exhaustiveCheck } from '@utils/helpers'
import React, { useMemo } from 'react'
import ConsentOverview from '../ConsentOverview'
import ProvidersOverview from '../ProvidersOverview'

function OverviewContent({ page }: { page: OverviewPage }) {
  const content = useMemo(() => {
    switch (page) {
      case OverviewPage.Consent:
        return <ConsentOverview />
      case OverviewPage.ProviderSelection:
        return <ProvidersOverview />
      default:
        exhaustiveCheck(page)
    }
  }, [page])

  return (
    <div>
      <div data-view="page-id-overview/v2"></div>
      {content}
    </div>
  )
}

export default OverviewContent
