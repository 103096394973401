/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { Input } from '@components'
import { getFreeInputType } from '@utils/authInputs'
import { IAuthInput } from '@models/IAuthInput'
import { TextChoice, ImageChoice } from './components'
import { useTranslation } from 'react-i18next'

interface IMfaAnswerProps {
  challenge: IAuthInput
  saveAnswer: (id: string, answer: string | undefined) => void
}

const MfaAnswer = ({ challenge, saveAnswer }: IMfaAnswerProps) => {
  const { t } = useTranslation(['fieldNames', 'fieldHelpTexts', 'v2_mfa'])

  const { id, input_value_case } = challenge

  switch (input_value_case) {
    case 'ClearTextInputValue': {
      const { clear_text_input_value } = challenge
      const { allowed_characters, is_sensitive, validation_rules, default_value } =
        clear_text_input_value!

      const label = challenge.help_text.replaceAll('<br>', '\n')

      const placeholder = t('v2_mfa:enter_answer_placeholder')
      return (
        <Input
          defaultValue={default_value}
          type={getFreeInputType(allowed_characters, is_sensitive)}
          placeholder={placeholder}
          label={label}
          validations={validation_rules}
          saveValue={(value: string | undefined) => {
            saveAnswer(id, value)
          }}
          activeColor="#2d2d2d"
        />
      )
    }

    case 'SingleChoiceInputValue': {
      const { options } = challenge.single_choice_input_value!
      return <TextChoice challengeId={id} options={options} saveAnswer={saveAnswer} />
    }

    case 'SingleChoiceImageInputValue': {
      const { options } = challenge.single_choice_image_input_value!
      return <ImageChoice challengeId={id} options={options} saveAnswer={saveAnswer} />
    }

    default:
      return null
  }
}

export default MfaAnswer
