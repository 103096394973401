import styled, { css } from 'styled-components'
import { gap } from '@styles'
import { device } from '@constants/devices'

const Dimension = css`
  width: 180px;
  height: 60px;
`
export const CloseButton = styled.button`
  background: none;
  border: none;
  color: #2D2D2D;
`


export const Root = styled.div`
  height: 80px;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  background-color: white;
  box-sizing: border-box;

  ${device.desktop} {
    padding-left: 35px;
    padding-right: 35px;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;
  align-self: flex-end;

  ${Dimension}
`

export const Logo = styled.img`
  object-fit: contain;

  ${Dimension}
`

export const BackLink = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  ${gap(5, 'px', 'h')}
  cursor: pointer;
`

export const BackContainer = styled.div`
  max-width: 15px;
`
