import { shallowEqual, useSelector } from 'react-redux'
import IStoreState from '@store/IStoreState'

// Retrieves from the redux store all the necessary state for flow/page management
const usePageManagerState = () => {
  const selector = (state: IStoreState) => {
    const { client, general, providers, countries, login } = state
    const { selectedProvider, items } = providers
    const { isLoading, splashScreenVisited } = general
    const { consentId, flowType, authUriMode, id: clientId } = client
    const { countrySelected: selectedCountry } = countries
    const { loginInformation, mfaData, loginStatus } = login
    return {
      selectedProvider,
      providers: items,
      selectedCountry,
      consentId,
      flowType,
      isLoading,
      loginInformation,
      authUriMode,
      hasMfaData: !!mfaData,
      extendConsent: 'extend' in client,
      clientId,
      splashScreenVisited,
      loginStatus,
    }
  }
  return useSelector(selector, shallowEqual)
}

export default usePageManagerState
