import styled from 'styled-components'
import { device } from '@constants/devices'

export const Root = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  ${device.tablet} {
    font-size: 12px;
  }
  font-weight: 400;
`
