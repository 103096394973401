import { LinkingLogos } from '@components'
import React, { PropsWithChildren } from 'react'
import useConsent from '../../hooks'

export interface IConsentLogosProps {
  middleLogo: JSX.Element
}

const ConsentLogos = ({ middleLogo }: PropsWithChildren<IConsentLogosProps>) => {
  const { providerLogo, clientLogo } = useConsent()

  return <LinkingLogos rightLogo={providerLogo} leftLogo={clientLogo} middleLogo={middleLogo} />
}

export default ConsentLogos
