import styled, { css } from 'styled-components/macro'

export const ThumbRoundlet = styled('div').withConfig<{
  src: string
  size?: number
  padding?: number
  borderWidth?: number
  contain?: boolean
}>({
  shouldForwardProp: (prop) => !['src', 'size', 'padding', 'borderWidth', 'contain'].includes(prop),
})(({ src, size = 56, padding, borderWidth = 1, contain = false, theme }) => {
  // For default thumbs, which crop their image, padding should be 0.
  // For contained thumbs, padding should be 8 so corner cropping doesn't occur.

  if (typeof padding === 'undefined') {
    padding = contain ? 8 : 0
  }

  const imgSize = size - borderWidth * 2 - padding * 2
  const bgSize = contain ? 'contain' : 'cover'

  return css`
    border-radius: 50%;
    width: ${size}px;
    height: ${size}px;
    border: ${theme.colours.tints.charcoal[10]} solid ${borderWidth}px;
    background: ${theme.colours.neutral.white};
    overflow: hidden;
    flex-shrink: 0;

    &::after {
      content: '';
      border-radius: ${contain ? 0 : '50%'};
      display: block;
      margin-top: ${padding}px;
      margin-left: ${padding}px;
      width: ${imgSize}px;
      height: ${imgSize}px;
      background: url(${src}) center center / ${bgSize} no-repeat;
    }
  `
})
