import IStoreState from '../store/IStoreState'
import IProvider from '../models/IProvider'
import { IBaseEvent } from '../middlewares/analytics'
import { getProviderType, getSessionId } from './helpers'
import { getActiveCountry, getRegionFromCode } from './country'
import { AMAZON_CONSENT_PAGE_ENABLED_CLIENT_IDS } from './constants'
import { getAbTestsPayload } from './ab-tests'

export const getConsentPayload = (state: IStoreState): IBaseEvent => {
  const { providers } = state
  return {
    ...getBaseEvent(state),
    providerId: getProviderId(providers.selectedProvider),
    providerType: getProviderType(providers.selectedProvider),
    meta: {
      consentPageType: state.client.consentPageType,
    },
  }
}

export const getConsentGrantedPayload = (state: IStoreState, consentId?: string): IBaseEvent => {
  const { providers } = state
  const baseEvent = getBaseEvent(state)
  return {
    ...baseEvent,
    consentId,
    providerId: getProviderId(providers.selectedProvider),
    providerType: getProviderType(providers.selectedProvider),
    meta: {
      consentPageType: state.client.consentPageType,
    },
  }
}

export const getConsentCanceledPayload = (state: IStoreState): IBaseEvent => {
  const { providers } = state
  const baseEvent = getBaseEvent(state)
  return {
    ...baseEvent,
    providerId: getProviderId(providers.selectedProvider),
    providerType: getProviderType(providers.selectedProvider),
    meta: {
      consentPageType: state.client.consentPageType,
    },
  }
}

export const getProviderSelectionPayload = (state: IStoreState): IBaseEvent => {
  const { client } = state
  return {
    ...getBaseEvent(state),
    consentId: client.consentId,
  }
}

export const getProviderSelectedPayload = (
  state: IStoreState,
  {
    provider,
    searchTerm,
    filteredProviders,
    countryProviders,
    allProviders,
  }: {
    provider: IProvider
    searchTerm: string
    filteredProviders: IProvider[]
    countryProviders: IProvider[]
    allProviders: IProvider[]
  },
): IBaseEvent => {
  const { client } = state
  return {
    ...getBaseEvent(state),
    consentId: client.consentId,
    providerId: getProviderId(provider),
    providerType: getProviderType(provider),
    meta: {
      searchTerm,
      filteredProviders: getProvidersIdsAndNames(filteredProviders),
      countryProviders: getProvidersIdsAndNames(countryProviders),
      allProviders: getProvidersIdsAndNames(allProviders),
    },
  }
}

export const getProviderSearchedPayload = (
  state: IStoreState,
  {
    searchTerm,
    filteredProviders,
    countryProviders,
    allProviders,
  }: {
    searchTerm: string
    filteredProviders: IProvider[]
    countryProviders: IProvider[]
    allProviders: IProvider[]
  },
): IBaseEvent => {
  const { client } = state
  return {
    ...getBaseEvent(state),
    consentId: client.consentId,
    meta: {
      searchTerm,
      filteredProviders: getProvidersIdsAndNames(filteredProviders),
      countryProviders: getProvidersIdsAndNames(countryProviders),
      allProviders: getProvidersIdsAndNames(allProviders),
    },
  }
}

export function getCountryPagePayload(state: IStoreState): IBaseEvent {
  const { client } = state
  return {
    ...getBaseEvent(state),
    consentId: client.consentId,
  }
}

const getSelectedBranchId = (provider: IProvider) =>
  provider ? provider.selectedBranchId : undefined

const getBaseEvent = (state: IStoreState): IBaseEvent => {
  const { client, general, providers } = state
  const { amazonConsentAccordionOpened, amazonConsentChangeBankClicked } = general
  const { id, flowType, clientSettings, origin } = client
  const { data_api_plan, data_use_category } = clientSettings
  const isAmazonConsent = AMAZON_CONSENT_PAGE_ENABLED_CLIENT_IDS.includes(id)
  const { sessionId } = getSessionId()

  const activeCountry = getActiveCountry(state)

  const abTestsPayload = getAbTestsPayload(state)

  return {
    authFlowId: sessionId!,
    clientId: id,
    loginFlow: flowType,
    dataApiPlan: data_api_plan,
    origin,
    region: activeCountry ? getRegionFromCode(activeCountry) : undefined,
    meta: {
      data_use_category,
      ...(isAmazonConsent && {
        consent_accordion_opened: amazonConsentAccordionOpened,
        change_bank_clicked: amazonConsentChangeBankClicked,
      }),
      ...abTestsPayload,
      branchId: getSelectedBranchId(providers.selectedProvider),
    },
    countryCode: activeCountry,
  }
}

export const getAuthErrorPayload = (state: IStoreState, errorCode: string): IBaseEvent => {
  const { client, providers } = state
  return {
    ...getBaseEvent(state),
    consentId: client.consentId,
    providerId: getProviderId(providers.selectedProvider),
    providerType: getProviderType(providers.selectedProvider),
    errorCode,
  }
}

export const getAuthDialogInitializedPayload = (state: IStoreState): IBaseEvent => {
  const { client } = state
  return {
    ...getBaseEvent(state),
    consentId: client.consentId,
  }
}

const getProviderId = (provider: IProvider) => (provider ? provider.connector_id : undefined)

const getProvidersIdsAndNames = (providers: IProvider[]) =>
  providers.map((provider) => ({
    id: provider.provider_id,
    name: provider.display_name.toLocaleLowerCase(),
  }))

export const getAuthStartPayload = (state: IStoreState): IBaseEvent => {
  const { client, providers } = state
  return {
    ...getBaseEvent(state),
    consentId: client.consentId,
    providerId: getProviderId(providers.selectedProvider),
    providerType: getProviderType(providers.selectedProvider),
  }
}

export const getAuthDialogFailedToInitializePayload = (
  state: IStoreState,
  errorCode: string,
  errorMessage: string,
  tlCorrelationId: string,
): IBaseEvent => ({
  ...getBaseEvent(state),
  errorCode,
  errorMessage,
  meta: {
    tl_correlation_id: tlCorrelationId,
  },
})

export const getAuthInputsPageLoadedPayload = (state: IStoreState): IBaseEvent => ({
  ...getBaseEvent(state),
})

export const getAuthInputsStepLoadedPayload = (state: IStoreState): IBaseEvent => ({
  ...getBaseEvent(state),
})

export const getAuthInputsSubmittedPayload = (state: IStoreState): IBaseEvent => ({
  ...getBaseEvent(state),
})

export const getAuthInputsSuccessPayload = (state: IStoreState): IBaseEvent => ({
  ...getBaseEvent(state),
})

export const getAuthInputsErrorPayload = (state: IStoreState): IBaseEvent => ({
  ...getBaseEvent(state),
})

export const getSplashScreenPageLoadedPayload = (state: IStoreState): IBaseEvent => ({
  ...getBaseEvent(state),
})

export const getSplashScreenProceedPayload = (state: IStoreState): IBaseEvent => ({
  ...getBaseEvent(state),
})

export const getBranchSelectionPageLoadedPayload = (state: IStoreState): IBaseEvent => ({
  ...getBaseEvent(state),
})

export const getBranchSelectedPayload = (state: IStoreState): IBaseEvent => ({
  ...getBaseEvent(state),
})

export const getAlternativeFlowInitiatedPayload = (state: IStoreState): IBaseEvent => ({
  ...getBaseEvent(state),
})
