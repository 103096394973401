import { List } from '@components'
import { IListProps } from '@components/List/List'
import React, { FC } from 'react'
import { Root, TopGradient } from './styles'

const ScrollableList: FC<IListProps> = (props) => {
  return (
    <Root>
        <TopGradient />
        <List {...props} />
    </Root>
  )
}

export default ScrollableList