import React from 'react'
import {
  TL_FCA_NUMBER,
  TL_FCA_URL,
  TL_NAME,
  TL_PRIVACY_URL,
  TL_TOS_URL,
  UK_AUTHORITY_NAME,
} from '@constants/legal'
import { useNestedTranslation, useShouldDisplayProviderScopes, usePermissionsString } from '@hooks'
import { DefaultPageLayout } from '@layouts'
import { isOnlyInfoScope } from '@utils/scope'
import useConsent, { useDataUseWithFallback } from '../../hooks'
import ConsentButtons from '../ConsentButtons'
import ConsentDataAccessNote from '../ConsentDataAccessNote'
import ConsentDataUseAccessNote from '../ConsentDataUseAccessNote'
import ConsentLayoutUKEU from '../ConsentLayoutUKEU'
import ConsentLegalNote from '../ConsentLegalNote'
import ConsentNotesAccordionUKEU from '../ConsentNotesAccordionUKEU'
import ConsentNotesLink from '../ConsentNotesLink'
import ConsentNotesTabsUKEU from '../ConsentNotesTabsUKEU'
import ConsentOngoingAccessNote from '../ConsentOngoingAccessNote'
import {
  ConsentPermissionsListNote,
  ConsentProviderPermissionsListNote,
  ConsentLegalNoteContent,
} from '@components'
import ConsentLogosTLCheck from '../ConsentLogosTLCheck'

function ConsentContentUKUnregulated() {
  const titleTranslation = useNestedTranslation('v2_consent', ['title'])
  const subtitleTranslation = useNestedTranslation('v2_consent', ['subtitle'])
  const { dataUseCopy, dataUseBase } = useDataUseWithFallback()

  const whatDataAmISharingTranslation = useNestedTranslation('v2_consent', [
    'what_data_am_i_sharing',
  ])
  const howDoesThisWorkTranslation = useNestedTranslation('v2_consent', ['how_does_this_work'])
  const { providerName, clientName, scope, shouldDisplayNewSubtitleCopy, providerScope } =
    useConsent()

  const title = isOnlyInfoScope(scope)
    ? titleTranslation('access_share_info_scope', {
        entityName: TL_NAME,
        clientName,
        providerName,
      })
    : titleTranslation('access_share', {
        entityName: TL_NAME,
        clientName,
      })

  const subtitle = subtitleTranslation(
    shouldDisplayNewSubtitleCopy ? 'retrieve_data_with_data_use' : 'retrieve_data',
    {
      clientName,
      entityName: TL_NAME,
      dataUse: dataUseBase,
    },
  )
  const shouldDisplayProviderScopes = useShouldDisplayProviderScopes({ scope, providerScope })
  const permissionsString = usePermissionsString({ providerName, scope })

  const notesProps = {
    howDoesThisWork: (
      <>
        <div data-view="how-does-this-work-explanation">
          {howDoesThisWorkTranslation('explanation.not_regulated', {
            providerName,
          })}
          .
        </div>
        <br />
        <div data-view="regulation-information">
          <div>
            {howDoesThisWorkTranslation('encryption_info.bank', {
              entityName: TL_NAME,
            })}
            .
          </div>
          <br />
          <div>{howDoesThisWorkTranslation('login_info', { clientName })}.</div>
          <br />
          <div>
            {howDoesThisWorkTranslation('authority_info', {
              entityName: TL_NAME,
              authorityName: UK_AUTHORITY_NAME,
            })}
            . <ConsentNotesLink href={TL_FCA_URL}>FRN {TL_FCA_NUMBER}</ConsentNotesLink>
          </div>
        </div>
      </>
    ),
    whatDataAmISharing: (
      <>
        <ConsentDataAccessNote />
        <br />
        <ConsentPermissionsListNote scope={scope} />
        <br />
        <ConsentOngoingAccessNote />
        {shouldDisplayProviderScopes && (
          <>
            <div>
              {whatDataAmISharingTranslation('info.share_provider', {
                entityName: TL_NAME,
                providerName,
              })}
              :
            </div>
            <br />
            <ConsentProviderPermissionsListNote scope={scope} providerScope={providerScope} />
            <br />
            <div data-view="sharing-disclaimer">
              {whatDataAmISharingTranslation('disclaimer.data_not_used', {
                clientName,
                permissionsString,
              })}
              .
            </div>
          </>
        )}
      </>
    ),
    howIsMyDataUsed: (
      <>
        {dataUseCopy}
        .
        <br />
        <br />
        <ConsentDataUseAccessNote />
      </>
    ),
  }

  return (
    <DefaultPageLayout title={title} subtitle={subtitle} logo={<ConsentLogosTLCheck />}>
      <ConsentLayoutUKEU
        notesAccordion={<ConsentNotesAccordionUKEU {...notesProps} />}
        notesTabs={<ConsentNotesTabsUKEU {...notesProps} />}
        agreement={
          <>
            <ConsentButtons />
            <ConsentLegalNote>
              <ConsentLegalNoteContent
                entityName={TL_NAME}
                tosLink={TL_TOS_URL}
                privacyLink={TL_PRIVACY_URL}
              />
            </ConsentLegalNote>
          </>
        }
      />
    </DefaultPageLayout>
  )
}

export default ConsentContentUKUnregulated
