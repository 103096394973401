import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useConsent from './useConsent'

function useDataUseWithFallback() {
  const { clientName, dataUse, setDataUseCopy } = useConsent()

  const { t } = useTranslation('v2_consent')

  const dataUseBase = dataUse || t('data_use_category.default')

  const dataUseCopy = t('how_is_my_data_used.data_use.custom', {
    clientName,
    dataUse: dataUseBase,
  })

  // Set the exact data use copy to be stored in the consent DB
  useEffect(() => {
    setDataUseCopy(dataUseCopy)
  }, [dataUseCopy, setDataUseCopy])

  return { dataUseCopy, dataUseBase }
}

export default useDataUseWithFallback
