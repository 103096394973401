import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { ErrorCode } from '../../utils/errors'
import { faExclamationTriangleRegular } from '../../assets/icons'

export interface ErrorUI {
  code: ErrorCode
  title: string
  message: string
  icon: IconDefinition
  color: string
  button?: {
    text: string
    handler: () => void
  }
}

export const errorMapper: { [key in ErrorCode]: ErrorUI } = {
  [ErrorCode.UnauthorizedSession]: {
    code: ErrorCode.UnauthorizedSession,
    title: 'Session timed out',
    message:
      'It seems something went wrong with this session. Please close this page and try again.',
    color: '#F7AB1B',
    icon: faExclamationTriangleRegular as IconDefinition,
    button: {
      text: 'Close Page',
      handler: () => {
        window.close()
      },
    },
  },
  [ErrorCode.NoSessionId]: {
    code: ErrorCode.NoSessionId,
    title: 'Invalid session',
    message: 'Session id was not found. Please close this page and try again.',
    color: '#F7AB1B',
    icon: faExclamationTriangleRegular as IconDefinition,
    button: {
      text: 'Close Page',
      handler: () => {
        window.close()
      },
    },
  },
  [ErrorCode.InternalServerError]: {
    code: ErrorCode.InternalServerError,
    title: 'Internal error',
    message: 'Oops something went wrong! Please close this page and try again.',
    color: '#F7AB1B',
    icon: faExclamationTriangleRegular as IconDefinition,
    button: {
      text: 'Close Page',
      handler: () => {
        window.close()
      },
    },
  },
  [ErrorCode.BadRequestError]: {
    code: ErrorCode.BadRequestError,
    title: 'Bad Request error',
    message: 'Oops something went wrong! Please close this page and try again.',
    color: '#F7AB1B',
    icon: faExclamationTriangleRegular as IconDefinition,
    button: {
      text: 'Close Page',
      handler: () => {
        window.close()
      },
    },
  },
  [ErrorCode.UnexpectedError]: {
    code: ErrorCode.BadRequestError,
    title: 'Unexpected error',
    message: 'Oops something went wrong! Please close this page and try again.',
    color: '#F7AB1B',
    icon: faExclamationTriangleRegular as IconDefinition,
    button: {
      text: 'Close Page',
      handler: () => {
        window.close()
      },
    },
  },
}

export function getError(errorCode: ErrorCode): ErrorUI {
  return errorMapper[errorCode]
}
