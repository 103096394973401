/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useClientColors } from '@hooks'
import { Root, ImageContainer, Image, Text, Highlighted } from './styles'

export interface IListItemOwnProps {
  text: string
  imgUrl: string
  item: any
  highlight: string | undefined
  onItemClick: (item: any) => void
}

interface IListItemStyleProps {
  imgContainerSize: number
  imgSize: number
}

type IListItemProps = IListItemOwnProps & IListItemStyleProps

const ListItem = ({
  text,
  imgUrl,
  highlight,
  onItemClick,
  item,
  imgContainerSize,
  imgSize,
}: IListItemProps) => {
  const { primaryColor, tertiaryColor_10 } = useClientColors()

  let highlighted: string[]
  let unhighlighted: string[]
  if (highlight) {
    const re = new RegExp(highlight, 'ig')
    highlighted = text.match(re)!
    unhighlighted = text.split(re)
  }

  const renderImage = () => (
    <ImageContainer imgContainerSize={imgContainerSize}>
      <Image src={imgUrl} alt={text} imgSize={imgSize} />
    </ImageContainer>
  )

  const renderText = () => (
    <Text>
      {highlight
        ? unhighlighted!.map((str, i) => {
            const key = `${str}${i}`
            if (i === 0) {
              return <span key={key}>{str}</span>
            }
            return (
              <React.Fragment key={key}>
                <Highlighted color={primaryColor}>{highlighted.shift()}</Highlighted>
                <span>{str}</span>
              </React.Fragment>
            )
          })
        : text}
    </Text>
  )
  return (
    <Root onClick={() => onItemClick(item)} hoverColor={tertiaryColor_10}>
      {renderImage()}
      {renderText()}
    </Root>
  )
}

export default ListItem
