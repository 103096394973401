export const BreakPoints = {
  xs: 475,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
} as const
export type BreakPoints = (typeof BreakPoints)[keyof typeof BreakPoints]

export type Dimensions = keyof typeof BreakPoints

export const BreakPointsPixels = {
  xs: `${BreakPoints.xs}px`,
  sm: `${BreakPoints.sm}px`,
  md: `${BreakPoints.md}px`,
  lg: `${BreakPoints.lg}px`,
  xl: `${BreakPoints.xl}px`,
} as const

export const device = {
  xs: `max-width: ${BreakPointsPixels.xs}`,
  sm: `max-width: ${BreakPointsPixels.sm}`,
  md: `max-width: ${BreakPointsPixels.md}`,
  lg: `max-width: ${BreakPointsPixels.lg}`,
  xl: `max-width: ${BreakPointsPixels.xl}`,
}
