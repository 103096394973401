import { useNestedTranslation, usePermissionsList } from '@hooks'
import { isOnlyInfoScope } from '@utils/scope'

// This turns the list (array) of permissions into a string
// Example
// - Full name
// - Debits and standing orders
// - Balance
// -----> full name, balance, debits, standing orders and balance
function usePermissionsString({
  scope,
  providerName,
  useNewCopy = false,
}: {
  scope: string[]
  providerName: string
  useNewCopy?: boolean
}) {
  const t = useNestedTranslation('v2_consent', ['what_data_am_i_sharing'])
  const permissions = usePermissionsList(scope, { useNewCopy }).reduce((permissions, current) => {
    const permissionChunks = current
      .split(` ${t('list.and')} `)
      .map((chunk) => chunk.toLocaleLowerCase())

    return [...permissions, ...permissionChunks]
  }, [] as string[])

  if (isOnlyInfoScope(scope)) {
    return t('disclaimer.info_scope', { providerName })
  }

  if (permissions.length === 1) {
    return permissions[0]
  }

  return `${permissions.slice(0, permissions.length - 1).join(', ')} ${t('list.and')} ${
    permissions[permissions.length - 1]
  }`
}

export default usePermissionsString
