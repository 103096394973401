/* eslint-disable @typescript-eslint/no-explicit-any */
import ActionTypeKeys from '../actions/ActionTypeKeys'
import ActionTypes from '../actions/ActionTypes'
import { IProvidersState } from '../store/IStoreState'

const initialState: IProvidersState = {
  items: [],
  // TODO: Why do we need to specify default selected provider?
  selectedProvider: {} as any,
}

export default function providersReducer(
  state: IProvidersState = initialState,
  action: ActionTypes,
): IProvidersState {
  switch (action.type) {
    case ActionTypeKeys.SAVE_PROVIDERS_LIST:
      return {
        ...state,
        items: action.payload,
      }

    case ActionTypeKeys.SET_SELECTED_PROVIDER: {
      const provider: any = action.payload || {}
      return {
        ...state,
        selectedProvider: provider,
      }
    }

    case ActionTypeKeys.SET_SELECTED_BRANCH: {
      const branch = action.payload
      return {
        ...state,
        selectedProvider: { ...state.selectedProvider, selectedBranchId: branch },
      }
    }

    default:
      return state
  }
}
