import styled from 'styled-components'
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome'
import { EuTooltip as CoreTooltip } from '@prisma'

export const QuestionIcon = styled(FaIcon)`
  align-self: center;
  position: absolute;
  right: -2px;
  color: #ccc;
`

export const QuestionIconContainer = styled.div`
  display: inline-flex;
  position: absolute;
  right: -20px;
`

export const Tooltip = styled(CoreTooltip)`
  display: inherit;
`
