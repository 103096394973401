import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import LanguageDetector from 'i18next-browser-languagedetector'
import resourcesToBackend from 'i18next-resources-to-backend'
import { FALLBACK_LANG } from '@utils/i18n'
import * as Sentry from '@utils/sentry'

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'language_id',
}

const languageDetector = new LanguageDetector()

const missingKeyErrorsMap: { [key: string]: boolean } = {}

// What does this mean and why the heck is the variable name `__not_used`?
// The i18next library has a concent of a default namespace (abbreviated as "NS")
// When using the translated strings in React components, you normally do something like
// `const { t } = useTranslation('v2_providers')` where 'v2_providers` is the name of the namespace
// where the translated strings live.
// However, if you don't pass anything into the `useTranslation(...)` function and use it like this:
// `const { t } = useTranslation()`, the i18next library will fallback to the `defaultNS` specified in the
// config. (If not specified, it'll use the string "translation" as the default namespace.)
// Why would we ever want to use `useTranslation(...)` without passing the namespace, tho?
// In some scenarios (e. g. `src/contexts/end-user-components/index.tsx`, at the time of writing),
// we use the `useTranslation(...)` hook to get access to the `i18n` store to get the `language` from it.
// And in this scenario, we don't want to load any particular namespace, so we use it like this:
// `const { i18n } = useTranslation`. When we use it like this, however, the i18next library will try
// to fetch the translation file with the default namespace's name, which would normally fail as there's
// no such file. Hence we do `if (ns === DEFAULT_NS)` below to check if the `ns` to load corresponds to the
// default NS and if it does, we return an empty object instead of trying to fetch the translation file.
const DEFAULT_NS = '__not_used__'

const failedNamespaces = new Set()

i18n
  .use(
    resourcesToBackend((lng, ns, clb) => {
      if (ns === DEFAULT_NS) {
        return clb(null, {})
      }

      import(`../public/locales/${lng}/${ns}.json`)
        .then((resources) => {
          if (failedNamespaces.has(ns)) {
            failedNamespaces.delete(ns)
          }

          return clb(null, resources)
        })
        .catch((error) => {
          failedNamespaces.add(ns)

          // report failure to load translation resource file (due to a network error etc.)
          Sentry.exception(`Failed to load translations`, { extra: { error, lng, ns } })
          return clb(error, null)
        })
    }),
  )
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'de', 'es', 'fr', 'it', 'pl', 'sv', 'pt', 'nl', 'fi'],
    load: 'languageOnly',
    fallbackLng: FALLBACK_LANG,
    detection: options,
    debug: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    ns: [],
    defaultNS: DEFAULT_NS,
    saveMissing: true,
    missingKeyHandler(languages, ns, key) {
      const serializedKey = `${ns}:${key}`

      // prevent sending an error for each `t(...)` call
      // (the `t(...)` ) translation function gets call on every component re-render
      // resulting in the same error being reported many times and cluttering the logs
      const hasSentError = missingKeyErrorsMap[serializedKey]

      // if the resource file for translations failed to load for some reason (e. g. a network error)
      // we don't want to report all the missing keys in the this file to Sentry
      const failedNamespace = failedNamespaces.has(ns)

      if (!hasSentError && !failedNamespace) {
        Sentry.exception(`Missing translation key: ${ns}:${key}`, { extra: { languages } })

        missingKeyErrorsMap[serializedKey] = true
      }
    },
  })

export function getLanguageId() {
  return i18n.resolvedLanguage || i18n.languages[0] || i18n.language.substring(0, 2)
}

export default i18n
