import { AlphaNumericRegex, AwsS3UrlRegex, DefaultLogoUri, HexaRegex, UrlRegex } from './constants'
import { Customisation } from '../models/customisation'

export function isCustomisationValid(customisation: Customisation) {
  const ukReg = customisation.client_attributes?.regulatoryInformation?.uk
  const euReg = customisation.client_attributes?.regulatoryInformation?.eu

  return AlphaNumericRegex.test(customisation.client_name) &&
    UrlRegex.test(customisation.cancellation_uri) &&
    ukReg?.privacyPolicyUrl
    ? UrlRegex.test(ukReg?.privacyPolicyUrl)
    : true && ukReg?.registrationPageUrl
    ? UrlRegex.test(ukReg?.registrationPageUrl)
    : true && ukReg?.termsOfServiceUrl
    ? UrlRegex.test(ukReg?.termsOfServiceUrl)
    : true && euReg?.privacyPolicyUrl
    ? UrlRegex.test(euReg?.privacyPolicyUrl)
    : true && euReg?.registrationPageUrl
    ? UrlRegex.test(euReg?.registrationPageUrl)
    : true && euReg?.privacyPolicyUrl
    ? UrlRegex.test(euReg?.privacyPolicyUrl)
    : true &&
      (!customisation.data_use || customisation.data_use.length < 255) &&
      isAwsS3UrlValid(customisation.logo_uri) &&
      HexaRegex.test(customisation.primary_colour) &&
      HexaRegex.test(customisation.secondary_colour) &&
      HexaRegex.test(customisation.tertiary_colour)
}

export function isAwsS3UrlValid(logoUri: unknown) {
  if (logoUri instanceof Blob) {
    return true
  }

  const uri = logoUri as string

  return uri.length < 255 && (AwsS3UrlRegex.test(uri) || DefaultLogoUri.test(uri))
}
