export enum Env {
  Development = 'development',
  Sandbox = 'sandbox',
  Production = 'production',
}

const ENV_CONFIG = {
  [Env.Development]: {
    AUTH_DIALOG_URI: 'https://login.t7r.dev',
    CONSOLE_URI: 'https://console.t7r.dev',
    AUTH_DIALOG_BACKEND_URI: 'https://login-api.t7r.dev',
    AUTH_ANALYTICS_URI: 'https://auth-analytics.t7r.dev',
    APP_ENV: Env.Development,
    VWO_SDK_KEY: '772e6f41c4032b15de0c0d352bb2f80d',
  },
  [Env.Sandbox]: {
    AUTH_DIALOG_URI: 'https://login.truelayer-sandbox.com',
    CONSOLE_URI: 'https://console.truelayer.com',
    AUTH_DIALOG_BACKEND_URI: 'https://login-api.truelayer-sandbox.com',
    AUTH_ANALYTICS_URI: 'https://auth-analytics.truelayer-sandbox.com',
    APP_ENV: Env.Sandbox,
    VWO_SDK_KEY: '095a1e9bbcd4ad63cf5e24593cc0ffd5',
  },
  [Env.Production]: {
    AUTH_DIALOG_URI: 'https://login.truelayer.com',
    CONSOLE_URI: 'https://console.truelayer.com',
    AUTH_DIALOG_BACKEND_URI: 'https://login-api.truelayer.com',
    AUTH_ANALYTICS_URI: 'https://auth-analytics.truelayer.com',
    APP_ENV: Env.Production,
    VWO_SDK_KEY: 'ab5c2fd95dd23530ddaad0de7497ff8d',
  },
}

export function getEnv() {
  // get env vars based on the URL
  const { origin } = window.location

  switch (origin) {
    case ENV_CONFIG[Env.Production].AUTH_DIALOG_URI:
      return ENV_CONFIG[Env.Production]
    case ENV_CONFIG[Env.Sandbox].AUTH_DIALOG_URI:
      return ENV_CONFIG[Env.Sandbox]
    default:
      return ENV_CONFIG[Env.Development]
  }
}
