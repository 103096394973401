import { useTranslation } from 'react-i18next'

// Wrapper around react-i18next's t function to target deeply nested keys
// in translation files
const useNestedTranslation = (namespace: string, keys: string[]) => {
  const { t: t_original } = useTranslation(namespace)

  const keysPath = keys.join('.')

  if (keys.length) {
    return (key: string, object?: object) => t_original(`${keysPath}.${key}`, object)
  }

  return (key: string, object?: object) => t_original(key, object)
}

export default useNestedTranslation
