import React, { PropsWithChildren } from 'react'
import { FaIcon, chevronLeftSolid, timesRegular, arrowLeftRegular } from '@assets/icons'
import { useViewport, usePageContext } from '@hooks'
import { useTranslation } from 'react-i18next'
import { Root, LogoContainer, Logo, BackLink, BackContainer, CloseButton } from './styles'

export interface IHeaderProps {
  /**
   * Logo to be displayed at the center of the header
   */
  logo?: string | JSX.Element
  /**
   * Override default behaviour of GoBack
   */
  onClickGoBackOverride?: () => void
}

/**
 * The Header component serves a header and navigation bar in the default app page layout.
 */
const Header = ({ logo, onClickGoBackOverride }: PropsWithChildren<IHeaderProps>) => {
  const { t } = useTranslation('v2_layout')
  const { isMobile } = useViewport()
  const { goBack, closeApp } = usePageContext()

  const onClickGoBack = () => {
    onClickGoBackOverride ? onClickGoBackOverride() : goBack()
  }

  const renderMobileBack = () => (
    <div onClick={onClickGoBack} data-view="back-button">
      <FaIcon icon={chevronLeftSolid} />
    </div>
  )

  const renderDesktopBack = () => (
    <BackLink onClick={onClickGoBack} data-view="back-button">
      <FaIcon icon={arrowLeftRegular} />
      <div>{t('back')}</div>
    </BackLink>
  )

  return (
    <Root>
      <BackContainer>{isMobile ? renderMobileBack() : renderDesktopBack()}</BackContainer>
      {logo && (
        <LogoContainer>{typeof logo === 'string' ? <Logo src={logo} /> : logo}</LogoContainer>
      )}
        <CloseButton aria-label="Close Application" data-view="button-close-auth-dialog" onClick={closeApp} >
            <FaIcon icon={timesRegular}  />
        </CloseButton>
      
    </Root>
  )
}

export default Header
