/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { ListItem } from './components'
import { Root } from './styles'

interface IListOwnProps {
  /**
   * List of objects representing the items
   */
  items: any[]
  /**
   * Object property to be used as unique key
   */
  keyProp: string
  /**
   * Object property to be used as display text
   */
  textProp: string
  /**
   * Object property to be used as image source
   */
  imgProp: string
  /**
   * Substring to highlight
   */
  highlight?: string
  /**
   * Function to execuse when an item is clicked
   */
  onItemClick: (item: any) => void
  className?: string
}

export interface IListStyleProps {
  /**
   *  The width/height of the image in pixels
   */
  imgContainerSize?: number
  /**
   *  The width/height of the image in pixels
   */
  imgSize?: number
}

export type IListProps = IListOwnProps & IListStyleProps

const List = ({
  items,
  keyProp,
  textProp,
  imgProp,
  highlight,
  onItemClick,
  imgContainerSize = 36,
  imgSize = 36,
  className,
}: IListProps) => (
  <Root className={className}>
    {items.map((item) => (
      <ListItem
        key={item[keyProp]}
        onItemClick={onItemClick}
        imgUrl={item[imgProp]}
        text={item[textProp]}
        item={item}
        imgContainerSize={imgContainerSize}
        imgSize={imgSize}
        highlight={highlight}
      />
    ))}
  </Root>
)

export default List
