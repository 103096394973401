import { device } from '@constants/devices'
import { hideScrollBar } from '@styles'
import styled from 'styled-components'

export const Root =styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  position: relative;
  ${hideScrollBar}

  &:after {
    content: '';
    display: block;
    position: sticky;
    z-index: 2;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    height: 40px;
    flex-shrink: 0;
    bottom: 0;
    ${device.mobile} {
      width: 100%;
    }
  }
`

export const TopGradient = styled.div`
  content:'';
  height: 24px;
  background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  position: sticky;
  z-index: 2;
  top: 0;
  pointer-events: none;
`;