import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Container = styled.div<{ color: string; bgColor: string }>`
  border: 1px solid ${(props) => props.color};
  border-radius: 3px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 17px;
`

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Message = styled.div`
  font-size: 12px;
  margin-left: 10px;
`

export const NotificationIcon = styled(FontAwesomeIcon)`
  height: 16px;
`
