import styled from 'styled-components'
import { ContainerStyle } from '@styles'
import FooterText from '@components/FooterText'

const Root = styled(FooterText)`
  margin-top: 16px;
  ${ContainerStyle}
`

export default Root
