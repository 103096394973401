/* eslint-disable @typescript-eslint/no-explicit-any */
import IProvider from '@models/IProvider'
import { isEmpty } from '@utils/helpers'
import { Page, PagePath } from '@models/IPage'
import { isCsMock } from '@utils/providers'
import { isSplashScreenEnabled } from '@utils/client'
import { LoginStatus } from '@models/loginStatus'

export const getPage = (
  isLoading: boolean,
  selectedProvider: IProvider,
  consentId: string | undefined,
  selectedCountry: string | undefined,
  multipleCountries: boolean,
  loginInformation: any,
  authUriMode: string,
  extendConsent: boolean,
  clientId: string,
  splashScreenVisited: boolean,
  hasMfaData: boolean,
  loginStatus: LoginStatus,
  shouldRenderConsentBeforeAuthInputs: boolean,
): Page | undefined => {
  if (window.location.pathname === PagePath.OVERVIEW) {
    return Page.OVERVIEW
  }

  if (window.location.pathname === PagePath.OVERVIEW_V2) {
    return Page.OVERVIEW_V2
  }

  if (window.location.pathname === PagePath.CLIENT_ERROR) {
    return Page.CLIENT_ERROR
  }

  if (window.location.pathname === PagePath.NEXI) {
    return Page.NEXI
  }

  if (!isLoading) {
    if (window.location.pathname === PagePath.INTERACTIVE) {
      return Page.INTERACTIVE
    }

    if (extendConsent) {
      return Page.CONSENT_RECONFIRMATION
    }

    if (isSplashScreenEnabled(clientId)) {
      if (!splashScreenVisited) {
        return Page.SPLASH_SCREEN
      }
    }

    if (!selectedCountry && multipleCountries) {
      return Page.COUNTRIES
    }

    if (isEmpty(selectedProvider)) {
      return Page.PROVIDERS
    }

    if (selectedProvider.branches && !selectedProvider.selectedBranchId) {
      return Page.BRANCHES
    }

    if (selectedProvider.auth_inputs.length) {
      // Special flow: for re-auth, skip directly to LOGIN
      if (authUriMode === 'ReAuth' && !isCsMock(selectedProvider)) {
        return Page.LOGIN
      }

      // Special flow: CONSENT -> AUTHINPUTS
      if (shouldRenderConsentBeforeAuthInputs) {
        if (!consentId) {
          return Page.CONSENT
        }

        if (isEmpty(loginInformation)) {
          return Page.AUTHINPUTS
        }
      } else {
        // Normal flow: AUTHINPUTS -> CONSENT
        if (isEmpty(loginInformation)) {
          return Page.AUTHINPUTS
        }

        if (!consentId) {
          return Page.CONSENT
        }
      }

      if (hasMfaData && loginStatus !== LoginStatus.InProgress) {
        return Page.MFA
      }
      return Page.LOGIN
    }

    if (!consentId) {
      return Page.CONSENT
    }

    if (loginStatus === LoginStatus.Fail) {
      return Page.LOGIN_ERROR
    }

    if (hasMfaData && loginStatus !== LoginStatus.InProgress) {
      return Page.MFA
    }

    return Page.LOGIN
  }
  return undefined
}

export const getPath = (page: Page | undefined): PagePath | string => {
  switch (page) {
    case Page.COUNTRIES:
      return PagePath.COUNTRIES
    case Page.PROVIDERS:
      return PagePath.PROVIDERS
    case Page.AUTHINPUTS:
      return PagePath.AUTH_INPUTS
    case Page.CONSENT:
      return PagePath.CONSENT
    case Page.NEXI:
      return PagePath.NEXI
    case Page.MFA:
      return PagePath.MFA
    case Page.LOGIN:
      return PagePath.LOGIN
    case Page.LOGIN_ERROR:
      return PagePath.LOGIN_ERROR
    case Page.IOS_REDIRECT:
      return PagePath.IOS_REDIRECT
    case Page.CLIENT_ERROR:
      return PagePath.CLIENT_ERROR
    case Page.INTERACTIVE:
      return PagePath.INTERACTIVE
    case Page.OVERVIEW:
      return PagePath.OVERVIEW
    case Page.CONSENT_RECONFIRMATION:
      return PagePath.CONSENT_RECONFIRMATION
    case Page.SPLASH_SCREEN:
      return PagePath.SPLASH_SCREEN
    case Page.BRANCHES:
      return PagePath.BRANCHES
    default:
      return window.location.pathname
  }
}
