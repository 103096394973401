import styled, { css } from 'styled-components'
import { gap } from '@styles'
import { device } from '@constants/devices'

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  padding: 92px 30px;
  ${device.tablet} {
    padding: 92px 160px;
  }
`

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${gap(22, 'px', 'v')}
  font-size: 14px;
`

export const ButtonsContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  width: 100%;
  ${(props) => !props.isMobile && gap(6, 'px', 'h')}
  ${(props) => props.isMobile && ButtonContainerMobileStyle}
`

const ButtonContainerMobileStyle = css`
  flex-direction: column;
  ${gap(10, 'px', 'v')}
`
