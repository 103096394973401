import { isEqual } from 'lodash'
import usePermissionsList from '@hooks/usePermissionsList'

function useShouldDisplayProviderScopes({
  scope,
  providerScope,
}: {
  scope: string[]
  providerScope: string[]
}) {
  const permissionsList = usePermissionsList(scope)
  const clientPermissionsList = usePermissionsList(providerScope)

  return !isEqual(permissionsList, clientPermissionsList)
}

export default useShouldDisplayProviderScopes
