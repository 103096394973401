enum ActionTypeKeys {
  /* countries */
  SAVE_COUNTRIES_LIST = 'SAVE_COUNTRIES_LIST',
  /* providers */
  SAVE_PROVIDERS_LIST = 'SAVE_PROVIDERS_LIST',
  SET_SELECTED_PROVIDER = 'SET_SELECTED_PROVIDER',
  SET_SELECTED_BRANCH = 'SET_SELECTED_BRANCH',
  SET_DEFAULT_PROVIDER_PARAMS = 'SET_DEFAULT_PROVIDER_PARAMS',
  UNSET_SELECTED_PROVIDER = 'UNSET_SELECTED_PROVIDER',
  SET_SELECTED_AUTH_METHOD = 'SET_SELECTED_AUTH_METHOD',
  UNSET_SELECTED_AUTH_METHOD = 'UNSET_SELECTED_AUTH_METHOD',
  UPDATE_AUTH_METHOD = 'UPDATE_AUTH_METHOD',
  UPDATE_AUTH_METHOD_FIELD = 'UPDATE_AUTH_METHOD_FIELD',
  /* login */
  SET_LOGIN_STATUS = 'SET_LOGIN_STATUS',
  ADD_LOGIN_INFORMATION = 'ADD_LOGIN_INFORMATION',
  RESET_LOGIN_INFORMATION = 'RESET_LOGIN_INFORMATION',
  SET_MFA_DATA = 'SET_MFA_DATA',
  SET_RETURN_URL = 'SET_RETURN_URL',
  RESET_RETURN_URL = 'RESET_RETURN_URL',
  /* general */
  SET_CLIENT_ERROR = 'SET_CLIENT_ERROR',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_DIALOG_VERSION = 'SET_DIALOG_VERSION',
  PROCEED_FROM_SPLASH_SCREEN = 'PROCEED_FROM_SPLASH_SCREEN',
  RESET_SPLASH_SCREEN_VISITED = 'RESET_SPLASH_SCREEN_VISITED',
  AMAZON_CONSENT_ACCORDION_OPENED = 'AMAZON_CONSENT_ACCORDION_OPENED',
  AMAZON_CONSENT_CHANGE_BANK_CLICKED = 'AMAZON_CONSENT_CHANGE_BANK_CLICKED',
  SET_AB_TESTS = 'SET_AB_TESTS',
  /* client */
  SAVE_CLIENT_SETTINGS = 'SAVE_CLIENT_SETTINGS',
  SAVE_CLIENT_ATTRIBUTES = 'SAVE_CLIENT_ATTRIBUTES',
  SAVE_SESSION = 'SAVE_SESSION',
  SET_CONSENT_ID = 'SET_CONSENT_ID',
  /* countries */
  SET_SELECTED_COUNTRY = 'SET_SELECTED_COUNTRY',
  SET_FLOW_TYPE = 'SET_FLOW_TYPE',
}

export default ActionTypeKeys
