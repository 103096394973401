import React from 'react'
import { useNestedTranslation } from '@hooks'
import useConsent from '../../hooks'

function ConsentDataUseAccessNote() {
  const t = useNestedTranslation('v2_consent', ['how_is_my_data_used'])
  const { clientName, scope } = useConsent()

  const ongoingAccess = scope.includes('offline_access')

  return ongoingAccess ? (
    <div data-view="stop-access-data-information">
      {t('access.ongoing', {
        clientName,
      })}
      .
    </div>
  ) : (
    <div data-view="one-time-access">
      {t('access.one_time', {
        clientName,
      })}
      .
    </div>
  )
}

export default ConsentDataUseAccessNote
