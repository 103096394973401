import Color from 'color'
import { useSelector } from 'react-redux'
import IStoreState from '@store/IStoreState'
import { primaryColour, secondaryColour, tertiaryColour } from '@config/defaultColors'

const colorsSelector = (state: IStoreState) => {
  const settings = state.client.clientSettings

  return {
    primaryColor: settings.primary_colour || primaryColour,
    secondaryColor: settings.secondary_colour || secondaryColour,
    tertiaryColor: settings.tertiary_colour || tertiaryColour,
  }
}

const useClientColors = () => {
  const { primaryColor, secondaryColor, tertiaryColor } = useSelector(colorsSelector)

  return {
    primaryColor,
    secondaryColor,
    secondaryColor_80: Color(secondaryColor).alpha(0.8).toString(),
    tertiaryColor,
    tertiaryColor_10: Color(tertiaryColor).alpha(0.1).toString(),
  }
}

export default useClientColors
