import axios from 'axios'
import IProvider from '../models/IProvider'
import { getDialogBackendUrl, getProviderId, getSessionId } from '../utils/helpers'
import { EventType, getClientInfos, IBaseEvent } from './analytics'
import { nameCustomisations, getFlagFromCode, codeCustomisations } from '../utils/country'
import { handleApiError } from './http-error-handler'
import { ISessionResponse } from '@models/IResponse'

export const backend = axios.create({
  baseURL: getDialogBackendUrl(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

backend.interceptors.response.use(
  (response) => response,
  (error) => {
    throw handleApiError(error)
  },
)

export const getClientRequest = async () => {
  const response = await backend.get('/api/v1/client', {
    params: {
      ...getSessionId(),
    },
  })

  return response.data
}

export const getSessionRequest = async () => {
  const { sessionId } = getSessionId()
  const response = await backend.get<ISessionResponse>(`/api/v1/sessions/${sessionId}`)

  return response.data
}

export const updateSessionRequest = async ({
  sessionId,
  languageId,
  dataUseDescription,
}: {
  sessionId: string
  languageId: string
  dataUseDescription: string
}) => {
  await backend.put(`/api/v1/sessions/${sessionId}`, {
    language_id: languageId,
    data_use_description: dataUseDescription,
  })
}

export const grantConsentRequest = async (
  dataUse: string,
  providerId: string,
  languageCode: string,
) => {
  const body = {
    data_use_description: dataUse,
    provider_id: providerId,
    language_code: languageCode,
  }
  const response = await backend.post('/api/v1/consents', body, {
    params: {
      ...getSessionId(),
    },
  })

  return response.data.id
}

export const getCountriesRequest = async () => {
  const response = await backend.get('/api/v1/countries')

  const isInCodeCustomisations = (code: string): code is keyof typeof codeCustomisations =>
    code in codeCustomisations

  const isInNameCustomisations = (code: string): code is keyof typeof nameCustomisations =>
    code in nameCustomisations

  return response.data.countries.map((country: Record<string, string>) => ({
    code: (isInCodeCustomisations(country.alpha_2_code)
      ? codeCustomisations[country.alpha_2_code]
      : country.alpha_2_code
    ).toLowerCase(),
    displayName: isInNameCustomisations(country.name)
      ? nameCustomisations[country.name]
      : country.name,
    logoUrl: getFlagFromCode(country.alpha_2_code.toLowerCase()),
  }))
}

export const getProvidersRequest = async () => {
  const response = await backend.get('/providers', {
    params: {
      ...getSessionId(),
    },
  })

  let providers: IProvider[] = []

  if (response.data.results.length > 0) {
    providers = response.data.results.map(convertProvider)
  }

  return providers
}

export const loginRequest = async (data: object, params?: object) => {
  // TODO: Add correct typing
  const response = await backend.post('/api/v1/login', data, {
    params: { ...getSessionId() },
  })
  return response
}

export const authUriRequest = async (
  event: IBaseEvent,
  provider: IProvider,
  authInputs = {},
  preConsent = false,
  abortSignal: AbortSignal | undefined = undefined,
) => {
  const analyticsData = getClientInfos(event, provider)

  let branchData

  if (provider.branches) {
    branchData = { [provider.branches.authInputKey]: provider.selectedBranchId }
  }
  const data = {
    auth_inputs: { ...authInputs, ...branchData },
    ...analyticsData,
    event: EventType.AuthStart,
  }

  const response = await backend.post('/api/v1/authuri', data, {
    params: {
      ...getSessionId(),
      connectorId: provider.connector_id,
      preConsent,
    },
    signal: abortSignal,
  })

  return response
}

export const errorInfoRequest = async (errorId: string) => {
  const response = await backend.get(`/api/v1/errors/${errorId}`)
  return response
}

// [Restructuration AWS S3] Needed to be able to deploy auth dialog
// spa independendly of auth server => no breaking time for the user
const convertProvider = (provider: IProvider) => ({
  ...provider,
  provider_id: getProviderId(provider),
  provider_scope_mappings: provider.provider_scope_mappings ?? {},
})

export const consentReconfirmationRequest = async (connectionId: string) => {
  const response = await backend.post(`/api/v1/connections/${connectionId}/extend`)
  return response
}

export const consentWithdrawalRequest = async (connectionId: string) => {
  const response = await backend.delete(`/api/v1/connections/${connectionId}/revoke`)
  return response
}
