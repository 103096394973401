interface IError {
  code: ErrorCode
  message: string
  details?: string
}

export enum ErrorCode {
  UnauthorizedSession = 'unauthorized_session',
  NoSessionId = 'no_session_id',
  InternalServerError = 'internal_server_error',
  BadRequestError = 'bad_request_error',
  UnexpectedError = 'unexpected_error',
}

export class AuthDialogSpaError extends Error implements IError {
  code: ErrorCode

  details?: string | undefined

  constructor(code: ErrorCode, message: string, details?: string) {
    super(message)
    this.code = code
    this.details = details
  }

  static Create(code: ErrorCode, message: string, details?: string): Error {
    return new AuthDialogSpaError(code, message, details)
  }
}
