import React from 'react'
import { DefaultPageLayout } from '@layouts'
import { useClientLogo, usePageContext } from '@hooks'
import { useTranslation } from 'react-i18next'
import useStateRestore from '@hooks/useStateRestore'
import { Page } from '@models/IPage'
import { CountrySelect } from '../../end-user-components'
import useCountries from './hooks'
import { useProcessedList } from '@hooks/useListSearch/utils'
import { Container } from './styles'

const Countries = () => {
  const { countries, onCountryClick, countrySelected } = useCountries()
  const clientLogo = useClientLogo()

  const { t } = useTranslation('v2_countries')

  const items = useProcessedList(countries, {
    listKey: 'displayName',
    topValues: ['United Kingdom'],
    filterOutTerm: undefined,
    localised: true,
    localisedPrefixKey: 'code',
    localisedKeySuffix: '_display_name',
  })

  const { changePage } = usePageContext()

  useStateRestore(Page.COUNTRIES)

  const onCountrySelectChange = async (countryCode: string) => {
    await onCountryClick(countryCode)

    changePage(Page.PROVIDERS)
  }

  return (
    <DefaultPageLayout title={t('title')} logo={clientLogo}>
      <div data-view="page-id-countries"></div>
      <Container>
        <CountrySelect
          value={countrySelected}
          name={t('title')}
          countries={items.map((country) => ({
            code: country.code,
            name: country.displayName,
            icon_uri: country.logoUrl,
          }))}
          onChange={onCountrySelectChange}
        />
      </Container>
    </DefaultPageLayout>
  )
}

export default Countries
