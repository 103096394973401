/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IValidationRule, AllowedCharacters } from '@models/IAuthInput'

/*
    FREE INPUT FIELD
*/

export const validated = (input: string, validation: IValidationRule): boolean =>
  RegExp(validation.regex).test(input)

export const isEmpty = (input: string): boolean => input === ''

export const passesValidations = (input: string, validations: IValidationRule[]): boolean => {
  if (validations) {
    return validations.every((v) => validated(input, v))
  }
  return true
}

export const isValidInput = (input: string, validations: IValidationRule[]): boolean =>
  !isEmpty(input) && passesValidations(input, validations)

export const getWrongValidation = (
  input: string,
  validations: IValidationRule[],
): IValidationRule => validations.find((v) => !validated(input, v))!

export const getFreeInputType = (type: AllowedCharacters, isSensitive: boolean) => {
  if (isSensitive) {
    return 'password'
  }
  switch (type) {
    case 'Alphanumeric':
      return 'text'
    case 'Numeric':
      return 'number'
    default:
      return 'text'
  }
}
