import { IClientAttributes } from '../store/IStoreState'
import { SPLASH_SCREEN_ENABLED_CLIENT_IDS } from './constants'

export const getRegulatoryInformation = ({
  activeCountry,
  clientAttributes,
}: {
  activeCountry: string
  clientAttributes: IClientAttributes
}) => {
  return activeCountry === 'uk'
    ? clientAttributes.regulatory_information.uk
    : clientAttributes.regulatory_information.eu
}

export const isClientRegulated = ({
  activeCountry,
  clientAttributes,
}: {
  activeCountry: string
  clientAttributes: IClientAttributes
}) => {
  const regulatoryInformation = getRegulatoryInformation({ activeCountry, clientAttributes })

  return regulatoryInformation?.ais_regulated ?? false
}

export const isSplashScreenEnabled = (clientId: string) => {
  return SPLASH_SCREEN_ENABLED_CLIENT_IDS.includes(clientId);
}