import IStoreState from '@store/IStoreState'

export const getAbTestsPayload = (state: IStoreState) => {
  const { abTests } = state.general

  // we only want to include those values with 'A' or 'B'
  // as the `null` value means that the user is not
  // part of the test
  return Object.keys(abTests).reduce((acc, key) => {
    const value = abTests[key as keyof typeof abTests]

    if (value === null) {
      return acc
    }

    // we need to prefix the key with `ab_test_` so that
    // it is more readable in Mixpanel
    const payloadKey = `ab_test_${key}`

    return { ...acc, [payloadKey]: value }
  }, {} as Record<string, 'A' | 'B'>)
}
