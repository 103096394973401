import IProvider from '@models/IProvider'
import { useState } from 'react'
import useFilteredOptions from '../../../end-user-components/hooks/useFilteredOptions'
import { OptionEntity } from 'src/end-user-components/components/form/types'

const useProviderSearch = (providers: IProvider[]) => {
  const [searchTerm, setSearchTerm] = useState('')
  const { filteredOptions } = useFilteredOptions<IProvider>(
    searchTerm,
    providers.map((p) => ({ value: p, label: p.display_name })),
  )

  const filteredProviders = filteredOptions.map((x) => (x as OptionEntity<IProvider>).value)

  return {
    searchTerm,
    setSearchTerm,
    filteredProviders,
  }
}

export default useProviderSearch
