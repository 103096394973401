import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import IStoreState from '../../store/IStoreState'
import keys from '../ActionTypeKeys'
import { getUriWithQueryParams } from '../../utils/helpers'
import {
  ISetIsLoading,
  ISetDialogVersion,
  IProceedFromSplashScreen,
  IResetSplashScreenVisited,
  ISetAmazonConsentAccordionOpened,
  ISetAmazonConsentChangeBankClicked,
  ISetAbTests,
} from './IGeneralActions'
import { CancelEventType } from '../../middlewares/analytics'
import { sendConsentCanceledAnalytics } from '../analytics'
import { setCountry } from '@actions/countries/countriesActions'
import { setSelectedProvider } from '@actions/providers/providersActions'
import { getAlpha2CountryCode } from '@utils/country'
import { redirectToExternalUrl } from '@utils/navigation'

export function setClientError(
  clientErrorCode: string,
  clientErrorMessage: string,
  withRequestId = true,
) {
  return {
    payload: { clientErrorCode, clientErrorMessage, withRequestId },
    type: keys.SET_CLIENT_ERROR,
  }
}

export function setIsLoading(value: boolean): ISetIsLoading {
  return {
    payload: value,
    type: keys.SET_IS_LOADING,
  }
}

export function setDialogVersion(value: number): ISetDialogVersion {
  return {
    payload: value,
    type: keys.SET_DIALOG_VERSION,
  }
}

export function proceedFromSplashScreen(): IProceedFromSplashScreen {
  return {
    type: keys.PROCEED_FROM_SPLASH_SCREEN,
  }
}

export function resetSplashScreenVisited(): IResetSplashScreenVisited {
  return {
    type: keys.RESET_SPLASH_SCREEN_VISITED,
  }
}

export function setAmazonConsentAccordionOpened(): ISetAmazonConsentAccordionOpened {
  return {
    type: keys.AMAZON_CONSENT_ACCORDION_OPENED,
  }
}

export function setAmazonConsentChangeBankClicked(): ISetAmazonConsentChangeBankClicked {
  return {
    type: keys.AMAZON_CONSENT_CHANGE_BANK_CLICKED,
  }
}

/*
 * Set the page title to the client name + " - Connect your bank account"
 */

export const setTitlePage: ActionCreator<ThunkAction<unknown, IStoreState, void, Action>> =
  (pageTitle: string) => () => {
    if (pageTitle) {
      document.title = `${pageTitle} - Connect your bank account`
    } else {
      document.title = 'TrueLayer - Connect your bank account'
    }
  }

export const cancelClickedAction: ActionCreator<ThunkAction<void, IStoreState, void, Action>> =
  (event: CancelEventType) =>
  (dispatch: ThunkDispatch<IStoreState, void, Action>, getState: () => IStoreState) => {
    const { client } = getState()
    const { clientSettings, redirectUri } = client
    const { cancellation_uri } = clientSettings

    dispatch(sendConsentCanceledAnalytics(event))

    const stateParameter = client.state ? `state=${client.state}` : ''

    if (cancellation_uri) {
      redirectToExternalUrl(getUriWithQueryParams(cancellation_uri, [stateParameter]))
    } else if (redirectUri) {
      redirectToExternalUrl(
        getUriWithQueryParams(redirectUri, ['error=access_denied', stateParameter]),
      )
    }
  }

export const handleCountryAndProviders: ActionCreator<
  ThunkAction<Promise<void>, IStoreState, void, Action>
> =
  () =>
  async (
    dispatch: ThunkDispatch<IStoreState, void, Action>,
    getState: () => IStoreState,
  ): Promise<void> => {
    const {
      client,
      providers: { items: providers },
    } = getState()
    const { countryId } = client

    if (countryId) {
      dispatch(setCountry(getAlpha2CountryCode(countryId)))

      const providersFromCountry = providers.filter((x) => x.country === countryId)
      if (providersFromCountry.length === 1) {
        dispatch(setSelectedProvider(providersFromCountry[0]))
      }
    } else {
      // try to select country by browser locale

      // all distinct countries from providers
      const countryCodes = Array.from(new Set(providers.map((p) => p.country)))
      const browserCountryCode = navigator.language.split('-')[1]?.toLowerCase() ?? null

      const browserCountry = countryCodes?.find(
        (code) => code === getAlpha2CountryCode(browserCountryCode),
      )

      if (browserCountry) {
        dispatch(setCountry(browserCountry))
      }
    }
  }

export function setAbTests(payload: ISetAbTests['payload']): ISetAbTests {
  return {
    type: keys.SET_AB_TESTS,
    payload,
  }
}
