import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IButton, INotification } from '@models/IAuthInput'
import {
  resetLoginInformation,
  addLoginInformation,
  setLoginStatus,
} from '@actions/login/loginActions'
import { useNestedTranslation } from '@hooks'
import { useTranslation } from 'react-i18next'
import IStoreState from '@store/IStoreState'
import { LoginStatus } from '@models/loginStatus'

const useMfa = () => {
  const { t } = useTranslation('formNames')
  const mfaData = useSelector((state: IStoreState) => state.login.mfaData)
  const { id, fields: challenges, buttons, notifications } = mfaData!
  const [formData, setFormData] = useState<Record<string, string | null | undefined>>({})
  const [mfaCompleted, setMfaCompleted] = useState(false)

  useEffect(() => {
    setMfaCompleted(
      Object.keys(formData).length === challenges.length &&
        Object.values(formData).every((val) => val !== undefined && val !== '' && val !== null),
    )
  }, [formData, challenges.length])

  const dispatch = useDispatch()

  const handleContinue = () => {
    dispatch(resetLoginInformation())
    // This id is the page level id
    dispatch(addLoginInformation(formData))
    dispatch(setLoginStatus(LoginStatus.InProgress))
  }

  const saveAnswer = (id: string, answer: string | undefined) => {
    // This id is the field level id
    const newFormData = { ...formData, [id]: answer }
    setFormData(newFormData)
  }

  return {
    pageTitle: t(`${id}_form_display_name`),
    challenges,
    saveAnswer,
    handleContinue,
    mfaCompleted,
    buttonLabel: useLocalisedButtonLabel(buttons),
    notification: useLocalisedNotification(notifications),
  }
}

export const useLocalisedNotification = (notifications?: INotification[]) => {
  const t = useNestedTranslation('v2_mfa', ['notifications'])
  return notifications && notifications.length ? t(`${notifications[0].id}`) : undefined
}

const useLocalisedButtonLabel = (buttons?: IButton[]) => {
  const t = useNestedTranslation('v2_mfa', ['buttons'])
  return buttons && buttons.length ? t(`${buttons[0].id}`) : t('continue')
}

export default useMfa
