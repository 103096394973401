import React from 'react'
import { useTranslation } from 'react-i18next'

import { JumpToInputWrapper, JumpToInputLink } from './styles'

export function JumpToInput({
  refSearchInput,
}: {
  refSearchInput: React.RefObject<HTMLInputElement>
}) {
  const { t } = useTranslation('end_user_components')

  const handleClickBackToInput = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault()

    if (refSearchInput.current) {
      refSearchInput.current.getElementsByTagName('input')[0]?.focus()
    }
  }
  const handleKeyBackToInput = (evt: React.KeyboardEvent<HTMLElement>) => {
    evt.preventDefault()
    if (evt.code === 'Enter') {
      if (refSearchInput.current) {
        refSearchInput.current.getElementsByTagName('input')[0]?.focus()
      }
    }
  }

  return (
    <JumpToInputWrapper>
      <JumpToInputLink
        tabIndex={0}
        data-testid="jump.to.input"
        onClick={handleClickBackToInput}
        onKeyPress={handleKeyBackToInput}
      >
        {t('jump.to.input')}
      </JumpToInputLink>
    </JumpToInputWrapper>
  )
}
