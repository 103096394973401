import { useState } from 'react'

const useMultiClearTextInputValue = (
  id: string,
  saveData: (key: string, val: string | null | (string | null)[]) => void,
) => {
  const [values, setValues] = useState<(string | null)[]>([''])
  const setValuesWrapper = (i: number, val: string | null) => {
    const newValues = [...values]
    newValues[i] = val
    setValues(newValues)
    return newValues
  }
  const handleValue = (value: string | null, i: number) => {
    const newValues = setValuesWrapper(i, value)
    if (value === null) {
      saveData(id, null)
    } else {
      saveData(id, newValues)
    }
  }
  const addValue = () => {
    const i = values.length
    setValuesWrapper(i, '')
  }

  return {
    values,
    handleValue,
    addValue,
  }
}

export default useMultiClearTextInputValue
