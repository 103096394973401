import { maxMobileViewportWidth } from './viewport'

const size = {
  mobile: '1px',
  tablet: `${maxMobileViewportWidth}px`,
  desktop: '992px',
}

interface IDevice {
  mobile: string
  tablet: string
  desktop: string
}

export const device: IDevice = {
  mobile: `@media (min-width: ${size.mobile})`,
  tablet: `@media (min-width: ${size.tablet})`,
  desktop: `@media (min-width: ${size.desktop})`,
}
