export const PROVIDERS = [
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-revolut',
    provider_id: 'ob-revolut',
    connector_id: 'ob-revolut',
    name: 'revolut',
    display_name: 'Revolut',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/revolut.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/revolut.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/revolut.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/revolut.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/revolut.svg',
    main_bg_color: '#0067EA',
    main_button_color: '#eb008d',
    mobile_bg_color: '#0067EA',
    mobile_button_color: '#E652A4',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-lloyds-business',
    provider_id: 'ob-lloyds-business',
    connector_id: 'ob-lloyds-business',
    name: 'lloyds',
    display_name: 'Lloyds Business',
    country: 'uk',
    auth_method: 'ob',
    division: 'business',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/lloyds.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/lloyds.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/lloyds.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/lloyds.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/lloyds.svg',
    main_bg_color: '#00553e',
    main_button_color: '#649c00',
    main_dropdown_color: '#00553e',
    mobile_bg_color: '#00553e',
    mobile_button_color: '#649c00',
    mobile_help_link_color: '#649c00',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: ['info', 'balance', 'transactions', 'cards', 'offline_access'],
    id: 'ob-ms',
    provider_id: 'ob-ms',
    connector_id: 'ob-ms',
    name: 'ms',
    display_name: 'M&S Bank',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/ms.svg',
    white_logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/whites/ms.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/ms.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/alternatives/ms.svg',
    white_icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/whites/ms.svg',
    main_bg_color: '#F2F2F2',
    main_button_color: '#4D8210',
    mobile_bg_color: '#F2F2F2',
    mobile_button_color: '#4D8210',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-nationwide',
    provider_id: 'ob-nationwide',
    connector_id: 'ob-nationwide',
    name: 'nationwide',
    display_name: 'Nationwide',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/nationwide.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/whites/nationwide.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/nationwide.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/alternatives/nationwide.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/whites/nationwide.svg',
    main_bg_color: '#002878',
    main_button_color: '#83389b',
    mobile_bg_color: '#002878',
    mobile_button_color: '#83389b',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: ['info', 'accounts', 'balance', 'transactions', 'standing_orders', 'offline_access'],
    id: 'ob-chelsea-building-society',
    provider_id: 'ob-chelsea-building-society',
    connector_id: 'ob-chelsea-building-society',
    name: 'chelsea-building-society',
    display_name: 'Chelsea Building Society',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/chelsea-building-society.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/whites/chelsea-building-society.svg',
    icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/chelsea-building-society.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/alternatives/chelsea-building-society.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/whites/chelsea-building-society.svg',
    main_bg_color: '#E6E6E6',
    main_button_color: '#c22a2f',
    mobile_bg_color: '#E6E6E6',
    mobile_button_color: '#c22a2f',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: true,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-ulster-business',
    provider_id: 'ob-ulster-business',
    connector_id: 'ob-ulster-business',
    name: 'ulster',
    display_name: 'Ulster Bankline',
    country: 'uk',
    auth_method: 'ob',
    division: 'business',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/ulster.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/ulster.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/ulster.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/ulster.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/ulster.svg',
    main_bg_color: '#0a2f64',
    main_button_color: '#1e808f',
    mobile_bg_color: '#0a2f64',
    mobile_button_color: '#1e808f',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-bos',
    provider_id: 'ob-bos',
    connector_id: 'ob-bos',
    name: 'bos',
    display_name: 'Bank of Scotland',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/bos.svg',
    white_logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/whites/bos.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/bos.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/alternatives/bos.svg',
    white_icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/whites/bos.svg',
    main_bg_color: '#05286a',
    main_button_color: '#048161',
    main_dropdown_color: '#005ba2',
    mobile_bg_color: '#05286a',
    mobile_button_color: '#048161',
    mobile_help_link_color: '#7FADD0',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-lloyds-commercial',
    provider_id: 'ob-lloyds-commercial',
    connector_id: 'ob-lloyds-commercial',
    name: 'lloyds',
    display_name: 'Lloyds Commercial',
    country: 'uk',
    auth_method: 'ob',
    division: 'corporate',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/lloyds.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/lloyds.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/lloyds.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/lloyds.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/lloyds.svg',
    main_bg_color: '#00553e',
    main_button_color: '#649c00',
    main_dropdown_color: '#00553e',
    mobile_bg_color: '#00553e',
    mobile_button_color: '#649c00',
    mobile_help_link_color: '#649c00',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: ['info', 'balance', 'transactions', 'cards', 'offline_access'],
    id: 'ob-capital-one',
    provider_id: 'ob-capital-one',
    connector_id: 'ob-capital-one',
    name: 'capital-one',
    display_name: 'Capital One',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/capital-one.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/capital-one.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/capital-one.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/capital-one.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/capital-one.svg',
    main_bg_color: '#053877',
    main_button_color: '#007138',
    main_dropdown_color: '#053877',
    mobile_bg_color: '#053877',
    mobile_button_color: '#007138',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: true,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: ['info', 'balance', 'transactions', 'cards', 'offline_access'],
    id: 'oauth-amex',
    provider_id: 'oauth-amex',
    connector_id: 'oauth-amex',
    name: 'amex',
    display_name: 'Amex',
    country: 'uk',
    auth_method: 'oauth',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/amex.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/amex.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/amex.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/amex.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/amex.svg',
    main_bg_color: '#009abc',
    main_button_color: '#002663',
    main_dropdown_color: '#009abc',
    mobile_bg_color: '#16243d',
    mobile_button_color: '#009BB6',
    mobile_help_link_color: '#F1F1F1',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      cards: ['info', 'accounts', 'balance', 'transactions'],
      direct_debits: ['info', 'accounts', 'balance', 'transactions'],
      standing_orders: ['info', 'accounts', 'balance', 'transactions'],
      transactions: ['info', 'accounts', 'balance', 'transactions'],
      info: ['info', 'accounts', 'balance', 'transactions'],
      beneficiaries: ['info', 'accounts', 'balance', 'transactions'],
      accounts: ['info', 'accounts', 'balance', 'transactions'],
      balance: ['info', 'accounts', 'balance', 'transactions'],
      offline_access: ['info', 'accounts', 'balance', 'transactions'],
      scheduled_payments: ['info', 'accounts', 'balance', 'transactions'],
      info_name: ['info', 'accounts', 'balance', 'transactions'],
      info_date_of_birth: ['info', 'accounts', 'balance', 'transactions'],
      info_addresses: ['info', 'accounts', 'balance', 'transactions'],
      info_phone_numbers: ['info', 'accounts', 'balance', 'transactions'],
      info_email_addresses: ['info', 'accounts', 'balance', 'transactions'],
    },
  },
  {
    scopes: ['info', 'accounts', 'balance', 'transactions', 'cards', 'offline_access'],
    id: 'ob-first-direct',
    provider_id: 'ob-first-direct',
    connector_id: 'ob-first-direct',
    name: 'first-direct',
    display_name: 'first direct',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/first-direct.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/whites/first-direct.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/first-direct.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/alternatives/first-direct.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/whites/first-direct.svg',
    main_bg_color: '#626268',
    main_button_color: '#000000',
    main_dropdown_color: '#000000',
    mobile_bg_color: '#000000',
    mobile_button_color: '#878787',
    mobile_help_link_color: '#878787',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: ['info', 'accounts', 'balance', 'transactions', 'offline_access'],
    id: 'ob-transferwise',
    provider_id: 'ob-transferwise',
    connector_id: 'ob-transferwise',
    name: 'transferwise',
    display_name: 'Wise',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/transferwise.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/transferwise.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/transferwise.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/transferwise.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/transferwise.svg',
    main_bg_color: '#37517E',
    main_button_color: '#00B9FF',
    mobile_bg_color: '#37517E',
    mobile_button_color: '#00B9FF',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: ['balance', 'transactions', 'cards', 'offline_access'],
    id: 'ob-barclaycard',
    provider_id: 'ob-barclaycard',
    connector_id: 'ob-barclaycard',
    name: 'barclaycard',
    display_name: 'Barclaycard',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/barclaycard.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/barclaycard.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/barclaycard.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/barclaycard.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/barclaycard.svg',
    main_bg_color: '#018fd0',
    main_button_color: '#064884',
    main_dropdown_color: '#018fd0',
    mobile_bg_color: '#018fd0',
    mobile_button_color: '#064884',
    mobile_help_link_color: '#064884',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: ['balance', 'transactions', 'cards', 'offline_access'],
    id: 'ob-mbna',
    provider_id: 'ob-mbna',
    connector_id: 'ob-mbna',
    name: 'mbna',
    display_name: 'MBNA',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/mbna.svg',
    white_logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/whites/mbna.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/mbna.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/alternatives/mbna.svg',
    white_icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/whites/mbna.svg',
    main_bg_color: '#00284C',
    main_button_color: '#FED141',
    main_dropdown_color: '#107987',
    mobile_bg_color: '#00284C',
    mobile_button_color: '#107987',
    mobile_help_link_color: '#FED141',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-virgin-money',
    provider_id: 'ob-virgin-money',
    connector_id: 'ob-virgin-money',
    name: 'virgin-money',
    display_name: 'Virgin Money',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/virgin-money.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/whites/virgin-money.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/virgin-money.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/alternatives/virgin-money.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/whites/virgin-money.svg',
    main_bg_color: '#d3d3d3',
    main_button_color: '#9f1609',
    mobile_bg_color: '#DF0B0B',
    mobile_button_color: '#9f1609',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: true,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-barclays',
    provider_id: 'ob-barclays',
    connector_id: 'ob-barclays',
    name: 'barclays',
    display_name: 'Barclays',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/barclays.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/barclays.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/barclays.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/barclays.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/barclays.svg',
    main_bg_color: '#007EB6',
    main_button_color: '#00aeef',
    main_dropdown_color: '#00aeef',
    mobile_bg_color: '#007EB6',
    mobile_button_color: '#00aeef',
    mobile_help_link_color: '#00aeef',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-rbs-business',
    provider_id: 'ob-rbs-business',
    connector_id: 'ob-rbs-business',
    name: 'rbs',
    display_name: 'Royal Bank of Scotland Bankline',
    country: 'uk',
    auth_method: 'ob',
    division: 'business',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/rbs.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/rbs.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/rbs.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/rbs.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/rbs.svg',
    main_bg_color: '#0A2F64',
    main_button_color: '#1E808F',
    main_dropdown_color: '#4A94D3',
    mobile_bg_color: '#0A2F64',
    mobile_button_color: '#1E808F',
    mobile_help_link_color: '#4A94D3',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-monzo',
    provider_id: 'ob-monzo',
    connector_id: 'ob-monzo',
    name: 'monzo',
    display_name: 'Monzo',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/monzo.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/monzo.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/monzo.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/monzo.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/monzo.svg',
    main_bg_color: '#15233c',
    main_button_color: '#00a0d6',
    mobile_bg_color: '#15233c',
    mobile_button_color: '#00a0d6',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-tsb',
    provider_id: 'ob-tsb',
    connector_id: 'ob-tsb',
    name: 'tsb',
    display_name: 'TSB',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/tsb.svg',
    white_logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/whites/tsb.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/tsb.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/alternatives/tsb.svg',
    white_icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/whites/tsb.svg',
    main_bg_color: '#007BC3',
    main_button_color: '#e51a9b',
    main_dropdown_color: '#00539B',
    mobile_bg_color: '#007BC3',
    mobile_button_color: '#e51a9b',
    mobile_help_link_color: '#FAB7DE',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-barclays-business',
    provider_id: 'ob-barclays-business',
    connector_id: 'ob-barclays-business',
    name: 'barclays',
    display_name: 'Barclays Business',
    country: 'uk',
    auth_method: 'ob',
    division: 'business',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/barclays.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/barclays.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/barclays.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/barclays.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/barclays.svg',
    main_bg_color: '#007EB6',
    main_button_color: '#00aeef',
    main_dropdown_color: '#00aeef',
    mobile_bg_color: '#007EB6',
    mobile_button_color: '#00aeef',
    mobile_help_link_color: '#00aeef',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-hsbc-business',
    provider_id: 'ob-hsbc-business',
    connector_id: 'ob-hsbc-business',
    name: 'hsbc',
    display_name: 'HSBC Business',
    country: 'uk',
    auth_method: 'ob',
    division: 'business',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/hsbc.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/hsbc.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/hsbc.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/hsbc.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/hsbc.svg',
    main_bg_color: '#515358',
    main_button_color: '#af1406',
    main_dropdown_color: '#383838',
    mobile_bg_color: '#272727',
    mobile_button_color: '#af1406',
    mobile_help_link_color: '#af1406',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: ['info', 'accounts', 'balance', 'transactions', 'offline_access'],
    id: 'ob-danske',
    provider_id: 'ob-danske',
    connector_id: 'ob-danske',
    name: 'danske',
    display_name: 'Danske Bank',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/danske.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/danske.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/danske.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/danske.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/danske.svg',
    main_bg_color: '#003755',
    main_button_color: '#009EDD',
    mobile_bg_color: '#003755',
    mobile_button_color: '#009EDD',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-starling',
    provider_id: 'ob-starling',
    connector_id: 'ob-starling',
    name: 'starling',
    display_name: 'Starling',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/starling.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/starling.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/starling.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/starling.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/starling.svg',
    main_bg_color: '#2c1c49',
    main_button_color: '#7433ff',
    mobile_bg_color: '#2c1c49',
    mobile_button_color: '#7433FF',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: true,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-santander',
    provider_id: 'ob-santander',
    connector_id: 'ob-santander',
    name: 'santander',
    display_name: 'Santander',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/santander.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/santander.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/santander.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/santander.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/santander.svg',
    main_bg_color: '#EC0000',
    main_button_color: '#EC0000',
    mobile_bg_color: '#EC0000',
    mobile_button_color: '#EC0000',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-rbs',
    provider_id: 'ob-rbs',
    connector_id: 'ob-rbs',
    name: 'rbs',
    display_name: 'Royal Bank of Scotland',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/rbs.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/rbs.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/rbs.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/rbs.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/rbs.svg',
    main_bg_color: '#0A2F64',
    main_button_color: '#1E808F',
    main_dropdown_color: '#4A94D3',
    mobile_bg_color: '#0A2F64',
    mobile_button_color: '#1E808F',
    mobile_help_link_color: '#4A94D3',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-lloyds',
    provider_id: 'ob-lloyds',
    connector_id: 'ob-lloyds',
    name: 'lloyds',
    display_name: 'Lloyds',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/lloyds.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/lloyds.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/lloyds.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/lloyds.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/lloyds.svg',
    main_bg_color: '#00553e',
    main_button_color: '#649c00',
    main_dropdown_color: '#00553e',
    mobile_bg_color: '#00553e',
    mobile_button_color: '#649c00',
    mobile_help_link_color: '#649c00',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-ulster',
    provider_id: 'ob-ulster',
    connector_id: 'ob-ulster',
    name: 'ulster',
    display_name: 'Ulster Bank',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/ulster.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/ulster.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/ulster.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/ulster.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/ulster.svg',
    main_bg_color: '#0a2f64',
    main_button_color: '#1e808f',
    mobile_bg_color: '#0a2f64',
    mobile_button_color: '#1e808f',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-bos-business',
    provider_id: 'ob-bos-business',
    connector_id: 'ob-bos-business',
    name: 'bos',
    display_name: 'Bank of Scotland Business',
    country: 'uk',
    auth_method: 'ob',
    division: 'business',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/bos.svg',
    white_logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/whites/bos.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/bos.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/alternatives/bos.svg',
    white_icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/whites/bos.svg',
    main_bg_color: '#05286a',
    main_button_color: '#048161',
    main_dropdown_color: '#005ba2',
    mobile_bg_color: '#05286a',
    mobile_button_color: '#048161',
    mobile_help_link_color: '#7FADD0',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-halifax',
    provider_id: 'ob-halifax',
    connector_id: 'ob-halifax',
    name: 'halifax',
    display_name: 'Halifax',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/halifax.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/whites/halifax.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/halifax.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/alternatives/halifax.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/whites/halifax.svg',
    main_bg_color: '#0040bb',
    main_button_color: '#FFC82E',
    main_dropdown_color: '#000000',
    mobile_bg_color: '#0040bb',
    mobile_button_color: '#FFC82E',
    mobile_help_link_color: '#679bff',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-tesco',
    provider_id: 'ob-tesco',
    connector_id: 'ob-tesco',
    name: 'tesco',
    display_name: 'Tesco Bank',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/tesco.svg',
    white_logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/whites/tesco.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/tesco.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/alternatives/tesco.svg',
    white_icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/whites/tesco.svg',
    main_bg_color: '#1b3160',
    main_button_color: '#c9435a',
    main_dropdown_color: '#1b3160',
    mobile_bg_color: '#1b3160',
    mobile_button_color: '#c9435a',
    mobile_help_link_color: '#b6dadd',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: true,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-tide',
    provider_id: 'ob-tide',
    connector_id: 'ob-tide',
    name: 'tide',
    display_name: 'Tide',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/tide.svg',
    white_logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/whites/tide.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/tide.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/alternatives/tide.svg',
    white_icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/whites/tide.svg',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-natwest-business',
    provider_id: 'ob-natwest-business',
    connector_id: 'ob-natwest-business',
    name: 'natwest',
    display_name: 'NatWest Bankline',
    country: 'uk',
    auth_method: 'ob',
    division: 'business',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/natwest.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/natwest.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/natwest.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/natwest.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/natwest.svg',
    main_bg_color: '#42145f',
    main_button_color: '#0c7f88',
    main_dropdown_color: '#ad1982',
    mobile_bg_color: '#42145f',
    mobile_button_color: '#0c7f88',
    mobile_help_link_color: '#ad1982',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-hsbc',
    provider_id: 'ob-hsbc',
    connector_id: 'ob-hsbc',
    name: 'hsbc',
    display_name: 'HSBC',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/hsbc.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/hsbc.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/hsbc.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/hsbc.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/hsbc.svg',
    main_bg_color: '#515358',
    main_button_color: '#af1406',
    main_dropdown_color: '#383838',
    mobile_bg_color: '#272727',
    mobile_button_color: '#af1406',
    mobile_help_link_color: '#af1406',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: ['info', 'accounts', 'balance', 'transactions', 'standing_orders', 'offline_access'],
    id: 'ob-yorkshire-building-society',
    provider_id: 'ob-yorkshire-building-society',
    connector_id: 'ob-yorkshire-building-society',
    name: 'yorkshire-building-society',
    display_name: 'Yorkshire Building Society',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/yorkshire-building-society.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/whites/yorkshire-building-society.svg',
    icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/yorkshire-building-society.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/alternatives/yorkshire-building-society.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/icons/whites/yorkshire-building-society.svg',
    main_bg_color: '#707070',
    main_button_color: '#009C41',
    mobile_bg_color: '#707070',
    mobile_button_color: '#009C41',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: true,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
  {
    scopes: [
      'info',
      'accounts',
      'balance',
      'transactions',
      'cards',
      'direct_debits',
      'standing_orders',
      'offline_access',
    ],
    id: 'ob-natwest',
    provider_id: 'ob-natwest',
    connector_id: 'ob-natwest',
    name: 'natwest',
    display_name: 'NatWest',
    country: 'uk',
    auth_method: 'ob',
    division: 'retail',
    logo_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/natwest.svg',
    white_logo_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/whites/natwest.svg',
    icon_url: 'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/natwest.svg',
    alternative_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/alternatives/natwest.svg',
    white_icon_url:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/icons/whites/natwest.svg',
    main_bg_color: '#42145f',
    main_button_color: '#0c7f88',
    main_dropdown_color: '#ad1982',
    mobile_bg_color: '#42145f',
    mobile_button_color: '#0c7f88',
    mobile_help_link_color: '#ad1982',
    disabled: false,
    hidden: false,
    max_login_time: 10000,
    is_beta: false,
    auth_inputs: [],
    provider_scope_mappings: {
      info: ['accounts', 'info'],
      accounts: ['accounts'],
      balance: ['accounts', 'balance'],
      transactions: ['accounts', 'transactions'],
      cards: ['info', 'cards'],
      offline_access: ['offline_access'],
      direct_debits: ['accounts', 'direct_debits'],
      standing_orders: ['accounts', 'standing_orders'],
      beneficiaries: ['accounts', 'beneficiaries'],
      scheduled_payments: ['scheduled_payments'],
      info_name: ['accounts', 'info_name'],
      info_date_of_birth: ['accounts', 'info_date_of_birth'],
      info_addresses: ['accounts', 'info_addresses'],
      info_phone_numbers: ['accounts', 'info_phone_numbers'],
      info_email_addresses: ['accounts', 'info_email_addresses'],
    },
  },
]
