import React, { createContext, FC, useState } from 'react'

type Scale = {
  scale: number
  height?: number
  width?: number
}

interface IScaleContext {
  scale: Scale | null
  setScale: (scale: Scale) => void
}

export const ScaleContext = createContext<IScaleContext | null>(null)

interface IScaleProviderProps {
  children: React.ReactNode
  initialScale?: Scale
}

export const useScale = () => {
  const scaleContext = React.useContext(ScaleContext)

  if (!scaleContext) {
    throw new Error('useScale must be used within ScaleProvider')
  }

  return scaleContext
}

export const ScaleProvider: FC<IScaleProviderProps> = ({ initialScale, children }) => {
  const [scale, setScale] = useState<IScaleContext['scale']>(initialScale ?? null)

  return <ScaleContext.Provider value={{ scale, setScale }}>{children}</ScaleContext.Provider>
}
