import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ThumbRoundlet } from '../../../components/ThumbRoundlet'
import { OptionEntity } from '../types'
import { OptionComponentProps } from './SelectableListBase'
import { OptionElement, OptionLabel, IconElement, OptionLabelWrapper } from './styles'

export function OptionComponentBigWithThumbRoundlet<T>(props: OptionComponentProps<T>) {
  return (
    <OptionComponentWithThumbRoundlet {...props}>
      {(icon) => <ThumbRoundlet contain={true} padding={6} src={icon} />}
    </OptionComponentWithThumbRoundlet>
  )
}

export function OptionComponentSmallWithThumbRoundlet<T>(props: OptionComponentProps<T>) {
  return (
    <OptionComponentWithThumbRoundlet {...props}>
      {(icon) => <ThumbRoundlet contain={false} padding={9} src={icon} />}
    </OptionComponentWithThumbRoundlet>
  )
}

function OptionComponentWithThumbRoundlet<T>(
  props: OptionComponentProps<T> & {
    children: (icon: string) => React.ReactNode
  },
) {
  const { t } = useTranslation('end_user_components')
  const selected = props.value === props.option.value

  const optionWithIcon =
    'icon' in props.option ? (props.option as OptionEntity<T> & { icon: string }) : null

  return (
    <OptionElement
      type="submit"
      aria-label={t('form.select.item.aria.label', {
        name: props.option.label,
      })}
      onClick={() => props.onChange(props.option.value)}
    >
      {optionWithIcon ? props.children(optionWithIcon.icon) : null}

      <OptionLabelWrapper>
        <OptionLabel size="bodyM">{props.option.label}</OptionLabel>

        {selected && <IconElement $selected src={faCheck} />}
      </OptionLabelWrapper>
    </OptionElement>
  )
}
