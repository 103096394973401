import { combineReducers } from 'redux'
import { BrowserHistory } from 'history'
import { connectRouter } from 'connected-react-router'

import IStoreState from '../store/IStoreState'
import clientReducer from './client'
import generalReducer from './general'
import providersReducer from './providers'
import loginReducer from './login'
import countriesReducer from './countries'

const createRootReducer = (history: BrowserHistory) =>
  combineReducers<IStoreState>({
    client: clientReducer,
    general: generalReducer,
    login: loginReducer,
    countries: countriesReducer,
    providers: providersReducer,
    router: connectRouter(history),
  })

export default createRootReducer
