import styled from 'styled-components'
import { EuLoader } from '@prisma'
import { gap } from '@styles'
import { FaIcon } from '@assets/icons'

export const Root = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${gap(30, 'px', 'v')}
`

export const Loader = styled(EuLoader)`
  height: ${(props) => props.size}px;
`

export const SuccessIcon = styled(FaIcon)<{ dimension: number; show: boolean }>`
  font-size: ${(props) => props.dimension}px;
`

export const FailureIcon = styled(FaIcon)<{ dimension: number }>`
  font-size: ${(props) => props.dimension}px;
`

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${gap(10, 'px', 'v')}
`

export const Message = styled.div`
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: flex-end;
  ${gap(1, 'px', 'h')}
`

export const SubMessage = styled.div<{ hide: boolean }>`
  visibility: ${(props) => (props.hide ? 'hidden' : 'visible')}
  font-size: 14px;
  max-width: 370px;
`
