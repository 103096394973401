import { FooterLink, FooterText } from '@components'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { getRegulatoryInformation, isClientRegulated } from '@utils/client'
import {
  getLocaleEuLegalUrls,
  TL_EU_REFERENCE_NUMBER,
  TL_EU_REFERENCE_URL,
  TL_FCA_NUMBER,
  TL_FCA_URL,
  TL_NAME,
  TL_PRIVACY_URL,
  TL_TOS_URL,
} from '@constants/legal'
import { useSelector } from 'react-redux'
import IStoreState from '@store/IStoreState'

function Footer() {
  const { t, i18n } = useTranslation('v2_auth_inputs')
  const { client, providers } = useSelector((state: IStoreState) => state)
  const provider = providers.selectedProvider

  const clientName = client.clientSettings.client_name
  const activeCountry = provider.country

  const regulatoryInformation = getRegulatoryInformation({
    activeCountry,
    clientAttributes: client.clientAttributes,
  })

  const isRegulated = isClientRegulated({
    clientAttributes: client.clientAttributes,
    activeCountry,
  })

  const isUK = activeCountry === 'uk'

  const entityName = isRegulated ? clientName : TL_NAME

  const legalUrls = (() => {
    if (isRegulated) {
      return {
        privacy: regulatoryInformation.privacy_policy_url,
        tos: regulatoryInformation.terms_of_service_url,
      }
    }

    if (isUK) {
      return {
        privacy: TL_PRIVACY_URL,
        tos: TL_TOS_URL,
      }
    }

    return getLocaleEuLegalUrls({ languages: i18n.languages })
  })()

  const licence = (() => {
    if (isRegulated) {
      return {
        number: regulatoryInformation.registration_number,
        url: regulatoryInformation.registration_page_url,
      }
    }

    if (isUK) {
      return {
        number: TL_FCA_NUMBER,
        url: TL_FCA_URL,
      }
    }

    return {
      number: TL_EU_REFERENCE_NUMBER,
      url: TL_EU_REFERENCE_URL,
    }
  })()

  const licenceCopy = isUK ? t('footer.fca') : t('footer.rn')

  return (
    <FooterText>
      <Trans ns="v2_auth_inputs" i18nKey="footer.agreements" values={{ entityName }}>
        <FooterLink href={legalUrls.tos}>Terms of Service</FooterLink> and{' '}
        <FooterLink href={legalUrls.privacy}>Privacy Policy</FooterLink>
      </Trans>{' '}
      {licenceCopy}: <FooterLink href={licence.url}>{licence.number}</FooterLink>.
      {isUK && client.clientSettings.is_agent && ` ${t('footer.agent', { clientName })}`}
    </FooterText>
  )
}

export default Footer
