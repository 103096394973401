// The main reason for introducing this context provider is that
// the consent pages are rendered both in the auth flow itself,
// but also in the preview mode in the developer console.
// And we need to provide slightly different values each time.
import React, { createContext, ReactNode, Dispatch, SetStateAction } from 'react'
import { ClientType } from '@models/clientType'
import { DataUseCategory, IRegulatoryInformation } from '@store/IStoreState'

export interface IConsentContext {
  clientName: string
  clientId: string
  providerName: string
  providerLogo: string
  providerScope: string[]
  clientType: ClientType
  scope: string[]
  publicScope: string[]
  dataUse: string
  dataUseCopy: string
  email?: string
  setDataUseCopy: Dispatch<SetStateAction<string>>
  clientLogo: string
  onAllowClick: () => Promise<void> | void
  onCancelClick: () => void
  hiddenAuthUriFinished: boolean
  regulatoryInformation: IRegulatoryInformation
  dataUseCategory: DataUseCategory
  shouldDisplayNewSubtitleCopy: boolean
  preLoadedUrl: string | null
}

export const ConsentContext = createContext<IConsentContext | null>(null)

export function ConsentContextProvider({
  children,
  value,
}: {
  children: ReactNode
  value: IConsentContext
}) {
  return <ConsentContext.Provider value={value}>{children}</ConsentContext.Provider>
}
