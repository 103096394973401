import styled from 'styled-components'
import { ContainerStyle } from '@styles'

export const Title = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
  ${ContainerStyle}
`

export const Message = styled.div`
  text-align: center;
  ${ContainerStyle}
`

export const ButtonsContainer = styled.div`
  ${ContainerStyle}
`
