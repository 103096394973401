import React from 'react'
import { timesSolid } from '@assets/icons'
import { useClickOutside } from '@hooks'
import { Overlay, Modal, CloseButton } from './styles'

interface HelpTextModalProps {
  handleClose: () => void
  show: boolean
  readonly text: string
}

const HelpTextModal = (props: HelpTextModalProps) => {
  const { handleClose, show, text } = props

  const ref = useClickOutside(() => handleClose())

  return show ? (
    <Overlay>
      <Modal ref={ref}>
        {text}
        <CloseButton icon={timesSolid} onClick={handleClose} />
      </Modal>
    </Overlay>
  ) : null
}

export default HelpTextModal
