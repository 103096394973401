/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren } from 'react'
import { exclamationCircleSolid, timesCircleSolid } from '@assets/icons'
import { IValidationRule } from '@models/IAuthInput'
import { useViewport } from '@hooks'
import useInput from './hooks'
import { Root, InputContainer, StyledInput, ErrorIcon, ClearIcon, Label } from './styles'

export interface IInputProps {
  type: 'text' | 'number' | 'password'
  defaultValue?: string
  label?: string
  placeholder?: string
  validations?: IValidationRule[]
  saveValue: (value: any) => void
  activeColor: string
}

const Input = ({
  type,
  defaultValue,
  label,
  placeholder,
  validations,
  saveValue,
  activeColor,
}: PropsWithChildren<IInputProps>) => {
  const { isMobile } = useViewport()
  const { value, errorMsg, isWrong, reset, onChange, onFocus, onBlur, isFocused, inputRef } =
    useInput(defaultValue, validations, saveValue)

  const renderInput = () => (
    <StyledInput
      ref={inputRef}
      type={type}
      placeholder={placeholder}
      value={value}
      isWrong={isWrong}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      activeColor={activeColor}
      data-lpignore="true"
    />
  )

  const renderIcon = () => {
    if (isFocused) {
      return isWrong ? (
        <ErrorIcon icon={exclamationCircleSolid} />
      ) : isMobile ? (
        <ClearIcon icon={timesCircleSolid} onTouchStart={reset} activeColor={activeColor} />
      ) : (
        <ClearIcon icon={timesCircleSolid} onMouseDown={reset} activeColor={activeColor} />
      )
    }
    return null
  }
  return (
    <Root>
      <InputContainer>
        {renderInput()}
        {renderIcon()}
      </InputContainer>
      <Label error={isWrong} isFocused={isFocused} activeColor={activeColor}>
        {errorMsg || label}
      </Label>
    </Root>
  )
}

export default Input
