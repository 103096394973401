/* eslint-disable @typescript-eslint/no-explicit-any */
// Inspiration taken from https://github.com/radix-ui/primitives/blob/main/packages/react/id/src/id.tsx
import * as React from 'react'
import useIsomorphicLayoutEffect from '../useIsomorphicLayoutEffect'

// We `toString()` to prevent bundlers from trying to `import { useId } from 'react';`
const useReactId = (React as any)['useId'.toString()] || (() => undefined)
let count = 0

function useId() {
  const [id, setId] = React.useState<string | undefined>(useReactId())
  // React versions older than 18 will have client-side ids only as the `useId`
  // API will only be published once React 18 is out
  useIsomorphicLayoutEffect(() => {
    setId((reactId) => reactId ?? String(count++))
  }, [])

  return id ? `id-${id}` : ''
}

export default useId
