import styled from 'styled-components'

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`

export const Logo = styled.img`
  max-width: 40px;
  max-height: 40px;
`

export const LogoContainer = styled.div`
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #efefef;
  border-radius: 50%;
  box-sizing: border-box;
`
