import React, { PropsWithChildren } from 'react'
import { Root, Container, Info } from './styles'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IErrorLayoutProps {}

/**
 * This page layout is used by most pages in the Auth Dialog
 */
const ErrorLayout = ({ children }: PropsWithChildren<IErrorLayoutProps>) => (
  <Root>
    <Container>
      <Info>{children}</Info>
    </Container>
  </Root>
)

export default ErrorLayout
