import React from 'react'
import { useTranslation } from 'react-i18next'

import { InputWithDebounce } from './InputWithDebounce'
import { SelectableListBaseProps } from './SelectableListBase'
import { SearchWrapper } from './styles'

export function SearchInput<T>({
  name,
  placeholder,
  onSearch,
  searchAdjacentComponent,
  search,
  setSearch,
  handleInputFocus,
  refSearchInput,
}: Partial<SelectableListBaseProps<T>> & {
  search: string
  setSearch(s: string): void
  handleInputFocus(): void
  refSearchInput: React.RefObject<HTMLInputElement>
}) {
  const { t } = useTranslation('end_user_components')

  return (
    <SearchWrapper>
      <InputWithDebounce
        name={name}
        ref={refSearchInput}
        placeholder={placeholder || t('form.select.search')}
        onChange={(query) => {
          setSearch(query)
          onSearch && onSearch(query)
        }}
        onFocus={handleInputFocus}
        value={search}
        setValue={setSearch}
      />
      {searchAdjacentComponent}
    </SearchWrapper>
  )
}
