import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { Page } from '@models/IPage'
import {
  sendAuthInputsPageLoadedAnalytics,
  sendBranchSelectionPageLoaded,
  sendConsentAnalytics,
  sendCountryLoadedAnalytics,
  sendProviderSelectionAnalytics,
  sendSplashScreenPageLoaded,
} from '@actions/analytics'

const usePageEvents = (page: Page | undefined, isLoading: boolean) => {
  const dispatch = useDispatch()

  // Effect to send loaded page events
  useEffect(() => {
    if (isLoading) return

    switch (page) {
      case Page.COUNTRIES:
        dispatch(sendCountryLoadedAnalytics())
        break
      case Page.PROVIDERS:
        dispatch(sendProviderSelectionAnalytics())
        break
      case Page.CONSENT:
        dispatch(sendConsentAnalytics())
        break
      case Page.AUTHINPUTS:
        dispatch(sendAuthInputsPageLoadedAnalytics())
        break
      case Page.SPLASH_SCREEN:
        dispatch(sendSplashScreenPageLoaded())
        break
      case Page.BRANCHES:
        dispatch(sendBranchSelectionPageLoaded())
        break
      default:
        break
    }
  }, [page, dispatch, isLoading])
}

export default usePageEvents
