import IconButton from '@components/IconButton'
import { device } from '@constants/devices'
import styled from 'styled-components'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
`

export const Root = styled.div`
  background-color: #fff;
  position: fixed;
  z-index: 1;

  top: 100px;
  right: 16px;
  left: 16px;
  ${device.tablet} {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 530px;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const Content = styled.div`
  padding: 8px 40px 40px;
  text-align: center;
`

export const Title = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 0px;
  margin-bottom: 16px;
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 0px;
  margin-bottom: 16px;
`

export const CloseIconContainer = styled.div`
  display: flex;
`

export const CloseIcon = styled(IconButton)`
  margin-left: auto;
  margin-right: 16px;
  margin-top: 8px;
`
