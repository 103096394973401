import React from 'react'
import {
  universitySolid,
  universityLight,
  exchangeSolid,
  exchangeLight,
  checkSolid,
} from '@assets/icons'
import {
  ProgressTrackContainer,
  Step,
  Line,
  LabelsContainer,
  Label,
  IconsContainer,
  Icon,
  SmallIcon,
} from './styles'

interface ProgressTrackProps {
  active: string
}

const ProgressTrack = (props: ProgressTrackProps) => {
  const first = props.active === '1'
  const second = props.active === '2'
  const checkIcon = <SmallIcon icon={checkSolid} />
  return (
    <>
      <IconsContainer>
        <Icon icon={first ? universitySolid : universityLight} offsetLeft={second} />
        <Icon icon={second ? exchangeSolid : exchangeLight} offsetRight={first} />
      </IconsContainer>
      <ProgressTrackContainer>
        <Step active={first}>{first ? '1' : checkIcon}</Step>
        <Line />
        <Step active={second}>{second && '2'}</Step>
      </ProgressTrackContainer>
      <LabelsContainer page={props.active}>
        <Label bold={first}>Login per accedere al conto</Label>
        <Label bold={second}>Login per consultare saldo e movimenti</Label>
      </LabelsContainer>
    </>
  )
}

export default ProgressTrack
