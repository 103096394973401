import { useLayoutEffect, useState } from 'react'

import { Dimensions, BreakPoints } from '../components/styles/breakpoints'

// add subscribers in order to avoid global context
const subscribers = new Set<() => void>()

let currentViewport: Dimensions

let IS_LISTENING = false

export function useResponsive(): Dimensions {
  if (!IS_LISTENING) {
    calculate()
    window.addEventListener('resize', handleResize)
    IS_LISTENING = true
  }
  const [state, setState] = useState<Dimensions>(currentViewport)

  useLayoutEffect(() => {
    const subscriber = () => {
      setState(currentViewport)
    }
    subscribers.add(subscriber)
    return () => {
      subscribers.delete(subscriber)
      if (subscribers.size === 0) {
        window.removeEventListener('resize', handleResize)
        IS_LISTENING = false
      }
    }
  }, [])

  return state
}

function calculate(): void {
  const width = window.innerWidth

  const dimension = Object.keys(BreakPoints).find(
    (key) => width <= BreakPoints[key as Dimensions],
  ) as Dimensions | undefined

  if (dimension && dimension !== currentViewport) {
    currentViewport = dimension
  }
}

function handleResize(): void {
  const oldViewport = currentViewport
  calculate()
  if (oldViewport === currentViewport) return
  for (const subscriber of subscribers) {
    subscriber()
  }
}
