import { IAuthInputMethod } from '@models/IAuthInput'
import IProvider from '@models/IProvider'
import { IBranchItem } from '@pages/Branches/models'

export const stepIsBranchSelection = (step: IAuthInputMethod) =>
  step.fields.length === 1 &&
  step.fields[0].input_value_case === 'SingleChoiceInputValue' &&
  step.fields[0].single_choice_input_value?.type !== 'Standard'

export const getBranchListData = (step: IAuthInputMethod, provider: IProvider): IBranchItem[] => {
  const { options } = step.fields[0].single_choice_input_value!
  return options.map((option) => ({
    name: option.value,
    key: option.key,
    logo: provider.icon_url,
  }))
}
