import { RouterState } from 'connected-react-router'
import { IMfaData } from '@models/IAuthInput'
import IProvider from '../models/IProvider'
import { ICountry } from '../models/ICountry'
import { LoginStatus } from '@models/loginStatus'
import { AbTestKey } from '@contexts/ab-tests/configs'

export enum DataApiPlan {
  Develop = 'Develop01',
  Scale = 'Scale01',
  Enterprise = 'Enterprise01',
  SelfServe = 'SelfServe01',
}

export enum ConsentPageType {
  Default = 'Default',
  BankAccountVerification = 'BankAccountVerification',
}

export enum VerificationApiPlan {
  None = 'None',
  Develop = 'Develop01',
  Scale = 'Scale01',
  Enterprise = 'Enterprise01',
  SelfServe = 'SelfServe01',
}

export enum DataUseCategory {
  None = 'none',
  CustomDescription = 'custom_description',
  AccountVerification = 'account_verification',
  PersonalFinanceManagement = 'personal_finance_management',
  CreditAffordability = 'credit_affordability',
  RentalAffordability = 'rental_affordability',
  IncomeVerification = 'income_verification',
  Identity = 'identity',
  Tax = 'tax',
  Rewards = 'rewards',
  Cashback = 'cashback',
  MonthlyInvoiceVerification = 'monthly_invoice_verification',
}

export default interface IStoreState {
  readonly general: IGeneralState
  readonly client: IClientState
  readonly login: ILoginState
  readonly providers: IProvidersState
  readonly countries: ICountryState
  router: RouterState
}

export interface IClientStateBase {
  clientSettings: IClientSettings
  clientAttributes: IClientAttributes
  consentId?: string
  scope: string[]
  publicScope: string[]
  flowType: string
  id: string
  redirectUri: string
  authFlowId: string
  origin?: string
  state?: string
  authUriMode: string
  countryId?: string
  providerId?: string
  consentPageType: ConsentPageType
}

export interface IClientStateWithExtend extends IClientStateBase {
  connectionId: string
  extend: {
    nextReconfirmationDate: string
    reauth?: {
      link: string
    }
  }
}

export type IClientState = IClientStateBase | IClientStateWithExtend

export interface IClientSettings {
  cancellation_uri: string
  client_name: string
  data_api_plan: DataApiPlan
  verification_api_plan: VerificationApiPlan
  data_use: string
  data_use_category: DataUseCategory
  logo_uri: string
  primary_colour: string
  secondary_colour: string
  tertiary_colour: string
  is_agent: boolean
  email?: string
}

export interface IClientAttributes {
  regulatory_information: { [key in 'eu' | 'uk']: IRegulatoryInformation }
}

export interface IRegulatoryInformation {
  ais_regulated: boolean
  registration_number?: string
  privacy_policy_url?: string
  terms_of_service_url?: string
  registration_page_url?: string
}

export interface IGeneralState {
  clientErrorCode: string
  clientErrorMessage: string
  withRequestId: boolean
  isLoading: boolean
  dialogVersion: number
  splashScreenVisited: boolean
  amazonConsentAccordionOpened: boolean
  amazonConsentChangeBankClicked: boolean
  abTests: Record<AbTestKey, 'A' | 'B' | null>
}

export interface ILoginState {
  loginStatus: LoginStatus
  loginInformation: object
  mfaData?: IMfaData
  returnUrl: string | null
}

export interface IProvidersState {
  items: IProvider[]
  selectedProvider: IProvider
}

export interface ICountryState {
  countrySelected?: string
  countries?: ICountry[]
}
