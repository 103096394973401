/* eslint-disable react-hooks/exhaustive-deps */
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import React, {
  ReactNode,
  useContext,
  useState,
  createContext,
  useCallback,
  useEffect,
  PropsWithChildren,
} from 'react'
import { FaIcon, chevronUpSolid, chevronDownLight } from '@assets/icons'
import { useId } from '@hooks'
import {
  AccordionButtonContainer,
  AccordionItemContainer,
  AccordionPanelContainer,
  AccordionTitleContainer,
  AccordionTitleText,
} from './styles'

const AccordionContext = createContext<{ themeColor: string } | null>(null)

export function useAccordion() {
  const accordionContext = useContext(AccordionContext)

  if (accordionContext === null) {
    throw new Error(
      '`AccordionContext` is equal to `null`. It seems like you forgot to wrap your component in the `Accordion` component.',
    )
  }

  return accordionContext
}

export function Accordion({
  children,
  themeColor,
}: {
  children: ReactNode
  /**
   *  The color of the highlighted UI elements (usually the client's secondary color)
   */
  themeColor: string
}) {
  return <AccordionContext.Provider value={{ themeColor }}>{children}</AccordionContext.Provider>
}

interface IAccordionItemContext {
  isOpen: boolean
  toggleOpen(): void
  buttonId: string
  panelId: string
}

const AccordionItemContext = createContext<IAccordionItemContext | null>(null)

export function useAccordionItem() {
  const accordionItemContext = useContext(AccordionItemContext)

  if (accordionItemContext === null) {
    throw new Error(
      '`AccordionItemContext` is equal to `null`. It seems like you forgot to wrap your component in the `AccordionItem`.',
    )
  }

  return accordionItemContext
}

export function AccordionItem({
  children,
  /**
   *  If present, the accordion item will switch into the "controlled" mode
   * and will solely be controlled via the `isOpen` prop
   */
  isOpen: isOpenProp,
  className,
  onOpen,
}: {
  children: ReactNode
  isOpen?: boolean
  className?: string
  onOpen?: () => void
}) {
  const isControlled = typeof isOpenProp === 'boolean'
  const [isOpen, setIsOpen] = useState(isControlled ? isOpenProp : false)
  const id = useId()
  const buttonId = `button-${id}`
  const panelId = `panel-${id}`

  useEffect(() => {
    // sync the `isOpenProp` with the internal state
    if (isControlled) {
      setIsOpen(isOpenProp)
    }
  }, [isControlled, isOpenProp])

  useEffect(() => {
    if (isOpen && onOpen) {
      onOpen()
    }
  }, [isOpen])

  const toggleOpen = useCallback(() => {
    if (!isControlled) {
      setIsOpen((isOpen) => !isOpen)
    }
  }, [isControlled])

  return (
    <AccordionItemContext.Provider
      value={{
        isOpen,
        toggleOpen,
        buttonId,
        panelId,
      }}
    >
      <AccordionItemContainer className={className}>{children}</AccordionItemContainer>
    </AccordionItemContext.Provider>
  )
}

export function AccordionButton({
  children,
  /**
   * If `true`, it won't be possible to interact with the button
   */
  disabled,
}: {
  children: ReactNode
  disabled?: boolean
}) {
  const { toggleOpen, isOpen, buttonId, panelId } = useAccordionItem()
  const { themeColor } = useAccordion()

  return (
    <AccordionButtonContainer
      id={buttonId}
      isOpen={isOpen}
      onClick={toggleOpen}
      openColor={themeColor}
      disabled={disabled}
      aria-expanded={isOpen}
      aria-controls={panelId}
    >
      {children}
    </AccordionButtonContainer>
  )
}

export function AccordionTitle({
  children,
  icon,
  iconLight,
  'data-view': dataView,
}: PropsWithChildren<{
  icon: IconDefinition
  iconLight: IconDefinition
  'data-view'?: string
}>) {
  const { isOpen } = useAccordionItem()

  return (
    <AccordionTitleContainer data-view={dataView}>
      <FaIcon icon={isOpen ? icon : iconLight} />
      <AccordionTitleText isOpen={isOpen}>{children}</AccordionTitleText>
    </AccordionTitleContainer>
  )
}

export function AccordionChevron() {
  const { isOpen } = useAccordionItem()

  return <FaIcon icon={isOpen ? chevronUpSolid : chevronDownLight} />
}

export function AccordionPanel({
  children,
  'data-view': dataView,
}: {
  children: ReactNode
  'data-view'?: string
}) {
  const { isOpen, buttonId, panelId } = useAccordionItem()

  return (
    <AccordionPanelContainer
      id={panelId}
      aria-labelledby={buttonId}
      role="region"
      hidden={!isOpen}
      data-view={dataView}
    >
      {children}
    </AccordionPanelContainer>
  )
}
