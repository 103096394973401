import * as vwo from 'vwo-node-sdk'
import React, { createContext, useCallback, useEffect, useReducer } from 'react'
import { VWO_ACCOUNT_ID } from '@utils/constants'
import { getEnv } from '@utils/env'
import * as Sentry from '@utils/sentry'
import { exhaustiveCheck, getSessionId } from '@utils/helpers'
import { isValidVariationName } from './utils'
import { useActiveCountry } from '@utils/country'
import { AbTestKey, AB_TESTS } from './configs'
import { useDispatch, useSelector } from 'react-redux'
import { setAbTests } from '@actions/general/generalActions'
import IStoreState from '@store/IStoreState'

export enum Status {
  Loading,
  Success,
  Error,
}

type AbTestsContextType = {
  status: Status
  isBaselineVariant: (abTestKey: AbTestKey) => boolean
  isAlternativeVariant: (abTestKey: AbTestKey) => boolean
  trackConversion: (abTestKey: AbTestKey) => void
}

const AbTestsContext = createContext<AbTestsContextType | null>(null)

type VwoState =
  | { status: Status.Loading; client: null }
  | { status: Status.Success; client: vwo.vwoInstance }
  | { status: Status.Error; client: null }

type VwoStateAction = { type: 'success'; payload: vwo.vwoInstance } | { type: 'error' }

function vwoStateReducer(state: VwoState, action: VwoStateAction): VwoState {
  switch (action.type) {
    case 'success':
      return { status: Status.Success, client: action.payload }
    case 'error':
      return { status: Status.Error, client: null }
    default:
      exhaustiveCheck(action)
  }
}

const sdkKey = getEnv().VWO_SDK_KEY
const sessionId = getSessionId().sessionId as unknown as string

export function AbTestsProvider({ children }: { children: React.ReactNode }) {
  const [vwoState, dispatchVwo] = useReducer(vwoStateReducer, {
    status: Status.Loading,
    client: null,
  })
  const activeCountry = useActiveCountry()
  const selectedProvider = useSelector((state: IStoreState) => state.providers.selectedProvider)
  const clientId = useSelector((state: IStoreState) => state.client.id)
  const dispatch = useDispatch()
  const abTestsState = useSelector((state: IStoreState) => state.general.abTests)

  useEffect(() => {
    vwo
      .getSettingsFile(VWO_ACCOUNT_ID, sdkKey)
      .then((settingsFile) => {
        const vwoClientInstance = vwo.launch({
          settingsFile,
        })

        dispatchVwo({ type: 'success', payload: vwoClientInstance })
      })
      .catch((err) => {
        const isTrackingBlockedError =
          typeof err === 'string' &&
          err.includes('Request failed for fetching account settings. Got Status Code: 0')

        // Don't log errors that are caused by browsers or browser add-ons
        // that block requests to VWO's server since it is featured on the
        // lists of known trackers.
        // This is a known issue and there's no need to flood Sentry with them.
        if (!isTrackingBlockedError) {
          Sentry.exception(err)
        }

        dispatchVwo({ type: 'error' })
      })
  }, [])

  useEffect(() => {
    if (vwoState.status !== Status.Success) {
      return
    }

    // evaluate the variation for each ab test defined in the
    // `AB_TESTS` config object
    // the `useEffect` makes sure the variations are in sync with the custom variables,
    // whenever they change
    const newAbTestsState = Object.keys(AB_TESTS).reduce((acc, key) => {
      const variationName = vwoState.client.activate(
        AB_TESTS[key as unknown as AbTestKey].campaignId,
        sessionId,
        {
          customVariables: {
            country: activeCountry,
            providerId: selectedProvider.provider_id,
            clientId,
          },
        },
      )

      // check if the variation name is valid ('A' or 'B')
      if (variationName !== null && !isValidVariationName(variationName)) {
        Sentry.exception(`Invalid variation name: ${variationName}, must be 'A' or 'B'`)

        return acc
      }

      return { ...acc, [key]: variationName }
    }, {} as Record<AbTestKey, 'A' | 'B' | null>)

    dispatch(setAbTests(newAbTestsState))
  }, [activeCountry, dispatch, vwoState.client, vwoState.status, selectedProvider, clientId])

  const isBaselineVariant = useCallback(
    (abTestKey: AbTestKey) => {
      return abTestsState[abTestKey] === 'A' || abTestsState[abTestKey] === null
    },
    [abTestsState],
  )

  const isAlternativeVariant = useCallback(
    (...args: Parameters<typeof isBaselineVariant>) => {
      return !isBaselineVariant(...args)
    },
    [isBaselineVariant],
  )

  const trackConversion = useCallback(
    async (abTestKey: AbTestKey) => {
      if (vwoState.status !== Status.Success) {
        return
      }

      vwoState.client.track(AB_TESTS[abTestKey].campaignId, sessionId, AB_TESTS[abTestKey].goalId, {
        customVariables: {
          country: activeCountry,
        },
      })
    },
    [activeCountry, vwoState.client, vwoState.status],
  )

  return (
    <AbTestsContext.Provider
      value={{ status: vwoState.status, isBaselineVariant, isAlternativeVariant, trackConversion }}
    >
      {children}
    </AbTestsContext.Provider>
  )
}

export function useAbTests() {
  const context = React.useContext(AbTestsContext)

  if (context === null) {
    throw new Error('useAbTests must be used within a ABTestsProvider')
  }

  return context
}
