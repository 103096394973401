/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { AuthInput, Button } from '@components'
import { DefaultPageLayout } from '@layouts'
import { IAuthInputMethod } from '@models/IAuthInput'
import IStoreState from '@store/IStoreState'
import { AuthInputsContainer, StyledForm, SubmitContainer } from './styles'

export interface IFormProps {
  step: IAuthInputMethod
  isLastStep: boolean
  canSubmit: boolean
  saveData: (key: string, value: string) => void
  submitForm: () => void
}

export const isFormValid = (step: IAuthInputMethod, data: object) => {
  const inputIds = step.fields.map((f) => f.id)
  const values = Object.values(data)
  return inputIds.every((id) => id in data) && !values.includes(null)
}

const Form = ({
  step,
  isLastStep,
  canSubmit,
  saveData,
  submitForm,
}: PropsWithChildren<IFormProps>) => {
  const provider = useSelector((state: IStoreState) => state.providers.selectedProvider)

  const renderAuthInputs = () =>
    step?.fields ? (
      <AuthInputsContainer>
        {step.fields!.map((field) => (
          <AuthInput key={field.id} authInput={field} saveData={saveData} />
        ))}
      </AuthInputsContainer>
    ) : null

  const renderSubmitButton = () => (
    <SubmitContainer>
      <Button
        variant="primary"
        label={isLastStep ? 'login' : 'next'}
        onClick={() => submitForm()}
        disabled={!canSubmit}
        type="submit"
        color={provider.main_button_color || 'black'}
      />
    </SubmitContainer>
  )

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    submitForm()
  }

  return (
    <DefaultPageLayout logo={provider.icon_url}>
      <StyledForm onSubmit={handleFormSubmit}>
        {renderAuthInputs()}
        {renderSubmitButton()}
      </StyledForm>
    </DefaultPageLayout>
  )
}

export default Form
