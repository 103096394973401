import {
  infoCircleLight,
  infoCircleSolid,
  lockAltLight,
  lockAltSolid,
  syncAltLight,
  syncAltSolid,
} from '@assets/icons'
import {
  Accordion,
  AccordionButton,
  AccordionChevron,
  AccordionItem,
  AccordionPanel,
  AccordionTitle,
} from '@components'
import { useClientColors } from '@hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from './styles'

function ConsentNotesAccordionUKEU({
  howDoesThisWork,
  howIsMyDataUsed,
  whatDataAmISharing,
}: {
  howDoesThisWork: JSX.Element
  howIsMyDataUsed: JSX.Element
  whatDataAmISharing: JSX.Element
}) {
  const { secondaryColor } = useClientColors()
  const { t } = useTranslation('v2_consent')

  return (
    <Container>
      <Accordion themeColor={secondaryColor}>
        <AccordionItem isOpen>
          <AccordionButton disabled>
            <AccordionTitle icon={syncAltSolid} iconLight={syncAltLight}>
              {t('what_data_am_i_sharing.title')}
            </AccordionTitle>
          </AccordionButton>
          <AccordionPanel>{whatDataAmISharing}</AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <AccordionTitle icon={infoCircleSolid} iconLight={infoCircleLight}>
              {t('how_does_this_work.title')}
            </AccordionTitle>
            <AccordionChevron />
          </AccordionButton>
          <AccordionPanel>{howDoesThisWork}</AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <AccordionTitle icon={lockAltSolid} iconLight={lockAltLight}>
              {t('how_is_my_data_used.title')}
            </AccordionTitle>
            <AccordionChevron />
          </AccordionButton>
          <AccordionPanel>{howIsMyDataUsed}</AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Container>
  )
}

export default ConsentNotesAccordionUKEU
