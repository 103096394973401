import { sendAlternativeFlowInitiated } from '@actions/analytics'
import useNestedTranslation from '@hooks/useNestedTranslation'
import IStoreState from '@store/IStoreState'
import { ALTERNATIVE_FLOW_CALL_TO_ACTION_COPY, ALTERNATIVE_FLOW_CLIENT_IDS } from '@utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { redirectToExternalUrl } from '@utils/navigation'
import { getUriWithQueryParams } from '@utils/helpers'

const useAlternativeFlow = () => {
  const clientId = useSelector((state: IStoreState) => state.client.id)
  const redirectUri = useSelector((state: IStoreState) => state.client.redirectUri)
  const t = useNestedTranslation('v2_providers', ['not_found_call_to_action'])
  const dispatch = useDispatch()

  const clientHasAlternativeFlow = ALTERNATIVE_FLOW_CLIENT_IDS.includes(clientId)
  const translationKey = ALTERNATIVE_FLOW_CALL_TO_ACTION_COPY[clientId]

  const triggerAlternativeFlow = () => {
    dispatch(sendAlternativeFlowInitiated())
    redirectToExternalUrl(getUriWithQueryParams(redirectUri, ['alternativeFlow=true']))
  }

  return {
    ctaCopy: translationKey ? t(translationKey) : t('search_for_a_different_bank'),
    clientHasAlternativeFlow,
    triggerAlternativeFlow,
  }
}

export default useAlternativeFlow
