import React, { MouseEvent, PropsWithChildren } from 'react'
import { FaIcon } from '@assets/icons'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { useClientColors } from '@hooks'
import { Root, Action } from './styles'

export interface INotFoundProps {
  /**
   * Fontawesome icon to display
   */
  icon: IconDefinition
  /**
   * The search term for which the item was not found
   */
  search: string
  /**
   * Text message to display
   */
  message: string
  /**
   * Text that make up the action button
   */
  actionText: string
  /**
   * Function to execute on action click
   */
  onActionClick: () => void
}

/**
 * The NotFound component can be displayed when the user searched for a
 * list item that did not exist.
 */
const NotFound = ({
  icon,
  search,
  message,
  actionText,
  onActionClick,
}: PropsWithChildren<INotFoundProps>) => {
  const { primaryColor, secondaryColor, secondaryColor_80 } = useClientColors()

  const handleAction = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    onActionClick()
  }

  return (
    <Root>
      <FaIcon icon={icon} color="#aaa" style={{ fontSize: '60px' }} />
      <div>
        <span>{message} </span>
        <strong style={{ color: primaryColor }}>{search}</strong>
      </div>
      <Action onMouseDown={handleAction} color={secondaryColor} hoverColor={secondaryColor_80}>
        {actionText}
      </Action>
    </Root>
  )
}

export default NotFound
