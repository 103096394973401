import { LoginStatus } from '@models/loginStatus'
import ActionTypeKeys from '../actions/ActionTypeKeys'
import ActionTypes from '../actions/ActionTypes'

import { ILoginState } from '../store/IStoreState'

const initialState: ILoginState = {
  loginStatus: LoginStatus.Idle,
  loginInformation: {},
  mfaData: undefined,
  returnUrl: null,
}
export default function loginReducer(
  state: ILoginState = initialState,
  action: ActionTypes,
): ILoginState {
  switch (action.type) {
    case ActionTypeKeys.SET_MFA_DATA:
      return {
        ...state,
        mfaData: action.payload,
      }

    case ActionTypeKeys.ADD_LOGIN_INFORMATION:
      return {
        ...state,
        loginInformation: { ...state.loginInformation, ...action.payload },
      }

    case ActionTypeKeys.RESET_LOGIN_INFORMATION:
      return {
        ...state,
        loginInformation: {},
      }

    case ActionTypeKeys.SET_LOGIN_STATUS:
      return {
        ...state,
        loginStatus: action.payload,
      }

    case ActionTypeKeys.SET_RETURN_URL:
      return {
        ...state,
        returnUrl: action.payload,
      }

    case ActionTypeKeys.RESET_RETURN_URL:
      return {
        ...state,
        returnUrl: null,
      }

    default:
      return state
  }
}
