import * as React from 'react'

import './index.css'

const UnsupportedBrowser = () => (
  <div id="update-browser-message">
    <div>
      <p className="head">You seem to be using a browser that is not supported.</p>
      <p>
        You will get the best experience on{' '}
        <a rel="noopener" target="blank" href="https://www.google.com/chrome">
          Chrome
        </a>
        ,{' '}
        <a rel="noopener" target="blank" href="https://www.mozilla.org/en-GB/firefox/new">
          Firefox
        </a>
        ,{' '}
        <a
          rel="noopener"
          target="blank"
          href="https://www.microsoft.com/en-gb/windows/microsoft-edge"
        >
          Edge
        </a>{' '}
        or{' '}
        <a rel="noopener" target="blank" href="https://support.apple.com/en-us/HT204416">
          Safari
        </a>
        .
      </p>
    </div>
  </div>
)

export default UnsupportedBrowser
