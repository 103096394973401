import { useTranslation } from 'react-i18next'
import {
  BaseItem,
  BaseItemWithLocalization,
  IListOptions,
  IListOptionsWithLocalization,
} from './types'

const DYNAMIC_LIST_TRANSLATION_FILES = ['countryNames']

// overload for NOT providing localization options
export function useProcessedList<ListKey extends string, Item extends BaseItem<ListKey>>(
  items: Item[],
  listOptions: IListOptions<ListKey>,
): Item[]

// overload for providing localization options
export function useProcessedList<
  ListKey extends string,
  LocalisedPrefixKey extends string,
  Item extends BaseItemWithLocalization<ListKey, LocalisedPrefixKey>,
>(items: Item[], listOptions: IListOptionsWithLocalization<ListKey, LocalisedPrefixKey>): Item[]

// overload for when this function is called in another overload function
// (https://github.com/microsoft/TypeScript/issues/44392#issuecomment-854195330)
export function useProcessedList<
  ListKey extends string,
  LocalisedPrefixKey extends string,
  Item extends BaseItemWithLocalization<ListKey, LocalisedPrefixKey>,
>(
  items: Item[],
  listOptions: IListOptionsWithLocalization<ListKey, LocalisedPrefixKey> | IListOptions<ListKey>,
): Item[]

export function useProcessedList<
  ListKey extends string,
  LocalisedPrefixKey extends string,
  Item extends BaseItemWithLocalization<ListKey, LocalisedPrefixKey>,
>(
  items: Item[],
  listOptions: IListOptionsWithLocalization<ListKey, LocalisedPrefixKey> | IListOptions<ListKey>,
): Item[] {
  const { listKey, topValues, filterOutTerm, localised } = listOptions

  const { t } = useTranslation(DYNAMIC_LIST_TRANSLATION_FILES)

  // If it is a localised list, translate all the text items
  if (localised) {
    const { localisedPrefixKey, localisedKeySuffix } = listOptions

    items = items.map((item) => {
      const translationKey = `${item[localisedPrefixKey]}${localisedKeySuffix}`
      return {
        ...item,
        [listKey]: t(translationKey), // replace initial text with localised text
      }
    })
  }

  // By default re-order everything alphabetically
  const sortedItems = items.slice().sort((a, b) => (a[listKey] > b[listKey] ? 1 : -1))

  // Put the top items at the start of the array
  let reorderedItems: Item[]
  if (topValues) {
    const top = topValues
      .map((topValue) => items.find((x) => x[listKey] === topValue))
      .filter((x) => x !== undefined) as Item[]
    const bottom = sortedItems.filter((i) => !topValues.includes(i[listKey]))
    reorderedItems = [...top, ...bottom]
  } else {
    reorderedItems = sortedItems
  }

  // Clean items from filterOutTerm
  let cleanItems = reorderedItems
  if (filterOutTerm) {
    cleanItems = reorderedItems.map((i) => ({
      ...i,
      [listKey]: i[listKey].replace(filterOutTerm, '').trim(),
    }))
  }
  return cleanItems
}
