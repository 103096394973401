import 'core-js/es/map'
import 'core-js/es/set'
import 'promise-polyfill/src/polyfill'

import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { default as App } from './App'
import { unregister } from './registerServiceWorker'
import store, { history } from './store'
import './i18n'

import './assets/css/font.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'

import * as Sentry from './utils/sentry'
import { supportedBrowser } from './utils/browser'
import { UnsupportedBrowser } from './pages'
import { ViewportProvider, PageProvider, AbTestsProvider } from './contexts'
import AppLoader from './pages/AppLoader'
import { ErrorBoundary } from './components'
import { EndUserComponentsProvider } from '@contexts/end-user-components'
import { ScaleProvider } from '@contexts/scale'

Sentry.init(history)

const AuthDialog = () => (
  <Provider store={store}>
    <ErrorBoundary>
      <BrowserRouter>
        <ViewportProvider>
          <AbTestsProvider>
            <PageProvider>
              <EndUserComponentsProvider>
                <ScaleProvider>
                  <Suspense fallback={<AppLoader />}>
                    <App />
                  </Suspense>
                </ScaleProvider>
              </EndUserComponentsProvider>
            </PageProvider>
          </AbTestsProvider>
        </ViewportProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </Provider>
)

const container = document.getElementById('root') as HTMLElement

const root = createRoot(container)

root.render(supportedBrowser() ? <AuthDialog /> : <UnsupportedBrowser />)

unregister()
