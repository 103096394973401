import { ButtonStyle } from '@components/common/styles'
import styled from 'styled-components'

export const Root = styled.a<{
  $color: string
  $variant: 'primary' | 'secondary' | 'tertiary'
  $loading: boolean
  disabled: boolean
}>`
  // override default styles injected by browsers
  text-decoration: none;
  &:hover {
    color: #fff;
  }
  ${ButtonStyle}
`
