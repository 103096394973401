import { useNestedTranslation } from '@hooks'
import { intersection } from '@utils/helpers'
import { format } from '@utils/scope'

// Translation namespace + nested keys
const tKeys = ['what_data_am_i_sharing']

// Categorised scopes
const identityScopes = [
  'info:name',
  'info:date_of_birth',
  'info:addresses',
  'info:phone_numbers',
  'info:email_addresses',
]
const infoScopes = ['info']
const accountScopes = ['accounts', 'cards']
const balanceScopes = ['balance']
const transactionsScopes = ['transactions', 'direct_debits', 'standing_orders']

const usePermissionsList = (
  scope: string[],
  { useNewCopy = false }: { useNewCopy?: boolean } = {},
) => {
  const t = useNestedTranslation(useNewCopy ? 'v3_consent' : 'v2_consent', tKeys)

  const permissions: string[] = []
  const identityPermissions = intersection(identityScopes, scope)
  const infoPermissions = intersection(infoScopes, scope)
  const accountPermissions = intersection(accountScopes, scope)
  const balancePermissions = intersection(balanceScopes, scope)
  const transactionPermissions = intersection(transactionsScopes, scope)

  // Identity bullet ("info:name" and "info" scope mean the same -> Full Name)
  if (identityPermissions.length > 0 || infoPermissions.length > 0) {
    if (infoPermissions.length > 0 && identityPermissions.indexOf('info:name') === -1) {
      identityPermissions.unshift('info:name')
    }
    permissions.push(format(identityPermissions, t))
  }

  // Accounts bullet
  if (accountPermissions.length > 0) {
    permissions.push(t('list.accounts'))
  }

  // Balance bullet
  if (balancePermissions.length > 0) {
    permissions.push(t('list.balance'))
  }

  // Transactions bullet
  if (transactionPermissions.length > 0) {
    permissions.push(format(transactionPermissions, t))
  }

  return permissions
}

export default usePermissionsList
