import Bowser from 'bowser'

const bowser = Bowser.parse(navigator.userAgent)

export const getBrowser = () => {
  return bowser.browser
}

export const getEngine = () => {
  return bowser.engine
}

export const getOS = () => {
  return bowser.os
}

export const getPlatform = () => {
  return bowser.platform
}

export default bowser
