export const UrlRegex =
  /^$|^((((((https?)|[\w\.]+):\/\/)[\w\.-]+))|((\w+\.)+[\w\.]*))(:\d{3,6}\/?)?[\w\/\.#:-]+$/i //eslint-disable-line

export const AlphaNumericRegex = /^[\w'\-\s]{1,125}$/i

export const AwsS3UrlRegex =
  /^$|^https:\/\/truelayer-client-logos\.s3(-eu-west-1)?\.amazonaws.com\/(dev\/)?\d+\.png$/i

export const HexaRegex = /^$|^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6})$/i

export const DefaultLogoUri =
  /^https:\/\/truelayer-client-logos\.s3(-eu-west-1)?\.amazonaws.com\/placeholder.svg$/i

// Amazon US Client Id
export const AMAZON_CLIENT_ID = 'amazonprod-2ce685'
export const AMAZON_DE_CLIENT_ID = 'amzn-de43f4'
const AMAZON_DE_SANDBOX_CLIENT_ID = 'sandbox-amzn-de43f4'

export const NEW_CONSENT_SUBTITLE_COPY_CLIENT_IDS = [
  AMAZON_CLIENT_ID,
  'jeshuanewconsentcopy-19b4a5',
  'tomnewsubheadingtest-cc1fc3',
  'cypresstest-958g23',
  'jamazon-b11b69',
]

export const SPLASH_SCREEN_ENABLED_CLIENT_IDS = [
  'dataxamazon-2d9797',
  AMAZON_DE_CLIENT_ID,
  AMAZON_DE_SANDBOX_CLIENT_ID,
]

export const AMAZON_CONSENT_PAGE_ENABLED_CLIENT_IDS = [
  'dataxamazon-2d9797',
  AMAZON_DE_CLIENT_ID,
  AMAZON_DE_SANDBOX_CLIENT_ID,
]

export const CONDENSED_CONSENT_ENABLED_LANGUAGE_IDS = ['en', 'sv', 'de', 'fr', 'es', 'pt', 'fi']

export const VWO_ACCOUNT_ID = '661882'

export const ALTERNATIVE_FLOW_CLIENT_IDS = [
  'uber-b4c334',
  'sandbox-uber-b4c334',
  'caoanle13-3448e3',
]

// This object holds translation keys for clients
export const ALTERNATIVE_FLOW_CALL_TO_ACTION_COPY: { [clientId: string]: string } = {
  'uber-b4c334': 'enter_details_manually',
  'sandbox-uber-b4c334': 'enter_details_manually',
}
