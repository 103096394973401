import styled, { css } from 'styled-components'
import Color from 'color'
import { gap } from '@styles'
import { EuLoader as CoreEuLoader } from '@prisma'

export const ButtonStyle = css<{
  $color: string
  $variant: 'primary' | 'secondary' | 'tertiary'
  $loading: boolean
  disabled?: boolean
}>`
  border: 1px solid ${(props) => props.$color};
  text-transform: uppercase;
  letter-spacing: 1px;
  height: 45px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  opacity: 1;
  width: 100%;
  transition: background-color 0.5s ease;
  display: inline-block;
  box-sizing: border-box;
  font-weight: 600;
  padding: 12px;

  ${(props) => props.$variant === 'primary' && PrimaryButton}
  ${(props) => props.$variant === 'secondary' && SecondaryButton}
  ${(props) => props.$variant === 'tertiary' && TertiaryButton}
  ${(props) => props.disabled && DisabledStyle}

  &[disabled] {
    pointer-events: none;
  }

  ${(props) => props.$loading && LoadingStyle}
`

export const ButtonLoader = styled(CoreEuLoader).attrs({
  size: 'small',
  Spinner: { color: 'black' },
  thickness: 'large',
})`
  svg {
    width: 20px;
    height: 16px;
  }
`

const PrimaryButton = css<{ $color: string }>`
  color: white;
  background-color: ${(props) => props.$color};
  :hover {
    background-color: ${(props) => Color(props.$color).darken(0.1).toString()};
  }
`

const SecondaryButton = css<{ $color: string }>`
  color: ${(props) => props.$color};
  background-color: white;
  :hover {
    color: ${(props) => Color(props.$color).darken(0.1).toString()};
    border-color: ${(props) => Color(props.$color).darken(0.1).toString()};
  }
`

const TertiaryButton = css<{ $color: string }>`
  color: ${(props) => props.$color};
  background-color: white;
  border: none;
`

const DisabledStyle = css`
  cursor: default;
  opacity: 0.4;
  user-select: none;

  :focus {
    outline: none;
  }
`

const LoadingStyle = css`
  display: flex;
  justify-content: center;
  ${gap(5, 'px', 'h')}
`
