import * as Sentry from '@sentry/react'
import type { BrowserHistoryOptions } from 'history'
import { CaptureContext, Event } from '@sentry/types'
import { Integrations } from '@sentry/tracing'
import { getAppEnv, getBuildNumber } from './helpers'

export function init(history: BrowserHistoryOptions) {
  const env = getAppEnv()

  if (env === undefined) {
    return
  }

  const tracesSampleRate = {
    local: 0,
    development: 0,
    sandbox: 0.2,
    production: 0.2,
  }

  try {
    Sentry.init({
      dsn: 'https://a3b1c5cebf524d7990f58607d84a9f76@o268643.ingest.sentry.io/5285290',
      environment: env,
      release: getBuildNumber(),
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
      tracesSampleRate: tracesSampleRate[env] ?? 0,
      ignoreErrors: [
        // These seems to stem from the fact that a client is trying to inject
        // some JavaScript into a webview and read some variables
        // that aren't defined, which throws errors
        // https://sentry.io/organizations/truelayer/issues/2214473262/events/f27465ae08fa4b4bb5943d8188c9579b/
        "Can't find variable: gonative_channel_status_ios",
        "Can't find variable: setDeviceUUID",
        "Can't find variable: resetViewport",
        "Can't find variable: resize_login",
        "Can't find variable: Accounts",
        "Can't find variable: resetViewpoint",
        "Can't find variable: clickLoanApp",
        // This is thrown when using Google Translate plugin, and is a bug in Chrome
        // https://github.com/facebook/react/issues/11538#issuecomment-417504600
        "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
      ],
    })
  } catch (e) {
    console.error('Error initializing Sentry', e)
  }
}

export function setClient(clientId: string) {
  try {
    Sentry.configureScope((scope) => {
      scope.setUser({ id: clientId })
    })
  } catch (e) {
    console.error('Error setup the user client', e)
  }
}

export function message(message: string, context?: CaptureContext) {
  Sentry.captureMessage(message, context)
}

export function exception(ex: Error | string, context?: CaptureContext) {
  console.error(ex, context)

  Sentry.captureException(ex, context)
}

export function event(event: Event) {
  Sentry.captureEvent(event)
}

export function setProviderId(providerId?: string) {
  Sentry.setTag('providerId', providerId)
}

export function setAppLanguage(appLanguage?: string) {
  Sentry.setTag('appLanguage', appLanguage)
}

export function setAbTestFlags(abTestFlags: Record<string, 'A' | 'B'>) {
  Sentry.setTags(abTestFlags)
}
