import React, { useState, FC } from 'react'
import { IImageInputOption, IImage } from '@models/IAuthInput'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle'
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { useViewport } from '@hooks'
import { Root, ChoiceContainer, RadioButtonIcon, ChoiceRoot } from '../styles'
import { QuestionIcon, Tooltip, QuestionIconContainer } from './styles'
import HelpTextModal from './HelpTextModal'

interface IImageChoiceProps {
  challengeId: string
  options: IImageInputOption[]
  saveAnswer: (id: string, answer: string | undefined) => void
}

const ImageChoice: FC<IImageChoiceProps> = ({ challengeId, options, saveAnswer }) => {
  const { isMobile } = useViewport()
  const [choice, setChoice] = useState<string>()
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [helpText, setHelpText] = useState<string>('')

  const onChoiceClick = (choice: string) => {
    setChoice(choice)
    saveAnswer(challengeId, choice)
  }

  function showModal(text: string) {
    setModalVisible(true)
    setHelpText(text)
  }

  const renderChoice = (key: string, value: IImage) => {
    const selected = choice === key
    return (
      <ChoiceContainer onClick={() => onChoiceClick(key)} selected={selected} selectedColor="black">
        <img src={`data:image/png;base64, ${value.base_64}`} alt="" />
        <RadioButtonIcon
          icon={selected ? faCheckCircle : faCircle}
          selected={selected}
          selectedColor="black"
        />
      </ChoiceContainer>
    )
  }

  const renderQuestionIcon = (value: IImage) => {
    const { help_text } = value
    if (help_text) {
      return (
        <QuestionIconContainer>
          {isMobile ? (
            <QuestionIcon icon={faQuestionCircle} onClick={() => showModal(help_text)} />
          ) : (
            <Tooltip message={help_text} placement="left">
              <QuestionIcon icon={faQuestionCircle} />
            </Tooltip>
          )}
        </QuestionIconContainer>
      )
    }
    return null
  }

  const renderHelpTextModal = () =>
    isMobile ? (
      <HelpTextModal
        show={modalVisible}
        text={helpText}
        handleClose={() => setModalVisible(false)}
      />
    ) : null

  const renderOptions = (option: IImageInputOption) => {
    const { key, value } = option

    return (
      <ChoiceRoot key={key}>
        {renderChoice(key, value)}
        {renderQuestionIcon(value)}
      </ChoiceRoot>
    )
  }

  return (
    <Root>
      {options.map((opt) => renderOptions(opt))}
      {renderHelpTextModal()}
    </Root>
  )
}

export default ImageChoice
