import React from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FaIcon } from '@assets/icons'
import { Root } from './styles'

export interface IBackgroundPlaceholderProps {
  /**
   * Font awesome icon for the background
   */
  icon: IconDefinition
  /**
   * Text message for the background
   */
  message: string
}

/**
 * This component can serve as background for pages
 */
const BackgroundPlaceholder = ({ icon, message }: IBackgroundPlaceholderProps) => (
  <Root>
    <FaIcon icon={icon} size="4x" color="#aaa" />
    <div>{message}</div>
  </Root>
)

export default BackgroundPlaceholder
