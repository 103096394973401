import React from 'react'
import { IAuthInput } from '@models/IAuthInput'
import { TextQuestion, ImagesContainer, ImageQuestion } from './styles'
import { useTranslation } from 'react-i18next'

interface IMfaQuestionProps {
  challenge: IAuthInput
}

const MfaQuestion = ({ challenge }: IMfaQuestionProps) => {
  const { t } = useTranslation('fieldNames')

  const { id, images } = challenge

  function renderImageQuestions() {
    return images && images.length > 0 ? (
      <ImagesContainer>
        {images.map((img, index) => (
          <ImageQuestion key={index} src={`data:image/png;base64, ${img.base_64}`} alt="" />
        ))}
      </ImagesContainer>
    ) : null
  }

  function renderTextQuestion() {
    return <TextQuestion>{t(`${id}_field_display_name`)}</TextQuestion>
  }

  return (
    <>
      {renderImageQuestions()}
      {renderTextQuestion()}
    </>
  )
}

export default MfaQuestion
