import { device } from '@constants/devices'
import { ContainerStyle, gap } from '@styles'
import styled from 'styled-components'

export const TabsContainer = styled.div`
  font-size: 14px;
`

export const TabListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${gap(14, 'px', 'h')}
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  ${ContainerStyle}
`

export const TabContainer = styled.button<{
  isActive: boolean
  activeColor: string
}>`
  border-style: none;
  &::-moz-focus-inner {
    border: 0;
  }
  background: none;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 20px 0;
  font-weight: ${({ isActive }) => (isActive ? '700' : '400')};
  border-bottom: 3px solid;
  border-bottom-color: ${({ isActive, activeColor }) => (isActive ? activeColor : 'transparent')};
  color: ${(props) => (props.isActive ? props.activeColor : 'inherit')};
  ${device.desktop} {
    &:hover {
      color: ${(props) => props.activeColor};
    }
  }
`

export const TabPanelContainer = styled.div`
  ${ContainerStyle}
  // needs to override "position: relative" set by
  // "ContainerStyle" do to shadow positioning
  position: static;
  padding-top: 24px;
  padding-bottom: 32px;
`
