import {
  Button,
  ConsentPermissionsListNote,
  ConsentProviderPermissionsListNote,
  FooterText,
  IButtonProps,
} from '@components'
import { device } from '@constants/devices'
import { ContainerStyle, gap, hideScrollBar, upperGradientStyle } from '@styles'
import styled from 'styled-components'

export const StyledConsentPermissionsListNote = styled(ConsentPermissionsListNote)`
  font-weight: 700;
`

export const StyledConsentProviderPermissionsListNote = styled(ConsentProviderPermissionsListNote)`
  font-weight: 700;
`

export const StyledButton = styled(Button)<IButtonProps>``

export const ButtonsContainer = styled.div`
  ${ContainerStyle}
  display: flex;
  margin-top: auto;
  flex-direction: column;
  ${gap(16, 'px', 'v')}

  ${device.tablet} {
    flex-direction: row;
    ${gap(0, 'px', 'v')}
    ${gap(10, 'px', 'h')}
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  ${hideScrollBar}
  position: relative;
`

export const LegalFooter = styled(FooterText)`
  display: block;
  margin-top: 12px;
  ${ContainerStyle}
  text-align: center;
  color: #2d2d2d;
`

export const BottomContainer = styled.div`
  position: relative;
  ${upperGradientStyle}
`
