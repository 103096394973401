import { OptionItem, OptionEntity } from '../types'
import { isGroupOption } from './isGroupOption'

export function scrollTo(targetPosition: number): Promise<number> {
  // set the scrollbar position with setTimeout so that
  // we can move the action to the end of the stack
  return new Promise<number>((resolve) => {
    window.setTimeout(() => {
      window.scroll({
        top: targetPosition,
        left: 0,
        behavior: 'smooth',
      })
      resolve(1)
    }, 1)
  })
}

export function flatAndUnique<T>(arr: OptionItem<T>[]): OptionEntity<T>[] {
  return uniqueOptions(flatOptions(arr))
}

export function uniqueOptions<T>(arr: OptionEntity<T>[]): OptionEntity<T>[] {
  return arr.filter(
    (optionValue, index, self) =>
      index === self.findIndex((option) => option.value === optionValue.value),
  )
}

export function uniqueOptionsItem<T>(arr: OptionItem<T>[]): OptionItem<T>[] {
  return arr.reduce<OptionItem<T>[]>((acc, option) => {
    // current option is a group option
    if (isGroupOption(option)) {
      const newGroup = uniqueOptions<T>(option.options)
      option.options = newGroup
      return [...acc, option]
    } else {
      // current option is a simple option
      const isAlreadyPresentByValue =
        acc.findIndex((item) => !isGroupOption(item) && item.value === option.value) > -1

      if (!isAlreadyPresentByValue) {
        return acc.concat(option)
      }
    }

    return [...acc]
  }, [])
}

export function flatOptions<T>(arr: OptionItem<T>[]): OptionEntity<T>[] {
  return arr.reduce<OptionEntity<T>[]>(
    (acc, curr) => acc.concat(isGroupOption(curr) ? curr.options : curr),
    [],
  )
}

/**
 * function to match part of the the provider name with the word typed by the user
 * both of the words are normalized:
 * converted the letters and the accents into ascii chars (range \u0300-\u036f) and then
 * the accents chars are removed so that the match is just on letters
 */
export function normaliseWord(word: string) {
  return word
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
}
