import React from 'react'

import { StyleContextProps, StylesContext } from './components/styles/StylesContext'

type EndUserComponentsProviderProps = StyleContextProps & {
  language?: string
}

export function EndUserComponentsProvider({
  language,
  ...styleContextProps
}: EndUserComponentsProviderProps) {
  return <StylesContext {...styleContextProps} />
}
