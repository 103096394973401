import styled from 'styled-components'
import { device } from '@constants/devices'
import { gap, ContainerStyle } from '@styles'
import { Button } from '@components'

const padding = 40

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(45, 45, 45, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  ${device.mobile} {
    width: 100%;
  }
  ${device.tablet} {
    width: 560px;
    border-radius: 5px;
    max-height: 350px;
  }
  ${device.desktop} {
    max-width: 700px;
  }
  // As flex item
  flex-grow: 1;
`

export const Header = styled.div<{ bgColor: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  height: 60px;
  padding: 0 ${padding}px;
  color: white;
  font-weight: 300;
  border-radius: 5px 5px 0 0;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${padding}px;
  flex-grow: 1;
`

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${gap(30, 'px', 'v')}
  ${ContainerStyle}
`

export const MobileButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ${gap(20, 'px', 'v')}
`

export const MobileQuestion = styled.div`
  text-align: center;
  line-height: 1.5;
`

export const DesktopTitle = styled.div`
  font-weight: 600;
`

export const DesktopQuestions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 300px;
  ${gap(20, 'px', 'v')}
`

export const DesktopButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${gap(10, 'px', 'h')}
`

// As often times the copy on this button will be longer than a typical 'yes close'
// We set the padding to 0 to allow for more text room
export const AlternativeFlowButton = styled(Button)`
  padding: 0;
`
