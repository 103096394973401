import styled from 'styled-components'
import { Tabs, TabPanel } from '@components'
import { CONTAINER_SIDE_PADDING, CONTAINER_SIDE_PADDING_DESKTOP } from '@styles'
import { device } from '@constants/devices'

export const StyledTabs = styled(Tabs)`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`

export const StyledTabPanel = styled(TabPanel)`
  overflow-y: scroll;

  &::after {
    content: '';
    height: 50px;
    position: absolute;
    bottom: 0;
    left: ${CONTAINER_SIDE_PADDING}px;
    right: ${CONTAINER_SIDE_PADDING}px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;
    ${device.tablet} {
      left: ${CONTAINER_SIDE_PADDING_DESKTOP}px;
      right: ${CONTAINER_SIDE_PADDING_DESKTOP}px;
    }
  }
`
