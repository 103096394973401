import React from 'react'

import {
  OptionComponentBigWithThumbRoundlet,
  OptionComponentSmallWithThumbRoundlet,
} from './OptionComponent'
import { RadioButtonOption } from './RadioButtonOption'
import {
  SelectableListBase,
  SelectableListBaseProps,
} from './SelectableListBase'

export type SelectableListProps<T> = Omit<
  SelectableListBaseProps<T>,
  'OptionComponent'
>

export function SelectableListWithBigOption(
  props: SelectableListProps<string>,
) {
  return (
    <SelectableListBase
      {...props}
      OptionComponent={OptionComponentBigWithThumbRoundlet}
    />
  )
}

export function SelectableListWithSmallOption(
  props: SelectableListProps<string>,
) {
  return (
    <SelectableListBase
      {...props}
      OptionComponent={OptionComponentSmallWithThumbRoundlet}
    />
  )
}

export function SelectableListWithRadioButton(
  props: SelectableListProps<string>,
) {
  return <SelectableListBase {...props} OptionComponent={RadioButtonOption} />
}
