import debounce from 'lodash/debounce'
import React, { useState, useCallback, useEffect } from 'react'

import { PrismaSearchInput, PrismaInputProps } from '../../prisma'
import { useTheme } from 'styled-components'

export const InputWithDebounce = React.forwardRef(function InputWithDebounce(
  {
    onChange,
    setValue,
    ...props
  }: Omit<PrismaInputProps, 'onChange'> & {
    onChange(str: string): void
    setValue: (s: string) => void
  },
  ref: React.Ref<HTMLInputElement>,
) {
  const { input } = useTheme()
  const [search, setSearch] = useState<string>('')

  const debouncedOnChange = useCallback(
    debounce((str: string) => {
      setValue(str)
      onChange(str)
    }, 300),
    [setValue, onChange],
  )

  useEffect(() => {
    setSearch(props.value || '') // Update search state when the value prop changes
  }, [props.value])

  const handleChange = (e: any) => {
    const query = e.target.value
    setSearch(query)
    debouncedOnChange(query)
  }

  return (
    <PrismaSearchInput
      {...props}
      ref={ref}
      onChange={handleChange}
      value={search}
      border={{
        hoverColor: input.hover.border.color,
        focusColor: input.focus.border.color,
      }}
    />
  )
})
