import React, { FC } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Button } from '@components'

export type NoResultCallToActionProps = {
  actionMessage: string
  actionColor: string
  onAction: () => void
}

const NoResultCallToAction: FC<NoResultCallToActionProps> = ({
  actionMessage,
  actionColor,
  onAction,
}) => {
  const { t } = useTranslation('end_user_components')
  return (
    <CallToActionContainer>
      <CallToActionMessage>{t('provider.no.result.call.to.action.message')}</CallToActionMessage>
      <StyledButton
        label={actionMessage}
        variant="secondary"
        color={actionColor}
        onClick={onAction}
        dataView="no-result-action-button"
      />
    </CallToActionContainer>
  )
}

const CallToActionContainer = styled.div`
  padding: 26px 0 2px 0;
  display: flex;
  justify-content: center;
  place-items: center;
  flex-direction: column;
`

const CallToActionMessage = styled.p`
  margin: 0;
  margin-right: 6px;
`

const StyledButton = styled(Button)`
  width: auto;
  margin-top: 8px;
  > div {
    display: inline-block;
  }
`

export default NoResultCallToAction
