import store from '@store'
import { v4 as uuidV4 } from 'uuid'
import snakeCase from 'lodash.snakecase'
import { Region } from '@models/ICountry'
import 'unfetch/polyfill'
import IProvider from '../models/IProvider'
import {
  getAuthAnalyticsApiBaseUrl,
  getBrowserDetails,
  getDeviceType,
  getOSDetails,
  getProviderType,
  getScreenSize,
  getSystemLanguages,
} from '../utils/helpers'

interface IAnalyticsOptions {
  uri: string
}

interface IOperation {
  name: string
  properties: Record<string, unknown>
}

interface ITrackProperties {
  clientId: string
  credentialsId?: string
  errorCode?: string
  providerId?: string
  version: string
  consentId?: string
}

interface ITrackMessage {
  event: string
  properties: ITrackProperties
}

const convertObjKeysToSnakeCase = (obj: object) =>
  (Object.keys(obj) as (keyof typeof obj)[]).reduce(
    (prev, key) => ({ ...prev, [snakeCase(key)]: obj[key] }),
    {} as Record<string, unknown>,
  )

class Analytics {
  private options: IAnalyticsOptions

  constructor(options: IAnalyticsOptions) {
    this.options = options
  }

  public getInfos(infos: Record<string, unknown>) {
    return {
      id: uuidV4(),
      event_type: infos.event,
      ...convertObjKeysToSnakeCase(infos),
    }
  }

  /**
   * Send a track message
   * @param message
   */
  public async track(message: ITrackMessage) {
    const data = {
      id: uuidV4(),
      event_type: message.event,
      ...convertObjKeysToSnakeCase(message.properties),
    }

    await this.send(data, '/event')
  }

  /**
   * Send an identity message to add the client id to the user profile
   * @param message
   */
  public async identifyClient(clientId: string) {
    const operation: IOperation = {
      name: '$set_once',
      properties: {
        'Client Id': clientId,
      },
    }

    await this.identify(operation)
  }

  /**
   * Send an identity message to add consent ids in the user profile
   * @param message
   */
  public async identifyConsent(providerId: string, consentId: string) {
    const propertyName = this.capitalizeFirstLetter(`${providerId} Consent Ids`)
    const operation: IOperation = {
      name: '$union',
      properties: {
        [propertyName]: [consentId],
      },
    }

    await this.identify(operation)
  }

  /**
   * Send an identity message to add consent ids in the user profile
   * @param message
   */
  public async identifyCredentials(providerId: string, credentialsId: string) {
    const propertyName = this.capitalizeFirstLetter(`${providerId} Credentials Ids`)
    const operation: IOperation = {
      name: '$union',
      properties: {
        [propertyName]: [credentialsId],
      },
    }

    await this.identify(operation)
  }

  private async identify(operation: IOperation) {
    const data = {
      id: uuidV4(),
      operation,
    }

    await this.send(data, '/identity')
  }

  private async send(data: Record<string, unknown>, endpoint: string) {
    const url = `${this.options.uri}${endpoint}`

    const response = await fetch(url, {
      body: JSON.stringify(data),
      credentials: 'include',
      // This option prevents browsers from terminating the HTTP requests
      // when the document unloads. Useful for tracking e. g. clicking the
      // "Allow" button on the consent screen, which results in a redirect
      // to the provider's authentication page.
      keepalive: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (response.status !== 200) {
      throw new Error(response.statusText)
    }
  }

  private capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
}

export enum EventType {
  ConsentPageLoaded = 'ConsentPageLoaded',
  ConsentGranted = 'ConsentGranted',
  CountryPageLoaded = 'CountryPageLoaded',
  CountrySelected = 'CountrySelected',
  ProviderSelectionPageLoaded = 'ProviderSelectionPageLoaded',
  ProviderSelected = 'ProviderSelected',
  LoginPageLoaded = 'LoginPageLoaded',
  AuthStart = 'AuthStart',
  AuthSuccess = 'AuthSuccess',
  AuthError = 'AuthError',
  ConsentDenied = 'ConsentDenied',
  ExceptionError = 'ExceptionError',
  RetryLoginClicked = 'RetryLoginClicked',
  CancelAfterErrorClicked = 'CancelAfterErrorClicked',
  AuthDialogInitialized = 'AuthDialogInitialized',
  AuthDialogFailedToInitialize = 'AuthDialogFailedToInitialize',
  AuthInputsPageLoaded = 'AuthInputsPageLoaded',
  AuthInputsStepLoaded = 'AuthInputsStepLoaded',
  AuthInputsSubmitted = 'AuthInputsSubmitted',
  AuthInputsSuccess = 'AuthInputsSuccess',
  AuthInputsError = 'AuthInputsError',
  SplashScreenPageLoaded = 'SplashScreenPageLoaded',
  SplashScreenProceed = 'SplashScreenProceed',
  SplashScreenCancelled = 'SplashScreenCancelled',
  ProviderSearched = 'ProviderSearched',
  BranchSelectionPageLoaded = 'BranchSelectionPageLoaded',
  BranchSelected = 'BranchSelected',
  AlternativeFlowInitiated = 'AlternativeFlowInitiated',
}

export type CancelEventType =
  | EventType.ConsentDenied
  | EventType.CancelAfterErrorClicked
  | EventType.SplashScreenCancelled

const browserDetails = {
  BrowserDetails: getBrowserDetails(),
  OperatingSystem: getOSDetails(),
  DeviceType: getDeviceType(),
  ScreenSize: getScreenSize(),
  ScreenWidth: window.screen.width || '',
  ScreenHeight: window.screen.height || '',
  BrowserLanguage: navigator.language || '',
  SystemLanguages: getSystemLanguages(),
}

/* Common fields shared between events */
export interface IBaseEvent {
  authFlowId: string
  clientId: string
  loginFlow: string
  dataApiPlan: string
  consentId?: string
  providerId?: string
  errorCode?: string
  errorMessage?: string
  providerType?: string
  exceptionError?: string
  origin?: string
  region?: Region
  meta?: Record<string, unknown>
  countryCode?: string
}

const createTrackingFn = (eventType: EventType) => async (event: IBaseEvent) => {
  const message = {
    event: eventType,
    properties: {
      ...browserDetails,
      ...event,
    },
  }

  return track(message)
}

export const trackProviderSelectionPageLoaded = createTrackingFn(
  EventType.ProviderSelectionPageLoaded,
)

export const trackProviderSelected = createTrackingFn(EventType.ProviderSelected)

export const trackProviderSearched = createTrackingFn(EventType.ProviderSearched)

export const trackAuthStart = createTrackingFn(EventType.AuthStart)

export const trackAuthError = createTrackingFn(EventType.AuthError)

export const trackConsentPageLoaded = createTrackingFn(EventType.ConsentPageLoaded)

export const trackCountryPageLoaded = createTrackingFn(EventType.CountryPageLoaded)

export const trackConsentGranted = createTrackingFn(EventType.ConsentGranted)

export const trackAuthDialogInitialized = createTrackingFn(EventType.AuthDialogInitialized)

export const trackAuthDialogFailedToInitialize = createTrackingFn(
  EventType.AuthDialogFailedToInitialize,
)

export const trackAuthInputsPageLoaded = createTrackingFn(EventType.AuthInputsPageLoaded)

export const trackAuthInputsStepLoaded = createTrackingFn(EventType.AuthInputsStepLoaded)

export const trackAuthInputsSubmitted = createTrackingFn(EventType.AuthInputsSubmitted)

export const trackAuthInputsSuccess = createTrackingFn(EventType.AuthInputsSuccess)

export const trackAuthInputsError = createTrackingFn(EventType.AuthInputsError)

export const trackSplashScreenPageLoaded = createTrackingFn(EventType.SplashScreenPageLoaded)

export const trackSplashScreenProceed = createTrackingFn(EventType.SplashScreenProceed)

export const trackBranchSelectionPageLoaded = createTrackingFn(EventType.BranchSelectionPageLoaded)

export const trackBranchSelected = createTrackingFn(EventType.BranchSelected)

export const trackAlternativeFlowInitiated = createTrackingFn(EventType.AlternativeFlowInitiated)

export const trackCountrySelected = async (event: IBaseEvent, countryCode: string) => {
  const message = {
    event: EventType.CountrySelected,
    properties: {
      ...browserDetails,
      ...event,
      countryCode,
    },
  }

  await track(message)
}

export const trackCancelClicked = async (event: IBaseEvent, eventType: CancelEventType) => {
  const message = {
    event: eventType,
    properties: {
      ...browserDetails,
      ...event,
    },
  }

  await track(message)
}

const track = async (
  // omit the `message.properties.version` property from the type
  message: Omit<ITrackMessage, 'properties'> & {
    properties: Omit<ITrackMessage['properties'], 'version'>
  },
) => {
  try {
    const analytics = new Analytics({
      uri: getAuthAnalyticsApiBaseUrl(),
    })
    await analytics.track({
      ...message,
      properties: { ...message.properties, version: getAuthDialogVersion() },
    })
  } catch (e) {
    console.error(e)
  }
}

export const getClientInfos = (event: IBaseEvent, selectedProvider: IProvider) => {
  try {
    const analytics = new Analytics({ uri: getAuthAnalyticsApiBaseUrl() })
    return analytics.getInfos({
      ...browserDetails,
      ...event,
      version: getAuthDialogVersion(),
      ProviderType: getProviderType(selectedProvider),
      providerId: selectedProvider.connector_id,
    })
  } catch (e) {
    console.error(e)
    return {}
  }
}

const getAuthDialogVersion = (): string => {
  const version = store.getState().general.dialogVersion
  return `v${version}`
}
