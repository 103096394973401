import {
  ConsentPermissionsListNote,
  ConsentProviderPermissionsListNote,
  FooterLink,
} from '@components'
import { TL_NAME, TL_PRIVACY_URL, TL_TOS_URL } from '@constants/legal'
import { usePermissionsString, useShouldDisplayProviderScopes, useTranslatedDatause } from '@hooks'
import { ConsentPageLayout } from '@layouts'
import useConsent, { useCondensedSubtitle } from '@pages/Consent/hooks'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ConsentLogosTLCheck from '../ConsentLogosTLCheck'

function CondensedUKUnregulated() {
  const { t } = useTranslation('v3_consent')
  const subtitle = useCondensedSubtitle({ isRegulated: false })
  const {
    clientName,
    dataUse,
    dataUseCategory,
    scope,
    providerScope,
    providerName,
    setDataUseCopy,
  } = useConsent()
  const translatedDataUse = useTranslatedDatause({ dataUse, dataUseCategory })
  const entityName = TL_NAME
  const shouldDisplayProviderScopes = useShouldDisplayProviderScopes({ scope, providerScope })
  const permissionsString = usePermissionsString({ providerName, scope, useNewCopy: true })
  const dataUseCopy = t('what_data_am_i_sharing.access_information_unregulated', {
    clientName,
    dataUse: translatedDataUse,
  })

  useEffect(() => {
    setDataUseCopy(dataUseCopy)
  }, [dataUseCopy])

  return (
    <ConsentPageLayout
      logos={<ConsentLogosTLCheck />}
      title={t('title')}
      subtitle={subtitle}
      accordionIsOpen={true}
      accordionTitle={t('what_data_am_i_sharing.title')}
      accordionContent={
        <>
          <p>{dataUseCopy}</p>
          <ConsentPermissionsListNote useNewCopy scope={scope} />
          <br />
          <p>
            {t('what_data_am_i_sharing.sharing_disclaimer_uk', {
              entityName,
            })}
          </p>
          <p>
            {t('what_data_am_i_sharing.security_disclaimer', {
              entityName,
            })}
          </p>
          {shouldDisplayProviderScopes && (
            <>
              <p>{t('what_data_am_i_sharing.additional_share', { providerName, entityName })}</p>
              <ConsentProviderPermissionsListNote
                useNewCopy
                scope={scope}
                providerScope={providerScope}
              />
              <br />
              <p>
                {t('what_data_am_i_sharing.additional_not_used_unregulated', {
                  clientName,
                  permissionsString,
                })}
              </p>
            </>
          )}
        </>
      }
      footer={
        <Trans ns="v3_consent" i18nKey="footer">
          By tapping ’Allow’, you agree to{' '}
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <FooterLink href={TL_TOS_URL}>TrueLayer’s Terms of Service</FooterLink> and{' '}
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <FooterLink href={TL_PRIVACY_URL}>Privacy Policy</FooterLink>
        </Trans>
      }
    />
  )
}

export default CondensedUKUnregulated
