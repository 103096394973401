import React, { AnchorHTMLAttributes, ReactNode } from 'react'
import { useClientColors } from '@hooks'
import { Link } from './styles'

function FooterLink({
  children,
  ...props
}: { children: ReactNode } & AnchorHTMLAttributes<HTMLAnchorElement>) {
  const { secondaryColor } = useClientColors()

  return (
    <Link color={secondaryColor} target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </Link>
  )
}

export default FooterLink
