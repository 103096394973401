import React from 'react'

import { SelectableListRequiredProps } from './SelectableListBase'
import { isGroupOption } from './isGroupOption'
import { OptionGroup } from './styles'

export function OptionListComponent<T>(props: SelectableListRequiredProps<T>) {
  return (
    <>
      {props.options.map((option, index) => {
        let group = `${index}`

        if (isGroupOption(option)) {
          group = option.optionGroupLabel
          return (
            <React.Fragment key={`fragment-${group}`}>
              <OptionGroup size="bodyXs" key={`group-${index}`}>
                {group}
              </OptionGroup>
              {option.options.map((optionItem) => (
                <props.OptionComponent
                  key={`grouped-${optionItem.value}`}
                  onChange={props.onChange}
                  value={props.value}
                  option={optionItem}
                />
              ))}
            </React.Fragment>
          )
        }

        return (
          <props.OptionComponent
            key={`not-grouped-${option.value}`}
            onChange={props.onChange}
            value={props.value}
            option={option}
          />
        )
      })}
    </>
  )
}
