import React from 'react'
import {
  EU_AUTHORITY_NAME,
  getLocaleEuLegalUrls,
  TL_NAME,
  TL_EU_REFERENCE_NUMBER,
  TL_EU_REFERENCE_URL,
} from '@constants/legal'
import { useNestedTranslation, useShouldDisplayProviderScopes, usePermissionsString } from '@hooks'
import { DefaultPageLayout } from '@layouts'
import { isOnlyInfoScope } from '@utils/scope'
import { useTranslation } from 'react-i18next'
import useConsent, { useDataUseWithFallback } from '../../hooks'
import ConsentButtons from '../ConsentButtons'
import ConsentDataUseAccessNote from '../ConsentDataUseAccessNote'
import ConsentLayoutUKEU from '../ConsentLayoutUKEU'
import ConsentLegalNote from '../ConsentLegalNote'
import ConsentNotesAccordionUKEU from '../ConsentNotesAccordionUKEU'
import ConsentNotesLink from '../ConsentNotesLink'
import ConsentNotesTabsUKEU from '../ConsentNotesTabsUKEU'
import {
  ConsentPermissionsListNote,
  ConsentProviderPermissionsListNote,
  ConsentLegalNoteContent,
} from '@components'
import ConsentDataAccessNote from '../ConsentDataAccessNote'
import ConsentOngoingAccessNote from '../ConsentOngoingAccessNote'
import ConsentLogosTLCheck from '../ConsentLogosTLCheck'

function ConsentContentEUUnregulated() {
  const titleTranslation = useNestedTranslation('v2_consent', ['title'])
  const subtitleTranslation = useNestedTranslation('v2_consent', ['subtitle'])
  const whatDataAmISharingTranslation = useNestedTranslation('v2_consent', [
    'what_data_am_i_sharing',
  ])
  const howDoesThisWorkTranslation = useNestedTranslation('v2_consent', ['how_does_this_work'])

  const { i18n } = useTranslation()

  const legalUrls = getLocaleEuLegalUrls({ languages: i18n.languages })

  const { providerName, clientName, scope, shouldDisplayNewSubtitleCopy, providerScope } =
    useConsent()

  const { dataUseCopy, dataUseBase } = useDataUseWithFallback()

  const title = isOnlyInfoScope(scope)
    ? titleTranslation('access_share_info_scope', {
        entityName: TL_NAME,
        clientName,
        providerName,
      })
    : titleTranslation('access_share', {
        entityName: TL_NAME,
        clientName,
      })

  const subtitle = subtitleTranslation(
    shouldDisplayNewSubtitleCopy ? 'retrieve_data_with_data_use' : 'retrieve_data',
    {
      clientName,
      entityName: TL_NAME,
      dataUse: dataUseBase,
    },
  )

  const shouldDisplayProviderScopes = useShouldDisplayProviderScopes({ scope, providerScope })
  const permissionsString = usePermissionsString({ scope, providerName })

  const notesProps = {
    howDoesThisWork: (
      <>
        <div data-view="how-does-this-work-explanation">
          {howDoesThisWorkTranslation('explanation.not_regulated', {
            providerName,
          })}
          .
        </div>
        <br />
        <div data-view="regulation-information">
          <div>
            {howDoesThisWorkTranslation('encryption_info.bank', {
              entityName: TL_NAME,
            })}
            .
          </div>
          <br />
          <div>{howDoesThisWorkTranslation('login_info', { clientName })}.</div>
          <br />
          <div>
            {howDoesThisWorkTranslation('authority_info', {
              entityName: TL_NAME,
              authorityName: EU_AUTHORITY_NAME,
            })}
            .{' '}
            <ConsentNotesLink href={TL_EU_REFERENCE_URL}>
              RN {TL_EU_REFERENCE_NUMBER}
            </ConsentNotesLink>
          </div>
        </div>
      </>
    ),
    whatDataAmISharing: (
      <>
        <ConsentDataAccessNote />
        <br />
        <ConsentPermissionsListNote scope={scope} />
        <br />
        <ConsentOngoingAccessNote />
        {shouldDisplayProviderScopes && (
          <>
            <div>
              {whatDataAmISharingTranslation('info.share_provider', {
                entityName: TL_NAME,
                providerName,
              })}
              :
            </div>
            <br />
            <ConsentProviderPermissionsListNote scope={scope} providerScope={providerScope} />
            <br />
            <div data-view="sharing-disclaimer">
              {whatDataAmISharingTranslation('disclaimer.data_not_used', {
                clientName,
                permissionsString,
              })}
              .
            </div>
          </>
        )}
      </>
    ),
    howIsMyDataUsed: (
      <>
        {dataUseCopy}
        .
        <br />
        <br />
        <ConsentDataUseAccessNote />
      </>
    ),
  }

  return (
    <DefaultPageLayout title={title} subtitle={subtitle} logo={<ConsentLogosTLCheck />}>
      <ConsentLayoutUKEU
        notesAccordion={<ConsentNotesAccordionUKEU {...notesProps} />}
        notesTabs={<ConsentNotesTabsUKEU {...notesProps} />}
        agreement={
          <>
            <ConsentButtons />
            <ConsentLegalNote>
              <ConsentLegalNoteContent
                entityName={TL_NAME}
                tosLink={legalUrls.tos}
                privacyLink={legalUrls.privacy}
              />
            </ConsentLegalNote>
          </>
        }
      />
    </DefaultPageLayout>
  )
}

export default ConsentContentEUUnregulated
