/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, RefObject } from 'react'
import {
  BaseItem,
  BaseItemWithLocalization,
  IListOptions,
  IListOptionsWithLocalization,
} from './types'
import { useProcessedList } from './utils'

type Result<Item> = {
  list: Item[]
  search: string
  update: (e: React.ChangeEvent<HTMLInputElement>) => void
  reset: () => void
  isFocused: boolean
  onSearchFocus: () => void
  onSearchBlur: () => void
  searchRef: RefObject<HTMLInputElement>
  forceFocus: () => void
}

// overload for NOT providing localization options
function useListSearch<ListKey extends string, Item extends BaseItem<ListKey>>(
  items: Item[],
  listOptions: IListOptions<ListKey>,
  autoFocus?: boolean,
): Result<Item>

// overload for providing localization options
function useListSearch<
  ListKey extends string,
  LocalisedPrefixKey extends string,
  Item extends BaseItemWithLocalization<ListKey, LocalisedPrefixKey>,
>(
  items: Item[],
  listOptions: IListOptionsWithLocalization<ListKey, LocalisedPrefixKey>,
  autoFocus?: boolean,
): Result<Item>

/**
 * Hook to manage search within a list of objects
 */
function useListSearch<
  ListKey extends string,
  LocalisedPrefixKey extends string,
  Item extends BaseItemWithLocalization<ListKey, LocalisedPrefixKey>,
>(
  items: Item[],
  listOptions: IListOptionsWithLocalization<ListKey, LocalisedPrefixKey> | IListOptions<ListKey>,
  autoFocus = false,
): Result<Item> {
  const { listKey } = listOptions
  const processedItems = useProcessedList(items, listOptions)
  const [list, setList] = useState<Item[]>(processedItems)
  const [search, setSearch] = useState('')
  const [isFocused, setIsFocused] = useState(autoFocus)
  const searchRef = useRef<HTMLInputElement>(null)

  const forceFocus = () => {
    searchRef.current?.focus()
    setTimeout(() => window.scrollTo(0, 0), 100)
  }

  const onSearchFocus = () => {
    setIsFocused(true)
    setTimeout(() => window.scrollTo(0, 0), 100)
  }

  const onSearchBlur = () => {
    setIsFocused(false)
  }

  useEffect(() => {
    if (autoFocus) {
      forceFocus()
    }
  }, [autoFocus])

  useEffect(() => {
    const startsWith: Item[] = []
    const includes: Item[] = []
    processedItems.forEach((i) => {
      const itemStr = i[listKey].toLowerCase()
      const searchStr = search.toLowerCase()
      if (itemStr.includes(searchStr)) {
        if (itemStr.startsWith(searchStr)) {
          startsWith.push(i)
        } else {
          includes.push(i)
        }
      }
    })
    setList([...startsWith, ...includes])
  }, [search, listKey])

  const update = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const reset = () => {
    if (!search) {
      searchRef.current?.blur()
    } else {
      setSearch('')
    }
  }

  return {
    list,
    search,
    update,
    reset,
    isFocused,
    onSearchFocus,
    onSearchBlur,
    searchRef,
    forceFocus,
  }
}

export default useListSearch
