import { Region } from '@models/ICountry'
import { distinctArray, isEmpty } from './helpers'
import IProvider from '../models/IProvider'
import { useSelector } from 'react-redux'
import IStoreState from '@store/IStoreState'

/* Country Selection Step should be enabled if more than one distinct region is available */
export function isCountrySelectionStepEnabled(providers: IProvider[]): boolean {
  const uniqueRegions = distinctArray(
    providers,
    (source, compare) => source.country === compare.country,
  )
  return uniqueRegions.length > 1
}

export const nameCustomisations = {
  'United Kingdom of Great Britain and Northern Ireland': 'United Kingdom',
}

export const codeCustomisations = {
  GB: 'UK',
}

export function getFlagFromCode(countryCode: string) {
  const code = countryCode === 'uk' ? 'gb' : countryCode
  return `https://payment.truelayer.com/assets/country/${code.toLowerCase()}.png`
}

export function getRegionFromCode(countryCode: string): Region {
  switch (countryCode) {
    case 'uk':
      return Region.UK
    default:
      return Region.EU
  }
}

export const ISO_ALPHA_2_COUNTRY_CODE_MAP = {
  // maps the 'gb' (the correct ISO 3166-1 alpha-2 code)
  // to 'uk' (the incorrect code used by us)
  gb: 'uk',
}

const isCountryCodeInMap = (
  countryCode: string,
): countryCode is keyof typeof ISO_ALPHA_2_COUNTRY_CODE_MAP =>
  countryCode in ISO_ALPHA_2_COUNTRY_CODE_MAP

export const getAlpha2CountryCode = (countryCode: string) => {
  return isCountryCodeInMap(countryCode) ? ISO_ALPHA_2_COUNTRY_CODE_MAP[countryCode] : countryCode
}

// e.g en-GB -> en
export const getLanguageWithoutRegion = (languageCode: string) => {
  return languageCode.split('-')[0]
}

export const getActiveCountry = (state: IStoreState) => {
  if (!isEmpty(state.providers.selectedProvider)) {
    return state.providers.selectedProvider.country
  }

  if (!isCountrySelectionStepEnabled(state.providers.items) && state.providers.items.length > 0) {
    return state.providers.items[0].country
  }

  return state.countries.countrySelected
}

export const useActiveCountry = () => {
  return useSelector(getActiveCountry)
}
