import React from 'react'
import { usePermissionsList } from '@hooks'

function ConsentProviderPermissionsListNote({
  scope,
  providerScope,
  className,
  useNewCopy = false,
}: {
  scope: string[]
  providerScope: string[]
  className?: string
  useNewCopy?: boolean
}) {
  const permissions = usePermissionsList(scope, { useNewCopy })
  const providerPermissions = usePermissionsList(providerScope, { useNewCopy })

  // only those provider permissions that are *not* in the
  // (client) permissions
  const extraProviderPermissions = providerPermissions.filter(
    (providerPermission) => !permissions.includes(providerPermission),
  )

  return (
    <ul className={className} data-view="provider-permissions-list">
      {extraProviderPermissions.map((permission, i) => (
        <li key={`provider-permission-${i}`} data-view="provider-permission-bullet">
          {permission}
        </li>
      ))}
    </ul>
  )
}

export default ConsentProviderPermissionsListNote
