import React from 'react'
import { getPopularProviders } from '@pages/Providers/hooks/usePopularProviders'
import { noop } from '@utils/helpers'
import { PROVIDERS } from './data'
import { DefaultPageLayout } from '@layouts'
import { ViewContainer } from '@pages/Providers/Providers.styles'
import { ProviderSelect } from '../../../../end-user-components'
import { useAlternativeFlow, useClientColors, useClientLogo } from '@hooks'
import { useTranslation } from 'react-i18next'
import { useProcessedList } from '@hooks/useListSearch/utils'
import IProvider from '@models/IProvider'

function ProvidersOverview() {
  const { t } = useTranslation('v2_providers')
  const { secondaryColor } = useClientColors()
  const clientLogo = useClientLogo()
  const { ctaCopy, clientHasAlternativeFlow } = useAlternativeFlow()

  const popularProviders = getPopularProviders(PROVIDERS, 'uk')

  const items = useProcessedList(PROVIDERS, {
    listKey: 'display_name',
    topValues: popularProviders.map((x: IProvider) => x.display_name),
    filterOutTerm: undefined,
  })

  return (
    <DefaultPageLayout title={t('title')} logo={clientLogo}>
      <ViewContainer>
        <ProviderSelect
          onProviderSelect={noop}
          providers={items}
          onProviderSearch={noop}
          noResultCallToActionOptions={{
            copy: ctaCopy,
            color: secondaryColor,
            action: noop,
          }}
          showNoResultCallToActionBelowList={clientHasAlternativeFlow}
        />
      </ViewContainer>
    </DefaultPageLayout>
  )
}

export default ProvidersOverview
