import React from 'react'
import { usePermissionsList } from '../../hooks'

function ConsentPermissionsListNote({
  scope,
  className,
  useNewCopy = false,
}: {
  scope: string[]
  className?: string
  useNewCopy?: boolean
}) {
  const permissions = usePermissionsList(scope, { useNewCopy })

  return (
    <ul className={className} data-view="permissions-list">
      {permissions.map((permission, i) => (
        <li key={`permission-${i}`} data-view="permission-bullet">
          {permission}
        </li>
      ))}
    </ul>
  )
}

export default ConsentPermissionsListNote
