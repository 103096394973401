import styled from 'styled-components'
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome'

export const Root = styled.div`
  display: grid;
  grid-gap: 1em;
`

export const ChoiceRoot = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const ChoiceContainer = styled.div<{
  selected: boolean
  selectedColor: string
}>`
  border: 1px solid ${(props) => (props.selected ? props.selectedColor : '#D2DAE0')};
  &:hover {
    border-color: ${(props) => props.selectedColor};
    box-shadow: none;
  }
  color: #2d2d2d;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  box-shadow: ${(props) => (!props.selected ? '2px 2px 4px rgba(50, 50, 93, 0.08)' : 'none')};
  min-height: 43px;
`

export const RadioButtonIcon = styled(FaIcon)<{
  selected: boolean
  selectedColor: string
}>`
  width: 14px !important;
  color: ${(props) => (props.selected ? props.selectedColor : '#ccc')};
`
