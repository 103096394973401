import { ConsentPageLayout } from '@layouts'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import ConsentLogosTLCheck from '../ConsentLogosTLCheck'
import { FooterLink } from '@components'
import useConsent from '@pages/Consent/hooks/useConsent'
import { TL_PRIVACY_URL, TL_TOS_URL } from '@constants/legal'

const ConsentContentBankAccountVerification = () => {
  const { t } = useTranslation('consent_bank_account_verification')

  const { clientName, providerName } = useConsent()

  return (
    <ConsentPageLayout
      logos={<ConsentLogosTLCheck />}
      title={t('title')}
      subtitle={
        <>
          <p>{t('subtitle.data_partner', {clientName, providerName})}</p>
          <p>{t('subtitle.confirm')}</p>
        </>
      }
      accordionIsOpen={undefined}
      accordionChevron={true}
      accordionTitle={t('accordion.title')}
      accordionContent={
        <>
          <p>
            {t('accordion.provide_service', {clientName})}
          </p>
          <ul>
            <li>{t('accordion.account_holder_name')}</li>
            <li>{t('accordion.account_holder_number')}</li>
            <li>{t('accordion.iban')}</li>
          </ul>
          <br />
          <p>
            {t('accordion.regulated')}
          </p>
          <p>{t('accordion.share_data', {providerName})}</p>
          <ul>
            <li>{t('accordion.balance')}</li>
          </ul>
          <br />
          <p>
            {t('accordion.not_save_or_use', {clientName})}
          </p>
          <p>
            {t('accordion.keep_information_secure')}
          </p>
        </>
      }
      footer={
        <Trans ns="consent_bank_account_verification" i18nKey="footer">
          By continuing you're agreeing to our{' '}
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <FooterLink href={TL_TOS_URL}>End User Terms of Service</FooterLink> and{' '}
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <FooterLink href={TL_PRIVACY_URL}>Privacy Policy</FooterLink>.
        </Trans>
      }
    />
  )
}

export default ConsentContentBankAccountVerification
