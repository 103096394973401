/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoginStatus } from '@models/loginStatus'
import keys from '../ActionTypeKeys'
import {
  IAddLoginInformation,
  IResetLoginInformation,
  IResetReturnUrl,
  ISetLoginStatus,
  ISetMfaData,
  ISetReturnUrl,
} from './ILoginActions'

export function resetLoginInformation(): IResetLoginInformation {
  return { type: keys.RESET_LOGIN_INFORMATION }
}

export function addLoginInformation(fields: any): IAddLoginInformation {
  return { type: keys.ADD_LOGIN_INFORMATION, payload: fields }
}

export function setLoginStatus(loginStatus: LoginStatus): ISetLoginStatus {
  return { type: keys.SET_LOGIN_STATUS, payload: loginStatus }
}

export function setMfaData(data: any): ISetMfaData {
  return { type: keys.SET_MFA_DATA, payload: data }
}

export function setReturnUrl(returnUrl: string | null): ISetReturnUrl {
  return { type: keys.SET_RETURN_URL, payload: returnUrl }
}

export function resetReturnUrl(): IResetReturnUrl {
  return { type: keys.RESET_RETURN_URL }
}
