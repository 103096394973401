import React, { useMemo } from 'react'
import {
  AppLoader,
  Countries,
  Providers,
  Consent,
  AuthInputs,
  Mfa,
  Nexi,
  Login,
  LoginError,
  IOSRedirect,
  ClientError,
  Interactive,
  Overview,
  OverviewV2,
  ConsentReconfirmation,
  SplashScreen,
  Branches,
} from '@pages'
import { Page, PagePath } from '@models/IPage'
import { usePageContext } from '@hooks'
import { CloseOverlay, PlanBanner } from '@components'
import useApp from './useApp'
import ErrorContainer from '@components/ErrorBoundary/ErrorContainer'
import usePageEvents from '@contexts/page/usePageEvents'

const App = () => {
  const { isLoading, error, showAuthorisationBanner } = useApp()
  const { showCloseOverlay, page } = usePageContext()

  // Hook that sends page events to mixpanel
  usePageEvents(error ? undefined : page, isLoading) // if error, don't pass page to not send page event

  const isErrorPage = window.location.pathname === PagePath.CLIENT_ERROR

  const pageContent = useMemo(() => {
    switch (page) {
      case Page.COUNTRIES:
        return <Countries />
      case Page.PROVIDERS:
        return <Providers />
      case Page.CONSENT:
        return <Consent />
      case Page.AUTHINPUTS:
        return <AuthInputs />
      case Page.NEXI:
        return <Nexi />
      case Page.MFA:
        return <Mfa />
      case Page.LOGIN:
        return <Login />
      case Page.LOGIN_ERROR:
        return <LoginError />
      case Page.IOS_REDIRECT:
        return <IOSRedirect />
      case Page.CLIENT_ERROR:
        return <ClientError />
      case Page.INTERACTIVE:
        return <Interactive />
      case Page.OVERVIEW:
        return <Overview />
      case Page.OVERVIEW_V2:
        return <OverviewV2 />
      case Page.CONSENT_RECONFIRMATION:
        return <ConsentReconfirmation />
      case Page.SPLASH_SCREEN:
        return <SplashScreen />
      case Page.BRANCHES:
        return <Branches />
      default:
        return null
    }
  }, [page])

  if ((isLoading || !page) && !isErrorPage && window.location.pathname !== PagePath.NEXI) {
    return <AppLoader />
  }

  if (error) {
    return <ErrorContainer error={error} />
  }

  return (
    <>
      {showAuthorisationBanner && <PlanBanner />}
      {showCloseOverlay && <CloseOverlay />}
      {pageContent}
    </>
  )
}

export default App
