import { useSelector, useDispatch } from 'react-redux'
import IStoreState from '@store/IStoreState'
import IProvider from '@models/IProvider'
import { setCountry } from '@actions/countries/countriesActions'
import { sendCountrySelectedAnalytics } from '@actions/analytics/index'

const useCountries = () => {
  const providers = useSelector((state: IStoreState) => state.providers.items)
  let { countries } = useSelector((state: IStoreState) => state.countries)
  const countrySelected = useSelector((state: IStoreState) => state.countries.countrySelected)
  const dispatch = useDispatch()

  // Get unique set of country codes from provider list
  const countryCodes = Array.from(new Set(providers.map((p: IProvider) => p.country)))
  // Filter all countries based on unique set
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  countries = countries!.filter((c) => countryCodes.includes(c.code))

  const onCountryClick = (countryCode: string | undefined) => {
    dispatch(setCountry(countryCode))
    dispatch(sendCountrySelectedAnalytics(countryCode))
  }

  return { countries, onCountryClick, countrySelected }
}

export default useCountries
