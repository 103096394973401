import { Action, ActionCreator as ReduxActionCreator } from 'redux'
import { ThunkAction } from 'redux-thunk'

import {
  CancelEventType,
  trackAuthError,
  trackCancelClicked,
  trackConsentGranted,
  trackConsentPageLoaded,
  trackCountryPageLoaded,
  trackCountrySelected,
  trackProviderSelected,
  trackProviderSelectionPageLoaded,
  trackAuthDialogInitialized,
  trackAuthDialogFailedToInitialize,
  trackAuthInputsPageLoaded,
  trackAuthInputsStepLoaded,
  trackAuthInputsSubmitted,
  trackAuthInputsSuccess,
  trackAuthInputsError,
  trackSplashScreenPageLoaded,
  trackSplashScreenProceed,
  trackProviderSearched,
  trackBranchSelectionPageLoaded,
  trackBranchSelected,
  trackAlternativeFlowInitiated,
} from '../../middlewares/analytics'
import {
  getAuthErrorPayload,
  getConsentCanceledPayload,
  getConsentGrantedPayload,
  getConsentPayload,
  getCountryPagePayload,
  getProviderSelectedPayload,
  getProviderSelectionPayload,
  getAuthDialogInitializedPayload,
  getAuthDialogFailedToInitializePayload,
  getAuthInputsPageLoadedPayload,
  getAuthInputsStepLoadedPayload,
  getAuthInputsSubmittedPayload,
  getAuthInputsSuccessPayload,
  getAuthInputsErrorPayload,
  getSplashScreenPageLoadedPayload,
  getSplashScreenProceedPayload,
  getProviderSearchedPayload,
  getBranchSelectionPageLoadedPayload,
  getBranchSelectedPayload,
  getAlternativeFlowInitiatedPayload,
} from '../../utils/analytics'
import IStoreState from '../../store/IStoreState'
import IProvider from '../../models/IProvider'
import { fireAndForget } from '@utils/helpers'

type ActionCreator = ReduxActionCreator<ThunkAction<Promise<void>, IStoreState, void, Action>>

export const sendInitializedAnalytics: ActionCreator =
  () =>
  async (dispatch, getState): Promise<void> => {
    const payload = getAuthDialogInitializedPayload(getState())
    await fireAndForget(trackAuthDialogInitialized(payload))
  }

export const sendConsentAnalytics: ActionCreator =
  () =>
  async (dispatch, getState): Promise<void> => {
    const payload = getConsentPayload(getState())
    await fireAndForget(trackConsentPageLoaded(payload))
  }

export const sendConsentCanceledAnalytics: ActionCreator =
  (event: CancelEventType) =>
  async (dispatch, getState): Promise<void> => {
    await fireAndForget(sendConsentCanceledEvent(event, getState()))
  }

export async function sendConsentCanceledEvent(event: CancelEventType, state: IStoreState) {
  const payload = getConsentCanceledPayload(state)
  await trackCancelClicked(payload, event)
}

export const sendConsentGrantedAnalytics: ActionCreator =
  (consentId?: string) =>
  async (dispatch, getState): Promise<void> => {
    const payload = getConsentGrantedPayload(getState(), consentId)
    await fireAndForget(trackConsentGranted(payload))
  }

export const sendCountryLoadedAnalytics: ActionCreator =
  () =>
  async (dispatch, getState): Promise<void> => {
    const payload = getCountryPagePayload(getState())
    await fireAndForget(trackCountryPageLoaded(payload))
  }

export const sendCountrySelectedAnalytics: ActionCreator =
  (countryCode: string) =>
  async (dispatch, getState): Promise<void> => {
    const payload = getCountryPagePayload(getState())
    await fireAndForget(trackCountrySelected(payload, countryCode))
  }

export const sendProviderSelectionAnalytics: ActionCreator =
  () =>
  async (dispatch, getState): Promise<void> => {
    const payload = getProviderSelectionPayload(getState())
    await fireAndForget(trackProviderSelectionPageLoaded(payload))
  }

export const sendProviderSelectedAnalytics: ActionCreator =
  (options: {
    provider: IProvider
    searchTerm: string
    filteredProviders: IProvider[]
    countryProviders: IProvider[]
    allProviders: IProvider[]
  }) =>
  async (dispatch, getState): Promise<void> => {
    const payload = getProviderSelectedPayload(getState(), options)
    await fireAndForget(trackProviderSelected(payload))
  }

export const sendProviderSearchedAnalytics: ActionCreator =
  (options: {
    searchTerm: string
    filteredProviders: IProvider[]
    countryProviders: IProvider[]
    allProviders: IProvider[]
  }) =>
  async (dispatch, getState): Promise<void> => {
    const payload = getProviderSearchedPayload(getState(), options)
    await fireAndForget(trackProviderSearched(payload))
  }

export const sendAuthErrorAnalytics: ActionCreator =
  (errorCode: string) =>
  async (dispatch, getState): Promise<void> => {
    const payload = getAuthErrorPayload(getState(), errorCode)
    await fireAndForget(trackAuthError(payload))
  }

export const sendFailedToInitialize: ActionCreator =
  (errorCode: string, errorMessage: string, tlCorrelationId: string) =>
  async (dispatch, getState): Promise<void> => {
    const payload = getAuthDialogFailedToInitializePayload(
      getState(),
      errorCode,
      errorMessage,
      tlCorrelationId,
    )
    await fireAndForget(trackAuthDialogFailedToInitialize(payload))
  }

export const sendAuthInputsPageLoadedAnalytics: ActionCreator =
  () =>
  async (dispatch, getState): Promise<void> => {
    const payload = getAuthInputsPageLoadedPayload(getState())
    await fireAndForget(trackAuthInputsPageLoaded(payload))
  }

export const sendAuthInputsStepLoadedAnalytics: ActionCreator =
  () =>
  async (dispatch, getState): Promise<void> => {
    const payload = getAuthInputsStepLoadedPayload(getState())
    await fireAndForget(trackAuthInputsStepLoaded(payload))
  }

export const sendAuthInputsSubmitted: ActionCreator =
  () =>
  async (dispatch, getState): Promise<void> => {
    const payload = getAuthInputsSubmittedPayload(getState())
    await fireAndForget(trackAuthInputsSubmitted(payload))
  }

export const sendAuthInputsSuccess: ActionCreator =
  () =>
  async (dispatch, getState): Promise<void> => {
    const payload = getAuthInputsSuccessPayload(getState())
    await fireAndForget(trackAuthInputsSuccess(payload))
  }

export const sendAuthInputsError: ActionCreator =
  () =>
  async (dispatch, getState): Promise<void> => {
    const payload = getAuthInputsErrorPayload(getState())
    await fireAndForget(trackAuthInputsError(payload))
  }

export const sendSplashScreenPageLoaded: ActionCreator =
  () =>
  async (dispatch, getState): Promise<void> => {
    const payload = getSplashScreenPageLoadedPayload(getState())
    await fireAndForget(trackSplashScreenPageLoaded(payload))
  }

export const sendSplashScreenProceed: ActionCreator =
  () =>
  async (dispatch, getState): Promise<void> => {
    const payload = getSplashScreenProceedPayload(getState())
    await fireAndForget(trackSplashScreenProceed(payload))
  }

export const sendBranchSelectionPageLoaded: ActionCreator =
  () =>
  async (dispatch, getState): Promise<void> => {
    const payload = getBranchSelectionPageLoadedPayload(getState())
    await fireAndForget(trackBranchSelectionPageLoaded(payload))
  }

export const sendBranchSelected: ActionCreator =
  (branchKey: string) =>
  async (dispatch, getState): Promise<void> => {
    const payload = getBranchSelectedPayload(getState())
    await fireAndForget(trackBranchSelected(payload))
  }

export const sendAlternativeFlowInitiated: ActionCreator =
  () =>
  async (dispatch, getState): Promise<void> => {
    const payload = getAlternativeFlowInitiatedPayload(getState())
    await fireAndForget(trackAlternativeFlowInitiated(payload))
  }
