import styled, { css } from 'styled-components/macro'

import { Paragraph } from '../../Paragraph'
import { ThumbRoundlet } from '../../ThumbRoundlet'
import { PrismaIcon } from '../../prisma'
import { device } from '../../styles/breakpoints'

export const OPTION_HEIGHT = 84

export const SearchWrapper = styled.div`
  ${({ theme: { spaces } }) => css`
    max-height: ${spaces.xxxl};
    display: flex;
    width: 100%;
    margin-bottom: ${spaces.md};
  `}
  display: flex;

  > *:nth-child(2) {
    margin-left: ${(props) => props.theme.spaces.xs};
  }
`

export const OptionList = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    margin-bottom: ${theme.spaces.md};
    max-height: 400px;

    @media (${device.sm}), (max-height: ${OPTION_HEIGHT}) {
      max-height: unset;
      margin: 0 0 ${theme.spaces.md} 0;
      padding-left: 0;
    }
  `,
)

export const IconElement = styled(PrismaIcon)<
  Partial<{
    $selected: boolean
    direction: 'left' | 'right'
    fontSize: string
  }>
>`
  ${({ $selected, theme: { list_item, spaces, colours, typography }, direction, fontSize }) => `
    margin-${direction ?? 'right'}: ${spaces.xs};
    font-size: ${fontSize || typography.montserrat.fontSize.h1};
    color: ${$selected ? list_item.default.icon.color : colours.neutral.grey};
  `}
`

export const OptionGroup = styled(Paragraph)(
  ({ theme: { colours, spaces } }) => css`
    text-transform: uppercase;
    min-height: ${OPTION_HEIGHT}px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${colours.tints.charcoal[10]};
    padding: 0;
    background: transparent;
    text-decoration: none;
    border: none;
    outline: none;
    box-sizing: border-box;

    @media (${device.sm}) {
      margin-left: ${spaces.md};
    }
  `,
)

export const OptionElement = styled.button<{ height?: number }>(
  ({ theme: { list_item, spaces }, height }) => css`
    min-height: ${height || OPTION_HEIGHT}px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
    background: transparent;
    text-decoration: none;
    border: none;
    outline: none;
    box-sizing: border-box;
    background-color: ${list_item.default.background};
    border-bottom: ${list_item.default.border.width} solid;
    border-color: ${list_item.default.border.color};

    &:hover {
      background-color: ${list_item.hover.background};
      border-bottom: ${list_item.hover.border.width} solid;
      border-color: ${list_item.hover.border.color};
    }

    &:active {
      background-color: ${list_item.active.background};
      border-bottom: ${list_item.active.border.width} solid;
      border-color: ${list_item.active.border.color};
    }

    &:focus {
      background-color: ${list_item.focus.background};
      border-bottom: ${list_item.focus.border.width} solid;
      border-color: ${list_item.focus.border.color};
    }

    &:focus svg[data-icon='check-circle'] {
      border: 1px solid ${list_item.focus.icon.color};
      border-radius: 14px;
      box-sizing: border-box;
    }

    & ${ThumbRoundlet} {
      flex-shrink: 0;
      margin: 0 0 0 ${spaces.xs};

      @media (${device.sm}) {
        margin-left: ${spaces.md};
      }
    }

    &:hover ${IconElement} {
      color: ${list_item.hover.icon.color};
    }

    &:focus ${IconElement} {
      color: ${list_item.focus.icon.color};
    }

    &:active ${IconElement} {
      color: ${list_item.active.icon.color};
    }
  `,
)

export const OptionLabelWrapper = styled.div(
  ({ theme: { spaces } }) => css`
    width: 100%;
    height: 100%;
    max-height: ${OPTION_HEIGHT}px;
    text-align: left;
    margin-left: ${spaces.xs};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
)

export const OptionLabel = styled(Paragraph)`
  letter-spacing: ${(props) => props.theme.typography.montserrat.letterSpacing.caption};
  line-height: 1; // this fix the height of the label inside the option
`

export const NoResults = styled.div`
  width: 100%;
  text-align: center;

  @media (${device.sm}) {
    margin-top: 0;
  }
`

export const JumpToInputWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: ${OPTION_HEIGHT - 1}px;
  box-sizing: border-box;
  margin-top: ${({ theme }) => theme.spaces.lg};
`

export const JumpToInputLink = styled.a(
  ({ theme: { list_item, typography } }) => css`
    font-size: ${typography.montserrat.fontSize.bodyS};
    font-weight: ${typography.montserrat.fontWeight.regular};
    line-height: ${typography.montserrat.lineHeight.bodyS};
    color: ${list_item.selected.color};
    height: ${OPTION_HEIGHT - 1}px;
    box-sizing: border-box;
    padding-top: ${OPTION_HEIGHT / 4}px;
  `,
)

export const SmallOptionElement = styled(OptionElement)`
  min-height: 36px;
  border: none;
  border-bottom: none;
  border-color: none;
`
