import styled from 'styled-components'
import { ContainerStyle, gap, hideScrollBar } from '@styles'

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const AuthInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${ContainerStyle}
  ${gap(40, 'px', 'v')}
  overflow-y: scroll;
  ${hideScrollBar}
  margin-bottom: 48px;
`

export const SubmitContainer = styled.div`
  ${ContainerStyle}
  > button {
    width: 100%;
  }
  margin-bottom: 40px;
`
