import * as React from 'react'
import loading from './loading.svg'
import './Spinner.css'
import type { Properties } from 'csstype'

type CSSPosition = Properties['position']

interface ISpinnerProps {
  containerPosition: CSSPosition
  containerDisplay?: string
  containerJustifyContent?: string
  containerAlignItems?: string
  containerHeight?: string
  containerWidth?: string
  containerTop?: number
  containerBottom?: number
  containerLeft?: number
  containerRight?: number
  containerBackgroundColor?: string
  containerMargin?: string
}

const defaultProps: ISpinnerProps = {
  containerPosition: 'absolute',
  containerDisplay: 'flex',
  containerJustifyContent: 'center',
  containerAlignItems: 'center',
  containerHeight: '100vh',
  containerWidth: '100vw',
  containerTop: 0,
  containerBottom: 0,
  containerLeft: 0,
  containerRight: 0,
  containerBackgroundColor: 'white',
  containerMargin: '0 auto',
}

const Spinner = (props: ISpinnerProps) => {
  const styleContainer = {
    position: props.containerPosition,
    display: props.containerDisplay,
    justifyContent: props.containerJustifyContent,
    alignItems: props.containerAlignItems,
    height: props.containerHeight,
    width: props.containerWidth,
    top: props.containerTop,
    bottom: props.containerBottom,
    left: props.containerLeft,
    right: props.containerRight,
    backgroundColor: props.containerBackgroundColor,
    margin: props.containerMargin,
  }

  return (
    <div style={styleContainer}>
      <div style={{ height: 100, width: 100 }}>
        <img src={loading} alt="loading" data-chromatic="ignore" />
      </div>
    </div>
  )
}

Spinner.defaultProps = defaultProps

export default Spinner
