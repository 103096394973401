import { device } from '@constants/devices'
import { ContainerStyle, gap } from '@styles'
import styled from 'styled-components'

export const AccordionItemContainer = styled.div`
  font-size: 14px;
  border-top: 1px solid #efeeed;
  line-height: 1.5;
`

export const AccordionButtonContainer = styled.button<{
  isOpen: boolean
  openColor: string
  disabled?: boolean
}>`
  border-style: none;
  &::-moz-focus-inner {
    border: 0;
  }
  background: none;
  width: 100%;
  padding-top: 24px;
  padding-bottom: ${(props) => (props.isOpen ? '16px' : '24px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${(props) => (props.isOpen ? props.openColor : '#inherit')};
  ${ContainerStyle}
  ${device.desktop} {
    &:hover {
      color: ${(props) => props.openColor};
    }
  }
`

export const AccordionTitleContainer = styled.div`
  display: flex;
  align-items: center;
  ${gap(10, 'px', 'h')}
`

export const AccordionTitleText = styled.div<{ isOpen: boolean }>`
  font-size: 16px;
  font-weight: ${(props) => (props.isOpen ? '600' : '400')};
`

export const AccordionPanelContainer = styled.div`
  padding-bottom: 24px;
  ${ContainerStyle}
`
