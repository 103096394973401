import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import IStoreState from '../../store/IStoreState'
import keys from '../ActionTypeKeys'
import { getClientRequest, getSessionRequest } from '../../middlewares/api'
import {
  IClientConfiguration,
  IClientAttributes,
  IClientResponse,
  ISessionResponse,
} from '../../models/IResponse'
import {
  ISaveClientAttributes,
  ISaveClientSettings,
  ISetConsentId,
  ISaveSession,
  IFlowType,
} from './IClientActions'

export function saveClientConfiguration(client: IClientConfiguration): ISaveClientSettings {
  return {
    payload: client,
    type: keys.SAVE_CLIENT_SETTINGS,
  }
}

export function saveClientAttributes(attributes: IClientAttributes): ISaveClientAttributes {
  return {
    payload: attributes,
    type: keys.SAVE_CLIENT_ATTRIBUTES,
  }
}

function saveSession(session: ISessionResponse): ISaveSession {
  return {
    payload: session,
    type: keys.SAVE_SESSION,
  }
}

export function setConsentId(consentId?: string): ISetConsentId {
  return {
    payload: consentId,
    type: keys.SET_CONSENT_ID,
  }
}

export function setFlowType(typeName: string): IFlowType {
  return {
    payload: typeName,
    type: keys.SET_FLOW_TYPE,
  }
}

export const getClient: ActionCreator<ThunkAction<Promise<void>, IStoreState, void, Action>> =
  () =>
  async (dispatch: ThunkDispatch<IStoreState, void, Action>): Promise<void> => {
    const { attributes, ...clientConfiguration }: IClientResponse = await getClientRequest()
    dispatch(saveClientConfiguration(clientConfiguration))
    dispatch(saveClientAttributes(attributes))
  }

export const getSession: ActionCreator<ThunkAction<Promise<void>, IStoreState, void, Action>> =
  () =>
  async (dispatch: ThunkDispatch<IStoreState, void, Action>): Promise<void> => {
    const session = await getSessionRequest()
    dispatch(saveSession(session))
  }
