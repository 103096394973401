import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { getProvidersRequest } from '@middlewares/api'
import IStoreState from '@store/IStoreState'
import IProvider from '../../models/IProvider'
import keys from '../ActionTypeKeys'
import {
  ISaveProvidersListAction,
  ISetSelectedBranch,
  ISetSelectedProvider,
} from './IProvidersActions'
import { extractBranchDataFromAuthInputs } from '@utils/providers'

export function saveProvidersList(providers: IProvider[]): ISaveProvidersListAction {
  return {
    payload: providers,
    type: keys.SAVE_PROVIDERS_LIST,
  }
}

export function setSelectedProvider(provider?: IProvider): ISetSelectedProvider {
  return {
    payload: provider,
    type: keys.SET_SELECTED_PROVIDER,
  }
}

export function setSelectedBranch(branchValue: string | undefined): ISetSelectedBranch {
  return {
    payload: branchValue,
    type: keys.SET_SELECTED_BRANCH,
  }
}

export const getProviders: ActionCreator<ThunkAction<Promise<void>, IStoreState, void, Action>> =
  () =>
  async (dispatch: ThunkDispatch<IStoreState, void, Action>): Promise<void> => {
    const providers = await getProvidersRequest()

    const providersWithBranchExtracted = providers.map(extractBranchDataFromAuthInputs)

    if (providersWithBranchExtracted.length === 1) {
      dispatch(setSelectedProvider(providersWithBranchExtracted[0]))
    } else {
      dispatch(saveProvidersList(providersWithBranchExtracted))
    }
  }
