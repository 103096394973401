import {
  setAmazonConsentAccordionOpened,
  setAmazonConsentChangeBankClicked,
} from '@actions/general/generalActions'
import { infoCircleLight, infoCircleSolid } from '@assets/icons'
import {
  Accordion,
  AccordionButton,
  AccordionChevron,
  AccordionItem,
  AccordionPanel,
  AccordionTitle,
  Button,
  FooterLink,
} from '@components'
import { TL_PRIVACY_URL, TL_TOS_URL } from '@constants/legal'
import { useClientColors, useNestedTranslation, usePageContext } from '@hooks'
import { EmptyPageLayout } from '@layouts'
import { Page } from '@models/IPage'
import { ScrollingDiv } from '@pages/Consent/styles'
import IStoreState from '@store/IStoreState'
import React from 'react'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ConsentLogosTLCheck from '../ConsentLogosTLCheck'
import {
  BottomContainer,
  ButtonsContainer,
  Container,
  LegalFooter,
  PermissionListItem,
  PermissionsList,
  Title,
} from './styles'
import ConsentAllowButton from '../ConsentAllowButton/ConsentAllowButton'

const ConsentContentAmazon = () => {
  const providerName = useSelector(
    (state: IStoreState) => state.providers.selectedProvider.display_name,
  )
  const { secondaryColor } = useClientColors()
  const t = useNestedTranslation('v2_consent', ['amazon'])
  const { goBackToPage } = usePageContext()
  const dispatch = useDispatch()

  const onChangeBankClick = () => {
    dispatch(setAmazonConsentChangeBankClicked())
    goBackToPage(Page.PROVIDERS)
  }
  return (
    <EmptyPageLayout>
      <Container>
        <ConsentLogosTLCheck />
        <Title data-view="title">{t('title', { providerName })}</Title>
        <ScrollingDiv>
          <Accordion themeColor={secondaryColor}>
            <AccordionItem onOpen={() => dispatch(setAmazonConsentAccordionOpened())}>
              <AccordionButton>
                <AccordionTitle icon={infoCircleSolid} iconLight={infoCircleLight}>
                  {t('accordion.title')}
                </AccordionTitle>
                <AccordionChevron />
              </AccordionButton>
              <AccordionPanel>
                <p>{t('accordion.data_use')}</p>
                <p>{t('accordion.one_off_access')}</p>
                <PermissionsList data-view="permissions-list">
                  <PermissionListItem key={`permission-0`} data-view="permission-bullet">
                    {t('accordion.list.your_full_name')}
                  </PermissionListItem>
                  <PermissionListItem key={`permission-1`} data-view="permission-bullet">
                    {t('accordion.list.account')}
                  </PermissionListItem>
                </PermissionsList>
                <p>{t('accordion.explanation', { providerName })}</p>
                <p>{t('accordion.security')}</p>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </ScrollingDiv>
      </Container>
      <BottomContainer>
        <ButtonsContainer>
          <Button
            label={t('buttons.change_bank')}
            variant="secondary"
            color={secondaryColor}
            onClick={onChangeBankClick}
            dataView="button-change-bank"
          />
          <ConsentAllowButton label={t('buttons.allow')} />
        </ButtonsContainer>
        <LegalFooter>
          <Trans ns="v2_consent" i18nKey="amazon.legal_note">
            By clicking 'Allow', you're agreeing to the{' '}
            <FooterLink href={TL_TOS_URL}>Terms and Conditions</FooterLink> of this service and you
            acknowledge that you've read and understood the{' '}
            <FooterLink href={TL_PRIVACY_URL}>Privacy Statement</FooterLink>. You give explicit
            consent for Amazon and TrueLayer to validate the details of your selected bank.
          </Trans>
        </LegalFooter>
      </BottomContainer>
    </EmptyPageLayout>
  )
}

export default ConsentContentAmazon
