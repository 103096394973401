import { useSelector } from 'react-redux'
import { LoginStatus } from '@models/loginStatus'
import useLoginRequest from '@pages/Login/hooks/useLoginRequest'
import IStoreState from '@store/IStoreState'

const useHiddenAuthUri = (abortSignal: AbortSignal) => {
  const { provider, loginInfo, loginStatus, hasMfaData, returnUrl } = useSelector(
    (state: IStoreState) => ({
      provider: state.providers.selectedProvider,
      loginInfo: state.login.loginInformation,
      loginStatus: state.login.loginStatus,
      returnUrl: state.login.returnUrl,
      hasMfaData: !!state.login.mfaData,
    }),
  )

  // if the `returnUrl` is present or if the `mfaData` are already in the store,
  // we don't need to make an `authuri` request as we already have what we need
  // to determine what the next step after the consent page is
  // this scenario would occur after a successful validation of the auth inputs
  // where we use the same `authuri` request to validate the auth inputs
  const skip = !!returnUrl || hasMfaData

  // useLoginRequest hook primarily used on LOGIN page
  // invoked here with preConsent flag = true
  // as we are performing a hiddenAuthUri call on CONSENT page
  // This:
  //    - skips some error handling
  //    - does not redirect straight to the MFA page if mfaData is returned. It just stores it
  const { requestDone } = useLoginRequest(provider, loginInfo, true, skip, abortSignal)

  return {
    preLoadedUrl: loginStatus === LoginStatus.Success ? returnUrl : null,
    hiddenAuthUriFinished: requestDone,
  }
}

export default useHiddenAuthUri
