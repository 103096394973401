import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Action, ActionCreator } from 'redux'
import ActionTypeKeys from '../ActionTypeKeys'
import IStoreState from '../../store/IStoreState'
import { getCountriesRequest } from '../../middlewares/api'
import { ISaveCountriesListAction, ISetCountry } from './ICountriesActions'
import { ICountry } from '../../models/ICountry'

export const getCountries: ActionCreator<ThunkAction<Promise<void>, IStoreState, void, Action>> =
  () =>
  async (dispatch: ThunkDispatch<IStoreState, void, Action>): Promise<void> => {
    const countries = await getCountriesRequest()

    dispatch(saveCountriesList(countries))
  }

export const setCountry = (countryCode?: string): ISetCountry => ({
  payload: countryCode,
  type: ActionTypeKeys.SET_SELECTED_COUNTRY,
})

export function saveCountriesList(countries: ICountry[]): ISaveCountriesListAction {
  return {
    payload: countries,
    type: ActionTypeKeys.SAVE_COUNTRIES_LIST,
  }
}
