import React from 'react'
import { useClientColors } from '@hooks'
import { StyledComponentProps } from 'styled-components'
import { LinkContainer } from './styles'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ConsentNotesLink(props: StyledComponentProps<'a', any, Record<string, unknown>, never>) {
  const { secondaryColor } = useClientColors()

  return (
    <LinkContainer target="_blank" rel="noopener noreferrer" color={secondaryColor} {...props} />
  )
}

export default ConsentNotesLink
