import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useTranslatedDatause } from '@hooks'
import { ClientType } from '@models/clientType'
import {
  CondensedUKAgent,
  CondensedUKRegulated,
  CondensedUKUnregulated,
} from '@pages/Consent/components'
import { ConsentContextProvider } from '@pages/Consent/context'
import IStoreState from '@store/IStoreState'
import { NEW_CONSENT_SUBTITLE_COPY_CLIENT_IDS } from '@utils/constants'
import { defaultProviderLogo, defaultProviderName } from './data'

const PROVIDER_SCOPE = [
  'info',
  'accounts',
  'balance',
  'cards',
  'transactions',
  'direct_debits',
  'standing_orders',
  'products',
]

const SCOPE = [
  'info',
  'accounts',
  'balance',
  'cards',
  'transactions',
  'direct_debits',
  'standing_orders',
  'products',
]

const overviewStateSelector = (state: IStoreState) => {
  const clientId = state.client.id
  const isAgent = state.client.clientSettings.is_agent
  const { clientAttributes } = state.client
  // Since for console we are still only considering the uk regulation status
  // we are doing the same thing for the overview page, featured in the console
  // TODO: get the regulation status from the right region (uk vs eu) when
  // we do it in console
  const isRegulated = clientAttributes.regulatory_information?.uk?.ais_regulated ?? false

  const clientType: ClientType.UkAgent | ClientType.UkRegulated | ClientType.UkUnregulated = isAgent
    ? ClientType.UkAgent
    : isRegulated
    ? ClientType.UkRegulated
    : ClientType.UkUnregulated

  return {
    clientName: state.client.clientSettings.client_name,
    clientId,
    clientType,
    dataUse: state.client.clientSettings.data_use,
    dataUseCategory: state.client.clientSettings.data_use_category,
    clientLogo: state.client.clientSettings.logo_uri,
    clientAttributes: state.client.clientAttributes,
  }
}

function ConsentOverview() {
  const {
    clientName,
    clientId,
    clientType,
    dataUse,
    dataUseCategory,
    clientLogo,
    clientAttributes,
  } = useSelector(overviewStateSelector)

  const providerLogo = defaultProviderLogo
  const providerName = defaultProviderName
  const regulatoryInformation = clientAttributes.regulatory_information?.uk

  const translatedDataUse = useTranslatedDatause({
    dataUse,
    dataUseCategory,
  })

  const content = useMemo(() => {
    switch (clientType) {
      case ClientType.UkRegulated:
        return <CondensedUKRegulated />
      case ClientType.UkUnregulated:
        return <CondensedUKUnregulated />
      case ClientType.UkAgent:
        return <CondensedUKAgent />
    }
  }, [clientType])

  return (
    <ConsentContextProvider
      value={{
        clientName,
        clientId,
        providerName,
        providerLogo,
        providerScope: PROVIDER_SCOPE,
        clientType,
        scope: SCOPE,
        publicScope: SCOPE,
        dataUse: translatedDataUse,
        dataUseCopy: '',
        setDataUseCopy: () => undefined,
        clientLogo,
        onAllowClick: () => undefined,
        onCancelClick: () => undefined,
        hiddenAuthUriFinished: true,
        regulatoryInformation,
        dataUseCategory,
        shouldDisplayNewSubtitleCopy: NEW_CONSENT_SUBTITLE_COPY_CLIENT_IDS.includes(clientId),
        preLoadedUrl: 'https://example.org',
      }}
    >
      {content}
    </ConsentContextProvider>
  )
}

export default ConsentOverview
