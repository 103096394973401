import styled from 'styled-components'
import { gap, ContainerStyle } from '@styles'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${gap(30, 'px', 'v')}
  ${ContainerStyle}
`

export const LogoContainer = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: 1px solid #efefef;
  border-radius: 50%;
  overflow: hidden;
`

export const Logo = styled.img`
  width: 29px;
`

export const Text = styled.div`
  font-size: 14px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${gap(20, 'px', 'v')}
  width: 100%;
`
