import { useSelector, useDispatch } from 'react-redux'
import IStoreState from '@store/IStoreState'
import { cancelClickedAction } from '@actions/general/generalActions'
import { EventType } from '@middlewares/analytics'
import { usePageContext } from '@hooks'
import { LoginType } from '@pages/Login/models'
import { redirectToExternalUrl } from '@utils/navigation'

const useIOSRedirect = () => {
  const provider = useSelector((state: IStoreState) => state.providers.selectedProvider)
  const clientName = useSelector((state: IStoreState) => state.client.clientSettings.client_name)
  const { locationState } = usePageContext()
  const { returnUrl, loginType } = locationState as { returnUrl: string; loginType: LoginType }
  const dispatch = useDispatch()

  const onConfirmClick = () => {
    redirectToExternalUrl(returnUrl)
  }

  const onCancelClick = () => {
    dispatch(cancelClickedAction(EventType.ConsentDenied))
  }

  return {
    redirectName: loginType === 'Mfa' ? clientName : provider.display_name,
    providerLogo: provider.icon_url,
    onConfirmClick,
    onCancelClick,
  }
}

export default useIOSRedirect
