import { FooterText } from '@components'
import { device } from '@constants/devices'
import { ContainerStyle, gap, hideScrollBar, upperGradientStyle } from '@styles'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  ${ContainerStyle}
  overflow-y: scroll;
  ${hideScrollBar}
  padding-top: 40px;
`

export const Title = styled.h1`
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  padding: 20px 0 14px 0;
`

export const ButtonsContainer = styled.div`
  ${ContainerStyle}
  display: flex;
  margin-top: auto;
  flex-direction: column;
  ${gap(10, 'px', 'v')}

  ${device.tablet} {
    flex-direction: row;
    ${gap(0, 'px', 'v')}
    ${gap(10, 'px', 'h')}
  }
`

export const BottomContainer = styled.div`
  position: relative;
  ${upperGradientStyle}
  padding-bottom: 32px;
`

export const LegalFooter = styled(FooterText)`
  display: block;
  margin-top: 12px;
  text-align: center;
  position: relative;
  padding-left: 36px;
  padding-right: 36px;
  ${device.tablet} {
    padding-left: 65px;
    padding-right: 65px;
  }
`