import styled from 'styled-components'
import { device } from '@constants/devices'

const Root = styled.div`
  font-size: 9px;
  color: #2d2d2d;
  text-align: center;
  line-height: 1.6;
  ${device.desktop} {
    font-size: 10px;
  }
`

export default Root
