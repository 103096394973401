import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { circleRegular, dotCircleRegular } from '@assets/icons'
import { useClientColors } from '@hooks'
import { IInputOption } from '@models/IAuthInput'
import {
  Root,
  HelpText,
  OptionRoot,
  OptionContainer,
  OptionValue,
  OptionSeparator,
  RadioButtonIcon,
} from './styles'

export interface ISingleChoiceInputValueProps {
  /**
   * The ID of the input used to return to the connector
   */
  id: string
  /**
   * List of options to choose from
   */
  options: IInputOption[]
  /**
   * Function to be called to save the input data
   */
  saveData: (key: string, val: string) => void
}

const SingleChoiceInputValue: FC<ISingleChoiceInputValueProps> = ({ id, options, saveData }) => {
  const { t } = useTranslation(['fieldNames', 'fieldHelpTexts'])
  const helpText = t(`fieldHelpTexts:${id}_field_help_text`)
  const { tertiaryColor } = useClientColors()
  const [selected, setSelected] = useState<string>()

  useEffect(() => {
    if (selected) {
      saveData(id, selected)
    }
  }, [selected, id, saveData])

  const renderOption = (option: IInputOption) => {
    const optionIsSelected = selected === option.key
    return (
      <OptionRoot id={option.key} onClick={() => setSelected(option.key)}>
        <OptionContainer selected={optionIsSelected} selectedColor={tertiaryColor}>
          <OptionValue>{option.value}</OptionValue>
          <RadioButtonIcon
            icon={optionIsSelected ? dotCircleRegular : circleRegular}
            selected={optionIsSelected}
            selectedColor={tertiaryColor || 'black'}
            size="2x"
          />
          <OptionSeparator />
        </OptionContainer>
      </OptionRoot>
    )
  }

  return (
    <Root id={id}>
      <HelpText>{helpText}</HelpText>
      {options.map((option) => renderOption(option))}
    </Root>
  )
}

export default SingleChoiceInputValue
