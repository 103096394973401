import styled, { css } from 'styled-components'
import { ContainerStyle, CONTAINER_SIDE_PADDING, CONTAINER_SIDE_PADDING_DESKTOP } from '@styles'
import { FaIcon } from '@assets/icons'
import { device } from '../../constants/devices'

const inputPaddingPx = 15
const placeholderLeftPadding = 41
const defaultColor = '#bbb'
const borderColor = '#ccc'

export const Root = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  ${ContainerStyle}
  position: relative;
`

const SearchInputActiveState = css<{ activeColor: string }>`
  outline: none;
  border-color: ${(props) => props.activeColor};
  border-width: 2px;
  margin-bottom: -1px;
  padding-top: ${inputPaddingPx - 1}px;
  padding-left: ${placeholderLeftPadding - 1}px;
`

export const SearchInput = styled.input<{ activeColor: string }>`
  -moz-appearance;
  -webkit-appearance: none;
  display: block;
  border: 1px solid ${borderColor};
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 1px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${defaultColor};
    font-size: 16px;
    font-family: montserrat;
  }
  &:focus,
  &:hover {
    ${SearchInputActiveState}
  }
  padding: ${inputPaddingPx}px;
  padding-left: ${placeholderLeftPadding}px;
  width: 100%;
`

export const Icon = styled(FaIcon)<{ activeColor: string }>`
  // Center it horizontally in the input
  position: absolute;
  top: 50%;
  margin-top: -8px;
  color: ${defaultColor};
  ${SearchInput}:focus ~ & {
    color: ${(props) => props.activeColor};
  }
`

export const ClearIcon = styled(Icon)`
  right: ${inputPaddingPx + CONTAINER_SIDE_PADDING}px;
  ${device.tablet} {
    right: ${inputPaddingPx + CONTAINER_SIDE_PADDING_DESKTOP}px;
  }
`

export const LeftIcon = styled(Icon)`
  left: ${inputPaddingPx + CONTAINER_SIDE_PADDING}px;
  ${device.tablet} {
    left: ${inputPaddingPx + CONTAINER_SIDE_PADDING_DESKTOP}px;
  }
`
