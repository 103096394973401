import styled from 'styled-components'
import { ContainerStyle } from '@styles'
import { Logo as HeaderLogo } from '@components/Header/styles'
import { device } from '@constants/devices'

export const ViewContainer = styled.div`
  ${ContainerStyle}

  ${device.tablet} {
    overflow: hidden;
  }
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
export const ClientLogo = styled(HeaderLogo)``
