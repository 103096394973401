import { useLayoutEffect, RefObject } from 'react'

export function useScrollingEnd(
  ref: RefObject<HTMLDivElement>,
  callback: (scrolling: boolean) => void,
): void {
  useLayoutEffect(() => {
    const element = ref.current

    let isScrolling: number | undefined

    const handleScroll = () => {
      callback(true)

      window.clearTimeout(isScrolling)

      isScrolling = window.setTimeout(function () {
        if (element) {
          const isScrolledToEnd =
            element.scrollHeight - (element.scrollTop + element.clientHeight) < 1
          if (isScrolledToEnd) {
            callback(true)
          } else {
            callback(false)
          }
        }
      }, 250)
    }

    element?.addEventListener('scroll', handleScroll)
    return () => {
      window.clearTimeout(isScrolling)
      element?.removeEventListener('scroll', handleScroll)
    }
  }, [ref, callback])
}
