import IProvider from '@models/IProvider'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedProvider } from '@actions/providers/providersActions'
import { sendProviderSelectedAnalytics } from '@actions/analytics'
import useCountryProviders from './useCountryProviders'
import usePopularProviders from './usePopularProviders'
import IStoreState from '@store/IStoreState'
import { AMAZON_DE_CLIENT_ID } from '@utils/constants'

export const getProviderItems = (state: IStoreState) => {
  if (
    state.client.id === AMAZON_DE_CLIENT_ID &&
    Date.now() >= new Date('2023-06-30T16:30:00+01:00').valueOf() &&
    Date.now() < new Date('2023-07-03T09:00:00+01:00').valueOf()
  ) {
    return state.providers.items.filter((i) => i.provider_id !== 'xs2a-postbank-de')
  }

  return state.providers.items
}

const useProviders = () => {
  const allProviders = useSelector(getProviderItems)
  const { providers } = useCountryProviders()

  const popularProviders = usePopularProviders()

  const dispatch = useDispatch()
  const onProviderClick = ({
    provider,
    filteredProviders,
    searchTerm,
  }: {
    provider: IProvider
    filteredProviders: IProvider[]
    searchTerm: string
  }) => {
    dispatch(setSelectedProvider(provider))
    dispatch(
      sendProviderSelectedAnalytics({
        provider,
        filteredProviders,
        searchTerm,
        countryProviders: providers,
        allProviders,
      }),
    )
  }

  return {
    allProviders,
    providers,
    popularProviders,
    onProviderClick,
  }
}

export default useProviders
