import React from 'react'
import { BackgroundPlaceholder } from '@components'
import { searchLight } from '@assets/icons'
import { useTranslation } from 'react-i18next'

const BranchesBackground = () => {
  const { t } = useTranslation('v2_branches')
  return <BackgroundPlaceholder icon={searchLight} message={t('background_message')} />
}

export default BranchesBackground
