import React from 'react'
import { DefaultPageLayout } from '@layouts'
import {
  MfaChallenge,
  Button,
  NotificationBanner,
  LinkingLogos,
  CheckmarkMiddleLogo,
  TLMiddleLogo,
  Footer,
} from '@components'
import useStateRestore from '@hooks/useStateRestore'
import { Page } from '@models/IPage'
import { NotificationContainer, ChallengeContainer, ButtonsContainer } from './styles'
import useMfa from './hooks'
import { useSelector } from 'react-redux'
import IStoreState from '@store/IStoreState'
import { isClientRegulated } from '@utils/client'

const Mfa = () => {
  const {
    pageTitle,
    challenges,
    saveAnswer,
    handleContinue,
    mfaCompleted,
    buttonLabel,
    notification,
  } = useMfa()

  useStateRestore(Page.MFA)

  const client = useSelector((state: IStoreState) => state.client)
  const provider = useSelector((state: IStoreState) => state.providers.selectedProvider)

  const middleIcon = isClientRegulated({
    clientAttributes: client.clientAttributes,
    activeCountry: provider.country,
  }) ? (
    <CheckmarkMiddleLogo />
  ) : (
    <TLMiddleLogo />
  )

  // For now, always assume error notification
  const renderErrorBanner = () =>
    notification && (
      <NotificationContainer>
        <NotificationBanner type="error" message={notification} />
      </NotificationContainer>
    )
  return (
    <DefaultPageLayout
      title={pageTitle}
      logo={
        <LinkingLogos
          leftLogo={client.clientSettings.logo_uri}
          middleLogo={middleIcon}
          rightLogo={provider.icon_url}
        />
      }
    >
      <div data-view="page-id-mfa"></div>
      {renderErrorBanner()}
      <ChallengeContainer>
        {challenges.map((challenge) => (
          <MfaChallenge key={challenge.id} challenge={challenge} saveAnswer={saveAnswer} />
        ))}
      </ChallengeContainer>
      <ButtonsContainer>
        <Button
          label={buttonLabel}
          variant="primary"
          onClick={handleContinue}
          color="black"
          disabled={!mfaCompleted}
          type="submit"
        />
      </ButtonsContainer>
      <Footer />
    </DefaultPageLayout>
  )
}

export default Mfa
