import React, { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { Ellipsis } from '@components'
import { useViewport } from '@hooks'
import { checkCircleSolid } from '@assets/icons'
import { EmptyPageLayout } from '@layouts'
import IStoreState from '@store/IStoreState'
import { Message, MessagesContainer, Root, StyledLoader, SubMessage, SuccessIcon } from './styles'

export enum LoaderStatus {
  Loading = 'loading',
  Done = 'done',
}

export interface ILoaderProps {
  /**
   * Status of the loader, either loading or done
   */
  status?: LoaderStatus.Loading | LoaderStatus.Done
}

const Loader = ({ status = LoaderStatus.Loading }: PropsWithChildren<ILoaderProps>) => {
  const { isMobile } = useViewport()
  const provider = useSelector((state: IStoreState) => state.providers.selectedProvider)
  const loaderSize = isMobile ? 40 : 58

  const renderLoader = () =>
    status === LoaderStatus.Loading && (
      <StyledLoader
        size={isMobile ? 'small' : 'medium'}
        thickness={isMobile ? 'small' : 'medium'}
        Spinner={{
          color: provider.main_bg_color,
        }}
      >
        <img src={provider.icon_url} alt="Provider Logo" />
      </StyledLoader>
    )

  const renderSuccessIcon = () =>
    status === LoaderStatus.Done && (
      <SuccessIcon
        src={checkCircleSolid}
        color={provider.main_bg_color}
        dimension={loaderSize}
        show={status === LoaderStatus.Done}
      />
    )

  const renderMessage = () => (
    <Message>
      <div>Please wait</div>
      {status !== LoaderStatus.Done && <Ellipsis size={3} gap={3} marginBottom={4} animated />}
    </Message>
  )

  const renderSubMessage = () => (
    <SubMessage hide={status !== LoaderStatus.Done}>Success!</SubMessage>
  )
  return (
    <EmptyPageLayout>
      <Root>
        {renderLoader()}
        {renderSuccessIcon()}
        <MessagesContainer>
          {renderMessage()}
          {renderSubMessage()}
        </MessagesContainer>
      </Root>
    </EmptyPageLayout>
  )
}

export default Loader
