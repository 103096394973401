import { IListStyleProps } from '@components/List/List'
import { useViewport } from '@hooks'

const useBranchListStyle = (): IListStyleProps => {
  const { isMobile } = useViewport()
  return {
    imgContainerSize: isMobile ? 30 : 42,
    imgSize: isMobile ? 22 : 34,
  }
}

export default useBranchListStyle
