import { useDispatch } from 'react-redux'
import { useCallback, useState } from 'react'
import { cancelClickedAction } from '@actions/general/generalActions'
import { EventType } from '@middlewares/analytics'

const useCloseApp = () => {
  const [showCloseOverlay, setShowCloseOverlay] = useState(false)
  const dispatch = useDispatch()

  const closeApp = useCallback(() => {
    setShowCloseOverlay(true)
  }, [])

  const cancelCloseApp = useCallback(() => {
    setShowCloseOverlay(false)
  }, [])

  const confirmCloseApp = useCallback(() => {
    dispatch(cancelClickedAction(EventType.ConsentDenied))
  }, [dispatch])

  return {
    showCloseOverlay,
    closeApp,
    cancelCloseApp,
    confirmCloseApp,
  }
}

export default useCloseApp
