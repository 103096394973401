import ActionTypes from '../actions/ActionTypes'
import { ICountryState } from '../store/IStoreState'
import ActionTypeKeys from '../actions/ActionTypeKeys'

const initialState: ICountryState = {
  countrySelected: undefined,
  countries: [],
}

export default function countriesReducer(
  state: ICountryState = initialState,
  action: ActionTypes,
): ICountryState {
  switch (action.type) {
    case ActionTypeKeys.SET_SELECTED_COUNTRY:
      return {
        ...state,
        countrySelected: action.payload,
      }
    case ActionTypeKeys.SAVE_COUNTRIES_LIST:
      return {
        ...state,
        countries: action.payload,
      }
    default:
      return state
  }
}
