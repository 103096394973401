import { Page } from '@models/IPage'
import { useMemo } from 'react'
import { getPath } from './utils'

const usePath = (page?: Page) => {
  const path = useMemo(() => {
    return getPath(page)
  }, [page])

  return path
}

export default usePath
