import { useContext } from 'react'
import { ConsentContext } from '../context'

function useConsent() {
  const consentContext = useContext(ConsentContext)

  if (consentContext === null) {
    throw new Error(
      '`ConsentContext` is equal to `null`. It seems like you forgot to wrap your component in the `ConsentContextProvider`.',
    )
  }

  return consentContext
}

export default useConsent
