import color from 'color'
import merge from 'lodash/merge'

import { PrismaTheme } from '../prisma'
import { ThemeCustom, ThemeBuilderOptions, ColorsOptions, ThemeType } from './types'

const getTextColour = (c: color): color =>
  c.isDark()
    ? color(PrismaTheme.colours.neutral.white)
    : color(PrismaTheme.colours.neutral.charcoal)

export const colored = (color: color) => ({
  color,
  text: getTextColour(color),
})

export const defaultPalette = {
  primary: colored(color(PrismaTheme.colours.neutral.white)),
  secondary: colored(color(PrismaTheme.colours.tints.azure[100])),
  tertiary: colored(color(PrismaTheme.colours.tints.azure[100])),
}

export function getPaletteWithDefault(customPalette?: ColorsOptions) {
  return {
    primary: colored(color(customPalette?.c_primary || PrismaTheme.colours.neutral.white)),
    secondary: colored(color(customPalette?.c_secondary || PrismaTheme.colours.tints.azure[100])),
    tertiary: colored(color(customPalette?.c_tertiary || PrismaTheme.colours.tints.azure[100])),
  }
}

export function themeBuilder(options?: ThemeBuilderOptions): ThemeType {
  // we do have the main highlight color so we create a base theme starting from that....
  const basicTheme = themeByPalette(options?.customPalette)
  // ... and we override the base theme with the appearance passed by
  const theme = merge(basicTheme, options?.customTheme)

  return { ...PrismaTheme, ...theme }
}

export function themeByPalette(customPalette?: ColorsOptions): ThemeCustom {
  const palette = getPaletteWithDefault(customPalette)

  // const lightCharcoal = PrismaTheme.colours.tints.charcoal[30] || '#C0C0C0'
  const ultraLightCharcol = PrismaTheme.colours.tints.charcoal[10] || '#EAEAEA'
  const defaultBorderStyle = {
    radius: '',
    width: '1px',
    style: 'solid',
  }

  return {
    backgroundColor: palette.primary.color.hex(),
    button: {
      default: {
        background: palette.secondary.color.hex(),
        color: palette.secondary.text.hex(),
        icon: {
          color: palette.secondary.color.hex(),
        },
        border: {
          color: palette.tertiary.color.hex(),
          ...defaultBorderStyle,
        },
      },
      hover: {
        background: palette.secondary.color.lightness(35).hex(),
        color: palette.secondary.text.hex(),
        icon: {
          color: palette.secondary.color.hex(),
        },
        border: {
          color: palette.tertiary.color.hex(),
          ...defaultBorderStyle,
        },
      },
      active: {
        background: palette.secondary.color.lightness(35).hex(),
        color: palette.secondary.text.hex(),
        icon: {
          color: palette.secondary.color.hex(),
        },
        border: {
          color: palette.tertiary.color.hex(),
          ...defaultBorderStyle,
        },
      },
      focus: {
        background: palette.secondary.color.lightness(35).hex(),
        color: palette.secondary.text.hex(),
        icon: {
          color: palette.secondary.color.hex(),
        },
        border: {
          color: palette.tertiary.color.hex(),
          ...defaultBorderStyle,
        },
      },
      // prisma miss the implementation for this, at the moment it just add opacity 0.3
      //disabled: {},
    },
    list_item: {
      default: {
        background: '',
        color: palette.tertiary.text.hex(),
        icon: {
          color: palette.tertiary.color.hex(),
        },
        border: {
          color: ultraLightCharcol,
          ...defaultBorderStyle,
        },
      },
      hover: {
        background: palette.tertiary.color.alpha(0.1).toString(),
        color: palette.tertiary.text.hex(),
        icon: {
          color: palette.tertiary.color.hex(),
        },
        border: {
          color: ultraLightCharcol,
          ...defaultBorderStyle,
        },
      },
      active: {
        background: palette.tertiary.color.alpha(0.1).toString(),
        color: palette.tertiary.text.hex(),
        icon: {
          color: palette.tertiary.color.hex(),
        },
        border: {
          color: ultraLightCharcol,
          ...defaultBorderStyle,
        },
      },
      focus: {
        background: palette.tertiary.color.alpha(0.1).toString(),
        color: palette.tertiary.text.hex(),
        icon: {
          color: palette.tertiary.color.hex(),
        },
        border: {
          color: ultraLightCharcol,
          ...defaultBorderStyle,
        },
      },
      selected: {
        background: palette.tertiary.color.alpha(0.1).toString(),
        color: palette.tertiary.color.hex(),
        icon: {
          color: palette.tertiary.color.hex(),
        },
        border: {
          color: ultraLightCharcol,
          ...defaultBorderStyle,
        },
      },
    },
    spinner: {
      color: palette.secondary.color.hex(),
    },
    illustration: {
      color: palette.tertiary.color.hex(),
    },
    input: {
      default: {
        placeholder: {
          color: PrismaTheme.colours.neutral.charcoal,
        },
        label: {
          color: PrismaTheme.colours.neutral.charcoal,
        },
        border: {
          color: PrismaTheme.colours.neutral.charcoal,
        },
        color: PrismaTheme.colours.neutral.charcoal,
        background: PrismaTheme.colours.neutral.charcoal,
      },
      //invalid: {},
      //disabled: {},
      focus: {
        placeholder: {
          color: PrismaTheme.colours.neutral.charcoal,
        },
        label: {
          color: PrismaTheme.colours.neutral.charcoal,
        },
        border: {
          color: palette.tertiary.color.hex(),
        },
        color: PrismaTheme.colours.neutral.charcoal,
        background: PrismaTheme.colours.neutral.charcoal,
      },
      hover: {
        placeholder: {
          color: PrismaTheme.colours.neutral.charcoal,
        },
        label: {
          color: PrismaTheme.colours.neutral.charcoal,
        },
        border: {
          color: palette.tertiary.color.hex(),
        },
        color: palette.tertiary.text.hex(),
        background: palette.tertiary.color.hex(),
      },
    },
  }
}
