export interface IAuthInputMethod {
  readonly id: string
  readonly display_name: string
  readonly fields: IAuthInput[]
  readonly buttons?: IButton[]
  readonly notifications?: INotification[]
}

export type IMfaData = IAuthInputMethod

export type IInputValueCase =
  | 'ClearTextInputValue'
  | 'MultiClearTextInputValue'
  | 'SingleChoiceInputValue'
  | 'SingleChoiceImageInputValue'

export interface IAuthInput {
  readonly id: string
  readonly display_name: string
  readonly help_text: string
  readonly mandatory: boolean
  readonly input_value_case: IInputValueCase
  readonly images?: IImage[]
  readonly clear_text_input_value?: IClearTextInputValue
  readonly multi_clear_text_input_value?: IMultiClearTextInputValue
  readonly single_choice_input_value?: ISingleChoiceInputValue
  readonly single_choice_image_input_value?: ISingleChoiceImageInputValue
}

export type AllowedCharacters = 'Numeric' | 'Alphanumeric'

export interface IValidationRule {
  readonly regex: string
  readonly error_message: string
  readonly translation_key: string
}

export interface IClearTextInputValue {
  readonly is_sensitive: boolean
  readonly allowed_characters: AllowedCharacters
  readonly validation_rules: IValidationRule[]
  readonly default_value?: string
  readonly placeholder_value?: string
}

export interface IMultiClearTextInputValue {
  readonly is_sensitive: boolean
  readonly allowed_characters: AllowedCharacters
  readonly validation_rules: IValidationRule[]
}

export interface ISingleChoiceInputValue {
  readonly options: IInputOption[]
  readonly type: 'Branch' | 'Standard' | 'Invalid'
}

export interface IInputOption {
  readonly key: string
  readonly value: string
}

export interface ISingleChoiceImageInputValue {
  readonly options: IImageInputOption[]
}

export interface IImageInputOption {
  readonly key: string
  readonly value: IImage
}

export interface IImage {
  readonly base_64?: string
  readonly url?: string
  readonly help_text?: string
  readonly content_case: 'Url' | 'Base64'
}

enum ButtonType {
  INVALID,
  INFO,
  WARNING,
  NORMAL,
}

export interface IButton {
  readonly id: string
  readonly type: ButtonType
  readonly label_text: string
}

export enum NotificationType {
  INVALID,
  INFO,
  WARNING,
  ERROR,
}

export interface INotification {
  readonly id: string
  readonly type: NotificationType
  readonly message: string
}
