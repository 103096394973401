/* eslint-disable @typescript-eslint/no-explicit-any */
import { DataUseCategory, IClientAttributes } from '@store/IStoreState'
import snakeCase from 'lodash.snakecase'
import * as Sentry from '../utils/sentry'

export interface Customisation {
  client_name: string
  data_use: string
  data_use_category: DataUseCategory
  primary_colour: string
  secondary_colour: string
  tertiary_colour: string
  logo_uri: any | string // can be a blob of an aws link
  cancellation_uri: string
  is_agent: boolean
  client_attributes: {
    regulatoryInformation: { [key in 'eu' | 'uk']: RegulatoryInformation }
  }
}

export type RegulatoryInformation = {
  aisRegulated: boolean
  registrationNumber?: string
  privacyPolicyUrl?: string
  termsOfServiceUrl?: string
  registrationPageUrl?: string
}

const isDataUseCategory = (
  dataUseCategoryString: string,
): dataUseCategoryString is DataUseCategory =>
  Object.values<string>(DataUseCategory).includes(dataUseCategoryString)

export function getCustomisationFromMessageEvent(event: any): Customisation | undefined {
  if (event.data) {
    // The `DataUseCategory` enum in the console app (which emits the events)
    // uses camel case so we need to convert it to snake case (which is what we use)
    const dataUseCategory = snakeCase(event.data.dataUseCategory)

    // TODO: implement a more scalable solution for validating all the event attributes
    // using a library like `io-ts` or `zod`
    if (!isDataUseCategory(dataUseCategory)) {
      Sentry.exception(
        `An unknown \`dataUseCategory\` with the value of \`${dataUseCategory}\` was obtained.`,
      )
      return undefined
    }

    return {
      client_name: event.data.clientName || '',
      data_use: event.data.dataUse || '',
      data_use_category: dataUseCategory,
      primary_colour: event.data.primaryColour || '',
      secondary_colour: event.data.secondaryColour || '',
      tertiary_colour: event.data.tertiaryColour || '',
      logo_uri: event.data.logo || '',
      cancellation_uri: event.data.cancellationUri || '',
      is_agent: event.data.isAgent || '',
      client_attributes: event.data.clientAttributes,
    }
  }
  return undefined
}

export const convertClientAttributesFromCustomisation = (
  response: Customisation,
): IClientAttributes => ({
  regulatory_information: {
    uk: {
      ais_regulated: response.client_attributes.regulatoryInformation.uk.aisRegulated,
      registration_number: response.client_attributes.regulatoryInformation.uk.registrationNumber,
      privacy_policy_url: response.client_attributes.regulatoryInformation.uk.privacyPolicyUrl,
      terms_of_service_url: response.client_attributes.regulatoryInformation.uk.termsOfServiceUrl,
      registration_page_url:
        response.client_attributes.regulatoryInformation.uk.registrationPageUrl,
    },
    eu: {
      ais_regulated: response.client_attributes.regulatoryInformation.eu.aisRegulated,
      registration_number: response.client_attributes.regulatoryInformation.eu.registrationNumber,
      privacy_policy_url: response.client_attributes.regulatoryInformation.eu.privacyPolicyUrl,
      terms_of_service_url: response.client_attributes.regulatoryInformation.eu.termsOfServiceUrl,
      registration_page_url:
        response.client_attributes.regulatoryInformation.eu.registrationPageUrl,
    },
  },
})
