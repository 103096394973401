import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { SelectableListWithSmallOption } from './form/SelectableList/SelectableList'
import { OptionItem, SelectProps } from './form/types'
import { Country } from './types'

type CountrySelectProps = Omit<SelectProps<string>, 'options'> & {
  countries: Country[]
  leftIcon?: (option: OptionItem<string>) => JSX.Element | null
}

export function CountrySelect({ countries, ...props }: CountrySelectProps) {
  const { t } = useTranslation('end_user_components')

  const options = useMemo(
    () =>
      countries.map((country) => ({
        value: country.code,
        label: country.name,
        icon: country.icon_uri,
      })),
    [countries],
  )

  return (
    <PickerWrapper>
      <SelectableListWithSmallOption
        placeholder={t('country.search.placeholder')}
        options={options}
        {...props}
      />
    </PickerWrapper>
  )
}

export const PickerWrapper = styled.div`
  width: 100%;
`
