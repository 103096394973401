import React from 'react'
import { useNestedTranslation } from '@hooks'
import useConsent from '../../hooks'

function ConsentDataAccessNote() {
  const t = useNestedTranslation('v2_consent', ['what_data_am_i_sharing'])
  const { clientName, scope } = useConsent()

  const hasOngoingAccess = scope.includes('offline_access')

  return (
    <div data-view="data-access">
      {t(hasOngoingAccess ? 'info.access_ongoing' : 'info.access_one_time', {
        clientName,
      })}
      :
    </div>
  )
}

export default ConsentDataAccessNote
