import styled from 'styled-components'
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome'

export const Root = styled.div`
  display: grid;
  grid-gap: 1em;
`

export const HelpText = styled.div`
  font-size: 12px;
  text-align: center;
`

export const OptionRoot = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const OptionContainer = styled.div<{
  selected: boolean
  selectedColor: string
}>`
  color: #2d2d2d;
  padding: 10px 20px;
  margin: 5px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  min-height: 43px;
`

export const OptionSeparator = styled.hr`
  display: flex;
  width: 100%;
  flex-basis: 100%;
  margin-bottom: 0;
`

export const RadioButtonIcon = styled(FaIcon)<{
  selected: boolean
  selectedColor: string
}>`
  color: ${(props) => (props.selected ? props.selectedColor : '#ccc')};
`

export const OptionValue = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`
