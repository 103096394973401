import React, { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import IStoreState from '@store/IStoreState'
import { ProviderLogo } from '@components'
import { isColourDark } from '@utils/helpers'
import { Page, Container, Header, Body } from './styles'

export interface IProviderPageLayoutProps {
  bgColor?: string
  headerColor?: string
}

const ProviderPageLayout = ({
  children,
  bgColor = '#f4f7f9',
  headerColor = '#6683ac',
}: PropsWithChildren<IProviderPageLayoutProps>) => {
  const provider = useSelector((state: IStoreState) => state.providers.selectedProvider)
  return (
    <Page bgColor={bgColor}>
      <Container>
        <Header bgColor={headerColor}>
          <ProviderLogo provider={provider} useWhiteVersion={isColourDark(headerColor)} />
        </Header>
        <Body>{children}</Body>
      </Container>
    </Page>
  )
}

export default ProviderPageLayout
