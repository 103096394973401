import React, { forwardRef } from 'react'
import { timesCircleSolid, searchSolid } from '@assets/icons'
import { useClientColors, useViewport } from '@hooks'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Root, SearchInput, ClearIcon, LeftIcon } from './styles'

export interface ISearchProps {
  placeholder: string
  logo?: IconProp
  value: string
  isFocused: boolean
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  resetSearch: () => void
  onSearchFocus: () => void
  onSearchBlur: () => void
  'data-view'?: string
}

/**
 * This component provides a search bar UI + entries to call methods in the various input states
 */
const Search = forwardRef<HTMLInputElement, ISearchProps>((props, searchRef) => {
  const {
    placeholder,
    logo,
    value,
    isFocused,
    onSearchChange,
    resetSearch,
    onSearchFocus,
    onSearchBlur,
    'data-view': dataView,
  } = props
  const handleClear = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent> | React.TouchEvent<SVGSVGElement>,
  ) => {
    // We prevent the onBlur event to happen when clicking on the clear icon
    // This is carefully called after a TouchStart or MouseDown event to make it possible
    e.preventDefault()
    resetSearch()
  }
  const { isMobile } = useViewport()
  const { tertiaryColor } = useClientColors()
  return (
    <Root>
      <SearchInput
        ref={searchRef}
        placeholder={placeholder}
        onChange={onSearchChange}
        value={value}
        onFocus={onSearchFocus}
        onBlur={onSearchBlur}
        activeColor={tertiaryColor}
        data-view={dataView}
      />
      <LeftIcon icon={logo || searchSolid} activeColor={tertiaryColor} />
      {isFocused &&
        (isMobile ? (
          <ClearIcon
            icon={timesCircleSolid}
            onTouchStart={handleClear}
            activeColor={tertiaryColor}
          />
        ) : (
          <ClearIcon
            icon={timesCircleSolid}
            onMouseDown={handleClear}
            activeColor={tertiaryColor}
          />
        ))}
    </Root>
  )
})

export default Search
