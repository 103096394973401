import React from 'react'
import { EmptyPageLayout } from '@layouts'
import { LoginView } from '@components'
import useLogin from './hooks'

const Login = () => {
  const { provider, isSuccessfulLogin, type, isLongWait, clientName, status } = useLogin()

  return (
    <EmptyPageLayout>
      <div data-view="page-id-login"></div>
      <LoginView
        provider={provider}
        success={isSuccessfulLogin}
        type={type}
        isLongWait={isLongWait}
        clientName={clientName}
        status={status}
      />
    </EmptyPageLayout>
  )
}

export default Login
