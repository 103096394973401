import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, TabList, TabPanels } from '@components'
import { useClientColors } from '@hooks'
import { StyledTabPanel, StyledTabs } from './styles'

function ConsentNotesTabsUKEU({
  howDoesThisWork,
  whatDataAmISharing,
  howIsMyDataUsed,
}: {
  howDoesThisWork: JSX.Element
  howIsMyDataUsed: JSX.Element
  whatDataAmISharing: JSX.Element
}) {
  const { t } = useTranslation('v2_consent')
  const { secondaryColor } = useClientColors()

  return (
    <StyledTabs themeColor={secondaryColor}>
      <TabList>
        <Tab>{t('what_data_am_i_sharing.title')}</Tab>
        <Tab>{t('how_does_this_work.title')}</Tab>
        <Tab>{t('how_is_my_data_used.title')}</Tab>
      </TabList>
      <TabPanels>
        <StyledTabPanel>{whatDataAmISharing}</StyledTabPanel>
        <StyledTabPanel>{howDoesThisWork}</StyledTabPanel>
        <StyledTabPanel>{howIsMyDataUsed}</StyledTabPanel>
      </TabPanels>
    </StyledTabs>
  )
}

export default ConsentNotesTabsUKEU
