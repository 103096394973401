import { useSelector, useDispatch } from 'react-redux'
import IStoreState from '@store/IStoreState'
import { useEffect, useState } from 'react'
import { AuthDialogSpaError, ErrorCode } from '@utils/errors'
import { getClient, getSession, setConsentId } from '@actions/client/clientActions'
import * as Sentry from '@utils/sentry'
import { handleCountryAndProviders, setIsLoading } from '@actions/general/generalActions'
import { getSessionId } from '@utils/helpers'
import { getCountries } from '@actions/countries/countriesActions'
import { sendFailedToInitialize, sendInitializedAnalytics } from '@actions/analytics'
import { getProviders } from '@actions/providers/providersActions'
import { showAuthorisationBanner } from '@utils/authorisation'
import { errorMapper, ErrorUI } from '@components/ErrorBoundary/errors'
import { useTranslation } from 'react-i18next'
import { PagePath } from '@models/IPage'
import { useAbTests, Status as AbTestsStatus } from '@contexts/ab-tests'
import { getAbTestsPayload } from '@utils/ab-tests'

const useApp = () => {
  const [error, setError] = useState<ErrorUI | null>(null)
  const isDataLoading = useSelector((state: IStoreState) => state.general.isLoading)
  const {
    id: clientId,
    consentId,
    clientSettings,
    publicScope,
  } = useSelector((state: IStoreState) => state.client)
  const providerId = useSelector(
    (state: IStoreState) => state.providers.selectedProvider.provider_id,
  )
  const { client_name, data_api_plan, verification_api_plan } = clientSettings
  const { i18n } = useTranslation()
  const { status: abTestsStatus } = useAbTests()
  const abTestsPayload = useSelector(getAbTestsPayload)

  const dispatch = useDispatch()

  const { sessionId } = getSessionId()

  useEffect(() => {
    const initialiseApp = async () => {
      await dispatch(setIsLoading(true))
      const { sessionId } = getSessionId()
      if (
        !([PagePath.OVERVIEW, PagePath.OVERVIEW_V2, PagePath.CLIENT_ERROR] as string[]).includes(
          window.location.pathname,
        )
      ) {
        if (!sessionId) {
          throw AuthDialogSpaError.Create(ErrorCode.NoSessionId, 'No session id')
        }

        await Promise.all([
          dispatch(getProviders()),
          dispatch(getClient()),
          dispatch(getSession()),
          dispatch(getCountries()),
        ])

        dispatch(handleCountryAndProviders())

        dispatch(sendInitializedAnalytics())
      }
    }

    const initialise = async () => {
      try {
        await initialiseApp()

        setError(null)
      } catch (error) {
        if (
          error instanceof AuthDialogSpaError &&
          (error.code === ErrorCode.InternalServerError ||
            error.code === ErrorCode.UnauthorizedSession)
        ) {
          dispatch(sendFailedToInitialize(error.code, error.message))
        }

        const shouldReportError =
          // don't log Network Errors (e. g. when the network goes down)
          !(error instanceof Error && error.message === 'Network Error') &&
          // don't log AuthDialogSpaError (such as expired sessions etc.)
          !(error instanceof AuthDialogSpaError)

        if (shouldReportError) {
          Sentry.exception(error as Error)
        }

        const errorCode =
          error instanceof AuthDialogSpaError ? error.code : ErrorCode.UnexpectedError

        setError(errorMapper[errorCode])
      } finally {
        await dispatch(setIsLoading(false))
      }
    }

    initialise()
  }, [dispatch])

  useEffect(() => {
    const setupExternalClients = async () => {
      Sentry.setClient(clientId)
    }

    if (clientId && sessionId) {
      setupExternalClients()
    }
  }, [clientId, sessionId])

  useEffect(() => {
    Sentry.setProviderId(providerId)
  }, [providerId])

  useEffect(() => {
    Sentry.setAppLanguage(i18n.language)
  }, [i18n.language])

  useEffect(() => {
    Sentry.setAbTestFlags(abTestsPayload)
  }, [abTestsPayload])

  useEffect(() => {
    if (consentId) dispatch(setConsentId(consentId))
  }, [consentId, dispatch])

  useEffect(() => {
    const title = `${client_name || 'TrueLayer'} - Connect your bank account`
    if (client_name) document.title = title
  }, [client_name])

  return {
    isLoading: isDataLoading && abTestsStatus !== AbTestsStatus.Loading,
    showAuthorisationBanner: showAuthorisationBanner({
      verificationApiPlan: verification_api_plan,
      dataApiPlan: data_api_plan,
      scopes: publicScope,
    }),
    error,
  }
}

export default useApp
