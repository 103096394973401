export enum AbTestKey {
  AuthInputsAfterConsentScreen = 'auth_inputs_after_consent_screen',
}

export const AB_TESTS = {
  [AbTestKey.AuthInputsAfterConsentScreen]: {
    campaignId: 'auth-inputs-after-consent-screen',
    goalId: 'auth-input-after-consent-page-conversion-germany',
  },
  // TODO: perfect use case for TS `satisfies` once we update Babel
  // to a version where it's supported
  // (define an interface for the config object and
  // append `satisfies Record<string, IAbCampaign`)
} as const
