import styled from 'styled-components'
import { gap, ContainerStyle } from '@styles'

export const Root = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  ${gap(30, 'px', 'v')}
  font-weight: 300;
  margin-top: 40px;
  text-align: center;
  ${ContainerStyle}
`
