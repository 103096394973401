import { saveClientAttributes, saveClientConfiguration } from '@actions/client/clientActions'
import { exhaustiveCheck, getConsoleBaseUrl } from '@utils/helpers'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import OverviewContent from './components/OverviewContent/OverviewContent'
import { AuthDialogMessageType, isAuthDialogMessageEvent, OverviewPage } from './types'
import snakeCase from 'lodash.snakecase'
import { DataUseCategory } from '../../store/IStoreState'
import { useScale } from '../../contexts/scale'

function OverviewV2() {
  const { setScale } = useScale()
  const [page, setPage] = useState<OverviewPage>(OverviewPage.Consent)
  const dispatch = useDispatch()

  const messageReceived = useCallback(
    (e: MessageEvent) => {
      if (isAuthDialogMessageEvent(e)) {
        const { type, payload } = e.data

        switch (type) {
          case AuthDialogMessageType.Scale:
            setScale(payload)
            break
          case AuthDialogMessageType.Page:
            setPage(payload.page)
            break
          case AuthDialogMessageType.Customization:
            dispatch(
              saveClientConfiguration({
                client_name: payload.clientName,
                data_use: payload.dataUse ?? '',
                // The `DataUseCategory` enum in the console app (which emits the events)
                // uses camel case so we need to convert it to snake case (which is what we use)
                data_use_category: snakeCase(payload.dataUseCategory) as DataUseCategory,
                logo_uri:
                  payload.logo instanceof Blob ? URL.createObjectURL(payload.logo) : payload.logo,
                primary_colour: payload.primaryColour,
                secondary_colour: payload.secondaryColour,
                tertiary_colour: payload.tertiaryColour,
              }),
            )
            if (payload.clientAttributes) {
              const {
                clientAttributes: {
                  regulatoryInformation: { eu, uk },
                },
              } = payload

              dispatch(
                saveClientAttributes({
                  regulatory_information: {
                    eu: {
                      ais_regulated: eu.aisRegulated,
                      privacy_policy_url: eu.privacyPolicyUrl,
                      terms_of_service_url: eu.termsOfServiceUrl,
                      registration_number: eu.registrationNumber,
                      registration_page_url: eu.registrationPageUrl,
                    },
                    uk: {
                      ais_regulated: uk.aisRegulated,
                      privacy_policy_url: uk.privacyPolicyUrl,
                      terms_of_service_url: uk.termsOfServiceUrl,
                      registration_number: uk.registrationNumber,
                      registration_page_url: uk.registrationPageUrl,
                    },
                  },
                }),
              )
            }
            break
          default:
            exhaustiveCheck(type)
        }
      }
    },
    [dispatch, setScale],
  )

  useEffect(() => {
    window.addEventListener('message', messageReceived)
    window.parent.postMessage(
      {
        type: '[TRUELAYER]',
        message: 'AUTH_DIALOG_MOUNT',
        pages: [
          { key: 'CONSENT', label: 'Consent' },
          { key: 'PROVIDER_SELECTION', label: 'Provider selection' },
        ],
      },
      getConsoleBaseUrl(),
    )

    return () => {
      window.removeEventListener('message', messageReceived)
    }
  }, [messageReceived])

  return <OverviewContent page={page} />
}

export default OverviewV2
