import Fuse from 'fuse.js'
import React, { useRef, useState } from 'react'

import { useResponsive } from '../../../hooks/useResponsive'
import { useScrollingEnd } from '../../../hooks/useScrollingEnd'
import { OptionEntity, OptionItem } from '../types'
import { JumpToInput } from './JumpToInput'
import { NoResults } from './NoResults'
import { OptionListComponent } from './OptionListComponent'
import { ScrollDown } from './ScrollDown'
import { SearchInput } from './SearchInput'
import { OPTION_HEIGHT, OptionList } from './styles'
import { scrollTo } from './utils'
import useFilteredOptions from '../../../../end-user-components/hooks/useFilteredOptions'
import NoResultCallToAction from './NoResultCallToAction'
import styled from 'styled-components/macro'

export type SelectableListBaseProps<T> = SelectableListRequiredProps<T> &
  SelectableListOptionalProps<T>

/**
 * the extendedSearch props is available in to order to pass from outside a new searching strategy
 * see story: SelectableListBaseWithExtraSearch
 * */
export type ExtendedSearch<T> = {
  match: RegExp
  action: (
    searchQuery: string,
    options: OptionEntity<T>[],
    fuse: Fuse<OptionEntity<T>>,
  ) => OptionItem<T>[]
}

export type SelectableListRequiredProps<T> = {
  value?: T
  name: string
  onChange: (value: T) => void
  options: OptionItem<T>[]
  OptionComponent: (props: OptionComponentProps<T>) => JSX.Element
}

export type NoResultCallToActionOptions = {
  copy: string
  color: string
  action: () => void
}

type SelectableListOptionalProps<T> = Partial<{
  placeholder: string
  showJumpToInput: boolean
  extendedSearch: ExtendedSearch<T>[]
  fuzzySearchOptions: Omit<Fuse.IFuseOptions<OptionItem<T>>, 'keys'>
  searchAdjacentComponent: React.ReactNode
  onSearch: (query: string) => void
  optionTitle: React.ReactNode
  onScroll: (point: number) => void
  shouldShowScrollDownButton: boolean
  noResultCallToActionOptions: NoResultCallToActionOptions
  showNoResultCallToActionBelowList: boolean
}>

export type OptionComponentProps<T> = {
  onChange: (value: T) => void
  value?: T
  option: OptionEntity<T>
}

export function SelectableListBase<T>({
  value,
  name,
  onChange,
  options,
  placeholder,
  OptionComponent,
  fuzzySearchOptions,
  extendedSearch,
  searchAdjacentComponent,
  onSearch,
  onScroll,
  optionTitle,
  showJumpToInput = false,
  shouldShowScrollDownButton = true,
  noResultCallToActionOptions,
  showNoResultCallToActionBelowList,
}: SelectableListBaseProps<T>) {
  const size = useResponsive()
  const isScreenGreaterThanSmall = !['xs', 'sm'].includes(size)

  const [showScrollDownButton, setViewScrollDownButton] = useState<boolean>(true)
  const [search, setSearch] = useState<string>('')
  const refOptionsList = useRef<HTMLDivElement>(null)
  const refSearchInput = useRef<HTMLInputElement>(null)

  const { filteredOptions, flatOptions } = useFilteredOptions(
    search,
    options,
    fuzzySearchOptions,
    extendedSearch,
  )

  const jumpToInputAvailable = showJumpToInput && !search

  useScrollingEnd(refOptionsList, (bool) => {
    if (onScroll && refOptionsList.current) {
      const element = refOptionsList.current
      const point = element.scrollTop / OPTION_HEIGHT
      onScroll(point)
    }

    setViewScrollDownButton(!bool)
  })

  const handleInputFocus = () => {
    if (refSearchInput.current) {
      scrollTo(refSearchInput.current.offsetTop - 12)
    }
  }

  const handleCTA = () => {
    setSearch('') //clear the input
    noResultCallToActionOptions?.action()
  }

  return (
    <>
      <SearchInput
        name={name}
        placeholder={placeholder}
        onSearch={onSearch}
        searchAdjacentComponent={searchAdjacentComponent}
        search={search}
        setSearch={setSearch}
        handleInputFocus={handleInputFocus}
        refSearchInput={refSearchInput}
      />
      {filteredOptions.length ? (
        <>
          {optionTitle}
          <OptionList data-testid="selectable-list-container" ref={refOptionsList}>
            <OptionListComponent
              options={filteredOptions}
              onChange={onChange}
              value={value}
              OptionComponent={OptionComponent}
              name={`select-${name}`}
            />

            {jumpToInputAvailable && <JumpToInput refSearchInput={refSearchInput} />}
            {noResultCallToActionOptions && showNoResultCallToActionBelowList && (
              <NoResultCallToAction
                actionMessage={noResultCallToActionOptions?.copy}
                actionColor={noResultCallToActionOptions?.color}
                onAction={handleCTA}
              />
            )}
          </OptionList>

          {shouldShowScrollDownButton &&
            (search ? filteredOptions : flatOptions).length > 4 &&
            isScreenGreaterThanSmall && <ScrollDown show={showScrollDownButton} />}
        </>
      ) : (
        <NoResultContainer>
          <NoResults search={search!} />
          {noResultCallToActionOptions && (
            <NoResultCallToAction
              actionMessage={noResultCallToActionOptions?.copy}
              actionColor={noResultCallToActionOptions?.color}
              onAction={handleCTA}
            />
          )}
        </NoResultContainer>
      )}
    </>
  )
}

const NoResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`
