/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { trackAuthStart } from '@middlewares/analytics'
import { authUriRequest } from '@middlewares/api'
import { IAuthInputMethod } from '@models/IAuthInput'
import IStoreState from '@store/IStoreState'
import { getAuthStartPayload } from '@utils/analytics'
import { Error, Form, Loader } from './views'
import { LoaderStatus } from './views/Loader'
import { InteractiveStatus } from './types'
import { LoginStatus } from '@models/loginStatus'
import { LoginView } from '@components'
import { isEmpty } from '@utils/helpers'
import { redirectToExternalUrl } from '@utils/navigation'

const isFormValid = (step: IAuthInputMethod, data: object) => {
  const inputIds = step.fields.map((f) => f.id)
  const values = Object.values(data)
  return inputIds.every((id) => id in data) && !values.includes(null)
}

const Interactive = () => {
  // Interactive flow logic
  const [status, setStatus] = useState<InteractiveStatus>(InteractiveStatus.Waiting)
  const [redirectUrl, setRedirectUrl] = useState<string>('')
  const [authInputs, setAuthInputs] = useState<IAuthInputMethod[]>([])
  const { state, provider, clientName } = useSelector((state: IStoreState) => ({
    state,
    provider: isEmpty(state.providers.selectedProvider)
      ? state.providers.items.find((provider) => provider.provider_id === state.client.providerId)
      : state.providers.selectedProvider,
    clientName: state.client.clientSettings.client_name,
  }))

  // Form logic
  const steps: IAuthInputMethod[] = authInputs
  const [index, setIndex] = useState<number>(0)
  const [isLastStep, setIsLastStep] = useState<boolean>()
  const [step, setStep] = useState<IAuthInputMethod>()
  const [data, setData] = useState<object>({})
  const [canSubmit, setCanSubmit] = useState(false)

  const to = useRef<NodeJS.Timeout>()

  const cleanUpTimeout = () => {
    if (to.current) {
      clearTimeout(to.current)
    }
  }

  const makeAuthUriRequest = useCallback(async () => {
    setStatus(InteractiveStatus.Waiting)
    try {
      const payload = getAuthStartPayload(state)
      await trackAuthStart(payload)
      const response = await authUriRequest(payload, provider!, data)

      // Success field is true
      if (response.data.success) {
        setRedirectUrl(response.data.result.authorization_uri)
        setStatus(InteractiveStatus.Success)
        cleanUpTimeout()

        // Success field is false
      } else {
        const { error } = response.data
        if (error?.code) {
          // Check error code
          switch (error.code) {
            // Wait for subsequent call response
            case 'wait':
              to.current = setTimeout(makeAuthUriRequest, 2000)
              break
            // Need to complete interactive flow
            case 'need-interactive':
              if (error.details.steps.length > 0) {
                setAuthInputs(error.details.steps)
                setStatus(InteractiveStatus.Interactive)
                cleanUpTimeout()
              }
              break
            // Need to complete interactive flow
            case 'failed':
              setRedirectUrl(response.data.result.authorization_uri)
              setStatus(InteractiveStatus.FailedErrorCode)
              cleanUpTimeout()
              break
            // Default to fail
            default:
              setStatus(InteractiveStatus.Fail)
              cleanUpTimeout()
          }
        }
      }
    } catch (error) {
      setStatus(InteractiveStatus.Fail)
      cleanUpTimeout()
    }
  }, [])

  const getRedirectTimeout = (status: InteractiveStatus) => {
    return status === InteractiveStatus.FailedErrorCode ? 5000 : 1000
  }

  useEffect(() => {
    let redirectTimeout: NodeJS.Timeout

    if (status === InteractiveStatus.Success || status === InteractiveStatus.FailedErrorCode) {
      redirectTimeout = setTimeout(() => {
        redirectToExternalUrl(redirectUrl)
      }, getRedirectTimeout(status))
    }
    return () => clearTimeout(redirectTimeout)
  }, [status, redirectUrl])

  useEffect(() => {
    makeAuthUriRequest()
    return () => clearTimeout(to.current!)
  }, [makeAuthUriRequest])

  // Update step
  useEffect(() => {
    if (steps && steps.length) {
      const newStep = steps[index]
      setStep(newStep)
      if (index === steps.length - 1) {
        setIsLastStep(true)
      }
    }
  }, [steps, index])

  // Check form readiness
  useEffect(() => {
    if (step) {
      setCanSubmit(isFormValid(step, data))
    }
  }, [data, step])

  const saveData = (key: string, value: string) => {
    const newData = { [key]: value }
    setData((data) => ({ ...data, ...newData }))
  }

  const submitForm = () => {
    if (isLastStep) {
      makeAuthUriRequest()
    } else {
      setIndex(index + 1)
    }
  }

  let content

  switch (status) {
    case InteractiveStatus.Interactive:
      content = (
        <Form
          step={step!}
          isLastStep={isLastStep!}
          canSubmit={canSubmit}
          saveData={saveData}
          submitForm={submitForm}
        />
      )
      break

    case InteractiveStatus.Fail:
      content = <Error tryAgain={makeAuthUriRequest} />
      break
    case InteractiveStatus.Waiting:
    case InteractiveStatus.Success:
      content = (
        <Loader
          status={status === InteractiveStatus.Success ? LoaderStatus.Done : LoaderStatus.Loading}
        />
      )
      break
    case InteractiveStatus.FailedErrorCode:
      content = (
        <LoginView
          provider={provider!}
          type={'Interactive'}
          success={false}
          isLongWait={false}
          clientName={clientName}
          status={LoginStatus.FailedErrorCode}
        />
      )
      break
  }

  return (
    <>
      <div data-view="page-id-interactive"></div>
      {content}
    </>
  )
}

export default Interactive
