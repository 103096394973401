import { useSelector } from 'react-redux'
import IStoreState from '@store/IStoreState'

const useClientLogo = () => {
  const { client } = useSelector((state: IStoreState) => state)

  return client.clientSettings.logo_uri
}

export default useClientLogo
