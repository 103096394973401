import styled from 'styled-components'
import { device } from '@constants/devices'
import { gap } from '@styles'

export const Root = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  overflow-y: auto;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 60px 0;
  background-color: white;
  ${device.tablet} {
    width: 720px;
    height: 320px;
    min-height: 280px;
    border-radius: 5px;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${gap(22, 'px', 'v')}
  font-size: 16px;
`
