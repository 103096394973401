import { PagePath } from '@models/IPage'
import { VerificationApiPlan, DataApiPlan } from '@store/IStoreState'

export interface ShowBannerOptions {
  verificationApiPlan: VerificationApiPlan
  dataApiPlan: DataApiPlan
  scopes: string[]
}

enum ScopeDetails {
  DataOnly,
  VerificationOnly,
  Mixed
}

const getAuthorisationDetails = (options: ShowBannerOptions) => {
  const { verificationApiPlan, dataApiPlan } = options
  
  const isDataAuthorised = dataApiPlan !== DataApiPlan.Develop;
  const isVerificationAuthorised = verificationApiPlan !== VerificationApiPlan.Develop;
  
  return { isDataAuthorised, isVerificationAuthorised }
}

const getScopeDetails = (options: ShowBannerOptions): ScopeDetails => {
  const { scopes } = options;

  if (scopes.length === 1 && scopes[0] === 'verification') return ScopeDetails.VerificationOnly
  if (scopes.includes('verification')) return ScopeDetails.Mixed
  return ScopeDetails.DataOnly
}

const bannerFreePagesList = [PagePath.OVERVIEW_V2, PagePath.CLIENT_ERROR] as string[]

export function showAuthorisationBanner(options: ShowBannerOptions): boolean {
  const { isDataAuthorised, isVerificationAuthorised } = getAuthorisationDetails(options);
  const scopeDetails = getScopeDetails(options);

  if (bannerFreePagesList.includes(window.location.pathname)) {
    return false
  }

  switch(scopeDetails) {
    case ScopeDetails.DataOnly: 
      return !isDataAuthorised
    case ScopeDetails.VerificationOnly:
      return !isVerificationAuthorised
    case ScopeDetails.Mixed:
      return !isDataAuthorised ||!isVerificationAuthorised
  }
}


