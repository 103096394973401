import { ButtonLoader } from '@components/common/styles'
import React, { AnchorHTMLAttributes, ReactNode, forwardRef } from 'react'
import { Root } from './styles'

export type ButtonLinkProps = {
  disabled?: boolean
  color: string
  loading?: boolean
  variant: 'primary' | 'secondary' | 'tertiary'
  children: ReactNode
  dataView?: string
} & AnchorHTMLAttributes<HTMLAnchorElement>

const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(function ButtonLink(props, ref) {
  const {
    loading = false,
    disabled = false,
    children,
    color,
    variant,
    dataView,
    ...restProps
  } = props

  return (
    <Root
      ref={ref}
      $color={color}
      $variant={variant}
      disabled={disabled || loading}
      $loading={loading}
      data-view={dataView}
      {...restProps}
    >
      {children}
      {loading && <ButtonLoader />}
    </Root>
  )
})

export default ButtonLink
