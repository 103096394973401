import styled, { css } from 'styled-components/macro'

type FontSize = 'bodyM' | 'bodyS' | 'bodyXs' | 'bodyXss'

type FontWeight = 'thin' | 'regular' | 'semiBold' | 'bold'

// this must be a div and not a paragraph because of Validation Nesting from React
export const Paragraph = styled.div<{ size?: FontSize; bold?: FontWeight }>(
  ({ size, bold, theme: { typography, colours } }) => css`
    font-size: ${typography.montserrat.fontSize[size || 'bodyS']};
    line-height: ${typography.montserrat.lineHeight[size || 'bodyS']};
    font-weight: ${typography.montserrat.fontWeight[bold || 'regular']};
    color: ${colours.neutral.charcoal};
  `,
)
