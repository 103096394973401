import styled from 'styled-components'
import { FaIcon } from '@assets/icons'
import { device } from '@constants/devices'

export const Step = styled.div<{ active?: boolean }>`
  height: ${(props) => (props.active ? '20px' : '14px')};
  width: ${(props) => (props.active ? '20px' : '14px')};
  border-radius: 50%;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${(props) => (props.active ? '600' : '400')};
  font-size: 12px;
`

export const ProgressTrackContainer = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: center;
  align-items: center;
  width: 200px;
`

export const Line = styled.div`
  height: 1px;
  border-bottom: 1px solid black;
  width: 164px;
`

export const LabelsContainer = styled.div<{ page: string }>`
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  ${device.tablet} {
    ${(props) => (props.page === '1' ? '90%' : '75%')};
  }
`

export const Label = styled.div<{ bold: boolean }>`
  max-width: ${(props) => (props.bold ? '128px' : '124px')};
  font-size: 12px;
  font-weight: ${(props) => (props.bold ? '600' : '400')};
`

export const IconsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 200px;
`

export const Icon = styled(FaIcon)<{
  offsetRight?: boolean
  offsetLeft?: boolean
}>`
  width: 24px !important;
  height: 24px !important;
  position: relative;
  ${(props) => props.offsetRight && 'left: 4px;'}
  ${(props) => props.offsetLeft && 'right: 4px;'}
`

export const SmallIcon = styled(FaIcon)`
  width: 8px !important;
  height: 8px !important;
`
