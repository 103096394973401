import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { Paragraph } from '../../Paragraph'
import { PrismaIcon } from '../../prisma'

interface ScrollDownProps {
  show?: boolean
}

export const ScrollDown: React.FC<ScrollDownProps> = ({ show }) => {
  const { t } = useTranslation('end_user_components')

  return (
    <ScrollDownContainer aria-hidden={true} show={show}>
      <Text>{t('provider.list.scroll.down')}</Text>
      <Icon src={faChevronDown} />
    </ScrollDownContainer>
  )
}

export const ScrollDownContainer = styled.div<{ show?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => {
    return theme.colours.tints.charcoal[50]
  }};
  margin-bottom: ${({ theme }) => theme.spaces.xxl};

  & > *:not(:first-child) {
    margin-left: ${({ theme }) => theme.spaces.sm};
  }

  ${(props) =>
    props.show
      ? `visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;`
      : `visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;`}
`

export const Text = styled(Paragraph)`
  cursor: default;
  text-align: center;
  color: ${({ theme }) => theme.colours.tints.charcoal[50]};
  &:hover {
    color: ${({ theme }) => theme.colours.tints.charcoal[50]};
  }
`

export const Icon = PrismaIcon
