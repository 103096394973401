import { AxiosError } from 'axios'
import { AuthDialogSpaError, ErrorCode } from '@utils/errors'

export function handleApiError(response: AxiosError): Error {
  switch (response.response?.status) {
    case 403:
      return AuthDialogSpaError.Create(ErrorCode.UnauthorizedSession, 'Unauthorized session')
    case 500:
      return AuthDialogSpaError.Create(ErrorCode.InternalServerError, 'Internal error')
    case 400:
      return AuthDialogSpaError.Create(ErrorCode.BadRequestError, 'Bad request error')
    default:
      return response
  }
}
