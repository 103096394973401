import { useEffect } from 'react'
import { getOSDetails } from '@utils/helpers'
import { usePageContext } from '@hooks'
import { Page } from '@models/IPage'
import { LoginStatus } from '@models/loginStatus'
import { LoginType } from '../models'
import { redirectToExternalUrl } from '@utils/navigation'

const getRedirectTimeout = (status: LoginStatus) => {
  return status === LoginStatus.FailedErrorCode ? 5000 : 1000
}

const useSuccessRedirect = (
  status: LoginStatus,
  returnUrl: string | null,
  loginType: LoginType,
) => {
  const { changePage } = usePageContext()
  // Manage successful login
  useEffect(() => {
    let redirectTimeout: NodeJS.Timeout
    if ((status === LoginStatus.Success || status === LoginStatus.FailedErrorCode) && returnUrl) {
      if (getOSDetails() === 'iOS') {
        changePage(Page.IOS_REDIRECT, { returnUrl, loginType })
      } else {
        redirectTimeout = setTimeout(() => {
          redirectToExternalUrl(returnUrl)
        }, getRedirectTimeout(status))
      }
    }
    return () => clearTimeout(redirectTimeout)
  }, [status, returnUrl, changePage, loginType])
}
export default useSuccessRedirect
