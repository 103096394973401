import { useState, useRef, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { getWrongValidation, isValidInput } from '@utils/authInputs'
import { IValidationRule } from '@models/IAuthInput'

const useInput = (
  defaultValue = '',
  validations: IValidationRule[] = [],
  saveValue: (value: string | null) => void,
) => {
  const { t } = useTranslation('fieldErrors')
  const [value, setValue] = useState(defaultValue)
  const [errorMsg, setErrorMsg] = useState('')
  const [isWrong, setIsWrong] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleCorrectInput = useCallback(
    (val: string) => {
      setIsWrong(false)
      setErrorMsg('')
      saveValue(val)
    },
    [saveValue],
  )

  // Save the value to form when pre-filled with default value
  useEffect(() => {
    if (defaultValue) {
      // Always assume correct input if default value
      handleCorrectInput(defaultValue)
    }
  }, [defaultValue, handleCorrectInput])

  const handleIncorrectInput = (val: string) => {
    if (val === '') {
      setIsWrong(false)
      setErrorMsg('')
    } else {
      setIsWrong(true)
      const wrongValidation = getWrongValidation(val, validations)
      const { translation_key, error_message } = wrongValidation
      if (translation_key) {
        setErrorMsg(t(translation_key))
      } else {
        setErrorMsg(error_message)
      }
    }
    saveValue(null)
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setValue(value)
    if (isValidInput(value, validations)) {
      handleCorrectInput(value)
    }
  }

  const reset = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent> | React.TouchEvent<SVGSVGElement>,
  ) => {
    // This call prevents the blur event to fire (reset called with onMouseDown or onTouchStart)
    e.preventDefault()
    if (!value) {
      inputRef.current?.blur()
    } else {
      setValue('')
      handleIncorrectInput('')
    }
  }

  const onFocus = () => {
    setIsFocused(true)
  }

  const onBlur = () => {
    setIsFocused(false)
    if (!isValidInput(value, validations)) {
      handleIncorrectInput(value)
    }
  }

  return {
    value,
    errorMsg,
    isWrong,
    reset,
    onChange,
    onFocus,
    onBlur,
    isFocused,
    inputRef,
  }
}

export default useInput
