import { ButtonStyle } from '@components/common/styles'
import styled from 'styled-components'

export const Root = styled.button<{
  $color: string
  $variant: 'primary' | 'secondary' | 'tertiary'
  $loading: boolean
}>`
  ${ButtonStyle}
`
