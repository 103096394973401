import React, { ReactNode, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { SelectableListWithSmallOption } from './form/SelectableList'
import IProvider from '@models/IProvider'
import { getProviderId } from '@utils/helpers'
import { NoResultCallToActionOptions } from './form/SelectableList/SelectableListBase'

interface ProviderSelectProps {
  providers: IProvider[]
  children?: ReactNode
  onProviderSelect: (provider: IProvider) => void
  onProviderSearch?: (searchTerm: string) => void
  CountryPicker?: ReactNode
  noResultCallToActionOptions: NoResultCallToActionOptions
  showNoResultCallToActionBelowList: boolean
}

export function ProviderSelect({
  providers,
  onProviderSelect,
  onProviderSearch,
  CountryPicker,
  noResultCallToActionOptions,
  showNoResultCallToActionBelowList,
}: ProviderSelectProps) {
  const { t } = useTranslation('end_user_components')
  const [selectedProviderId, setSelectedProviderId] = useState<string>('')
  const refOptionsList = useRef<HTMLDivElement>(null)

  const onChange = (providerId: string) => {
    const foundProvider = providers.find((x) => x.provider_id === providerId)
    setSelectedProviderId(providerId)
    onProviderSelect(foundProvider!)
  }

  const onSearch = (searchTerm: string) => {
    onProviderSearch?.(searchTerm)
  }

  const providersToOptions = (providers: IProvider[]) =>
    providers.map((p) => ({
      value: getProviderId(p),
      label: p.display_name,
      icon: p.icon_url,
    }))

  return (
    <ProvidersListContainer ref={refOptionsList}>
      <SelectableListWithSmallOption
        placeholder={t('provider.search.placeholder')}
        optionTitle={<ProvidersLabel>{t('provider.list.label')}</ProvidersLabel>}
        value={selectedProviderId}
        name="search"
        onChange={onChange}
        onSearch={onSearch}
        options={providersToOptions(providers)}
        searchAdjacentComponent={CountryPicker}
        noResultCallToActionOptions={noResultCallToActionOptions}
        showNoResultCallToActionBelowList={showNoResultCallToActionBelowList}
      />
    </ProvidersListContainer>
  )
}

const ProvidersListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const ProvidersLabel = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 6px 0;
  color: ${({ theme }) => theme.colours.neutral.charcoal};
`
