/* eslint-disable @typescript-eslint/no-var-requires */
import thunk from 'redux-thunk'
import { applyMiddleware, createStore, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from '../reducers'
import IStoreState from './IStoreState'

const createHistory = require('history').createBrowserHistory

export const history = createHistory()
const initialState = {}

let middleware

if (process.env.NODE_ENV !== 'production') {
  middleware = composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history)))
} else {
  middleware = applyMiddleware(thunk, routerMiddleware(history))
}

const store = createStore(
  createRootReducer(history),
  initialState,
  middleware,
) as Store<IStoreState>

interface Module {
  hot: {
    accept: (path: string, fn: () => unknown) => unknown
  }
}

declare const module: Module
if (module.hot) {
  module.hot.accept('../reducers', () => {
    const nextReducer = require('../reducers')
    store.replaceReducer(nextReducer)
  })
}

export default store
