import { device } from '@constants/devices'
import { ContainerStyle, hideScrollBar, scrollbarStyle, upperGradientStyle } from '@styles'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  ${hideScrollBar}
  position: relative;

  & li {
    font-weight: 700;
  }
`

export const ScrollingDiv = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  position: relative;
  ${scrollbarStyle};
`

export const BottomContainer = styled.div`
  position: relative;
  ${upperGradientStyle}
`
export const LegalFooter = styled.div`
  font-size: 9px;
  color: #2d2d2d;
  text-align: center;
  line-height: 1.6;
  ${device.desktop} {
    font-size: 10px;
  }
  display: block;
  margin-top: 12px;
  ${ContainerStyle}
  text-align: center;
`
