import { infoCircleLight, infoCircleSolid } from '@assets/icons'
import {
  Accordion,
  AccordionButton,
  AccordionChevron,
  AccordionItem,
  AccordionPanel,
  AccordionTitle,
  ConsentLegalNoteContent,
  FooterLink,
} from '@components'
import {
  EU_AUTHORITY_NAME,
  TL_EU_REFERENCE_NUMBER,
  TL_EU_REFERENCE_URL,
  TL_NAME,
  TL_PRIVACY_URL,
  TL_TOS_URL,
} from '@constants/legal'
import {
  useClientColors,
  useNestedTranslation,
  usePermissionsString,
  useShouldDisplayProviderScopes,
  useTranslatedDatause,
} from '@hooks'
import { DefaultPageLayout } from '@layouts'
import { ScrollingDiv } from '@pages/Consent/styles'
import IStoreState from '@store/IStoreState'
import { getProviderScope } from '@utils/scope'
import React from 'react'
import { useSelector } from 'react-redux'
import ConsentButtons from '../ConsentButtons'
import ConsentLogosTLCheck from '../ConsentLogosTLCheck'
import {
  BottomContainer,
  Container,
  LegalFooter,
  StyledConsentPermissionsListNote,
  StyledConsentProviderPermissionsListNote,
} from './styles'

const ConsentContentMonthlyInvoiceVerification = () => {
  const { scope, providerScopeMappings, clientName, dataUse, dataUseCategory, providerName } =
    useSelector((state: IStoreState) => {
      const { client, providers } = state
      return {
        scope: client.scope,
        providerScopeMappings: providers.selectedProvider.provider_scope_mappings ?? {},
        clientName: client.clientSettings.client_name,
        dataUseCategory: client.clientSettings.data_use_category,
        dataUse: client.clientSettings.data_use,
        providerName: providers.selectedProvider.display_name,
      }
    })
  const { secondaryColor } = useClientColors()
  const t = useNestedTranslation('v2_consent', ['monthly_invoice_verification'])
  const providerScope = getProviderScope({ scope, providerScopeMappings })
  const shouldDisplayProviderScopes = useShouldDisplayProviderScopes({ scope, providerScope })
  const permissionsString = usePermissionsString({ providerName, scope })
  const translateDataUse = useTranslatedDatause({ dataUse, dataUseCategory })

  return (
    <DefaultPageLayout
      title={t('title', { clientName })}
      subtitle={t('subtitle', { clientName })}
      logo={<ConsentLogosTLCheck />}
    >
      <Container>
        <ScrollingDiv>
          <Accordion themeColor={secondaryColor}>
            <AccordionItem>
              <AccordionButton>
                <AccordionTitle icon={infoCircleSolid} iconLight={infoCircleLight}>
                  {t('accordion.title')}
                </AccordionTitle>
                <AccordionChevron />
              </AccordionButton>
              <AccordionPanel>
                <p>{t('accordion.data_use', { clientName, dataUse: translateDataUse })}</p>
                <p>{t('accordion.access_one_time', { clientName })}:</p>
                <StyledConsentPermissionsListNote scope={scope} />
                <br />
                {shouldDisplayProviderScopes && (
                  <>
                    <p>{t('accordion.additional_share', { providerName })}</p>
                    <StyledConsentProviderPermissionsListNote
                      scope={scope}
                      providerScope={providerScope}
                    />
                    <br />
                    <p>{t('accordion.additional_not_used', { clientName, permissionsString })}</p>
                  </>
                )}
                <p>{t('accordion.security')}</p>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </ScrollingDiv>
      </Container>
      <BottomContainer>
        <ConsentButtons />
        <LegalFooter>
          <ConsentLegalNoteContent
            privacyLink={TL_PRIVACY_URL}
            tosLink={TL_TOS_URL}
            entityName={TL_NAME}
          />
          . {t('authorisation_info', { authorityName: EU_AUTHORITY_NAME })}{' '}
          <FooterLink href={TL_EU_REFERENCE_URL}>FRN {TL_EU_REFERENCE_NUMBER}</FooterLink>
        </LegalFooter>
      </BottomContainer>
    </DefaultPageLayout>
  )
}

export default ConsentContentMonthlyInvoiceVerification
