import {
  ConsentPermissionsListNote,
  ConsentProviderPermissionsListNote,
  FooterLink,
} from '@components'
import { TL_NAME, TL_PRIVACY_URL, TL_TOS_URL } from '@constants/legal'
import { usePermissionsString, useShouldDisplayProviderScopes } from '@hooks'
import { ConsentPageLayout } from '@layouts'
import useConsent from '@pages/Consent/hooks'
import { hasOngoingAccess, hasOnlySignupPlusScope } from '@utils/scope'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ConsentLogosTLCheck from '../ConsentLogosTLCheck'

// add `accounts` to scope if not already present
// as it's implicitly required when using the `signupplus` scope
const getComputedScope = (scope: string[]) => {
  if (scope.includes('accounts')) {
    return scope
  }

  return ['accounts', ...scope]
}

const getSubtitleKey = (scope: string[]) => {
  if (hasOnlySignupPlusScope(scope)) {
    return hasOngoingAccess(scope)
      ? 'subtitle_signup_plus_basic_ongoing'
      : 'subtitle_signup_plus_basic_one_off'
  }

  return hasOngoingAccess(scope)
    ? 'subtitle_signup_plus_extra_ongoing'
    : 'subtitle_signup_plus_extra_one_off'
}

function ConsentContentSignupPlus() {
  const { t } = useTranslation('v3_consent')
  const { clientName, scope, publicScope, providerScope, providerName, setDataUseCopy } =
    useConsent()
  const entityName = TL_NAME
  const shouldDisplayProviderScopes = useShouldDisplayProviderScopes({ scope, providerScope })
  const permissionsString = usePermissionsString({ providerName, scope, useNewCopy: true })

  const computedScope = getComputedScope(scope)

  // we have to use `publicScope` as the `scope` variable doesn't
  // include product scopes (like "signupplus")
  const subtitle = t(getSubtitleKey(publicScope), { clientName })

  useEffect(() => {
    // We display the data use description in the subtitle
    // for the signupplus consent screen, hence we set the
    // data use copy to the subtitle here
    setDataUseCopy(subtitle)
  }, [setDataUseCopy, subtitle])

  return (
    <ConsentPageLayout
      logos={<ConsentLogosTLCheck />}
      title={t('title')}
      subtitle={subtitle}
      accordionIsOpen={true}
      accordionTitle={t('what_data_am_i_sharing.title')}
      accordionContent={
        <>
          <p>
            {t(
              hasOngoingAccess(scope)
                ? 'what_data_am_i_sharing.access_information_signup_plus_ongoing'
                : 'what_data_am_i_sharing.access_information_signup_plus_one_off',
            )}
          </p>
          <ConsentPermissionsListNote useNewCopy scope={computedScope} />
          <br />
          {shouldDisplayProviderScopes && (
            <>
              <p>{t('what_data_am_i_sharing.additional_share', { providerName, entityName })}</p>
              <ConsentProviderPermissionsListNote
                useNewCopy
                scope={scope}
                providerScope={providerScope}
              />
              <br />
              <p>
                {t('what_data_am_i_sharing.additional_not_used_unregulated', {
                  clientName,
                  permissionsString,
                })}
              </p>
            </>
          )}
          <p>
            {t('what_data_am_i_sharing.data_aggregator', {
              clientName,
            })}
          </p>
          <p>
            {t('what_data_am_i_sharing.security_disclaimer', {
              entityName,
            })}{' '}
            {t('what_data_am_i_sharing.sharing_disclaimer_uk', {
              entityName,
            })}
          </p>
        </>
      }
      footer={
        <>
          {t('footer_agent_note', { clientName })}{' '}
          <Trans ns="v3_consent" i18nKey="footer">
            By tapping ’Allow’, you agree to{' '}
            <FooterLink href={TL_TOS_URL}>TrueLayer’s Terms of Service</FooterLink> and{' '}
            <FooterLink href={TL_PRIVACY_URL}>Privacy Policy</FooterLink>
          </Trans>
        </>
      }
    />
  )
}

export default ConsentContentSignupPlus
