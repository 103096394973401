import styled from 'styled-components'
import { device } from '@constants/devices'
import { CONTAINER_SIDE_PADDING, CONTAINER_SIDE_PADDING_DESKTOP } from '@styles'

export const Root = styled.div<{
  hoverColor: string
}>`
  flex-shrink: 0;
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.hoverColor};
  }
  padding: 0 ${CONTAINER_SIDE_PADDING}px;

  ${device.tablet} {
    padding: 0 ${CONTAINER_SIDE_PADDING_DESKTOP}px;
  }
`

export const ImageContainer = styled.div<{ imgContainerSize: number }>`
  width: ${(props) => props.imgContainerSize}px;
  height: ${(props) => props.imgContainerSize}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: 1px solid #efefef;
  border-radius: 50%;
  overflow: hidden;
`

export const Image = styled.img<{ imgSize: number }>`
  width: ${(props) => props.imgSize}px;
  height: ${(props) => props.imgSize}px;
`

export const Text = styled.div`
  font-size: 16px;
  margin-left: 10px;
`

export const Highlighted = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  font-weight: 600;
`
