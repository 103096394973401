import {
  ConsentPermissionsListNote,
  ConsentProviderPermissionsListNote,
  FooterLink,
} from '@components'
import { usePermissionsString, useShouldDisplayProviderScopes, useTranslatedDatause } from '@hooks'
import { ConsentPageLayout } from '@layouts'
import useConsent, { useCondensedSubtitle } from '@pages/Consent/hooks'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ConsentLogosShieldCheck from '../ConsentLogosShieldCheck'

function CondensedUKRegulated() {
  const { t } = useTranslation('v3_consent')
  const subtitle = useCondensedSubtitle({ isRegulated: true })
  const {
    clientName,
    dataUse,
    dataUseCategory,
    scope,
    regulatoryInformation,
    providerScope,
    providerName,
    setDataUseCopy,
  } = useConsent()
  const translatedDataUse = useTranslatedDatause({ dataUse, dataUseCategory })
  const entityName = clientName
  const shouldDisplayProviderScopes = useShouldDisplayProviderScopes({ scope, providerScope })
  const permissionsString = usePermissionsString({ providerName, scope, useNewCopy: true })
  const dataUseCopy = t('what_data_am_i_sharing.access_information_regulated', {
    clientName,
    dataUse: translatedDataUse,
  })

  useEffect(() => {
    setDataUseCopy(dataUseCopy)
  }, [dataUseCopy])

  return (
    <ConsentPageLayout
      logos={<ConsentLogosShieldCheck />}
      title={t('title')}
      subtitle={subtitle}
      accordionIsOpen={true}
      accordionTitle={t('what_data_am_i_sharing.title')}
      accordionContent={
        <>
          <p>{dataUseCopy}</p>
          <ConsentPermissionsListNote useNewCopy scope={scope} />
          <br />
          <p>
            {t('what_data_am_i_sharing.sharing_disclaimer_regulated', {
              entityName,
            })}
          </p>
          {shouldDisplayProviderScopes && (
            <>
              <p>{t('what_data_am_i_sharing.additional_share', { providerName, entityName })}</p>
              <ConsentProviderPermissionsListNote
                useNewCopy
                scope={scope}
                providerScope={providerScope}
              />
              <br />
              <p>
                {t('what_data_am_i_sharing.additional_not_used_regulated', {
                  clientName,
                  permissionsString,
                })}
              </p>
            </>
          )}
          <p>
            {t('what_data_am_i_sharing.security_disclaimer_regulated', {
              entityName,
            })}
          </p>
        </>
      }
      footer={
        <Trans ns="v3_consent" i18nKey="footer_regulated">
          By continuing, you’re agreeing to{' '}
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <FooterLink href={regulatoryInformation.terms_of_service_url!}>
            <>{{ clientName }} End User Terms of Service</>
          </FooterLink>{' '}
          and {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <FooterLink href={regulatoryInformation.privacy_policy_url!}>Privacy Policy</FooterLink>
        </Trans>
      }
    />
  )
}

export default CondensedUKRegulated
