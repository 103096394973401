import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setConsentId } from '@actions/client/clientActions'
import { resetLoginInformation, resetReturnUrl } from '@actions/login/loginActions'
import { setSelectedBranch, setSelectedProvider } from '@actions/providers/providersActions'
import { Page } from '@models/IPage'
import { resetSplashScreenVisited } from '@actions/general/generalActions'

const useStateRestore = (page: Page) => {
  const dispatch = useDispatch()

  // Effect to restore app state depending on page
  useEffect(() => {
    switch (page) {
      case Page.PROVIDERS:
        dispatch(resetLoginInformation())
        dispatch(resetReturnUrl())
        dispatch(setSelectedProvider(undefined))
        break
      case Page.CONSENT:
        dispatch(setConsentId(undefined))
        break
      case Page.AUTHINPUTS:
        dispatch(resetLoginInformation())
        break
      case Page.SPLASH_SCREEN:
        dispatch(resetSplashScreenVisited())
        break
      case Page.BRANCHES:
        dispatch(setSelectedBranch(undefined))
        dispatch(resetReturnUrl())
        dispatch(resetLoginInformation())
        break
      default:
    }
  }, [page, dispatch])
}

export default useStateRestore
