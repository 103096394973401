const popularProviders = {
  fr: [
    "caisse d'epargne",
    'hello bank!',
    'amex',
    'crédit agricole',
    'bnp paribas',
    'revolut',
    'banque populaire',
    'crédit mutuel',
    'connexis cash',
    'société générale',
  ],
  de: [
    'sparkasse',
    'volksbanken raiffeisenbanken',
    'deutsche bank',
    'postbank',
    'commerzbank',
    'ing',
    'santander germany',
    'targobank germany',
    'deutschekredit germany',
    'comdirect',
    'n26 germany',
    'hypovereinsbar',
    'revolut germany',
  ],
  ie: ['ptsb', 'aib', 'revolut', 'bank of ireland', 'ulster bank'],
  it: [
    'revolut',
    'bpms',
    'unicredit',
    'intesa san paolo',
    'ubi banca',
    'banca carige',
    'poste italiane',
  ],
  lt: ['swedbank', 'seb bank'],
  pl: ['mbank'],
  es: ['revolut', 'banco de sabadell', 'caixa', 'banco standander', 'bbva', 'bankia'],
  uk: [
    'natwest',
    'halifax',
    'santander',
    'nationwide',
    'monzo',
    'barclays',
    'barclays business',
    'lloyds',
    'lloyds business',
    'hsbc',
    'hsbc business',
  ],
  us: [
    'capital one',
    'wells fargo',
    'citi bank',
    'chase bank',
    'us bank',
    'bank of america',
    'td bank',
    'pnc bank',
  ],
  at: ['erste bank', 'raiffeisenbank', 'bawag p.s.k.', 'bank austria', 'easybank'],
  fi: ['op', 'nordea', 'danske bank', 'säästöpankki', 'ålandsbanken', 'revolut finland'],
  be: [
    'belfius',
    'bnp paribas fortis',
    'ing',
    'kbc',
    'cbc banque',
    'argenta',
    'bpost',
    'beobank',
    'axa bank',
    'hello bank!',
    'europabank',
    'fintro',
    'n26 belgium',
    'revolut belgium',
    'keytrade',
    'nagelmackers',
    'vdk',
    'bank j. van breda',
    'aion',
    'banque cph',
    'crelan',
    'triodos',
  ],
  nl: [
    'ing',
    'rabobank',
    'abn amro',
    'sns',
    'regiobank',
    'asn',
    'revolut netherlands',
    'knab',
    'triodos',
  ],
} as const

export default popularProviders
