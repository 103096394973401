import React, { ButtonHTMLAttributes, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { ThumbRoundlet } from './ThumbRoundlet'
import { Country } from './types'

type CountryPickerButtonProps = {
  country: Country
  // make `onClick` required
  onClick: ButtonHTMLAttributes<HTMLButtonElement>['onClick']
} & ButtonHTMLAttributes<HTMLButtonElement>

export const CountryPickerButton = forwardRef<HTMLButtonElement, CountryPickerButtonProps>(
  function CountryPickerButton({ onClick, country, ...restProps }, ref) {
    const { t } = useTranslation('end_user_components')

    return (
      <StyledButton
        ref={ref}
        aria-label={t('country.picker.button.aria.label', {
          country_name: country.name,
        })}
        onClick={onClick}
        {...restProps}
      >
        <ThumbRoundlet aria-hidden="true" src={country.icon_uri} size={24} borderWidth={0} />
      </StyledButton>
    )
  },
)

const StyledButton = styled.button`
  padding: 11px;
  background-color: ${(props) => props.theme.colours.neutral.white};
  border: 1px #c0c0c0 solid;
  border-radius: ${(props) => props.theme.spaces.xxs};
  cursor: pointer;
  flex-shrink: 0;

  ${ThumbRoundlet} {
    transition: opacity 0.25s ease-in-out;
  }

  &:hover ${ThumbRoundlet} {
    opacity: 0.6;
  }
`
