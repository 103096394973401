import React, { PropsWithChildren } from 'react'
import { FaIcon, circleSolid } from '@assets/icons'
import { Root } from './styles'
import { useAnimatedEllipsis } from './hooks'

export interface IEllipsisOwnProps {
  /**
   * Number of dots in the ellipsis
   */
  dots?: number
}
export interface IELlipsisStyleProps {
  /**
   * Colour of the ellipsis dots
   */
  color?: string
  /**
   * Size (height & width) of the ellipsis dots in px
   */
  size?: number
  /**
   * Gap in px between the ellipsis dots
   */
  gap?: number
  /**
   * Set true for animated ellipsis
   */
  animated?: boolean
  /**
   * Duration in milliseconds of the animation
   */
  duration?: number
  /**
   * margin-bottom css in px to (useful to adjust the Ellipsis position with text)
   */
  marginBottom?: number
}

export type IEliipsisProps = IEllipsisOwnProps & IELlipsisStyleProps

/**
 * This component represents an ellipsis. The style of the dots can be customised
 * and the ellipsis can be animated as well.
 */
const Ellipsis = ({
  dots = 3,
  color = '#2d2d2d',
  size = 4,
  gap = 5,
  animated = false,
  duration = 1000,
  marginBottom = 0,
}: PropsWithChildren<IEliipsisProps>) => {
  const animatedDots = useAnimatedEllipsis(dots, duration)
  return (
    <Root gap={gap} marginBottom={marginBottom} data-chromatic="ignore">
      {[...Array(dots)].map((_, i) => (
        <FaIcon
          key={i}
          icon={circleSolid}
          color={color}
          style={{
            fontSize: `${size}px`,
            opacity: i + 1 > (animated ? animatedDots : dots) ? '0' : '1',
          }}
        />
      ))}
    </Root>
  )
}

export default Ellipsis
