import React from 'react'
import { useNestedTranslation } from '@hooks'
import useConsent from '../../hooks'

function ConsentOngoingAccessNote() {
  const t = useNestedTranslation('v2_consent', ['how_is_my_data_used'])
  const { clientName, scope } = useConsent()

  const hasOngoingAccess = scope.includes('offline_access')

  if (!hasOngoingAccess) {
    return null
  }

  return (
    <>
      <div data-view="data-ongoing-access">{t('access.ongoing', { clientName })}.</div>
      <br />
    </>
  )
}

export default ConsentOngoingAccessNote
