import { timesRegular } from '@assets/icons'
import Button from '@components/Button'
import { useClientColors, useId } from '@hooks'
import React, { KeyboardEventHandler, useCallback, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { Overlay, Root, Content, Title, Description, CloseIcon, CloseIconContainer } from './styles'
import FocusLock from 'react-focus-lock'

function ConfirmConsentWithdrawalModal({
  clientName,
  onConfirm,
  onClose,
}: {
  clientName: string
  onConfirm: () => void
  onClose: () => void
}) {
  const modalRoot = useMemo(() => document.getElementById('modal-root'), [])
  const dialogId = useId()
  const dialogLabelId = `${dialogId}-label`
  const dialogDescriptionId = `${dialogId}-description`
  const { secondaryColor } = useClientColors()
  const confirmButtonRefCallback = (ref: HTMLButtonElement | null) => {
    ref?.focus()
  }

  const onKeyDown: KeyboardEventHandler = useCallback(
    (event) => {
      if (event.code === 'Escape') {
        event.stopPropagation()

        onClose()
      }
    },
    [onClose],
  )

  if (!modalRoot) {
    throw new Error('Unable to mount the modal as no element with `[id="modal-root"]` found')
  }

  return createPortal(
    <>
      <Overlay onClick={onClose} />
      <FocusLock>
        <Root
          role="dialog"
          aria-labelledby={dialogLabelId}
          aria-describedby={dialogDescriptionId}
          aria-modal="true"
          onKeyDown={onKeyDown}
        >
          <CloseIconContainer>
            <CloseIcon aria-label="close" icon={timesRegular} onClick={onClose} />
          </CloseIconContainer>
          <Content>
            <Title id={dialogLabelId}>Do you wish to withdraw your consent?</Title>
            <Description id={dialogDescriptionId}>
              Withdrawing your consent means you will stop sharing your data with {clientName}.
            </Description>
            <Button
              dataView="button-confirm-consent-withdrawal"
              ref={confirmButtonRefCallback}
              label="Stop sharing"
              color={secondaryColor}
              onClick={onConfirm}
              variant="primary"
            ></Button>
          </Content>
        </Root>
      </FocusLock>
    </>,
    modalRoot,
  )
}

export default ConfirmConsentWithdrawalModal
