import React from 'react'
import { exclamationCircleSolid, FaIcon } from '@assets/icons'
import { useViewport } from '@hooks'
import { ErrorLayout } from '@layouts'
import useClientError from './hooks'
import { Message } from './styles'

const ClientError = () => {
  const { isMobile } = useViewport()
  const message = useClientError()

  return (
    <ErrorLayout>
      <FaIcon icon={exclamationCircleSolid} color="#c00023" size="3x" />
      <Message isMobile={isMobile}>{message}</Message>
    </ErrorLayout>
  )
}

export default ClientError
