import styled from 'styled-components'
import { device } from '@constants/devices'

export const Page = styled.div<{ bgColor: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  overflow-y: auto;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
  ${device.tablet} {
    width: 720px;
    height: 650px;
    border-radius: 5px;
  }
`

export const Header = styled.div<{ bgColor: string }>`
  background-color: ${(props) => props.bgColor};
  width: 100%;
  height: 100px;
  ${device.tablet} {
    height: 170px;
    border-radius: 5px 5px 0 0;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  .provider-logo {
    width: 170px;
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  text-align: center;
  width: 100%;
`
