/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from '@components'
import { useClientColors } from '@hooks'
import { IValidationRule, AllowedCharacters } from '@models/IAuthInput'
import { getFreeInputType } from '@utils/authInputs'

export interface IClearTextInputValueProps {
  /**
   * The ID of the input used to return to the connector
   */
  id: string
  /**
   * The input's placeholder text
   */
  placeholder?: string
  /**
   * The input's valdations
   */
  validations: IValidationRule[]
  /**
   * True for a password
   */
  isSensitive: boolean
  /**
   * Allowed characters define text or numbers
   */
  allowedCharacters: AllowedCharacters
  /**
   * The default value (pre-filled)
   */
  defaultValue?: string
  /**
   * Function to be called to save the input data
   */
  saveData: (key: string, val: string) => void
}

/**
 * This component displays a text input field to collect user information
 */
const ClearTextInputValue: FC<IClearTextInputValueProps> = ({
  id,
  validations,
  isSensitive,
  allowedCharacters,
  saveData,
  defaultValue,
}) => {
  const { t } = useTranslation(['fieldNames', 'fieldHelpTexts'])
  const displayName = t(`fieldNames:${id}_field_display_name`)
  const helpText = t(`fieldHelpTexts:${id}_field_help_text`)
  const { tertiaryColor } = useClientColors()

  const saveValue = useCallback((value: string) => {
    saveData(id, value)
  }, [])

  return (
    <Input
      type={getFreeInputType(allowedCharacters, isSensitive)}
      label={helpText}
      placeholder={displayName}
      validations={validations}
      saveValue={saveValue}
      activeColor={tertiaryColor}
      defaultValue={defaultValue}
    />
  )
}

export default ClearTextInputValue
