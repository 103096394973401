import { useState, useEffect, useRef } from 'react'
import { usePageContext } from '@hooks'
import { getAuthStartPayload } from '@utils/analytics'
import { trackAuthStart } from '@middlewares/analytics'
import { authUriRequest, loginRequest } from '@middlewares/api'
import { Page } from '@models/IPage'
import IProvider from '@models/IProvider'
import { getReturnUrlFromLoginResponse, isCsMock } from '@utils/providers'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { sendAuthErrorAnalytics } from '@actions/analytics'
import { INotification, NotificationType } from '@models/IAuthInput'
import { AxiosError } from 'axios'
import { setLoginStatus, setMfaData, setReturnUrl } from '@actions/login/loginActions'
import { LoginStatus } from '@models/loginStatus'
import IStoreState from '@store/IStoreState'

/**
 * Hook wrapper that does the necessary calls to auth server to login.
 */
const useLoginRequest = (
  provider: IProvider,
  loginInfo: object,
  // flag used to perform the authuri call in the background on the consent page
  preConsent = false,
  skip = false,
  abortSignal: AbortSignal | undefined = undefined,
) => {
  const dispatch = useDispatch()
  const { changePage } = usePageContext()
  const { getState } = useStore()
  const returnUrl = useSelector((state: IStoreState) => state.login.returnUrl)
  const [requestDone, setRequestDone] = useState(!!returnUrl)
  const to = useRef<NodeJS.Timeout>()

  const cleanUpTimeout = () => {
    if (to.current) {
      clearTimeout(to.current)
    }
  }

  useEffect(() => {
    const login = async () => {
      const handleSuccessfulLogin = (response: unknown) => {
        dispatch(setReturnUrl(getReturnUrlFromLoginResponse(provider, response)))
        dispatch(setLoginStatus(LoginStatus.Success))
        dispatch(setMfaData(undefined))
        cleanUpTimeout()
      }

      const handleLoginWithFailedErrorCode = (response: unknown) => {
        dispatch(setReturnUrl(getReturnUrlFromLoginResponse(provider, response)))
        dispatch(setLoginStatus(LoginStatus.FailedErrorCode))
        dispatch(setMfaData(undefined))
        cleanUpTimeout()
      }

      const handleFailedLogin = (error?: any) => {
        if (preConsent) return
        if (
          error &&
          error?.response?.status === 401 &&
          error.response.data.error === 'wrong_credentials'
        ) {
          // TODO: find out why it was error.response.error
          dispatch(sendAuthErrorAnalytics(error.response.error))
          const notification: INotification = {
            id: 'unauthorised-error',
            type: NotificationType.ERROR,
            message: error.response.data.error_description,
          }
          changePage(Page.AUTHINPUTS, { notification })
        }
        dispatch(setLoginStatus(LoginStatus.Fail))
        cleanUpTimeout()
      }

      const handleNeedMfa = (mfaData: unknown) => {
        dispatch(setLoginStatus(LoginStatus.NeedMfa))
        dispatch(setMfaData(mfaData))
        cleanUpTimeout()
      }

      const mockLogin = async () => {
        const body = {
          auth_method: 'Password',
          connector_id: 'mock',
          fields: loginInfo,
        }
        try {
          if (body && body.fields && Object.keys(body.fields).length) {
            const response = await loginRequest(body)
            handleSuccessfulLogin(response)
          }
        } catch (error) {
          handleFailedLogin(error as AxiosError)
        }
      }

      const defaultLogin = async () => {
        try {
          const authStartPayload = getAuthStartPayload(getState())
          await trackAuthStart(authStartPayload)
          const response = await authUriRequest(
            authStartPayload,
            provider,
            loginInfo,
            preConsent,
            abortSignal,
          )

          // Success field is true
          if (response.data.success) {
            handleSuccessfulLogin(response)

            // Success field is false
          } else {
            const { error } = response.data
            if (error?.code) {
              // Check error code
              switch (error.code) {
                // Need to complete MFA challenge
                case 'need-mfa':
                  if (error.details.steps.length > 0) {
                    handleNeedMfa(error.details.steps[0])
                  }
                  break
                //Handle failed login
                case 'failed':
                  handleLoginWithFailedErrorCode(response)
                  break
                // Wait for subsequent call response
                case 'wait':
                  to.current = setTimeout(login, 2000)
                  break
                // Default to fail
                default:
                  handleFailedLogin()
              }
            }
          }
        } catch (error) {
          handleFailedLogin()
        }
      }

      if (!skip) {
        if (isCsMock(provider)) {
          await mockLogin()
        } else {
          await defaultLogin()
        }
      }
      setRequestDone(true)
    }

    login()

    return () => clearTimeout(to.current!)
  }, [changePage, dispatch, getState, loginInfo, preConsent, provider, skip])

  return {
    returnUrl,
    requestDone,
  }
}

export default useLoginRequest
