import React from 'react'

export interface ITLLogoProps {
  /**
   * The height and width of the logo in pixels
   */
  height: number
  /**
   * The width and width of the logo in pixels
   */
  width: number
  /**
   * The colour of the logo
   */
  color: 'yellow' | 'black' | 'white'
}

/**
 * This component represents TrueLayer's logo
 */
const TLLogo = ({ height, width, color }: ITLLogoProps) => (
  <img
    style={{ width, height }}
    src={`https://truelayer-public-assets.s3.amazonaws.com/logo/mark/tl-${color}.svg`}
    alt="Truelayer Logo"
  />
)

export default TLLogo
