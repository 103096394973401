import React, { useCallback, useState } from 'react'
import { DefaultPageLayout } from '@layouts'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import IStoreState, { IClientStateWithExtend } from '@store/IStoreState'
import {
  Accordion,
  AccordionButton,
  AccordionChevron,
  AccordionItem,
  AccordionPanel,
  AccordionTitle,
  CheckmarkMiddleLogo,
  ConfirmConsentWithdrawalModal,
  ConsentLegalNoteContent,
  FooterLink,
  LinkingLogos,
  TLMiddleLogo,
} from '@components'
import { isClientRegulated } from '@utils/client'
import { infoCircleLight, infoCircleSolid } from '@assets/icons'
import {
  useClientColors,
  usePermissionsString,
  useShouldDisplayProviderScopes,
  useViewport,
} from '@hooks'
import useTranslatedDataUse from '@hooks/useTranslatedDataUse'
import { getProviderScope } from '@utils/scope'
import {
  TL_FCA_NUMBER,
  TL_FCA_URL,
  TL_NAME,
  TL_PRIVACY_URL,
  TL_TOS_URL,
  UK_AUTHORITY_NAME,
} from '@constants/legal'
import {
  StyledConsentPermissionsListNote,
  StyledConsentProviderPermissionsListNote,
  ButtonsContainer,
  StyledButton,
  Container,
  LegalFooter,
  BottomContainer,
} from './styles'
import { consentReconfirmationRequest, consentWithdrawalRequest } from '@middlewares/api'
import { redirectToExternalUrl } from '@utils/navigation'

function ConsentReconfirmation() {
  const {
    clientName,
    clientLogoUri,
    providerLogoUri,
    activeCountry,
    clientAttributes,
    scope,
    dataUseCategory,
    dataUse,
    providerScopeMappings,
    providerName,
    regulatoryInformation,
    reauthLink,
    nextReconfirmationConsent,
    connectionId,
    redirectUri,
  } = useSelector((state: IStoreState) => {
    const { providers } = state
    const client = state.client as IClientStateWithExtend

    return {
      clientName: client.clientSettings.client_name,
      clientLogoUri: client.clientSettings.logo_uri,
      providerLogoUri: providers.selectedProvider.icon_url,
      activeCountry: providers.selectedProvider.country,
      clientAttributes: client.clientAttributes,
      scope: client.scope,
      dataUseCategory: client.clientSettings.data_use_category,
      dataUse: client.clientSettings.data_use,
      providerScopeMappings: providers.selectedProvider.provider_scope_mappings ?? {},
      providerName: providers.selectedProvider.display_name,
      regulatoryInformation: client.clientAttributes.regulatory_information.uk,
      reauthLink: client.extend.reauth?.link,
      nextReconfirmationConsent: client.extend.nextReconfirmationDate,
      connectionId: client.connectionId,
      redirectUri: client.redirectUri,
    }
  })

  const { secondaryColor } = useClientColors()

  const { t } = useTranslation('consent_reconfirmation')

  const translatedDataUse = useTranslatedDataUse({ dataUse, dataUseCategory })

  const providerScope = getProviderScope({ scope, providerScopeMappings })

  const shouldDisplayProviderScopes = useShouldDisplayProviderScopes({ scope, providerScope })
  const isRegulated = isClientRegulated({
    clientAttributes,
    activeCountry,
  })
  const permissionsString = usePermissionsString({ providerName, scope })
  const entityName = isRegulated ? clientName : TL_NAME
  const [showConsentWithdrawalModal, setShowConsentWithdrawalModal] = useState(false)
  const { isMobile } = useViewport()

  const onConfirmClick = useCallback(async () => {
    if (reauthLink) {
      redirectToExternalUrl(reauthLink)
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await consentReconfirmationRequest(connectionId!)

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      redirectToExternalUrl(redirectUri!)
    }
  }, [connectionId, reauthLink, redirectUri])

  const onWithdrawClick = useCallback(() => {
    setShowConsentWithdrawalModal(true)
  }, [])

  const onWithdrawConfirm = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await consentWithdrawalRequest(connectionId!)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    redirectToExternalUrl(redirectUri!)
  }, [connectionId, redirectUri])

  const confirmButton = (
    <StyledButton
      label={t('confirm_consent')}
      variant="primary"
      color={secondaryColor}
      onClick={onConfirmClick}
      dataView="button-confirm-consent"
      key="button-confirm-consent"
    />
  )

  const withdrawButton = (
    <StyledButton
      label={t('withdraw_consent')}
      variant="secondary"
      color={secondaryColor}
      onClick={onWithdrawClick}
      dataView="button-withdraw-consent"
      key="button-withdraw-consent"
    />
  )

  return (
    <DefaultPageLayout
      title={t(isRegulated ? 'title_regulated' : 'title', {
        clientName,
      })}
      subtitle={t('subtitle', {
        date: new Intl.DateTimeFormat('en-GB').format(new Date(nextReconfirmationConsent)),
      })}
      logo={
        <LinkingLogos
          leftLogo={clientLogoUri}
          middleLogo={isRegulated ? <CheckmarkMiddleLogo /> : <TLMiddleLogo />}
          rightLogo={providerLogoUri}
        />
      }
    >
      <Container>
        <Accordion themeColor={secondaryColor}>
          <AccordionItem>
            <AccordionButton>
              <AccordionTitle icon={infoCircleSolid} iconLight={infoCircleLight}>
                {t('learn_more.title')}
              </AccordionTitle>
              <AccordionChevron />
            </AccordionButton>
            <AccordionPanel>
              <p>{t('learn_more.data_use', { clientName, dataUse: translatedDataUse })}</p>
              <p>{t('learn_more.access_ongoing', { clientName })}</p>
              <StyledConsentPermissionsListNote scope={scope} />
              <br />
              {shouldDisplayProviderScopes && (
                <>
                  <p>
                    {t('learn_more.additional_share', {
                      providerName,
                      entityName,
                    })}
                  </p>
                  <StyledConsentProviderPermissionsListNote
                    scope={scope}
                    providerScope={providerScope}
                  />
                  <br />
                  <p>
                    {t(
                      isRegulated
                        ? 'learn_more.additional_not_used_regulated'
                        : 'learn_more.additional_not_used',
                      {
                        clientName,
                        permissionsString,
                      },
                    )}
                  </p>
                </>
              )}
              <p>{t('learn_more.security', { entityName })}</p>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Container>
      <BottomContainer>
        <ButtonsContainer>
          {/* not using smth like `flex-direction: row-reverse` for a11y reasons */}
          {isMobile ? [confirmButton, withdrawButton] : [withdrawButton, confirmButton]}
        </ButtonsContainer>
        <LegalFooter>
          <ConsentLegalNoteContent
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            privacyLink={isRegulated ? regulatoryInformation.privacy_policy_url! : TL_PRIVACY_URL}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            tosLink={isRegulated ? regulatoryInformation.terms_of_service_url! : TL_TOS_URL}
            entityName={entityName}
          />
          . {t('authorisation_info', { entityName, authorityName: UK_AUTHORITY_NAME })}{' '}
          <FooterLink
            href={isRegulated ? regulatoryInformation?.registration_page_url : TL_FCA_URL}
          >
            FRN {isRegulated ? regulatoryInformation?.registration_number : TL_FCA_NUMBER}
          </FooterLink>
        </LegalFooter>
      </BottomContainer>
      {showConsentWithdrawalModal && (
        <ConfirmConsentWithdrawalModal
          clientName={clientName}
          onClose={() => {
            setShowConsentWithdrawalModal(false)
          }}
          onConfirm={onWithdrawConfirm}
        />
      )}
    </DefaultPageLayout>
  )
}

export default ConsentReconfirmation
