import React from 'react'
import { DefaultPageLayout } from '@layouts'
import { Search, Label } from '@components'
import { useListSearch, useStateRestore, useViewport } from '@hooks'
import { useTranslation } from 'react-i18next'
import { NoBranchFound, BranchList, BranchesBackground } from './components'
import { IBranchItem } from './models'
import { LabelContainer } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import IStoreState from '@store/IStoreState'
import { setSelectedBranch } from '@actions/providers/providersActions'
import { Page } from '@models/IPage'
import { sendBranchSelected } from '@actions/analytics'
import * as Sentry from '@utils/sentry'

const Branches = () => {
  const provider = useSelector((state: IStoreState) => state.providers.selectedProvider)

  if (!provider.branches) {
    Sentry.exception(`Unexpected state: provider with no branches`, {
      extra: {
        provider,
      },
    })
  }

  const branchesData = provider.branches!.branchesList
  const providerName = provider.display_name
  const providerLogo = provider.logo_url

  const dispatch = useDispatch()

  useStateRestore(Page.BRANCHES)

  const onBranchClick = (b: IBranchItem) => {
    dispatch(setSelectedBranch(b.key))
    dispatch(sendBranchSelected(b.key))
  }

  const { t } = useTranslation('v2_branches')
  const { isMobile } = useViewport()
  const { list, search, update, reset, isFocused, onSearchBlur, onSearchFocus, searchRef } =
    useListSearch(branchesData, {
      listKey: 'name',
      topValues: undefined,
      filterOutTerm: providerName,
    })

  const shouldRenderTitle = !isMobile || (!isFocused && branchesData.length === list.length)
  const shouldRenderBackground = isFocused && !search
  const shouldRenderList = !shouldRenderBackground && list.length > 0
  const shouldRenderLabel = shouldRenderList
  const shouldRenderNotFound = list.length === 0

  const renderSearchBar = () => (
    <Search
      ref={searchRef}
      placeholder={t('search_placeholder')}
      value={search}
      isFocused={isFocused}
      onSearchChange={update}
      resetSearch={reset}
      onSearchFocus={onSearchFocus}
      onSearchBlur={onSearchBlur}
    />
  )

  const renderLabel = () => {
    const text = search ? t('suggested_branches') : t('all_branches')
    return (
      shouldRenderLabel && (
        <LabelContainer>
          <Label text={text} />
        </LabelContainer>
      )
    )
  }

  const renderBackground = () => shouldRenderBackground && <BranchesBackground />

  const renderBranchList = () =>
    shouldRenderList && (
      <BranchList branches={list} highlight={search} onBranchClick={onBranchClick} />
    )

  const renderNoBranchFound = () =>
    shouldRenderNotFound && (
      <NoBranchFound providerName={providerName} search={search} onActionClick={reset} />
    )

  return (
    <DefaultPageLayout title={t('title')} logo={providerLogo} hideTitle={!shouldRenderTitle}>
      <div data-view="page-id-branches"></div>
      {renderSearchBar()}
      {renderLabel()}
      {renderBackground()}
      {renderBranchList()}
      {renderNoBranchFound()}
    </DefaultPageLayout>
  )
}

export default Branches
