import styled from 'styled-components'
import { ContainerStyle, hideScrollBar } from '@styles'
import { device } from '../../constants/devices'

export const Title = styled.h1`
  text-align: center;
  margin: 24px 0 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  ${ContainerStyle}

  ${device.tablet} {
    font-size: 20px;
    line-height: 30px;
  }
`

// `role="doc-subtitle"` is the proper aria role for subtitles
// https://www.w3.org/TR/dpub-aria-1.0/#doc-subtitle
export const Subtitle = styled.p.attrs({ role: 'doc-subtitle' })`
  font-size: 16px;
  line-height: 24px;
  color: #2d2d2d;
  text-align: center;
  margin-top: 16px;
  // overriding default margin-bottom provided by browser until
  // we have a proper CSS reset file in place
  margin-bottom: 0;
  ${ContainerStyle}
`

export const Body = styled.div<{ isLogoPresent: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex-grow: 1;
  padding-bottom: 32px;

  ${hideScrollBar}
`

export const TitlesContainer = styled.div`
  margin-bottom: 24px;
`
