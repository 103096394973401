import React from 'react'
import { defaultClientLogo } from '../../pages/Consent/data'
import Ellipsis from '../Ellipsis'
import { Logo, LogoContainer, Root } from './styles'

function LinkingLogos({
  middleLogo,
  rightLogo,
  leftLogo,
}: {
  leftLogo?: string
  middleLogo?: JSX.Element
  rightLogo: string | JSX.Element
}) {
  const left = (
    <LogoContainer>
      <Logo src={leftLogo || defaultClientLogo} alt="client logo" />
    </LogoContainer>
  )

  const center = middleLogo ? (
    <>
      <Ellipsis color="#aaa" size={3} gap={5} />
      {middleLogo}
      <Ellipsis color="#aaa" size={3} gap={5} />
    </>
  ) : (
    <Ellipsis color="#aaa" size={2} gap={4} dots={10} />
  )

  const right = (
    <LogoContainer>
      {typeof rightLogo === 'string' ? <Logo src={rightLogo} alt="bank logo" /> : rightLogo}
    </LogoContainer>
  )

  return (
    <Root>
      {left}
      {center}
      {right}
    </Root>
  )
}

export default LinkingLogos
