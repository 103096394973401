/* eslint-disable @typescript-eslint/no-non-null-assertion */
import IProvider from '@models/IProvider'
import useCountryProviders from './useCountryProviders'
import popularProviders from '../popularProviders'

const usePopularProviders = () => {
  // Providers from selected country
  const { providers, countryCode } = useCountryProviders()

  // Popular providers in selected country
  return getPopularProviders(providers, countryCode!)
}

// Helpers

const isCountryCodeInPopularProviders = (
  countryCode: string,
): countryCode is keyof typeof popularProviders => countryCode in popularProviders

export const getPopularProviders = (providers: IProvider[], countryCode: string) => {
  if (!isCountryCodeInPopularProviders(countryCode)) return []

  return popularProviders[countryCode]
    .map((name: string) =>
      providers.find((provider) => provider.display_name.toLowerCase() === name),
    )
    .filter((provider: IProvider | undefined): provider is IProvider => provider !== undefined)
}

export default usePopularProviders
