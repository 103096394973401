import { isInIframe } from './helpers'

// For Amazon or any other client using AuthDialog inside an iframe,
// redirect the entire page rather than the contents of the iframe
export function redirectToExternalUrl(url: string): void {
  if (isInIframe() && window.parent) {
    window.parent.location.href = url
  } else {
    window.location.href = url
  }
}
