import { AB_TESTS } from '@contexts/ab-tests/configs'
import ActionTypeKeys from '../actions/ActionTypeKeys'
import ActionTypes from '../actions/ActionTypes'

import { IGeneralState } from '../store/IStoreState'

const initialState: IGeneralState = {
  clientErrorCode: '',
  clientErrorMessage: '',
  withRequestId: true,
  isLoading: true,
  dialogVersion: 3.1,
  splashScreenVisited: false,
  amazonConsentAccordionOpened: false,
  amazonConsentChangeBankClicked: false,
  // populate abTests with null values for all
  // defined a/b tests
  abTests: Object.keys(AB_TESTS).reduce(
    (acc, key) => ({ ...acc, [key]: null }),
    {} as IGeneralState['abTests'],
  ),
}

export default function generalReducer(
  state: IGeneralState = initialState,
  action: ActionTypes,
): IGeneralState {
  switch (action.type) {
    case ActionTypeKeys.SET_CLIENT_ERROR:
      return {
        ...state,
        clientErrorCode: action.payload.clientErrorCode,
        clientErrorMessage: action.payload.clientErrorMessage,
        withRequestId: action.payload.withRequestId,
      }
    case ActionTypeKeys.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case ActionTypeKeys.SET_DIALOG_VERSION:
      return {
        ...state,
        dialogVersion: action.payload,
      }
    case ActionTypeKeys.PROCEED_FROM_SPLASH_SCREEN:
      return {
        ...state,
        splashScreenVisited: true,
      }
    case ActionTypeKeys.RESET_SPLASH_SCREEN_VISITED:
      return {
        ...state,
        splashScreenVisited: false,
      }
    case ActionTypeKeys.AMAZON_CONSENT_ACCORDION_OPENED:
      return {
        ...state,
        amazonConsentAccordionOpened: true,
      }
    case ActionTypeKeys.AMAZON_CONSENT_CHANGE_BANK_CLICKED:
      return {
        ...state,
        amazonConsentChangeBankClicked: true,
      }
    case ActionTypeKeys.SET_AB_TESTS:
      return {
        ...state,
        abTests: action.payload,
      }
    default:
      return state
  }
}
