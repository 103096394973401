/* eslint-disable no-console */
import { ErrorCode } from '@utils/errors'
import React, { Component, ReactNode } from 'react'
import * as Sentry from '../../utils/sentry'
import ErrorContainer from './ErrorContainer'
import { errorMapper } from './errors'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  public componentDidCatch(error: Error) {
    // in development—errors caught by error boundaries will still
    // bubble up to the uncaught error handler.
    // https://github.com/facebook/react/issues/10474
    Sentry.exception(error)
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorContainer error={errorMapper[ErrorCode.UnexpectedError]} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
