import { scrollbarStyle } from '@styles'
import styled from 'styled-components'

export const DesktopContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
`

export const ScrollingDiv = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  position: relative;
  ${scrollbarStyle};
`
