import { useCallback, useEffect, useMemo, useState } from 'react'
import { Page } from '@models/IPage'
import { isCountrySelectionStepEnabled } from '@utils/country'
import usePageManagerState from './usePageManagerState'
import { getPage } from './utils'
import { AbTestKey } from '@contexts/ab-tests/configs'
import { useAbTests } from '@contexts/ab-tests'
import { isCsMock } from '@utils/providers'

const usePage = (closeApp: () => void) => {
  const [pageStack, setPageStack] = useState<Page[]>([])
  const [locationState, setLocationState] = useState({})
  const { isAlternativeVariant } = useAbTests()
  const isFirstPage = pageStack.length === 1

  const {
    isLoading,
    selectedProvider,
    consentId,
    selectedCountry,
    loginInformation,
    authUriMode,
    providers,
    hasMfaData,
    extendConsent,
    clientId,
    splashScreenVisited,
    loginStatus,
  } = usePageManagerState()

  const multipleCountries = isCountrySelectionStepEnabled(providers)

  const shouldRenderConsentBeforeAuthInputs =
    isCsMock(selectedProvider) || isAlternativeVariant(AbTestKey.AuthInputsAfterConsentScreen)

  const page = useMemo(() => pageStack[pageStack.length - 1] as Page | undefined, [pageStack])
  const previousPage = useMemo(
    () => pageStack[pageStack.length - 2] as Page | undefined,
    [pageStack],
  )

  // Effect to update page upon redux store change
  useEffect(() => {
    const newPage = getPage(
      isLoading,
      selectedProvider,
      consentId,
      selectedCountry,
      multipleCountries,
      loginInformation,
      authUriMode,
      extendConsent,
      clientId,
      splashScreenVisited,
      hasMfaData,
      loginStatus,
      shouldRenderConsentBeforeAuthInputs,
    )

    // If newPage !== page the user advanced to another step in the flow
    if (newPage && newPage !== page) {
      setPageStack((prevPageStack) => {
        const newStack = [...prevPageStack]
        // If newPage === previousPage => the user clicked back so we pop a page off the stack
        // Except for the LOGIN page in cases of MFA flows that may reappear multiple times
        if (newPage === previousPage && newPage !== Page.LOGIN) {
          newStack.pop()
        } else {
          newStack.push(newPage)
        }

        return newStack
      })
    }
  }, [
    isLoading,
    selectedCountry,
    selectedProvider,
    consentId,
    multipleCountries,
    loginInformation,
    authUriMode,
    extendConsent,
    clientId,
    splashScreenVisited,
    loginStatus,
    // mfaData,
    // page,
    // previousPage,
  ])

  // Helper to manually change page
  const changePage = useCallback((page: Page, state?: object) => {
    setPageStack((prevPageStack) => {
      const newStack = [...prevPageStack]
      // if the page we are changing to is the same as the second last
      // do not push it on top of the stack
      // pop the last one instead to back to this page
      if (page === newStack[newStack.length - 2]) {
        newStack.pop()
      } else {
        newStack.push(page)
      }

      return newStack
    })
    if (state) {
      setLocationState(state)
    }
  }, [])

  // Helper to go back
  const goBack = useCallback(
    (times = 1) => {
      if (isFirstPage) {
        closeApp()
        return
      }
      const newStack = [...pageStack]
      for (let i = 0; i < times; i++) {
        newStack.pop()
      }
      setPageStack(newStack)
    },
    [closeApp, isFirstPage, pageStack],
  )

  // Go back to a specific page
  const goBackToPage = useCallback(
    (page: Page) => {
      const newStack = [...pageStack]
      while (newStack[newStack.length - 1] !== page) {
        newStack.pop()
      }
      setPageStack(newStack)
    },
    [pageStack],
  )

  return {
    page,
    isFirstPage,
    previousPage,
    locationState,
    changePage,
    goBack,
    goBackToPage,
  }
}

export default usePage
