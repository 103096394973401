import styled from 'styled-components'
import { mainContainerDesktopWidth, mainContainerTabletWidth } from '@constants/viewport'
import { device } from '../../constants/devices'
import { hideScrollBar } from '../../styles/index'

export const Page = styled.div<{ bgColor: string; $height?: number; $width?: number }>`
  background-color: ${(props) => props.bgColor};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ $height, $width }) => {
    if ($height && $width) {
      return `
        height: ${$height}px;
        width: calc(${$width}px + 1px);
      `
    }
    return ''
  }}
`

export const Container = styled.div<{
  isAuthorised: boolean
  $scale?: number
  $height?: number
  $width?: number
}>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
  ${hideScrollBar}
  background-color: white;
  ${({ $scale }) => $scale && `transform: scale(${$scale});`}
  ${({ $height, $width }) => {
    if ($height && $width) {
      return `
        transform-origin: 0 0;
      `
    }
    return ''
  }}
  ${device.mobile} {
    width: 100%;
    padding-top: ${(props) => (props.isAuthorised === false ? '80px' : '10px')};
  }
  ${device.tablet} {
    width: ${mainContainerTabletWidth}px;
    border: 2px solid #eee;
    border-radius: 5px;
    max-height: 722px;
    padding-top: 0;
    margin: 16px 0;
  }
  ${device.desktop} {
    width: ${mainContainerDesktopWidth}px;
  }
`
