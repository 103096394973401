import { infoCircleLight, infoCircleSolid } from '@assets/icons'
import {
  Accordion,
  AccordionButton,
  AccordionChevron,
  AccordionItem,
  AccordionPanel,
  AccordionTitle,
} from '@components'
import { useClientColors } from '@hooks'
import DefaultPageLayout from '@layouts/DefaultPageLayout'
import ConsentButtons from '@pages/Consent/components/ConsentButtons'
import React, { FC } from 'react'
import { BottomContainer, Container, LegalFooter, ScrollingDiv } from './styles'

export interface IConsentPageLayoutOwnProps {
  /**
   * The title of the consent page
   */
  title?: string
  /**
   * The subtitle of the consent page
   */
  subtitle?: string | JSX.Element
  /**
   * Consent logos
   */
  logos?: JSX.Element
  /**
   * The title of the accordion (the tab)
   */
  accordionTitle: string
  /**
   * The content of the accordion (the tab)
   */
  accordionContent: JSX.Element
  /**
   * Whether the accordion is open or not. Leave undefined to have it user-controlled
   */
  accordionIsOpen?: boolean
  /**
   * Whether the accordion chevron should be displayed or not
   */
  accordionChevron?: boolean
  /**
   * Footer of the page
   */
  footer: JSX.Element
}

export type IConsentPageLayoutProps = IConsentPageLayoutOwnProps

const ConsentPageLayout: FC<IConsentPageLayoutProps> = ({
  title,
  subtitle,
  logos,
  accordionTitle,
  accordionContent,
  accordionIsOpen,
  accordionChevron = false,
  footer,
}) => {
  const { secondaryColor } = useClientColors()
  return (
    <DefaultPageLayout title={title} subtitle={subtitle} logo={logos}>
      <Container>
        <ScrollingDiv>
          <Accordion themeColor={secondaryColor}>
            <AccordionItem isOpen={accordionIsOpen}>
              <AccordionButton>
                <AccordionTitle
                  data-view="consent-accordion-title"
                  icon={infoCircleSolid}
                  iconLight={infoCircleLight}
                >
                  {accordionTitle}
                </AccordionTitle>
                {accordionChevron && <AccordionChevron />}
              </AccordionButton>
              <AccordionPanel data-view="consent-accordion-panel">
                {accordionContent}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </ScrollingDiv>
      </Container>
      <BottomContainer>
        <ConsentButtons />
        <LegalFooter data-view="consent-footer">{footer}</LegalFooter>
      </BottomContainer>
    </DefaultPageLayout>
  )
}

export default ConsentPageLayout
