import React from 'react'
import { ProviderPageLayout } from '@layouts'
import { usePageContext, useViewport } from '@hooks'
import { Page } from '@models/IPage'
import { FaIcon, exclamationTriangleSolid } from '@assets/icons'
import { Button } from '@components'
import { useSelector, useDispatch } from 'react-redux'
import IStoreState from '@store/IStoreState'
import { cancelClickedAction } from '@actions/general/generalActions'
import { EventType } from '@middlewares/analytics'
import { useTranslation } from 'react-i18next'
import { BodyContainer, Center, ButtonsContainer } from './styles'
import { setLoginStatus } from '@actions/login/loginActions'
import { LoginStatus } from '@models/loginStatus'

const LoginError = () => {
  const { t } = useTranslation('v2_login_error')
  const { changePage } = usePageContext()
  const provider = useSelector((state: IStoreState) => state.providers.selectedProvider)
  const { isMobile } = useViewport()
  const dispatch = useDispatch()

  const handleTryAgain = () => {
    dispatch(setLoginStatus(LoginStatus.InProgress))
    changePage(Page.LOGIN)
  }

  const handleCancel = () => {
    dispatch(cancelClickedAction(EventType.CancelAfterErrorClicked))
  }

  const renderCenter = () => (
    <Center>
      <FaIcon icon={exclamationTriangleSolid} color="#f8ba44" size="3x" />
      <div>{t('error_message')}</div>
    </Center>
  )

  const renderButtons = () => {
    const color = provider.main_button_color || '#32a3df'
    return (
      <ButtonsContainer isMobile={isMobile}>
        <Button variant="secondary" color={color} label={t('cancel')} onClick={handleCancel} />
        <Button variant="primary" color={color} label={t('try_again')} onClick={handleTryAgain} />
      </ButtonsContainer>
    )
  }

  return (
    <ProviderPageLayout headerColor={provider.main_bg_color}>
      <div data-view="page-id-login-error"></div>
      <BodyContainer>
        {renderCenter()}
        {renderButtons()}
      </BodyContainer>
    </ProviderPageLayout>
  )
}

export default LoginError
