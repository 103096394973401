import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const useNavigation = (path: string, locationState: unknown) => {
  const navigate = useNavigate()

  // Effect to change browser path location
  useEffect(() => {
    navigate(`${path}${window.location.search}`, {
      replace: true,
      state: locationState,
    })
  }, [path, locationState, navigate])
}

export default useNavigation
