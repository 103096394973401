import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { searchMinusLight } from '@assets/icons'
import { NotFound } from '@components'

export interface INoBranchFoundProps {
  /**
   * The search term
   */
  search: string
  /**
   * The provider's display name
   */
  providerName: string
  /**
   * Function to execute when user clicks on the action button
   */
  onActionClick: () => void
}

/**
 * This component renders when no branch is found from the branch selection filter
 */
const NoBranchFound = ({
  search,
  providerName,
  onActionClick,
}: PropsWithChildren<INoBranchFoundProps>) => {
  const { t } = useTranslation('v2_branches')
  return (
    <NotFound
      icon={searchMinusLight}
      message={t('not_found_message', { providerName })}
      search={search}
      actionText={t('not_found_action')}
      onActionClick={onActionClick}
    />
  )
}

export default NoBranchFound
