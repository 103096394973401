import React from 'react'
import { Button } from '@components'
import { useTranslation } from 'react-i18next'
import { useClientColors, useViewport } from '@hooks'
import { ButtonsContainer } from './styles'
import useConsent from '../../hooks'
import ConsentAllowButton from '../ConsentAllowButton/ConsentAllowButton'

function ConsentButtons() {
  const { t } = useTranslation('v2_consent')
  const { isMobile } = useViewport()
  const { secondaryColor } = useClientColors()
  const { onCancelClick } = useConsent()

  return (
    <ButtonsContainer>
      {!isMobile && (
        <Button
          label={t('cancel')}
          variant="secondary"
          color={secondaryColor}
          onClick={onCancelClick}
        />
      )}
      <ConsentAllowButton label={t('allow')} />
    </ButtonsContainer>
  )
}

export default ConsentButtons
