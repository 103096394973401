import React, { KeyboardEventHandler, useCallback } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { Icon } from './styles'

interface IconButtonProps extends FontAwesomeIconProps {
  'aria-label': string
  onClick: () => void
  icon: IconProp
}

function IconButton({ 'aria-label': ariaLabel, onClick, icon, ...props }: IconButtonProps) {
  const onKeyDown: KeyboardEventHandler = useCallback(
    (event) => {
      // The action button is activated by space on the keyup event, but the
      // default action for space is already triggered on keydown. It needs to be
      // prevented to stop scrolling the page before activating the button.
      if (event.code === 'Space') {
        event.preventDefault()
      }

      // If enter is pressed, activate the button
      else if (event.code === 'Enter') {
        event.preventDefault()
        onClick()
      }
    },
    [onClick],
  )

  const onKeyUp: KeyboardEventHandler = useCallback(
    (event) => {
      if (event.code === 'Space') {
        onClick()
      }
    },
    [onClick],
  )

  return (
    <Icon
      role="button"
      tabIndex={0}
      // the underlying `FaIcon` component has `aria-hidden=true`
      // so we need to override it to *not* remove it from the a11y tree
      aria-hidden="false"
      aria-label={ariaLabel}
      icon={icon}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      {...props}
    />
  )
}

export default IconButton
