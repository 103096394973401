import React, { PropsWithChildren } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cancelClickedAction } from '@actions/general/generalActions'
import { FaIcon, exclamationTriangleSolid } from '@assets/icons'
import { Button } from '@components'
import { useViewport } from '@hooks'
import { ProviderPageLayout } from '@layouts'
import { EventType } from '@middlewares/analytics'
import IStoreState from '@store/IStoreState'
import { BodyContainer, ButtonsContainer, Center } from './styles'

export interface IErrorProps {
  tryAgain: () => void
}

const Error = ({ tryAgain }: PropsWithChildren<IErrorProps>) => {
  const { isMobile } = useViewport()
  const provider = useSelector((state: IStoreState) => state.providers.selectedProvider)
  const dispatch = useDispatch()

  const handleTryAgain = () => {
    tryAgain()
  }

  const handleCancel = () => {
    dispatch(cancelClickedAction(EventType.CancelAfterErrorClicked))
  }

  const renderCenter = () => (
    <Center>
      <FaIcon icon={exclamationTriangleSolid} color="#f8ba44" size="3x" />
      <div>Sorry, we are experiencing technical difficulties. You may want to try that again?</div>
    </Center>
  )

  const renderButtons = () => {
    const color = provider.main_button_color || '#32a3df'
    return (
      <ButtonsContainer isMobile={isMobile}>
        <Button variant="secondary" color={color} label="cancel" onClick={handleCancel} />
        <Button variant="primary" color={color} label="try again" onClick={handleTryAgain} />
      </ButtonsContainer>
    )
  }
  return (
    <ProviderPageLayout headerColor={provider.main_bg_color}>
      <BodyContainer>
        {renderCenter()}
        {renderButtons()}
      </BodyContainer>
    </ProviderPageLayout>
  )
}

export default Error
