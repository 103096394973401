import { Button, FooterText, IButtonProps } from '@components'
import { device } from '@constants/devices'
import { ContainerStyle, gap, hideScrollBar, upperGradientStyle } from '@styles'
import styled from 'styled-components'

export const StyledButton = styled(Button)<IButtonProps>``

export const ButtonsContainer = styled.div`
  ${ContainerStyle}
  display: flex;
  margin-top: auto;
  flex-direction: column;
  ${gap(16, 'px', 'v')}

  ${device.tablet} {
    flex-direction: row;
    ${gap(0, 'px', 'v')}
    ${gap(10, 'px', 'h')}
  }
`

export const Title = styled.h1`
  text-align: center;
  margin: 24px 0 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  ${ContainerStyle}

  ${device.tablet} {
    font-size: 20px;
    line-height: 30px;
  }
  margin-bottom: 30px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow-y: scroll;
  ${hideScrollBar}
  padding-top: 40px;
`

export const PermissionsList = styled.ul`
  margin-bottom: 20px;
`

export const PermissionListItem = styled.li`
  font-weight: 600;
`

export const BottomContainer = styled.div`
  position: relative;
  ${upperGradientStyle}
  padding-bottom: 32px;
`

export const LegalFooter = styled(FooterText)`
  display: block;
  margin-top: 12px;
  ${ContainerStyle}
  text-align: center;
  color: #2d2d2d;
`
