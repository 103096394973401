import styled from 'styled-components'

const defaultColor = '#bbb'
const borderColor = '#ccc'

export default styled.input`
  appearance: none;
  width: 100%;
  border: 1px solid ${borderColor};
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 200;
  ::placeholder {
    font-weight: 300;
    color: ${defaultColor};
  }
  cursor: text;
  padding: 14px;
  letter-spacing: 1px;
  &:focus {
    outline: none;
  }
`
