/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import IStoreState from '@store/IStoreState'
import * as queryString from 'query-string'
import { setLoginStatus } from '@actions/login/loginActions'
import { getDialogBaseUrl } from '@utils/helpers'
import { LoginStatus } from '@models/loginStatus'
import { redirectToExternalUrl } from '@utils/navigation'

interface pageColors {
  header: string
  button: string
}

const useNexi = () => {
  const [page, setPage] = useState<string>()
  const [nextUrl, setNextUrl] = useState<string>()
  const [colors, setColors] = useState<pageColors>()
  const dispatch = useDispatch()
  const provider = useSelector((state: IStoreState) => state.providers.selectedProvider)

  useEffect(() => {
    // Get page number from url
    const pageNum = queryString.parse(window.location.search).page
    if (pageNum === '1' || pageNum === '2') {
      setPage(pageNum)
    } else {
      dispatch(setLoginStatus(LoginStatus.Fail))
      return
    }

    // Set the redirect url from "next" query
    const url = queryString.parse(window.location.search).next

    // Check same truelayer domain
    const domain = getDialogBaseUrl().split('login.')[1]
    if (url && url.includes(domain)) {
      setNextUrl(url as string)
    } else {
      dispatch(setLoginStatus(LoginStatus.Fail))
      return
    }

    // Set provider colours
    if (provider) {
      setColors({
        header: provider.main_bg_color || '#15195F',
        button: provider.main_button_color || '#2D32AA',
      })
      document.body.style.backgroundColor = provider.main_bg_color || '#15195F'
    } else {
      dispatch(setLoginStatus(LoginStatus.Fail))
    }
  }, [dispatch, provider])

  const redirectToNextUrl = () => {
    redirectToExternalUrl(nextUrl!)
  }

  return {
    page,
    colors,
    provider,
    redirectToNextUrl,
  }
}

export default useNexi
