import styled, { css } from 'styled-components'
import { FaIcon } from '@assets/icons'

const colors = {
  default: '#bbb',
  error: '#c00023',
}

export const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

export const StyledInput = styled.input<{
  isWrong: boolean
  activeColor: string
}>`
  appearance: none;
  width: 100%;
  border: 1px solid ${colors.default};
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 200;
  ::placeholder {
    font-weight: 300;
    color: ${colors.default};
  }
  cursor: text;
  padding: 14px;
  letter-spacing: 1px;

  // add right padding icon
  padding-right: 32px;

  &:focus,
  &:hover {
    outline: none;
    border-color: ${(props) => (props.isWrong ? colors.error : props.activeColor)};
  }
  border-color: ${(props) => (props.isWrong ? colors.error : colors.default)};
`

const IconStyle = css`
  position: absolute;
  right: 0px;
  margin-right: 10px;
  color: ${colors.default};
`

export const ClearIcon = styled(FaIcon)<{ activeColor: string }>`
  ${IconStyle}
  ${StyledInput}:focus ~ & {
    color: ${(props) => props.activeColor};
  }
  cursor: pointer;
`

export const ErrorIcon = styled(FaIcon)`
  ${IconStyle}
  color: ${colors.error};
`

export const Label = styled.div<{
  error: boolean
  isFocused: boolean
  activeColor: string
}>`
  font-size: 11px;
  margin-left: 12px;
  margin-top: 4px;
  color: ${(props) =>
    props.error ? colors.error : props.isFocused ? props.activeColor : colors.default};
  white-space: pre-line;
`
