import React, { ReactNode } from 'react'
import { useClientColors } from '@hooks'
import { useSelector } from 'react-redux'
import IStoreState from '@store/IStoreState'
import { showAuthorisationBanner } from '@utils/authorisation'
import { Container, Page } from './styles'
import { useScale } from '@contexts/scale'

const EmptyPageLayout = ({ children }: { children: ReactNode }) => {
  const { primaryColor } = useClientColors()
  const { client } = useSelector((state: IStoreState) => state)
  const dataApiPlan = client.clientSettings.data_api_plan
  const { verification_api_plan } = client.clientSettings
  const { scale } = useScale()

  return (
    <Page bgColor={primaryColor} $height={scale?.height} $width={scale?.width}>
      <Container
        data-view="empty-page-layout"
        $scale={scale?.scale}
        $height={scale?.height}
        $width={scale?.width}
        isAuthorised={!showAuthorisationBanner({
          verificationApiPlan: verification_api_plan,
          dataApiPlan,
          scopes: client.publicScope,
        })}
      >
        {children}
      </Container>
    </Page>
  )
}

export default EmptyPageLayout
