import styled from 'styled-components'
import { FaIcon } from '@assets/icons'
import { device } from '@constants/devices'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  padding: 60px 30px;
  ${device.tablet} {
    padding: 60px 24%;
  }
  text-align: center;
`

export const Message = styled.div<{ page: string }>`
  font-size: 14px;
  width: 100%;
`

export const LockIcon = styled(FaIcon)`
  margin-right: 4px;
  width: 10px !important;
  height: 10px !important;
`

export const LowerMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 11px;
`

export const Button = styled.button<{ bgColor: string }>`
  height: 40px;
  width: 172px;
  border-radius: 5px;
  background-color: ${(props) => props.bgColor};
  color: white;
  text-transform: uppercase;
  font-size: 13px;
  border: none;
  letter-spacing: 1.5pt;
  margin: 35px 0 10px 0;
`
