import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components/macro'

import { NoResults as NoResultsContainer } from './styles'

export function NoResults({ search }: { search: string }) {
  const { illustration } = useTheme()
  const { t } = useTranslation('end_user_components')
  return (
    <NoResultsContainer>
      <NoProviderFoundSvg color={illustration.color} />
      <p>
        {t('form.select.no.results')}
        <br />
        &#34;{search}&#34;
      </p>
    </NoResultsContainer>
  )
}

function NoProviderFoundSvg({ color }: { color?: string }): JSX.Element {
  return (
    <svg
      width="100%"
      height="260"
      viewBox="0 0 390 260"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="390" height="260" fill="white" />
      <path
        d="M307.265 220.443L280.615 193.845C279.955 193.406 279.294 192.966 278.633 192.966H275.77C282.598 185.053 287.003 174.502 287.003 163.071C287.003 138.012 266.3 117.35 241.192 117.35C215.864 117.35 195.381 138.012 195.381 163.071C195.381 188.35 215.864 208.793 241.192 208.793C252.644 208.793 262.996 204.616 271.145 197.802V200.66C271.145 201.319 271.365 201.979 271.806 202.638L298.455 229.236C299.556 230.335 301.318 230.335 302.199 229.236L307.265 224.18C308.366 223.301 308.366 221.542 307.265 220.443ZM241.192 198.242C221.59 198.242 205.953 182.635 205.953 163.071C205.953 143.728 221.59 127.901 241.192 127.901C260.573 127.901 276.431 143.728 276.431 163.071C276.431 182.635 260.573 198.242 241.192 198.242Z"
        fill={color}
      />
      <path
        d="M245.923 162.796L257.31 151.536C257.942 150.905 257.942 149.747 257.31 149.116L254.674 146.485C254.041 145.854 252.882 145.854 252.249 146.485L240.967 157.85L229.58 146.485C228.948 145.854 227.788 145.854 227.155 146.485L224.519 149.116C223.887 149.747 223.887 150.905 224.519 151.536L235.906 162.796L224.519 174.161C223.887 174.792 223.887 175.95 224.519 176.581L227.155 179.212C227.788 179.843 228.948 179.843 229.58 179.212L240.967 167.847L252.249 179.212C252.882 179.843 254.041 179.843 254.674 179.212L257.31 176.581C257.942 175.95 257.942 174.792 257.31 174.161L245.923 162.796Z"
        fill={color}
      />
      <path
        d="M232.545 110.196V94.951H222.964V112.221C226.048 111.258 229.251 110.574 232.545 110.196Z"
        fill={color}
      />
      <path
        d="M203.803 123.016V94.951H194.223V134.044C196.92 129.939 200.147 126.228 203.803 123.016Z"
        fill={color}
      />
      <path
        d="M187.584 148.205H175.062V94.951H165.482V148.205H146.321V94.951H136.741V148.205H124.766C120.574 148.205 117.58 151.533 117.58 155.466V167.57H112.79C110.096 167.57 108 169.99 108 172.411V174.831C108 176.344 108.898 177.252 110.395 177.252H186.634C185.902 174.121 185.434 170.884 185.254 167.57H127.161V157.887H185.558C185.95 154.558 186.634 151.321 187.584 148.205Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M189.134 42.6052L258.89 73.1654C260.088 73.7706 261.286 75.2835 261.286 76.7964V79.217C261.286 81.335 259.489 82.8479 257.693 82.8479H251.705V86.4788C251.705 88.5969 249.909 90.1098 248.113 90.1098H121.173C119.077 90.1098 117.58 88.5969 117.58 86.4788V82.8479H111.593C109.497 82.8479 108 81.335 108 79.217V76.7964C108 75.2835 108.898 73.7706 110.096 73.1654L179.853 42.6052C181.35 42 182.846 42 184.643 42C186.14 42 187.637 42 189.134 42.6052ZM127.161 76.4938V80.4273H242.125V76.4938L185.84 51.6825H183.146L127.161 76.4938Z"
        fill={color}
      />
    </svg>
  )
}
