import React, { forwardRef } from 'react'
import { Root } from './styles'
import { ButtonLoader } from '@components/common/styles'

interface IButtonOwnProps {
  /**
   * The text inside the button area
   */
  label: string
  /**
   * Function to execute when clicking on the button
   */
  onClick: () => void
  /**
   * Unique ID for testing
   */
  dataView?: string
  className?: string
}

export interface IButtonStyleProps {
  /**
   * The button's main colour
   */
  color: string
  /**
   * The button variant
   */
  variant: 'primary' | 'secondary' | 'tertiary'
  /**
   * Whether the button is disabled or not
   */
  disabled?: boolean
  /**
   * Show loader
   */
  loading?: boolean
  /**
   * The associated type
   */
  type?: 'button' | 'submit' | 'reset'
  /**
   * The associated form's ID (used if button outside of the `form` tree)
   */
  form?: string
}

export type IButtonProps = IButtonOwnProps & IButtonStyleProps

/**
 * A component to engage user click action
 */
const Button = forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {
  const {
    label,
    color,
    variant,
    dataView,
    form,
    disabled = false,
    loading = false,
    type = 'button',
    onClick,
    className,
  } = props

  return (
    <Root
      ref={ref}
      $color={color}
      $variant={variant}
      onClick={onClick}
      disabled={disabled || loading}
      type={type}
      // prevent passing the `loading` attribute onto the underlying HTML
      // element by using "transient props" in Styled Components
      // https://styled-components.com/docs/api#transient-props
      $loading={loading}
      data-view={dataView}
      form={form}
      className={className}
    >
      <div>{label}</div>
      {loading && <ButtonLoader />}
    </Root>
  )
})

export default Button
