import { css } from 'styled-components'
import { device } from '@constants/devices'

export const hideScrollBar = css`
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
`

// Simulate the gap property in a flexbox
// We can't use gap because of cross browser compatibility
export const gap = (gap: number, unit: 'px' | '%', direction: 'h' | 'v') => {
  const value = `${gap}${unit}`
  const property = direction === 'h' ? 'margin-left' : 'margin-top'
  return css`
    > *:not(:first-child) {
      ${property}: ${value} !important;
    }
  `
}

export const CONTAINER_SIDE_PADDING = 16
export const CONTAINER_SIDE_PADDING_DESKTOP = 65

export const ContainerStyle = css`
  position: relative;
  padding-left: ${CONTAINER_SIDE_PADDING}px;
  padding-right: ${CONTAINER_SIDE_PADDING}px;
  ${device.tablet} {
    padding-left: ${CONTAINER_SIDE_PADDING_DESKTOP}px;
    padding-right: ${CONTAINER_SIDE_PADDING_DESKTOP}px;
  }
`

// This hides content visually but keeps it for screen readers
export const VisuallyHidden = css`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const trackColor = 'transparent'
const thumbColor = '#aaa'
export const SCROLLBAR_WIDTH = 6

// Scrollbar styling for all browsers
export const scrollbarStyle = css`
  /* Firefox */
  scrollbar-color: ${thumbColor} ${trackColor};
  scrollbar-width: thin;

  /* Other browsers */
  ::-webkit-scrollbar {
    width: ${SCROLLBAR_WIDTH}px;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${trackColor};
  }
  ::-webkit-scrollbar-thumb {
    background: ${thumbColor};
    border-radius: 3px;
  }
`

export const upperGradientStyle = css`
  &:before {
    content: '';
    display: block;
    position: absolute;
    background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    height: 24px;
    width: 100%;
    top: -24px;
  }
`