import React from 'react'
import { EmptyPageLayout } from '@layouts'
import { useDispatch, useSelector } from 'react-redux'
import { cancelClickedAction, proceedFromSplashScreen } from '@actions/general/generalActions'
import { sendSplashScreenProceed } from '@actions/analytics'
import { EventType } from '@middlewares/analytics'
import { Button, FooterLink, LinkingLogos, TLLogo } from '@components'
import IStoreState from '@store/IStoreState'
import { BottomContainer, ButtonsContainer, Container, LegalFooter, Title } from './styles'
import { useClientColors, useStateRestore, useViewport } from '@hooks'
import { TL_EU_REFERENCE_NUMBER, TL_EU_REFERENCE_URL } from '@constants/legal'
import { Trans, useTranslation } from 'react-i18next'
import { Page } from '@models/IPage'

const SplashScreen = () => {
  useStateRestore(Page.SPLASH_SCREEN)
  const dispatch = useDispatch()

  const { secondaryColor } = useClientColors()

  const clientLogo = useSelector((state: IStoreState) => state.client.clientSettings.logo_uri)

  const { t } = useTranslation('splash_screen')

  const { isMobile } = useViewport()

  const onCancelClick = () => {
    dispatch(cancelClickedAction(EventType.SplashScreenCancelled))
  }

  const onProceedClick = () => {
    dispatch(sendSplashScreenProceed())
    dispatch(proceedFromSplashScreen())
  }

  const continueButton = (
    <Button
      label={t('button.continue')}
      variant="primary"
      color={secondaryColor}
      onClick={onProceedClick}
      dataView="button-continue"
    />
  )

  const cancelButton = (
    <Button
      label={t('button.cancel')}
      variant="secondary"
      color={secondaryColor}
      onClick={onCancelClick}
      dataView="button-cancel"
    />
  )

  return (
    <EmptyPageLayout>
      <div data-view="page-id-splash-screen"></div>
      <Container>
        <LinkingLogos
          leftLogo={clientLogo}
          rightLogo={<TLLogo color="yellow" height={26} width={26} />}
        />
        <Title data-view="title">{t('title')}</Title>
        <p data-view="description-bank">{t('description.bank')}</p>
        <p data-view="description-process">
          <Trans ns="splash_screen" i18nKey="description.process">
            <strong>Identity verification</strong> is a one time process that may take up to{' '}
            <strong>3 mins</strong>.
          </Trans>
        </p>
      </Container>
      <BottomContainer>
        <ButtonsContainer>
          {isMobile ? [continueButton, cancelButton] : [cancelButton, continueButton]}
        </ButtonsContainer>
        <LegalFooter data-view="footer">
          {t('footer')}{' '}
          <FooterLink href={TL_EU_REFERENCE_URL}>FRN {TL_EU_REFERENCE_NUMBER}</FooterLink>
        </LegalFooter>
      </BottomContainer>
    </EmptyPageLayout>
  )
}

export default SplashScreen
