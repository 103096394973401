import styled from 'styled-components'
import { FaIcon } from '@assets/icons'

export const Overlay = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
`

export const Modal = styled.div`
  display: flex;
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: white;
  width: 80%;
  min-height: 120px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  padding: 35px;
`

export const CloseButton = styled(FaIcon)`
  position: absolute;
  background: none;
  top: 0;
  right: 0;
  margin: 20px;
  font-size: 20px;
`
