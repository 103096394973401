import { device } from '@constants/devices'
import styled from 'styled-components'
import IconButton from '@components/IconButton'

export const Container = styled.div`
  position: relative;
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  padding: 15px;
  margin: 0;
  min-height: 80px;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  background: #d7851c;
  z-index: 1000;
  color: white;
  ${device.mobile} {
    height: 10vh;
  }
`

export const Warning = styled.div`
  display: flex;
`

export const Message = styled.h6`
  font-size: 10px;
  margin-left: 10px;
`

export const Title = styled.h5`
  font-size: 14px;
  margin-left: 10px;
`

export const EmailLink = styled.a`
  color: #ffffff;
  text-decoration: underline;

  &:hover {
    color: #ffffff;
    text-decoration: none;
  }
`

export const CloseIcon = styled(IconButton)`
  margin-left: 10px;
`
