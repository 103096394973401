/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import * as queryString from 'query-string'
import { errorInfoRequest } from '@middlewares/api'
import { useDispatch } from 'react-redux'
import { sendFailedToInitialize } from '@actions/analytics'

const useClientError = () => {
  const defaultMessage =
    'Oops! Something went wrong with your auth link, please check and try again'

  // Error code, message and TL correlation ID to be fetched from errorInfoRequest
  const [errorMessage, setErrorMessage] = useState<string>()

  const dispatch = useDispatch()

  useEffect(() => {
    const fetchErrorInfo = async () => {
      const errorId = queryString.parse(window.location.search).errorId as string
      try {
        if (errorId) {
          const response = await errorInfoRequest(errorId)
          const { error, error_description } = response.data
          const tlCorrelationId = response.headers['x-tl-correlation-id']
          if (error) {
            setErrorMessage(error_description)
            dispatch(sendFailedToInitialize('misconfigured_auth_link', error, tlCorrelationId))
          }
          return
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
      setErrorMessage(defaultMessage)
    }
    fetchErrorInfo()
  }, [])

  return errorMessage
}

export default useClientError
