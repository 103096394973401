import * as React from 'react'

import { Spinner } from '@components'

const AppLoader = () => (
  <div className="AppLoader">
    <Spinner containerPosition="absolute" containerHeight="100vh" containerWidth="100vw" />
  </div>
)

export default AppLoader
