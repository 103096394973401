import React from 'react'
import { useViewport, usePageContext, useClientColors, useAlternativeFlow } from '@hooks'
import { Button } from '@components'
import { useSelector } from 'react-redux'
import IStoreState from '@store/IStoreState'
import { useTranslation } from 'react-i18next'
import {
  Overlay,
  Container,
  Header,
  Body,
  MobileContainer,
  ContentContainer,
  MobileQuestion,
  MobileButtonsContainer,
  DesktopTitle,
  DesktopQuestions,
  DesktopButtonsContainer,
  AlternativeFlowButton,
} from './styles'

const CloseOverlay = () => {
  const { t } = useTranslation('v2_close_overlay')
  const { isMobile } = useViewport()
  const { confirmCloseApp, cancelCloseApp } = usePageContext()
  const { primaryColor, secondaryColor } = useClientColors()
  const clientName = useSelector((state: IStoreState) => state.client.clientSettings.client_name)
  const { clientHasAlternativeFlow, ctaCopy, triggerAlternativeFlow } = useAlternativeFlow()

  const renderButtons = () => (
    <>
      {clientHasAlternativeFlow ? (
        <AlternativeFlowButton
          label={ctaCopy}
          variant="secondary"
          color={secondaryColor}
          onClick={triggerAlternativeFlow}
          dataView="close-overlay-alternative-flow-button"
        />
      ) : (
        <Button
          label={t('yes_close')}
          variant="secondary"
          color={secondaryColor}
          onClick={confirmCloseApp}
          dataView="close-overlay-close-button"
        />
      )}
      <Button
        label={t('no_continue')}
        variant="primary"
        color={secondaryColor}
        onClick={cancelCloseApp}
        dataView="close-overlay-continue-button"
      />
    </>
  )

  const renderDesktopContent = () => {
    const question = clientHasAlternativeFlow
      ? t('do_you_want_to_go_back_different_method_of_verification')
      : t('do_you_want_to_go_back', { clientName })
    return (
      <>
        <Header bgColor={primaryColor}>{t('close_and_go_back')}</Header>
        <Body>
          <DesktopQuestions>
            <DesktopTitle>{t('are_you_sure')}</DesktopTitle>
            <div>{question}</div>
          </DesktopQuestions>
          <DesktopButtonsContainer>
            <DesktopButtonsContainer>{renderButtons()}</DesktopButtonsContainer>
          </DesktopButtonsContainer>
        </Body>
      </>
    )
  }

  const renderMobileContent = () => {
    const question = clientHasAlternativeFlow
      ? t('do_you_want_to_go_back_different_method_of_verification')
      : t('are_you_sure_you_want_to_close', { clientName })
    return (
      <MobileContainer>
        <ContentContainer>
          <MobileQuestion>{question}</MobileQuestion>
          <MobileButtonsContainer>{renderButtons()}</MobileButtonsContainer>
        </ContentContainer>
      </MobileContainer>
    )
  }

  return (
    <Overlay>
      <Container>
        {isMobile && renderMobileContent()}
        {!isMobile && renderDesktopContent()}
      </Container>
    </Overlay>
  )
}

export default CloseOverlay
