import { useClientColors } from '@hooks'
import { EndUserComponentsProvider as EndUserComponentsProviderImpl } from '../../end-user-components'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

export const EndUserComponentsProvider = ({ children }: { children: ReactNode }) => {
  const { i18n } = useTranslation()

  const { primaryColor, secondaryColor, tertiaryColor } = useClientColors()

  return (
    <EndUserComponentsProviderImpl
      theme={{
        customPalette: {
          c_primary: primaryColor,
          c_secondary: secondaryColor,
          c_tertiary: tertiaryColor,
        },
      }}
      language={i18n.language}
    >
      {children}
    </EndUserComponentsProviderImpl>
  )
}
