import Fuse from 'fuse.js'

import {
  flatAndUnique,
  normaliseWord,
  uniqueOptionsItem,
} from '../components/form/SelectableList/utils'
import { useMemo } from 'react'
import { OptionItem } from '../components/form/types'
import { ExtendedSearch } from '../components/form/SelectableList/SelectableListBase'

function useFilteredOptions<T>(
  search: string,
  _options: OptionItem<T>[],
  fuzzySearchOptions?: Omit<Fuse.IFuseOptions<OptionItem<T>>, 'keys'>,
  extendedSearch?: ExtendedSearch<T>[],
) {
  const options = useMemo(() => uniqueOptionsItem(_options), [_options])

  const flatOptions = useMemo(() => flatAndUnique(options), [options])

  const fuse = useMemo(
    () =>
      new Fuse(flatOptions, {
        useExtendedSearch: true,
        threshold: 0.4,
        keys: ['normalisedName', 'label', 'search_aliases'],
        ...fuzzySearchOptions,
      }),
    [fuzzySearchOptions, flatOptions],
  )

  const filteredOptions = useMemo(() => {
    if (search === '') return options
    const normalizedSearch = normaliseWord(search)

    if (extendedSearch && extendedSearch.length > 0) {
      const rule = extendedSearch.find((rule) => rule.match.test(normalizedSearch))
      if (rule) {
        return rule.action(normalizedSearch, flatOptions, fuse)
      }
    }
    return fuse.search(normalizedSearch).map((result) => result.item)
  }, [extendedSearch, flatOptions, fuse, options, search])

  return { filteredOptions, flatOptions }
}

export default useFilteredOptions
