import React, { PropsWithChildren } from 'react'
import { Header } from '@components'
import { EmptyPageLayout } from '@layouts'
import { Title, Body, Subtitle, TitlesContainer } from './styles'

export interface IDefaultPageLayoutProps {
  /**
   * The title of the page
   */
  title?: string
  /**
   * The logo to be displayed in the middle of the top header
   */
  logo?: string | JSX.Element
  /**
   * Use this to dynamically hide the title
   */
  hideTitle?: boolean
  /**
   * Override default behaviour of GoBack
   */
  onClickGoBackOverride?: () => void
  /**
   * The subtitle of the page
   */
  subtitle?: string | JSX.Element
}

/**
 * This page layout is used by most pages in the Auth Dialog
 */
const DefaultPageLayout = ({
  title,
  children,
  logo,
  hideTitle = false,
  onClickGoBackOverride,
  subtitle,
}: PropsWithChildren<IDefaultPageLayoutProps>) => (
  <EmptyPageLayout>
    <Header logo={logo} onClickGoBackOverride={onClickGoBackOverride} />
    <Body isLogoPresent={!!logo}>
      <TitlesContainer>
        {!hideTitle && <Title data-view="page-title">{title}</Title>}
        {!!subtitle && <Subtitle data-view="page-subtitle">{subtitle}</Subtitle>}
      </TitlesContainer>
      {children}
    </Body>
  </EmptyPageLayout>
)

export default DefaultPageLayout
