import { useSelector } from 'react-redux'
import IStoreState from '@store/IStoreState'
import { isCountrySelectionStepEnabled } from '@utils/country'
import IProvider from '@models/IProvider'

const selector = (state: IStoreState) => ({
  providers: state.providers.items,
  selectedCountry: state.countries.countrySelected,
})

const useCountryProviders = () => {
  const { providers, selectedCountry } = useSelector(selector)

  const isCountryStepEnabled = isCountrySelectionStepEnabled(providers)

  const countryCode = isCountryStepEnabled ? selectedCountry : providers[0].country
  const countryProviders = isCountryStepEnabled
    ? providers.filter((p: IProvider) => p.country === countryCode)
    : providers

  return { providers: countryProviders, countryCode }
}

export default useCountryProviders
