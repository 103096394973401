import usePage from './usePage'
import usePath from './usePath'
import useCloseApp from './useCloseApp'
import useNavigation from './useNavigation'

// Hook to manage all page operations
const usePageManager = () => {
  // Hook that manages app closing related states & actions
  const { showCloseOverlay, closeApp, cancelCloseApp, confirmCloseApp } = useCloseApp()

  // Hook that updates the page
  const { page, isFirstPage, previousPage, changePage, locationState, goBack, goBackToPage } =
    usePage(closeApp)

  // Hook that updates the browser path
  const path = usePath(page)

  // Hook that changes browser location
  useNavigation(path, locationState)

  return {
    page,
    isFirstPage,
    previousPage,
    locationState,
    goBack,
    goBackToPage,
    changePage,
    showCloseOverlay,
    closeApp,
    cancelCloseApp,
    confirmCloseApp,
  }
}

export default usePageManager
