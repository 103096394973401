/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { lockAltSolid } from '@assets/icons'
import ProviderPageLayout from '@layouts/ProviderPageLayout'
import { Message, Button, LowerMessage, LockIcon, Container } from './styles'
import { ProgressTrack } from './components'
import useNexi from './hooks'

const Nexi = () => {
  const { page, colors, provider, redirectToNextUrl } = useNexi()

  const renderMessage = () => (
    <Message page={page!}>
      {page === '1'
        ? `Per consultare le tue informazioni bancarie, dovrai loggarti su ${provider.display_name} due volte.`
        : 'Per consentire la consultazione del tuo saldo e dei tuoi movimenti bancari, effettua nuovamente il login.'}
    </Message>
  )

  const renderProgressTrack = () => <ProgressTrack active={page!} />
  const renderButton = () => (
    <Button bgColor={colors!.button} onClick={redirectToNextUrl}>
      Ok, procedi
    </Button>
  )

  const renderLowerMessage = () => (
    <LowerMessage>
      <LockIcon icon={lockAltSolid} />
      <div>Accesso Protetto</div>
    </LowerMessage>
  )

  return colors && page ? (
    <ProviderPageLayout bgColor="rgba(45, 45, 45, 0.85)" headerColor={colors.header}>
      <Container>
        {renderMessage()}
        {renderProgressTrack()}
        {renderButton()}
        {renderLowerMessage()}
      </Container>
    </ProviderPageLayout>
  ) : null
}

export default Nexi
