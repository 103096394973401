import * as React from 'react'
import IProvider from '../../models/IProvider'

interface ProviderLogoProps {
  provider: IProvider
  useWhiteVersion?: boolean
  useWhiteIcon?: boolean
}

class ProviderLogo extends React.Component<ProviderLogoProps> {
  static defaultProps = {
    useWhiteVersion: false,
    useWhiteIcon: false,
  }

  public render() {
    return <div className="provider-logo">{this.renderUrl()}</div>
  }

  private renderUrl() {
    const { provider } = this.props

    const url = this.getUrl()
    return url ? <img src={url} alt={provider.display_name} height="100%" /> : null
  }

  private getUrl() {
    const { provider, useWhiteVersion, useWhiteIcon } = this.props
    return useWhiteIcon
      ? provider.white_icon_url || provider.alternative_url
      : useWhiteVersion
      ? provider.white_logo_url || provider.logo_url
      : provider.logo_url
  }
}

export default ProviderLogo
