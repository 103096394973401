import { useState, useEffect } from 'react'

const useAnimatedEllipsis = (dotNumber: number, duration: number) => {
  const [animatedDotNumber, setAnimatedDotNumber] = useState(0)
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (animatedDotNumber >= dotNumber) {
        setAnimatedDotNumber(0)
      } else {
        setAnimatedDotNumber((n) => n + 1)
      }
    }, Math.ceil(duration / dotNumber))

    return () => clearTimeout(timeout)
  }, [animatedDotNumber, dotNumber, duration])

  return animatedDotNumber
}

export default useAnimatedEllipsis
