import { Button, ButtonLink } from '@components'
import { useClientColors } from '@hooks'
import React, { useMemo } from 'react'
import useConsent from '../../hooks'
import { isInIframe } from '@utils/helpers'

function ConsentAllowButton({ label }: { label: string }) {
  const { secondaryColor } = useClientColors()
  const { onAllowClick, hiddenAuthUriFinished, preLoadedUrl } = useConsent()

  const element = useMemo(() => {
    const commonProps = {
      label,
      variant: 'primary',
      color: secondaryColor,
      onClick: onAllowClick,
      dataView: 'button-allow',
    } as const

    // If the loading hasn't finished or there is no `preLoadedUrl`
    // (no `preLoadedUrl` signifies that there will be an additional step in
    // the auth dialog and we won't redirect the user to the provider right away),
    // we display a `button` HTML element
    if (!hiddenAuthUriFinished || preLoadedUrl === null) {
      return <Button {...commonProps} loading={!hiddenAuthUriFinished} />
    }

    const iframeProps = isInIframe() ? { target: '_parent' } : {}

    // If we have the `preLoadedUrl` ready, it means that after clicking
    // the Allow button, the user will be redirected to the provider, hence we
    // display a `ButtonLink` which renders an `a` HTML tag and pass the
    // `preLoadedUrl` to its `href` attributes
    return (
      <ButtonLink href={preLoadedUrl} {...commonProps} {...iframeProps}>
        {label}
      </ButtonLink>
    )
  }, [hiddenAuthUriFinished, label, onAllowClick, preLoadedUrl, secondaryColor])

  return element
}

export default ConsentAllowButton
