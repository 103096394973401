import { useId } from '@hooks'
import React, {
  Children,
  cloneElement,
  createContext,
  isValidElement,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react'
import { TabContainer, TabListContainer, TabPanelContainer, TabsContainer } from './styles'

const TabsContext = createContext<{
  selectedIndex: number
  themeColor: string
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>
  baseId: string // `tabId` and `tabPanelId` are derived from this value
} | null>(null)

const createTabId = ({ baseId, index }: { baseId: string; index: number }) =>
  `${baseId}-tab-${index}`

const createTabPanelId = ({ baseId, index }: { baseId: string; index: number }) =>
  `${baseId}-tab-panel-${index}`

export function useTabs() {
  const tabsContext = useContext(TabsContext)

  if (tabsContext === null) {
    throw new Error(
      '`TabsContext` is equal to `null`. It seems like you forgot to wrap your component in the `Tabs` component.',
    )
  }

  return tabsContext
}

export function Tabs({
  children,
  /**
   *  The color of the highlighted UI elements (usually the client's secondary color)
   */
  themeColor,
  className,
}: {
  children: ReactNode
  themeColor: string
  className?: string
}) {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const baseId = useId()

  return (
    <TabsContext.Provider
      value={{
        selectedIndex,
        themeColor,
        setSelectedIndex,
        baseId,
      }}
    >
      <TabsContainer className={className}>{children}</TabsContainer>
    </TabsContext.Provider>
  )
}

export function TabList({ children }: { children: ReactNode }) {
  const mappedChildren = useMemo(
    () =>
      Children.map(children, (child, index) => {
        if (!isValidElement<TabProps>(child)) {
          return child
        }

        return cloneElement(child, { index })
      }),
    [children],
  )

  const { setSelectedIndex } = useTabs()
  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (!Array.isArray(mappedChildren)) {
        return
      }
      switch (e.key) {
        case 'Home':
          setSelectedIndex(0)
          break
        case 'End':
          setSelectedIndex(mappedChildren.length - 1)
          break
        case 'ArrowLeft':
          setSelectedIndex((i) => (i + mappedChildren.length - 1) % mappedChildren.length)
          break
        case 'ArrowRight':
          setSelectedIndex((i) => (i + 1) % mappedChildren.length)
          break
      }
    },
    [mappedChildren, setSelectedIndex],
  )

  return (
    <TabListContainer onKeyDown={onKeyDown} role="tablist">
      {mappedChildren}
    </TabListContainer>
  )
}

type TabProps = { children: ReactNode; index?: number }

export function Tab({ children, index = -1 }: TabProps) {
  const { setSelectedIndex, selectedIndex, themeColor, baseId } = useTabs()
  const tabId = createTabId({ baseId, index })
  const tabPanelId = createTabPanelId({ baseId, index })

  const isActive = index === selectedIndex

  const onClick = useCallback(() => {
    setSelectedIndex(index)
  }, [index, setSelectedIndex])

  // only the active tab should be "tabbable" (see https://www.w3.org/TR/wai-aria-practices/#kbd_roving_tabindex)
  const tabIndexProps = isActive ? {} : { tabIndex: -1 }

  return (
    <TabContainer
      role="tab"
      id={tabId}
      aria-selected={isActive}
      aria-controls={tabPanelId}
      isActive={isActive}
      activeColor={themeColor}
      onClick={onClick}
      {...tabIndexProps}
    >
      {children}
    </TabContainer>
  )
}

export function TabPanels({ children }: { children: ReactNode }) {
  return (
    <>
      {Children.map(children, (child, index) => {
        if (!isValidElement<TabPanelProps>(child)) {
          return child
        }

        return cloneElement(child, { index })
      })}
    </>
  )
}

type TabPanelProps = {
  children: ReactNode
  index?: number
  onActive?: () => void
  className?: string
}

export function TabPanel({ children, index = -1, onActive, className }: TabPanelProps) {
  const { selectedIndex, baseId } = useTabs()
  const isActive = index === selectedIndex
  const tabId = createTabId({ baseId, index })
  const tabPanelId = createTabPanelId({ baseId, index })

  useEffect(() => {
    if (isActive && onActive) {
      onActive()
    }
  }, [isActive, onActive])

  return (
    <TabPanelContainer
      role="tabpanel"
      id={tabPanelId}
      aria-labelledby={tabId}
      tabIndex={0}
      hidden={!isActive}
      className={className}
    >
      {children}
    </TabPanelContainer>
  )
}
