import { useCallback, useEffect, useRef } from 'react'

const useClickOutside = (callback: () => void) => {
  const ref = useRef<HTMLDivElement>(null)

  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        callback()
      }
    },
    [callback],
  )
  useEffect(() => {
    document.addEventListener('click', clickListener, true)
    return () => {
      document.removeEventListener('click', clickListener, true)
    }
  }, [clickListener])
  return ref
}

export default useClickOutside
