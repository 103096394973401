import styled from 'styled-components'

export const TextQuestion = styled.div`
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 10px;
`

export const ImagesContainer = styled.div`
  margin-bottom: 25px;
  text-align: center;
`

export const ImageQuestion = styled.img``
