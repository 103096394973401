import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { saveClientAttributes, saveClientConfiguration } from '@actions/client/clientActions'
import { useViewport } from '@hooks'
import { EmptyPageLayout } from '@layouts'
import {
  convertClientAttributesFromCustomisation,
  getCustomisationFromMessageEvent,
} from '@models/customisation'
import { isCustomisationValid } from '@utils/customisation'
import { getConsoleBaseUrl } from '@utils/helpers'
import ConsentOverview from './components/ConsentOverview'
import { minMainContainerTabletHeight } from './constants'
import { Container } from './styles'
import { useScale } from '@contexts/scale'

declare global {
  interface Window {
    Cypress: unknown
  }
}

const Overview = () => {
  const dispatch = useDispatch()

  const { height } = useViewport()

  const messageReceived = useCallback(
    (e: MessageEvent) => {
      if (e.data.type && (e.data.type as string).includes('webpack')) {
        return
      }

      if (window.Cypress || e.origin === getConsoleBaseUrl()) {
        const customisation = getCustomisationFromMessageEvent(e)
        if (customisation && isCustomisationValid(customisation)) {
          if (customisation.logo_uri instanceof Blob) {
            const blob = new Blob([customisation.logo_uri])
            customisation.logo_uri = URL.createObjectURL(blob)
          }

          dispatch(saveClientConfiguration(customisation))
          dispatch(saveClientAttributes(convertClientAttributesFromCustomisation(customisation)))
        }
      }
    },
    [dispatch],
  )

  useEffect(() => {
    window.addEventListener('message', messageReceived)
    window.parent.postMessage(
      {
        type: '[TRUELAYER]',
        message: 'AUTH_DIALOG_MOUNT',
        pages: [
          { key: 'CONSENT', label: 'Consent' },
          { key: 'PROVIDER_SELECTION', label: 'Provider selection' },
        ],
      },
      '*',
    )

    return () => {
      window.removeEventListener('message', messageReceived)
    }
  }, [messageReceived])

  const { setScale } = useScale()

  useEffect(() => {
    setScale({ scale: height < minMainContainerTabletHeight ? 0.75 : 1.0 })
  })

  return (
    <Container>
      <EmptyPageLayout>
        <div data-view="page-id-overview"></div>
        <ConsentOverview />
      </EmptyPageLayout>
    </Container>
  )
}

export default Overview
