import { FALLBACK_LANG } from '@utils/i18n'

export const TL_FCA_URL = 'https://register.fca.org.uk/s/firm?id=0014G00002UxKoUQAV'
export const TL_FCA_NUMBER = 901096
export const TL_TOS_URL = 'https://truelayer.com/legal/enduser_tos/'
export const TL_PRIVACY_URL = 'https://truelayer.com/legal/privacy/'

export const TL_EU_REFERENCE_URL =
  'http://registers.centralbank.ie/FirmDataPage.aspx?firmReferenceNumber=C433487'
export const TL_EU_REFERENCE_NUMBER = 'C433487'

const TL_EU_LEGAL_URLS = {
  en: {
    privacy: 'https://truelayer.com/en-ie/legal/privacy/',
    tos: 'https://truelayer.com/en-ie/legal/enduser_tos/',
  },
  de: {
    privacy: 'https://truelayer.com/de-de/rechtliche-hinweise/datenschutzerklarung/',
    tos: 'https://truelayer.com/de-de/rechtliche-hinweise/nutzungsbedingungen-fur-endbenutzer/',
  },
  es: {
    privacy: 'https://truelayer.com/es-es/legal/aviso-de-privacidad/',
    tos: 'https://truelayer.com/es-es/legal/condiciones-del-servicio-para-el-usuario-final/',
  },
  fr: {
    privacy: 'https://truelayer.com/fr-fr/legal/politique-de-confidentialite/',
    tos: 'https://truelayer.com/fr-fr/legal/conditions-generales-dutilisation/',
  },
}

// the `language` param comes from i18n and typically looks like this:
// `['de-AT', 'de', 'en']` (going from the most specific lang to the fallback lang)
export const getLocaleEuLegalUrls = ({ languages }: { languages: readonly string[] }) => {
  const isLanguageSupported = (language: string): language is keyof typeof TL_EU_LEGAL_URLS =>
    language in TL_EU_LEGAL_URLS

  const matchedLang = languages.find(
    (lang) => isLanguageSupported(lang) && TL_EU_LEGAL_URLS[lang],
  ) as keyof typeof TL_EU_LEGAL_URLS | undefined

  return TL_EU_LEGAL_URLS[matchedLang ?? FALLBACK_LANG] as { privacy: string; tos: string }
}

export const TL_NAME = 'TrueLayer'

export const UK_AUTHORITY_NAME = 'Financial Conduct Authority'
export const EU_AUTHORITY_NAME = 'Central Bank of Ireland'
