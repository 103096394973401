import { sendProviderSearchedAnalytics } from '@actions/analytics'
import { useAlternativeFlow, useClientColors, useClientLogo, usePageContext } from '@hooks'
import useStateRestore from '@hooks/useStateRestore'
import { Page } from '@models/IPage'
import useCountries from '@pages/Countries/hooks'
import { getProviderId } from '@utils/helpers'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import debounce from 'lodash.debounce'
import useProviders from './hooks'
import { ThunkDispatch } from 'redux-thunk'
import IStoreState from '@store/IStoreState'
import { Action } from 'redux'
import useProviderSearch from './hooks/useProviderSearch'
import { DefaultPageLayout } from '@layouts'
import { useTranslation } from 'react-i18next'
import { useProcessedList } from '@hooks/useListSearch/utils'
import IProvider from '@models/IProvider'
import { ClientLogo, ViewContainer } from './Providers.styles'
import { CountryPickerButton, ProviderSelect } from '../../end-user-components'

const sendProviderSearchedAnalyticsDebounced = debounce(
  (
    options: Parameters<typeof sendProviderSearchedAnalytics>[0],
    dispatch: ThunkDispatch<IStoreState, void, Action>,
  ) => {
    return dispatch(sendProviderSearchedAnalytics(options))
  },
  1000,
)

const Providers = () => {
  const { providers, onProviderClick, popularProviders, allProviders } = useProviders()
  const { searchTerm, setSearchTerm, filteredProviders } = useProviderSearch(providers)
  const { countries, countrySelected } = useCountries()

  const { changePage } = usePageContext()
  const dispatch = useDispatch()
  const { t } = useTranslation('v2_providers')

  const clientLogo = useClientLogo()
  const { secondaryColor } = useClientColors()

  const items = useProcessedList(filteredProviders, {
    listKey: 'display_name',
    topValues: popularProviders.map((x: IProvider) => x.display_name),
    filterOutTerm: undefined,
  })

  const { ctaCopy, clientHasAlternativeFlow, triggerAlternativeFlow } = useAlternativeFlow()

  const onNoResultCallToAction = () => {
    if (clientHasAlternativeFlow) {
      triggerAlternativeFlow()
    }
    onProviderSearch('') // clear search
  }

  const onProviderSelect = (selectedProvider: IProvider) => {
    // flush the debounce function when provider is selected
    sendProviderSearchedAnalyticsDebounced.flush()

    onProviderClick({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      provider: providers.find(
        (provider) => getProviderId(provider) === selectedProvider.provider_id,
      )!,
      searchTerm,
      filteredProviders,
    })
  }

  const onCountryPickerButtonClick = () => {
    // flush the debounce function when changing country
    sendProviderSearchedAnalyticsDebounced.flush()

    changePage(Page.COUNTRIES)
  }

  const onProviderSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm)
  }

  useEffect(() => {
    sendProviderSearchedAnalyticsDebounced(
      {
        searchTerm,
        filteredProviders,
        allProviders,
        countryProviders: providers,
      },
      dispatch,
    )
  }, [searchTerm])

  useStateRestore(Page.PROVIDERS)

  return (
    <DefaultPageLayout title={t('title')} logo={<ClientLogo src={clientLogo} />}>
      <div data-view="page-id-providers"></div>
      <ViewContainer>
        <ProviderSelect
          onProviderSelect={onProviderSelect}
          providers={items}
          onProviderSearch={onProviderSearch}
          noResultCallToActionOptions={{
            copy: ctaCopy,
            color: secondaryColor,
            action: onNoResultCallToAction,
          }}
          CountryPicker={
            countries.length > 1 ? (
              <CountryPickerButton
                onClick={onCountryPickerButtonClick}
                country={
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  countries
                    .map((country) => ({
                      code: country.code,
                      name: country.displayName,
                      icon_uri: country.logoUrl,
                    }))
                    .find((country) => country.code === countrySelected)!
                }
              />
            ) : undefined
          }
          showNoResultCallToActionBelowList={clientHasAlternativeFlow}
        ></ProviderSelect>
      </ViewContainer>
    </DefaultPageLayout>
  )
}

export default Providers
