export function isOnlyInfoScope(scope: string[]) {
  const INFO_SCOPE_CHUNKS = ['info', 'info:name']

  return scope.every((scopeItem) => INFO_SCOPE_CHUNKS.includes(scopeItem))
}

export function hasSignupPlusScope(scope: string[]) {
  return scope.includes('signupplus')
}

export const hasOnlySignupPlusScope = (scope: string[]) => {
  const scopeWithoutOffline = scope.filter((s) => s !== 'offline_access')

  return scopeWithoutOffline.length === 1 && scopeWithoutOffline[0] === 'signupplus'
}

export const hasOngoingAccess = (scope: string[]) => {
  return scope.includes('offline_access')
}

// Takes an array of strings
// Adds a comma between each element
// Adds "and" before the last element
const syntaxReducer = (
  acc: string,
  curr: string,
  i: number,
  arr: string[],
  t: (key: string) => unknown,
) => {
  // First element
  if (!acc) return curr

  // Last element
  if (i + 1 === arr.length) return `${acc} ${t('list.and')} ${curr.toLowerCase()}`

  // Intermediate element
  return `${acc}, ${curr.toLowerCase()}`
}

// Format output string from list of scopes
export const format = (scopes: string[], t: (key: string) => string) =>
  scopes
    .map((x) => t(`list.${x.replace(':', '__')}`))
    .reduce((acc, curr, i, arr) => syntaxReducer(acc, curr, i, arr, t), '')

export const getProviderScope = ({
  scope,
  providerScopeMappings,
}: {
  scope: string[]
  providerScopeMappings: Record<string, string[] | undefined>
}) => {
  const providerScope = scope.reduce((providerScope, currentScopeItem) => {
    const currentProviderScopeItems = providerScopeMappings[currentScopeItem] ?? []

    const newProviderScopeItems = [
      currentScopeItem, // we always want to include the client scope in the bank scopes
      ...currentProviderScopeItems,
    ]

    return Array.from(new Set([...providerScope, ...newProviderScopeItems]))
  }, [] as string[])

  return providerScope
}
