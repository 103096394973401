import React from 'react'
import { Trans } from 'react-i18next'

import { FooterLink } from '@components'

interface IConsentLegalNoteContentProps {
  /**
   * Link to privacy policy
   */
  privacyLink: string
  /**
   * Link to terms of service
   */
  tosLink: string
  /**
   * Name of the entity operating
   */
  entityName: string
}

function ConsentLegalNoteContent({
  entityName,
  privacyLink,
  tosLink,
}: IConsentLegalNoteContentProps) {
  return (
    <Trans ns="v2_consent" i18nKey="legal_note.default" values={{ entityName }}>
      By continuing you agree to <FooterLink href={tosLink}>Terms of Service</FooterLink> and{' '}
      <FooterLink href={privacyLink}>Privacy Policy</FooterLink>
    </Trans>
  )
}

export default ConsentLegalNoteContent
