export enum Page {
  INITIAL = 'Initial',
  LOGIN = 'Login',
  PROVIDERS = 'Providers',
  COUNTRIES = 'Countries',
  CONSENT = 'Consent',
  AUTHINPUTS = 'AuthInputs',
  MFA = 'Mfa',
  NEXI = 'Nexi',
  LOGIN_ERROR = 'LoginError',
  IOS_REDIRECT = 'IOSRedirect',
  CLIENT_ERROR = 'ClientError',
  INTERACTIVE = 'Interactive',
  OVERVIEW = 'Overview',
  OVERVIEW_V2 = 'OverviewV2',
  CONSENT_RECONFIRMATION = 'ConsentReconfirmation',
  SPLASH_SCREEN = 'SplashScreen',
  BRANCHES = 'Branches',
}

export enum PagePath {
  COUNTRIES = '/countries',
  PROVIDERS = '/providers',
  CONSENT = '/consent',
  AUTH_INPUTS = '/auth-inputs',
  MFA = '/mfa',
  NEXI = '/continue/nexi',
  INTEGRATION = 'request-integration',
  LOGIN = '/login',
  LOGIN_ERROR = '/login-error',
  IOS_REDIRECT = '/ios-redirect',
  CLIENT_ERROR = '/error',
  INTERACTIVE = '/interactive',
  OVERVIEW = '/overview',
  OVERVIEW_V2 = '/overview/v2',
  CONSENT_RECONFIRMATION = '/consent-reconfirmation',
  SPLASH_SCREEN = '/splash-screen',
  BRANCHES = '/branches',
}
