import { DataUseCategory } from '@store/IStoreState'
import { useTranslation } from 'react-i18next'

function useTranslatedDataUse({
  dataUseCategory,
  dataUse,
}: {
  dataUseCategory: DataUseCategory
  dataUse: string
}) {
  const { t } = useTranslation('v2_consent')

  return !dataUseCategory || dataUseCategory === DataUseCategory.None || (dataUseCategory === DataUseCategory.CustomDescription && !dataUse)
    ? t('data_use_category.default')
    : dataUseCategory === DataUseCategory.CustomDescription
    ? dataUse
    : t(`data_use_category.${dataUseCategory}`)
}

export default useTranslatedDataUse
