import React, { PropsWithChildren, ReactNode } from 'react'
import { exclamationTriangleSolid, checkCircleSolid } from '@assets/icons'
import { Container, LeftSide, Message, NotificationIcon } from './styles'

export interface INotificationBannerProps {
  /**
   * The type of notification banner
   */
  type: NotificationType
  /**
   * The text message inside of the notification banner
   */
  message: ReactNode
}

type NotificationType = 'error' | 'success'

const getColors = (type: NotificationType) => {
  switch (type) {
    case 'error':
      return { color: 'rgb(201, 72, 78)', bgColor: 'rgb(247, 228, 229)' }
    case 'success':
      return { color: 'rgb(57, 152, 71)', bgColor: '#e3fae7' }
  }
}

const getIcon = (type: NotificationType) => {
  switch (type) {
    case 'error':
      return exclamationTriangleSolid
    case 'success':
      return checkCircleSolid
  }
}

const NotificationBanner = ({ type, message }: PropsWithChildren<INotificationBannerProps>) => {
  const { color, bgColor } = getColors(type)
  const icon = getIcon(type)

  return (
    <Container color={color} bgColor={bgColor}>
      <LeftSide>
        <NotificationIcon icon={icon} />
        <Message>{message}</Message>
      </LeftSide>
    </Container>
  )
}

export default NotificationBanner
