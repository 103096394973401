import styled from 'styled-components'
import { gap, ContainerStyle } from '@styles'

export const Root = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  ${gap(20, 'px', 'v')}
  margin-top: 50px;
  text-align: center;
  ${ContainerStyle}
`

export const Action = styled.div<{ color: string; hoverColor: string }>`
  font-size: 16px;
  color: ${(props) => props.color};
  :hover {
    text-decoration: underline;
    color: ${(props) => props.hoverColor};
  }
  cursor: pointer;
`
