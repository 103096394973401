import React, { useState, FC } from 'react'
import { IInputOption } from '@models/IAuthInput'
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faPhoneVolume } from '@fortawesome/pro-solid-svg-icons/faPhoneVolume'
import { faCommentAltDots } from '@fortawesome/pro-solid-svg-icons/faCommentAltDots'
import { Root, ChoiceContainer, RadioButtonIcon, ChoiceRoot } from '../styles'
import { ChoiceValue, ChoiceIcon } from './styles'

interface ITextChoiceProps {
  challengeId: string
  options: IInputOption[]
  saveAnswer: (id: string, answer: string | undefined) => void
}

const TextChoice: FC<ITextChoiceProps> = ({ challengeId, options, saveAnswer }) => {
  const [choice, setChoice] = useState<string>()

  const onChoiceClick = (choice: string) => {
    setChoice(choice)
    saveAnswer(challengeId, choice)
  }

  const renderChoiceIcon = (value: string) => {
    if (value.includes('email')) {
      return <ChoiceIcon icon={faEnvelope} />
    }
    if (value.includes('text')) {
      return <ChoiceIcon icon={faCommentAltDots} />
    }
    if (value.includes('voice') || value.includes('call')) {
      return <ChoiceIcon icon={faPhoneVolume} />
    }
    return null
  }

  const renderChoice = (option: IInputOption) => {
    const { key, value } = option
    const selected = choice === key

    return (
      <ChoiceRoot
        key={key}
        // eslint-disable-next-line no-console
        onClick={() => console.log('close error notification!')}
      >
        <ChoiceContainer
          onClick={() => onChoiceClick(key)}
          selected={selected}
          selectedColor="black"
        >
          <ChoiceValue>
            {renderChoiceIcon(value.toLowerCase())}
            <div>{value}</div>
          </ChoiceValue>
          <RadioButtonIcon
            icon={selected ? faCheckCircle : faCircle}
            selected={selected}
            selectedColor="black"
          />
        </ChoiceContainer>
      </ChoiceRoot>
    )
  }

  return <Root>{options.map((opt) => renderChoice(opt))}</Root>
}

export default TextChoice
