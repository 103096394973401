import React, { useState } from 'react'
import { useNestedTranslation, useViewport } from '@hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, timesRegular } from '@assets/icons'
import { Trans } from 'react-i18next'
import { CloseIcon, Container, EmailLink, Message, Title, Warning } from './styles'

const PlanBanner = () => {
  const [isDisplayed, setIsDisplayed] = useState(true)
  const t = useNestedTranslation('v2_consent', ['unauthorised_plan_banner'])

  const getTitle = () => t('title')

  const { isMobile } = useViewport()
  function renderIcon() {
    return !isMobile ? (
      <FontAwesomeIcon icon={faExclamationTriangle} color="#ffffff" size="2x" />
    ) : null
  }

  return isDisplayed ? (
    <Container data-view="unauthorised-plan-banner">
      {renderIcon()}
      <Warning>
        <div>
          <Title>{getTitle()}</Title>
          <Message>
            <Trans ns="v2_consent" i18nKey="unauthorised_plan_banner.message">
              This application has not been authorised for production use. Please do not enter your
              bank credentials if you are not testing this application. To remove this banner,
              please reach out to
              <EmailLink
                href="mailto:sales@truelayer.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                sales@truelayer.com
              </EmailLink>
              .
            </Trans>
          </Message>
        </div>
        {isMobile ? null : (
          <CloseIcon
            data-view="button-close-banner"
            aria-label="close banner"
            icon={timesRegular}
            onClick={() => {
              setIsDisplayed(false)
            }}
          />
        )}
      </Warning>
    </Container>
  ) : null
}

export default PlanBanner
