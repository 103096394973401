export interface ICountry {
  code: string
  displayName: string
  logoUrl: string
}

export enum Region {
  UK = 'uk',
  EU = 'eu',
}
