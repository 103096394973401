/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { IAuthInput } from '@models/IAuthInput'
import { ClearTextInputValue, MultiClearTextInputValue, SingleChoiceInputValue } from './components'

interface IAuthInputProps {
  authInput: IAuthInput
  saveData: (key: string, val: string) => void
}

const AuthInput = ({ authInput, saveData }: IAuthInputProps) => {
  const renderClearTextInputValue = (input: IAuthInput) => {
    const { id, clear_text_input_value } = input
    const { is_sensitive, allowed_characters, validation_rules, placeholder_value, default_value } =
      clear_text_input_value!

    return (
      <ClearTextInputValue
        id={id}
        validations={validation_rules}
        isSensitive={is_sensitive}
        allowedCharacters={allowed_characters}
        saveData={saveData}
        placeholder={placeholder_value}
        defaultValue={default_value}
      />
    )
  }

  const renderMultiClearTextInputValue = (input: IAuthInput) => {
    const { id, multi_clear_text_input_value } = input
    const { is_sensitive, allowed_characters, validation_rules } = multi_clear_text_input_value!
    return (
      <MultiClearTextInputValue
        id={id}
        validations={validation_rules}
        isSensitive={is_sensitive}
        allowedCharacters={allowed_characters}
        saveData={saveData}
      />
    )
  }

  const renderSingleChoiceInputValue = (input: IAuthInput) => {
    const { id, single_choice_input_value } = input
    const { options } = single_choice_input_value!
    return <SingleChoiceInputValue id={id} options={options} saveData={saveData} />
  }

  switch (authInput.input_value_case) {
    case 'ClearTextInputValue':
      return renderClearTextInputValue(authInput)

    case 'MultiClearTextInputValue':
      return renderMultiClearTextInputValue(authInput)

    case 'SingleChoiceInputValue':
      return renderSingleChoiceInputValue(authInput)

    default:
      return null
  }
}

export default AuthInput
