import { detect } from 'detect-browser'

const UNSUPPORTED_BROWSERS = ['ie']

// This code should run on IE11
export const supportedBrowser = () => {
  const browser = detect()
  if (browser && UNSUPPORTED_BROWSERS.filter((name) => name === browser.name).length > 0) {
    return false
  }
  return true
}
