import styled from 'styled-components'
import { IELlipsisStyleProps } from './Ellipsis'

export const Root = styled.div<IELlipsisStyleProps>`
  display: flex;
  // Cannot use gap function here
  > *:not(:first-child) {
    margin-left: ${(props) => props.gap}px !important;
  }
  margin-bottom: ${(props) => props.marginBottom}px;
`
