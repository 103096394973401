import { useState, useEffect } from 'react'

const useLongWait = () => {
  const [isLongWait, setIsLongWait] = useState<boolean>(false)
  // Set long wait = true after 10 seconds
  useEffect(() => {
    const longWaitTimeout = setTimeout(() => {
      setIsLongWait(true)
    }, 10000)

    return () => clearTimeout(longWaitTimeout)
  }, [])

  return isLongWait
}

export default useLongWait
